.at-analytic-centre__mode-toggle {
  display: flex;
  font-weight: 500;
  font-size: 14px;

  .at-toggle-slider {
    background-color: #bfdbfe !important;
    border: 2px solid #3b82f6;
  }

  .at-toggle-slider:before {
    height: 14px;
    width: 14px;
    left: 2px;
    bottom: 1px;
    background-color: #3b82f6 !important;
  }

  .at-toggle__input:checked + .at-toggle-slider {
    background-color: #bfdbfe !important;
  }
}
