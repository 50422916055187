.at-drive-search_title {
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;

  @media (max-width: 991px) {
    max-width: calc(100vw - 32rem);
  }

  @media (max-width: 767px) {
    max-width: calc(100vw - 18rem);
  }

  @media (max-width: 449px) {
    max-width: calc(100vw - 6rem);
  }
}

.at-drive-search_result {
  .at-drive_table-top_bar-path_name {
    @media (max-width: 899px) {
      > div {
        white-space: nowrap;
      }
    }
    @media (max-width: 449px) {
      > div {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .at-drive_table-top_bar-row_item-directory_path {
    @media (max-width: 449px) {
      grid-row: 2;
    }
  }

  .at-drive_table-top_bar-row_item-layout_buttons {
    @media (max-width: 449px) {
      grid-row: 1;
    }
  }
}
