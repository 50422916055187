.at-toggle__small {
  width: 30px;
  height: 20px;
  .at-toggle-slider {
    background-color: #dcdcdc;
  }
  .at-toggle-slider:before {
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
  }
  .at-toggle__input:checked + .at-toggle-slider {
    background-color: #f0631d;
    border: 1px solid #f0631d;
  }

  .at-toggle__input:focus + .at-toggle-slider {
    box-shadow: 0 0 1px #dbdbdb;
  }
  .at-toggle__input:checked + .at-toggle-slider:before {
    -webkit-transform: translate(15px, 1px);
    -ms-transform: translate(15px, 1px);
    transform: translate(15px, 1px);
  }
}

.at-hide-agent {
  display: flex;
  align-items: end;
  height: fit-content;
  color: #1f2937;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;

  .tag {
    margin-left: 10px;
    font-size: 10px;
    display: flex;
    font-weight: 500;
    padding: 2px 6px;
    background-color: #ffc800;
    border-radius: 5px;
    align-items: center;
  }
}
