.footer {
  background-color: $white-color;
  width: 100%;

  .footer-menu {
    height: 41px;
  }

  nav > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-weight: normal;

    a:not(.btn) {
      color: $dark-gray;
      display: block;
      margin-bottom: 3px;
      &:hover,
      &:focus {
        color: $default-states-color;
      }
    }
  }
  .social-area {
    padding: 15px 0;
    h5 {
      padding-bottom: 15px;
    }
  }
  .social-area > a:not(.btn) {
    color: $dark-gray;
    display: inline-block;
    vertical-align: top;
    padding: $padding-social-a;
    font-size: $font-size-large-navbar;
    font-weight: normal;
    line-height: $line-height;
    text-align: center;
    &:hover,
    &:focus {
      color: $default-states-color;
    }
  }
  .copyright {
    color: $default-states-color;
    padding: 10px 15px;
    margin: 10px 3px;
    line-height: 20px;
    font-size: $font-size-base;
  }
  hr {
    border-color: $medium-gray;
  }
  .title {
    color: $default-states-color;
  }
}

.footer-default {
  background-color: $smoke-bg;
}

.footer:not(.footer-big) {
  nav > ul {
    font-size: $font-size-base;
    li {
      margin-left: 20px;
      float: left;
    }
    a {
      padding: 10px 0px;
      margin: 10px 10px 10px 0px;
    }
  }
}

.full-page + .footer li a,
.full-page + .footer .copyright a {
  color: #fff !important;
}
