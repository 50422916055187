@import "../../atlas/prerequisite";

.profile-card {
  padding: 12px;
  width: 100%;
  border: 1px lightgray solid;

  @include min-screen(600px) {
    max-width: 550px;
  }
  @include max-screen(992px) {
    max-width: 400px;
    text-align: center;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 7px;
    font-size: 14px;
  }
}

.cobroke-agent {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0px;
  gap: 12px;

  img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    object-fit: contain;
  
  }

  @media (max-width: 992px) {
    justify-content: center;
    image { margin: 0 auto; }
  }
}
