@import "stylesheets/index.scss";

.react-datepicker-popper {
  z-index: 10 !important;
  top: initial;
  left: initial;
  font-size: 14px;
}

.react-datepicker-wrapper {
  display: block !important;

  .react-datepicker__year-dropdown-container {
    font-size: 14px;
  }

  .react-datepicker__year-read-view--down-arrow {
    top: 6px !important;
  }
}

.register-datepicker-form-control {
  height: 40px;
  margin-bottom: 0 !important;

  input {
    cursor: pointer;
    width: 100%;
  }

  svg {
    margin-left: auto;
    width: 20px;
    height: 20px;
  }
}

.react-lottie {
  margin: auto;
  width: 14%;
  @include max-screen(600px) {
    width: 40%;
  }
}

.logout-container {
  position: absolute;
  right: 20px;
  @include min-screen(600px) {
    top: 20px;
  }
}

.submit-button {
  background-color: #ee8e0d;
  color: #000;

  :disabled {
    color: grey;
    background-color: #fff;
  }
}

#stepper-card {
  svg[class*="MuiStepIcon-active-"],
  svg[class*="MuiStepIcon-completed-"] {
    color: #f0631d !important;
  }
}

.welcome-stepper-icon-container {
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  width: 60px;
  height: 60px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #f97316 0%,
    rgba(255, 247, 237, 0) 100%
  );

  .welcome-stepper-atlas-icon {
    width: 44px;
    height: 44px;
    padding: 12px;
    gap: 8px;
    fill: #f0631d;
  }

  .welcome-stepper-board {
    background: var(--General-White, #fff);
    border-radius: 999px;
  }
}

.welcome-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcome-left-panel {
  @media (min-width: 960px) {
    margin-right: 1.5rem !important;
  }
}
