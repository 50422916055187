body {
  font-family: Helvetica, sans-serif;
}

.react-autosuggest__container {
  position: relative;
  width: 100%;
}

.react-autosuggest__input {
  width: 100%;
  height: 48px;
  padding: 10px 20px;
  font-size: 14px;
  border: 1px solid #9ca3af;
  border-radius: 8px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 40px;
  width: calc(100% + 20px);
  margin-left: -10px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 14px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  max-height: 300px;
  overflow-y: scroll;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.at-autosuggest__required {
  position: absolute;
  font-size: 10px;
  font-style: italic;
  font-weight: 500;
  bottom: -15px;
  right: 6px;
  color: #a0a0a0;
}

.at-autosuggest__disabled {
  background-color: #e0e0e0;
  color: #c0c0c0;
}