@import "../../stylesheets/atlas/prerequisite";

.register-container {
  .at-checkbox {
    margin: 0;

    .at-checkbox__span-box {
      margin: 0;
    }
  }

  .register-container__spinner {
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -12px;
    z-index: 1;
  }

  .at-form-input__label {
    display: none;
  }

  .register-container__feedback {
    display: block;
    font-size: 12px;

    * {
      font-size: 12px;
    }
  }
}

.step-counters-stepper-header {
  display: flex;
  justify-content: center;
  margin-top: 15px !important;
  padding: 10px 0 !important;
  color: #6b7280;
  background-color: #f3f4f6 !important;

  .at-header__angle {
    margin: 3px 10px 3px 10px;
    color: #6b7280;
  }
}

.step-counters-stepper-header-active {
  color: #f0631e !important;
}

.register-stepper-icon-container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  margin-top: 12px;
  margin-bottom: 16px;
  width: 60px;
  height: 60px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #f97316 0%,
    rgba(255, 247, 237, 0) 100%
  );

  .register-stepper-atlas-icon {
    width: 44px;
    height: 44px;
    padding: 12px;
    gap: 8px;
    fill: #f0631d;
    border-radius: 999px;
    background: var(--General-White, #fff);
  }
}

.register-mobile-no-alert-box {
  color: #f0631d !important;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.register-team-info-full-name {
  text-transform: capitalize !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 600 !important;
}

.register-team-info-email {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.register-team-info-team {
  color: #f0631d !important;
  font-size: 14px !important;
  font-weight: 400 !important;

  .register-team-info-highlight {
    font-weight: 700 !important;
  }
}

.register-card {
  padding: 24px !important;
  border-radius: 16px !important;
  border: 1px solid #d1d5db;
  background: #fff;

  /* Shadow/Medium */
  box-shadow:
    0px 1px 1px 0px rgba(0, 0, 0, 0.08),
    0px 2px 1px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.register-verification {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  gap: 8px;
  flex-wrap: wrap;

  @media screen and (max-width: 576px) {
    flex-direction: column;

    .btn-new {
      width: 100%;
    }
  }
}