@import "../../stylesheets/atlas/prerequisite";
@import "src/assets/stylesheets/new_atlas/index.scss";

.at-login-background,
.login-container {
  @media (max-height: 568px) {
    height: 107vh !important;
  }
}

.at-login__header {
  text-align: center;
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-height: 700px) {
    margin-top: 4%;
  }
}

.at-login-header-language__container {
  width: 100%;
  max-width: 600px;
  justify-content: center;

  @media screen and (max-width: 992px) {
    padding: 15px 20px;
  }
}

.at-login__banner {
  width: 30%;
  max-width: 400px;
  height: 100%;
  position: relative;
  background: linear-gradient(164.35deg, #f0631d 0%, #f03864 100%);

  .at-login__banner-header {
    padding: 52px 24px;
  }

  p {
    font-size: 32px;
    font-weight: 500;
    line-height: 43.2px;
    color: white;
    width: 250px;
  }
}

.at-login__banner-slider {
  position: absolute;
  width: 100%;
  bottom: 0px;
  right: 0px;

  @media screen and (max-width: 992px) {
    margin: auto;
    width: 100%;
    position: inherit;
    transform: translateY(-30px);

    img {
      max-width: 100vw;
    }
  }
}

.at-login__footer {
  width: 100%;

  p {
    text-align: center;
    font-size: 12px;
    max-width: 650px;
    margin: auto;
    color: #6b7280;
  }

  @media screen and (max-width: 992px) {
    position: relative;
  }
}

.at-language_badge {
  width: fit-content !important;
  height: fit-content;
  display: flex;
  align-items: center;
  color: #1f2937;
  max-width: 200px;
  font-weight: 500;
  padding: 4px 8px 4px 0px;
  border-radius: 15px;
  border: 1px solid #d1d5db;

  span {
    display: flex;
  }
  
  p {
    font-size: 12px;
  }

  @media (min-width: 1500px) {
    font-size: 14px;
  }

  @media (max-width: 767px) {
    font-size: 12px;
    width: 150px;
    margin: 0px;
  }
}

.at-language__dropdown {
  cursor: pointer;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 0px 5px 5px #0000000d;
  position: absolute !important;
  margin-top: 40px;
  padding-bottom: 5px;
  min-width: 300px;
  height: fit-content;
  right: auto;
  z-index: 3;
  border: 1px solid $gray-300;
}