.at-presentation-statistic_showcase {
  display: inline-flex;
  width: 100%;
  gap: 12px;

  @media screen and (max-width: 720px) {
    display: block;
  }

  label {
    font-size: 14px;
    color: #374151;
  }

  p {
    font-size: 20px;
    color: #111827;
  }
}

.at-presentation-statistic_table,
.at-presentation-statistic_table-content_cont {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;

  .grid-controller {
    grid-template-columns: 1fr 1fr !important;
  }
}

.at-presentation-statistic_table {
  > .at-presentation-statistic_table-header_cont .at-table-row.at-table-head {
    background-color: #f3f4f6;
    border-radius: 8px;
    border: 0px;
  }

  > .at-presentation-statistic_table-content_cont > .at-table-row-cont {
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 0px;

    p {
      margin-top: 0px;
    }
  }

  .at-table-row__item-label {
    font-weight: 600;
    top: 10px;
  }

  .at-table__0 {
    p {
      font-weight: 500;
    }
  }

  @media (min-width: 992px) {
    > .at-presentation-statistic_table-content_cont > .at-table-row-cont {
      background-color: #fff !important;
    }
  }
  @media (max-width: 991px) {
    .at-table-row__item {
      padding-top: 32px;
    }
    .at-table__0 {
      grid-column: 1/-1;
      padding-top: 0;

      .at-table-row__item-label {
        display: none;
      }
    }
    .at-table-row-cont__divider {
      border-top: 1px solid #d1d5db;
    }
    > .at-presentation-statistic_table-content_cont > .at-table-row-cont:hover {
      background-color: white !important;
    }
  }
}

.at-presentation-statistic_table-second_layer {
  @media (min-width: 992px) {
    .at-table__0 {
      padding-left: 2.63rem !important;
      width: calc(100% - 2rem) !important;
    }
  }

  @media (max-width: 991px) {
    .at-table-row-cont {
      border-radius: 8px;
      background-color: #f3f4f6 !important;
      margin: 0rem 1rem 1rem;
      box-shadow: initial;
      border: 0px;
      padding-left: 0;
    }
  }
}

.at-presentation-stat-collapse_button {
  width: 20px;
  height: 20px;
  padding: 3px !important;
  background-color: #3983f6 !important;
  border-radius: 50% !important;
  margin-right: 0.5rem !important;

  @media (max-width: 991px) {
    align-self: flex-start;
    width: 23px;
    height: 23px;
    margin-top: 0.2rem !important;
  }
}
