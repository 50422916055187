.at-localised-center_table-title {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;

  @media screen and (max-width: 989px) {
    padding: 0px;
    padding-bottom: 16px;
    flex-wrap: wrap;
  }
}

.at-localised-center__upload-list {
  .at-input_upload-label {
    font-size: 14px;
    font-weight: 500;

    &::after {
      content: 'CSV, XLS, XLSX';
      font-size: 14px;
    }
  } 
}