@import "../../atlas/prerequisite";

.at-profile,
.at-admin-profile {
  display: grid;
  gap: 12px;
  grid-template-rows: repeat(2, auto);

  h4 {
    color: #111827;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 130%;
  }

  label {
    color: var(--text-900, #111827);
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
  }

  span,
  p {
    color: #111827;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.at-profile {
  grid-template-columns: repeat(8, 1fr);

  @include max-screen(1024px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, auto);
    gap: 16px;
  }

  .at-profile__agent-cont {
    grid-area: 1 / 1 / 3 / 3;

    @include max-screen(1024px) {
      grid-area: 1 / 1 / 2 / 4;
    }
  }

  .at-profile__agent-details {
    grid-area: 2 / 3 / 3 / 9;

    @include max-screen(1024px) {
      grid-area: 4 / 1 / 5 / 4;
    }
  }
}

.at-admin-profile {
  grid-template-columns: repeat(8, 1fr);

  @include max-screen(1400px) {
    grid-template-columns: 1fr 1fr 1fr repeat(5, 140px);
  }

  @include max-screen(1024px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, auto);
    gap: 16px;
  }

  .at-profile__agent-cont {
    grid-area: 1 / 1 / 3 / 4;
  }

  .at-profile__agent-details {
    grid-area: 2 / 4 / 3 / 9;

    @include max-screen(1024px) {
      grid-area: 5 / 1 / 6 / 4;
    }
  }
}

.at-profile__agent-action,
.at-profile__public-profile-action {
  display: flex;
  padding: 12px !important;
  border: 1px solid #d1d5db;
  background-color: white;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  svg {
    width: 36px;
    height: 36px;
    padding: 8px;
    fill: white;
    color: white;
    border-radius: 8px;
  }

  .at-party__dropbear--modal-root {
    top: 100px !important;
  }
}

.at-profile__public-profile-action {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
}

.at-profile__agent-cont {
  display: flex;
  gap: 16px;
  margin-bottom: 0px !important;
  height: fit-content;
  flex-direction: column;
  align-items: center;

  .at-card--profile_card-img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 50%;
  }

  .at-profile__agent-info {
    display: flex;
    flex-direction: column;
    gap: 8px;

    svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      color: #f0631d;
    }
  }

  .at-profile__agent-contact {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    svg {
      width: 20px;
      height: 20px;
      min-width: 20px;
    }

    span {
      width: 100%;
      text-align: center;
    }

    > div {
      display: flex;
      gap: 8px;
      width: 100%;
      min-height: 40px;
      cursor: pointer;
      padding: 8px 12px;
      align-items: center;
      border-radius: 25px;
      border: 1px solid #d1d5db;
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
    }
  }

  @include max-screen(1024px) {
    grid-area: 1 / 1 / 2 / 4;

    .at-profile__agent-contact {
      flex-direction: row;
      justify-content: center;

      > div {
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
        padding-left: 5px;
        justify-content: flex-start;
        border-radius: 50%;
        overflow: hidden;
      }

      .at-profile__agent-wechat {
        width: fit-content;
        border-radius: 25px;
        padding: 6px 10px;
      }

      span {
        display: none;
      }
    }
  }
}

.at-profile__change-password {
  width: 100%;
  padding: 20px 50% 20px 20px !important;

  @include max-screen(1024px) {
    padding: 20px !important;
  }
}

.at-profile__digital-signature {
  img {
    width: 300px;
    min-width: 300px;
    height: 140px;
    min-height: 140px;
    object-fit: cover;
    border-radius: 8px;
  }

  .at-profile__digital-signature-delete {
    border-radius: 50%;
    border: 1px solid #d1d5db;
    padding: 4px;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.at-card--profile__img-cont {
  border-radius: 5px;
  overflow: hidden;
  width: 125px;
  height: 125px;
  background-color: $grey-1;
  margin: 0px 10px 10px 0px;

  @include min-screen(600px) {
    width: 150px;
    height: 150px;
    margin: 0px 10px 10px 0px;
  }

  > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.at-card--profile__main {
  margin-bottom: 20px;
  text-align: center;

  @include min-screen(600px) {
    text-align: left;
  }
}

.at-card--profile_card {
  position: relative;
  margin: -5px 0px -2px 0px;

  .at-card--profile_card-img {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include max-screen(1000px) {
      background: #38474f;
      text-align: center;
    }

    @include min-screen(1000px) {
      left: 20px;
      position: absolute;
      background: transparent !important;
    }
    img {
      width: 130px;
      height: 130px;
      border-radius: 50%;
      object-fit: cover;
      border: 4px solid #ffff;
    }
  }

  .at-card--profile_card-top {
    padding: 12px 12px 12px 180px;
    background: #38474f;
    color: #ffff;

    @include max-screen(1000px) {
      padding: 12px;
    }
  }

  .at-card--profile_card-btm {
    background: #ffff;
    min-height: 120px;
    padding: 12px 12px 0px 180px;

    @include max-screen(1000px) {
      padding: 12px 12px 0px 12px;
    }
  }

  .at-card--profile_card-btn {
    @include min-screen(1000px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.at-card--profile-imgs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include min-screen(600px) {
    justify-content: flex-start;
  }
}

.at-card--profile__title {
  @include at-font(500);
  font-size: 31px;
}

.at-card--profile__subtitle {
  @include at-font(400);
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-block;
  margin-right: 10px;
}

.at-card--profile-card {
  padding: 30px;
  border-radius: 7px;
  background-color: #ffff;
}

.at-card--profile-list {
  display: grid;
  row-gap: 12px;
  column-gap: 16px;
  grid-template-columns: repeat(12, 1fr);
}

.at-card--profile-item {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  &:last-child {
    border: 0px;
  }

  @include min-screen(600px) {
    border: 0px;
  }
}

.at-card--profile-item-title {
  @include at-font(500);
  font-size: 14px;
  margin-bottom: 0.3rem;
}

.at-card--profile-header {
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    fill: $limed-spruce;
    margin-right: 5px;
  }
}

.at-card--profile-svg {
  svg {
    width: 20px;
    height: 20px;
    fill: $limed-spruce;
    margin-right: 5px;
  }
}

.grid-2-col,
.grid-3-col,
.grid-full-col {
  grid-column: 1/-1;
}

.grid-2-col {
  @include min-screen(600px) {
    grid-column: span 6;
  }
}

.grid-3-col {
  @include min-screen(600px) {
    grid-column: span 4;
  }
}
