.at-submitted-sales-report__group-sales {
  display: flex;
  align-items: center;
  margin: 8px 0px;
  width: 50%;
  padding: 12px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.at-submitted-sales-report__form-tab {
  border-bottom: 0px;
  margin-bottom: 0px;
  padding: 16px 16px 0px;

  @media screen and (max-width: 991px) {
    margin-bottom: 16px;
  }
}

.at-submitted-sales-report__form-header {
  width: 100%;
  padding: 12px 16px;
  border-bottom: 1px solid #d1d5db;

  @media screen and (max-width: 991px) {
    border-bottom: 0px;
    padding: 16px 0px;
    padding-top: 0px;
  }
}
