.at-leader-team-sales__agent-cont {
  border: 2px solid #f3f4f6;
  border-radius: 8px;
  margin: 10px 0px;
}

.at-leader-team-sales__agent {
  display: grid;
  grid-auto-rows: 25px;
  grid-template-columns: repeat(3, 1fr);
  min-height: 280px;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
