.at-card-heading {
  font-weight: 600;
  display: block;
  font-size: 1.3rem !important;
  word-break: break-all;
}

[class^="at-card-title"] {
  font-weight: 600;
  display: block;
  word-break: break-all;
  color: #f0631d;
}

.at-card-title {
  &-1 {
    font-size: 1.25rem;

    @media (max-width: 575px) {
      font-size: 1.125rem !important;
    }
  }
  &-2 {
    font-size: 1.125rem;

    @media (max-width: 575px) {
      font-size: 1rem;
    }
  }
}
