.new-agent-approval__additional-details-card {
  display: -ms-grid;
  display: grid;
  gap: 16px;
  padding: 16px;

  & .new-agent-approval__additional-detail-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 12px;
  }

  & > :last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  @media screen and (min-width: 768px) {
    -ms-grid-columns: 1fr 16px 1fr;
    grid-template-columns: repeat(2, 1fr);

    & > :nth-child(13) {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  @media screen and (min-width: 992px) {
    -ms-grid-columns: 1fr 16px 1fr 16px 1fr;
    grid-template-columns: repeat(3, 1fr);

    & > :nth-child(13) {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}

.new-agent-approval__refferal-admin {
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media screen and (max-width: 800px) {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }
}

.new-agent-approval__payment-proof {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .new-agent-approval__payment-proof-btn {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 8px 12px;
    margin: 4px !important;
    justify-content: center;
    align-items: center;
    border-radius: 999px;
    border: 1px solid #d1d5db;
    background: #ffffff;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);

    svg {
      display: flex;
      width: 20px;
      height: 20px;
      fill: #3b82f6;
      justify-content: center;
      align-items: center;
    }

    @media screen and (max-width: 800px) {
      display: none;
    }
  }
}

.new-agent-approval__reject-reason {
  display: flex;
  padding: 4px 4px 4px 8px;
  border-radius: 4px;
  color: #1f2937;
  width: 100%;
  font-size: 14px;
  line-height: 150%;
  border-left: 3px solid #f04438;
  background: #f3f4f6;
}

.new-agent-approval__form-tab {
  margin: 0px;
  padding: 16px 16px 0px;

  @media screen and (max-width: 991px) {
    margin: 0px;
    margin-bottom: 16px;
  }
}
