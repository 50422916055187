@import "../../atlas/prerequisite";
@import "../styles.scss";

.at-checkbox {
  display: inline-flex;
  cursor: pointer;
  margin: 5px;
  align-items: center;

  &:hover {
    .at-checkbox__tick {
      fill: rgba(#3b82f6, 0.2);
    }
  }

  &:active {
    .at-checkbox__span-box {
      border: 1px solid #3b82f6;
      box-shadow: inset 0px 0px 0px 1px rgba(#3b82f6, 1);
    }
  }
}

.at-checkbox-disabled {
  display: inline-flex;
  cursor: default;
  margin: 5px;
  align-items: center;

  .at-checkbox__span-box {
    background: #e5e7eb;
  }

  .at-checkbox__input:checked {
    ~ .at-checkbox__span-box {
      border: 1px solid #d1d5db;
      background-color: #d1d5db;

      .at-checkbox__tick {
        fill: $white;
      }
    }
  }
}

.at-checkbox__input {
  display: none;
  cursor: pointer;

  &:checked {
    ~ .at-checkbox__span-box {
      border: 2px solid #3b82f6;
      background-color: #3b82f6;

      .at-checkbox__tick {
        fill: $white;
      }
    }
  }
}

.at-checkbox__span-box {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin: auto;
  border: 1px solid #9ca3af;
  background-color: $white;
  width: 16px;
  height: 16px;
  min-width: 16px;
}

.at-checkbox__tick {
  width: 10px;
  height: 10px;
  fill: transparent;
}

.at-checkbox__label-text {
  flex: 1;
  font-size: 14px;
  line-height: 16px;
  user-select: none;
}

.at-divider-dot {
  display: block;
  width: 10px;
  height: 10px;
  min-width: 10px;
  border-radius: 50%;
  margin-right: 5px;
  content: "";
}
