@import "../../../atlas/prerequisite";

.at-hierarchy__title {
  font-size: 23px;
  @include at-font(500);
  margin: 20px 0px 10px 0px;

  &:first-of-type {
    margin: 0px 0px 10px 0px;
  }
}

.at-hierarchy__control-panel {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;

  @include max-screen(576px) {
    padding: 20px;
    background: #ffffff;
    border: 1px solid #d1d5db;
    box-shadow:
      0px 1px 1px rgba(0, 0, 0, 0.08),
      0px 2px 1px rgba(16, 24, 40, 0.06),
      0px 1px 3px rgba(16, 24, 40, 0.1);
    border-radius: 8px;
    margin-bottom: 12px;
  }
}

.at-hierarchy__filter-cont {
  background: white;
  width: 100%;
  z-index: 1;
  padding: 12px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border: 1px solid #d1d5db;

  @include max-screen(576px) {
    background: transparent;
    border-color: transparent;
  }
}

.at-hierarchy__filter {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40px, max-content));
  row-gap: 16px;
  column-gap: 10px;
  grid-auto-flow: row;
}

.at-hierarchy__head {
  display: flex;
  border: 1px solid #d1d5db;
  background: white;
  color: black;
  overflow: visible;
  padding: 0px 12px;
  margin-top: -1px;

  .at-hierarchy-row__item {
    position: relative;
    word-break: break-word;
    padding: 10px;
    min-width: 65px;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: #4b5563;

    &:not(:first-child) {
      width: 130px;
      min-width: 130px;
    }

    &:last-child {
      width: 70px;
      min-width: 70px;
    }
  }

  @media screen and (max-width: 850px) {
    display: none;
  }
}

.at-hierarchy__container {
  width: 100%;
  background: white;
  padding: 0px 12px;
  margin-top: -1px;
  border: 1px solid #d1d5db;

  @include max-screen(576px) {
    background: transparent;
    border-color: transparent;
  }
}

.at-hierarchy__agent-display {
  display: flex;
  align-items: center;

  .svg-icon-team {
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }
}
