.at-badge-icon {
  width: 40px;
  height: 40px;
  min-width: 40px;
  padding: 8px;
  display: flex;
  border-radius: 50%;
}

.at-badge-icon-lg {
  width: 48px;
  height: 48px;
  min-width: 48px;
  padding: 8px;
  display: flex;
  border-radius: 50%;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
}

.at-badge-icon-sm {
  width: 32px;
  height: 32px;
  min-width: 32px;
  padding: 8px;
  display: flex;
  border-radius: 50%;
  margin-right: 10px;
}

.at-badge-icon-primary {
  background-color: #dbeafe;
  svg {
    fill: #1d4ed8;
  }
}

.at-badge-icon-warning {
  background-color: #ffedd5;
  svg {
    fill: #f0631d;
  }
}

.at-badge-icon-orange {
  background-color: #ffedd5;
  svg {
    fill: #c2410c;
  }
}

.at-badge-icon-gray {
  background-color: #d1d5db;
  svg {
    fill: #374151;
  }
}

.at-badge-icon-light-gray {
  background-color: #e5e7eb;
  svg {
    fill: #6b7280;
  }
}

.at-badge-icon-yellow {
  background-color: #fef0c7;
  svg {
    fill: #f79009;
  }
}
