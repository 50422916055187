@import "../../../atlas/prerequisite";

.at-recruit__card-stat-first {
  display: flex;
  align-items: center;
  justify-content: center;

  @include max-screen($sidebar-breakpoint) {
    justify-content: start;
  }
}

.at-recruit__card-stat2 {
  display: grid;
  gap: 12px;
  padding: 16px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  align-self: stretch;
  border-radius: 8px;
  background: radial-gradient(
    238.45% 113.14% at 101.85% 93.53%,
    #d92d20 0%,
    #ea580c 100%,
    #ea580c 100%
  );

  > div {
    margin-bottom: 0px;
  }

  .at-form__content:first-child {
    grid-row: 1 / 3;
  }

  @include max-screen($sidebar-breakpoint) {
    display: block;

    > div {
      margin-bottom: 10px;
    }
  }
}

.at-recruit__card-stat3 {
  display: grid;
  gap: 20px;
  padding: 20px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  align-self: stretch;
  border-radius: 8px;
  background-color: #f3f4f6;

  > div:nth-child(1),
  > div:nth-child(2) {
    border-bottom: 1px solid #d1d5db;
  }

  label,
  span {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: #111827;
    margin-bottom: 2px;

    p {
      font-size: 14px;
      color: #374151;
      min-width: 100px;
      width: 100px;
    }
  }

  @include max-screen($sidebar-breakpoint) {
    gap: 0px;
    padding: 16px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, auto);

    > div:not(:last-child) {
      border-bottom: 1px solid #d1d5db;
    }
  }
}
