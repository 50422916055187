.at-img_container-fit_size {
  width: fit-content !important;
  height: fit-content !important;
}

.at-img_display-full_size {
  height: fit-content !important;
  max-height: 375px !important;
  width: auto !important;
  object-fit: cover;
}

.upload-icon-container {
  display: inline-flex;
  right: 5px;
  top: 5px;
  border-radius: 999px;
  width: 40px;
  height: 40px;

  .upload-atlas-icon {
    fill: #f04438;
    background: var(--General-White, #fff);
    border-radius: 999px;
  }
}
