.at-modal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: $zindex-modal;
  background-color: rgba($mirage, 0.9);
  color: $atlas-white;
  padding: 20px;
  overflow: auto;

  @include min-screen($recruit-strategy-bp) {
    padding: 50px;
  }
}

.at-modal-body {
  position: relative;
  height: 100%;
  width: 100%;
}

.at-modal-close {
  position: absolute;
  top: -5px;
  right: -5px;
  fill: $atlas-white;
  cursor: pointer;
  width: 25px;
  height: 25px;

  &:hover {
    fill: darken($atlas-white, 10%);
  }

  @include min-screen(600px) {
    top: -20px;
    right: -20px;
    width: 34px;
    height: 34px;
  }
}
