.at-simple_form_table {
  display: grid;
  @media (max-width: 767px) {
    grid-gap: 1rem 0;
  }

  .at-simple_form_table-head {
    @media (max-width: 767px) {
      display: none !important;
    }
  }

  .at-simple_form_table-row {
    display: flex;

    @media (max-width: 767px) {
      display: grid;
      padding: 0.5rem;
      grid-gap: 0.5rem 0;
      border: 1px solid #dee2e6;
      box-shadow:
        0 1px 3px rgba(0, 0, 0, 0.12),
        0 1px 2px rgba(0, 0, 0, 0.24);
    }
  }
  .at-simple_form_table-row_item {
    width: 100%;
    align-items: center !important;
    font-size: 1rem;

    @media (min-width: 768px) {
      padding: 10px;
    }
  }

  th.at-simple_form_table-row_item {
    background-color: #d6d8db;
    color: #212529;
  }

  td.at-simple_form_table-row_item {
    @media (max-width: 767px) {
      text-align: left;
      border: initial;
    }
  }

  .at-simple_form_table-label {
    color: #c0c0c0;
    font-weight: 500;
    font-style: italic;
    @media (min-width: 768px) {
      display: none;
    }
  }

  .form-control {
    max-width: 300px;
  }
}

.at-simple_form_table-update-btn {
  width: max-content;
  margin-left: 52%;

  @media (min-width: 1000px) {
    margin-left: 51.5%;
  }

  @media (max-width: 768px) {
    margin-left: 0px;
  }
}

.at-submission__reassign-btn {
  color: #fff;
  background-color: #6e10f3;
  border-color: #6512da;
  box-shadow: none;
}
.at-submission__reassign-btn:hover {
  color: #fff;
  background-color: #6613da;
  border-color: #5f12cc;
}

.at-submission__online-status {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  margin: auto 0;
  margin-right: 6px;
  display: inline-block;
}
