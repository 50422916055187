@import "src/assets/stylesheets/atlas/prerequisite.scss";

.at-presentation_centre-edit_project.at-project__container {
  grid-gap: 1rem;

  .at-projects__item {
    display: flex;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: initial;
    transition: 0.2s;
    border: 1px solid #d5d5d5;
    background-color: white;
    border-radius: 0.5rem;
    overflow: hidden;
    border: 1px solid #d1d5db;

    @include min-screen(420px) {
      grid-template-rows: initial;
    }
    @include min-screen(992px) {
      grid-gap: 0px;
      flex: 1 1 45%;
      max-width: 50%;
    }
    @media (max-width: 991px) {
      flex: 1 1 100%;
      max-width: 100%;
    }
    @media (min-width: 992px) {
      flex: 1 1 100%;
      max-width: 100%;
      .menu-expand & {
        flex: 1 1 100%;
        max-width: 100%;
      }
    }
    @media (min-width: 1000px) {
      flex: 1 1 45%;
      max-width: 50%;
    }
    @media (min-width: 1100px) {
      .menu-expand & {
        flex: 1 1 45%;
        max-width: 50%;
      }
    }
    @media (min-width: 1500px) {
      flex: 1 1 30%;
      max-width: 33.333%;
    }
    @media (min-width: 1900px) {
      .menu-expand & {
        flex: 1 1 30%;
        max-width: 33.333%;
      }
    }
    @media (min-width: 2800px) {
      flex: 1 1 25%;
      max-width: 25%;
      .menu-expand & {
        flex: 1 1 25%;
        max-width: 25%;
      }
    }
    @media (min-width: 3200px) {
      flex: 1 1 16.667%;
      max-width: 16.667%;
      .menu-expand & {
        flex: 1 1 20%;
        max-width: 20%;
      }
    }
    @media (min-width: 3800px) {
      .menu-expand & {
        flex: 1 1 16.667%;
        max-width: 16.667%;
      }
    }
  }

  .at-projects__image {
    .at-projects__img-cont {
      position: relative;
      height: 100%;
      width: 190px;
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;

      > img {
        height: 100%;
        width: 100%;
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
        object-fit: cover;
        transition: 0.2s;
      }

      @include max-screen(450px) {
        height: 60%;
        width: 100px;
      }
    }
  }

  .at-projects__specs {
    grid-column: 1;
    grid-row: 4;
    z-index: 2;
    padding: 0px var(--blackberry-padding) var(--blackberry-padding);

    @include min-screen(768px) {
      padding-top: var(--blackberry-padding);
      grid-column: 1/2;
      grid-row: 3;
    }
  }

  .at-projects__title-cont {
    grid-column: 1;
    grid-row: 1;
    overflow: hidden;

    @include min-screen(767px) {
      grid-column: 2/-1;
      grid-row: 1;
    }
  }

  .at-projects__title {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.5px;
    color: #374151;
    margin: 6px 0px;

    @media (max-width: 1399px) {
      height: 1.4rem !important;
      -webkit-line-clamp: 1 !important;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    @media (max-width: 991px) {
      width: 150px;
      height: 2rem !important;
    }
  }

  .at-projects__location {
    display: flex;

    p {
      font-size: 14px;
      color: #1f2937;
    }

    svg {
      color: #f0631e;
      width: clamp(15px, 1.2vw, 20px);
      height: clamp(15px, 1.2vw, 20px);
      margin-right: clamp(0.2rem, 1.2vw, 0.3rem);
      margin-left: -0.2rem;
      @media (max-width: 1499px) {
        margin-top: 0.1rem;
      }
    }
  }

  .at-projects__title__price {
    color: black;
    margin-top: 0.5rem;
    font-size: clamp(0.8rem, 1.2vw, 1rem);
    font-weight: 400;

    span {
      color: #c3410b;
    }
  }
  .project-list-status {
    font-size: 8px;
  }
}

.at-projects__details-cont {
  position: relative;
  width: 100%;
  grid-column: 2 / span 4;
  grid-row: 1;
  z-index: 2;
  padding: 10px 15px;
  transition: all 0.2s;
  color: #000;
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) and (max-width: 766px) {
    grid-column: 3/-1;
    grid-row: 1;
  }
  @include min-screen(992px) {
    grid-column: 3/-1;
    grid-row: 1 / span 2;

    .menu-expand & {
      grid-column: 2/-1;
      grid-row: 1 / span 2;
    }
  }

  p {
    @include min-screen(767px) {
      font-size: 13px;
    }
    @include max-screen(575px) {
      font-size: 12px;
    }
  }

  .at-projects__details-cont-top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > :first-child {
      flex-basis: 70%;

      @media (min-width: 1000px) {
        .menu-expand & {
          flex-basis: 60%;
        }
      }
    }
  }

  .at-projects__actions {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    display: flex;
    grid-gap: 0.3rem;

    button {
      padding: 2px;
      width: 32px;
      height: 32px;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    @include max-screen(1000px) {
      button {
        padding: 2px;
        width: 24px;
        height: 24px;

        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}

.at-projects-specs_cont-top {
  margin: 15px 0px 5px;
}

[class^="at-projects__spec-list_item-"] {
  display: flex;
  font-size: 0.8rem;
  margin-bottom: 5px;
  white-space: nowrap;

  & > span {
    display: flex;
    align-items: center;
  }
  .at-projects-specs_cont-top & {
    display: flex;
    svg,
    .at-projects__spec-text {
      color: #6b7280;
      margin-right: 5px;
    }
    .at-projects__spec-text {
      font-size: 13px;
    }
    .at-projects__spec-unit {
      color: #6b7280;
      margin-right: 10px;
    }
  }

  .at-projects-specs_cont-middle & {
    flex: 0 0 100%;
    white-space: pre-wrap;
    max-width: 100%;
    padding: 2px 0px;
    border-radius: 5px;
    svg,
    .at-projects__spec-text {
      color: #7cb9e3;
      font-size: 12px;
    }
  }

  .at-projects-specs_cont-bottom & {
    background-color: #2463eb;
    padding: 5px 10px;
    margin-right: 5px;
    margin-top: 5px;
    border-radius: 5px;
    svg {
      width: 14px;
      min-width: 14px;
      height: 14px;
    }
    .at-projects__spec-text {
      font-size: 11px;
    }
  }

  svg {
    width: 14px;
    min-width: 14px;
    height: 14px;
    margin-top: 3px;
    margin-right: 5px;
    color: #fff;
  }
  .at-projects__spec-text {
    font-weight: 500;
    color: #fff;
    font-size: 10px;
  }
}

.at-project-list-location {
  height: 4.4em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;

  @media (max-width: 1199px) {
    height: 1.6em !important;
    -webkit-line-clamp: 1 !important;
  }
}

.at-projects__category,
.at-projects__proptitle,
.at-projects__proptype {
  border-radius: 5px;
  position: absolute;
  display: flex;
  padding: 2px 4px;

  @include min-screen(767px) {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > svg {
    width: 22px;
    height: 22px;
    display: none;

    @include min-screen(767px) {
      display: block;
    }
  }

  > span {
    display: inline-block;
    font-size: 8px;
    font-weight: 500;
    width: 55px;

    @include min-screen(767px) {
      display: none;
    }
  }
}

.at-projects__category {
  left: 5px;
  top: 5px;
  > span {
    color: $atlas-white;
  }

  @include min-screen(767px) {
    left: 5px;
    bottom: -10px;
    top: initial;
  }
}

.at-projects__proptitle {
  left: 5px;
  top: 25px;
  > span {
    color: $atlas-white;
  }

  @include min-screen(767px) {
    left: 45px;
    bottom: -10px;
    top: initial;
  }
}

.at-projects__proptype {
  left: 5px;
  top: 45px;
  > span {
    color: $atlas-black;
  }

  @include min-screen(767px) {
    left: 85px;
    bottom: -10px;
    top: initial;
  }
}

.at-projects__tenure {
  position: absolute;
  bottom: -10px;
  right: 5px;
}

.at-projects__props {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;

  @include min-screen(767px) {
    display: flex;
    margin: 0px;
  }

  span,
  sub {
    color: $atlas-black;
    @include min-screen(767px) {
      color: $atlas-white;
    }
  }

  svg {
    width: 20px;
    height: 20px;
    fill: $atlas-black;
    @include min-screen(767px) {
      fill: $atlas-white;
    }
  }
}

.at-projects__title__text {
  font-size: 18px;
  color: #111827;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: calc(100% - 92px);

  @include max-screen(575px) {
    font-size: 14px !important;
  }
  @include max-screen(991px) {
    -webkit-line-clamp: 1 !important;
  }
  @include max-screen(1399px) {
    -webkit-line-clamp: 2;
  }
}

.at-projects__hr {
  margin: 10px 0px;
  border-color: #65b8a32b !important;
  border: 0.1px solid;
}

.at-projects__net-price {
  padding: 5px;

  @include min-screen(767px) {
    padding: 10px;
  }
}

.align-super {
  vertical-align: super;
}

.at-projects__available {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #f0631dbf;
  color: #ffffff;

  p {
    font-size: 10px;
    text-align: center;
    padding: 5px 0px;
    font-weight: 500;
  }
}

@media (max-width: 767px) {
  .at-presentation_centre-edit_project.at-project__container {
    .at-projects__img-cont {
      width: 10rem !important;
      height: 10rem !important;
      border-radius: 8px !important;
      overflow: hidden;
    }
    .at-projects__title__price {
      margin-top: 0.3rem;
    }
  }
  .at-projects__item {
    display: grid !important;
    position: relative;
    grid-template-rows: 1fr auto !important;
    grid-template-columns: auto 1fr 2rem !important;
    grid-template-areas:
      "image detail detail"
      "specification specification specification" !important;
    grid-gap: 0.5rem 1rem;
    padding: 1rem;
  }

  .at-projects__image {
    grid-area: image;
  }
  .at-projects__details-cont {
    display: contents;
  }

  .at-projects__title {
    margin: 0 !important;
  }

  .at-projects__info {
    grid-area: detail;
  }
  .at-projects-specs_cont {
    grid-area: specification;
  }
  .at-projects__actions {
    grid-area: action;
  }
  .at-projects-specs_cont-top {
    margin-top: 0;
    [class^="at-projects__spec-list_item-"] {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .at-projects-specs_cont-bottom {
    [class^="at-projects__spec-list_item-"] {
      margin-top: 0;
    }
  }
}

@media (max-width: 499px) {
  .at-presentation_centre-edit_project.at-project__container {
    .at-projects__img-cont {
      width: clamp(6rem, 1.2vw, 8rem) !important;
      height: clamp(6rem, 1.2vw, 8rem) !important;
    }
  }
}
