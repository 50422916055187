.payment-form {
  .display-inline-block {
    display: inline-block;
  }

  .btn-icon {
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 240px;
    height: 120px;
    background: white;
    box-shadow: 0px 0px 10px #b6b6b6;

    &:hover,
    &.active {
      background: rgba(0, 0, 0, 0.2);
    }
    .icon {
      width: 100px;
    }
  }
}

.checkout-payment-btn {
  width: 200px;
  height: 100px;

  button:not(.w-100) {
    width: 200px !important;
    height: 100px !important;

    img {
      width: 50px !important;
    }

    img:not(.icon) {
      width: 120px !important;
    }
  }
}
