.at-team-mgmt__add-pa {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #f9a01b;
  font-weight: 500;
  color: #f9a01b;

  svg {
    width: 25px;
    height: 25px;
    color: #f9a01b;
    margin-right: 10px;
  }
}

.at-team-mgmt__add-pa:hover {
  background-color: #f9a01b;
  color: white;

  svg {
    color: white;
  }
}
