.dialog-container-appointment {
  @media screen and (max-width: 600px) {
    width: 320px !important;
    max-width: 600px;
    min-height: 500px;
    height: 450px !important;
  }
}

.dialog-paper-appointment {
  @media screen and (max-width: 600px) {
    min-width: 300px;
  }
}

.snack-bar-display {
  @media screen and (max-width: 600px) {
    display: block !important;
  }
}

.appointment-at-card {
  padding: 0 !important;
}

.but-appointment {
  min-height: 80px;
  @media screen and (max-width: 600px) {
    left: 0 !important;
    right: 0 !important;
  }
}

.grid-item-appointment {
  padding: 30px 0 30px 0 !important;
  position: relative;
  @media screen and (max-width: 600px) {
    padding: 20px !important;
  }
}

.at-appointment-date_picker {
  cursor: pointer !important;
}

.dialog-paper {
  background-color: #2c3a3a !important;
}

.at-uploaded-container {
  display: flex;
  background-color: #7f7f7f;
  cursor: pointer;
  padding: 5px;
  max-width: 280px;
  border-radius: 5px;
  align-items: center;
  margin-bottom: 10px;

  > svg,
  img {
    width: 80px;
    height: 80px;
    margin-right: 10px;
  }
  > img {
    border-radius: 5px;
    object-fit: cover;
  }

  p {
    color: white;
    font-size: 12px;
  }
}

.at-profile__dropdown-paper-list {
  max-height: 500px;
}

.at-remark__card {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  background: white;

  .at-remark__card-title {
    color: #4b5563;
    font-size: 12px;
    font-weight: 500;
    line-height: 150%;
  }

  .at-remark__card-content {
    color: #1f2937;
    font-size: 14px;
    line-height: 150%;
  }

  .at-remark__card-remove {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: #f04438;
    cursor: pointer;
  }
}

.at-remark__remove-attachment {
  position: absolute;
  background: white;
  top: -5px;
  right: -5px;
  border-radius: 50%;
}

.at-remark__attachment-cont {
  width: 120px;
  height: 120px;
  position: relative;

  img {
    width: 120px;
    height: 120px;
    color: rgb(155, 155, 155);
    cursor: pointer;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
  }
}

.profile-datepicker-form-control {
  height: 45px;
  margin-bottom: 0 !important;

  input {
    cursor: pointer;
    width: 100%;
  }

  svg {
    margin-left: auto;
    width: 20px;
    height: 20px;
  }
}
