@import "../../atlas/prerequisite";

.at-sidebar--open {
  .at-new-sidebar__list,
  .at-sidebar__list {
    //arbitrary number that will work as long as the max-height is higher than all the child component value
    max-height: 100%;
    padding: 2px 0px;
    background: #2b2b2b;
    margin: 2px 8px;
    border-radius: 4px;
    width: 95%;
  }

  .at-sidebar__parent-cont,
  .at-sidebar__label {
    color: $atlas-white;
    .at-sidebar__chevron {
      transform: rotate(180deg);
    }
  }

  .at-sidebar__label::after {
    transform: scaleX(1);
  }
}

.at-sidebar-second--open {
  .at-new-sidebar__list,
  .at-sidebar__list {
    //arbitrary number that will work as long as the max-height is higher than all the child component value
    max-height: 100%;
    padding: 6px 0px !important;
  }

  .at-sidebar__parent-cont,
  .at-sidebar__label {
    color: $atlas-white;
    .at-sidebar__chevron {
      transform: rotate(180deg);
    }
  }

  .at-sidebar__label::after {
    transform: scaleX(1);
  }
}

.at-sidebar {
  background-color: $black;
  width: $sidebar-width;
  height: 100vh;
  z-index: $zindex-sidebar;
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow: auto;
  left: 0px;
  top: 0px;
  transition: transform 0.2s ease;
  transform: translateX(-#{$sidebar-width});
  -ms-overflow-style: none;
  scrollbar-width: none;

  @include min-screen($sidebar-breakpoint) {
    transform: translateX(0px);
  }

  &.hide-sidebar {
    transform: translateX(-#{$sidebar-width});
  }
}

.at-sidebar::-webkit-scrollbar {
  display: none;
}

.at-sidebar__list {
  max-height: 0px;
  transition:
    max-height 0.3s ease,
    padding 0.3s ease;
  overflow: hidden;
  padding: 1px 0px;
  position: relative;
  top: 2px;
  background-color: #38474f;
}

.at-sidebar__label {
  cursor: pointer;
  margin: 0px;
  color: $grey-1;
  position: relative;
  transition: all 0.2s ease;

  &::after {
    content: "";
    height: 2px;
    background-color: white;
    position: absolute;
    bottom: -7px;
    left: -10px;
    width: calc(100% + 10px);
    z-index: 3;
    transform: scaleX(0);
    transition: transform 0.3s ease;
    transform-origin: left;
  }

  &:hover,
  &:focus {
    color: white;
  }
}

.at-sidebar__list-item {
  z-index: 2;
  position: relative;
  top: 1px;
  margin: 3px 0;
}

.at-sidebar__list_top {
  transition: all 0.4s ease;
}

.at-sidebar__list-backdrop {
  position: absolute;
  transition: all 0.7s ease-out;
  top: -40px;
  left: -60px;
  width: 0;
  height: 100px;
  opacity: 0;

  .at-sidebar__link:hover & {
    opacity: 1;
    width: 200%;
    height: 100px;
    background-image: radial-gradient(#fbf4d94f, #efede74f, #ffffff00);
  }
}

.at-sidebar__link {
  transition:
    background-color 0.2s ease,
    color 0.2s ease;
  backdrop-filter: blur(2px);
  text-transform: initial !important;
  border-radius: 0px !important;
  text-align: start !important;
  overflow: hidden;

  .at-sidebar__list & {
    padding-left: 55px !important;
  }

  .at-sidebar__list &.child.active {
    padding-left: 40px !important;
  }

  &:hover {
    color: $atlas-white;
    background-color: rgba($mirage, 0.4);

    .at-sidebar__list & {
      background-color: #2a3d4a;
      box-shadow:
        0px 0px 1px 1px #ffffff00,
        0px 0px 5px 0px #686868;
      border-radius: 5px !important;
    }
  }

  &:focus {
    outline: none;
    color: $grey-2;

    .at-sidebar__list &.child {
      background-color: #2a3d4a;
      box-shadow:
        0px 0px 8px 0px #5b5b5b91,
        0px 0px 5px 0px #b2b2a1;
      transition: all 0.4s ease-out;
    }
  }

  &.active {
    color: $atlas-white;
    background-color: #38474f;
    color: $atlas-white !important;

    .at-sidebar__list & {
      background-color: #2a3d4a;
      box-shadow:
        0px 0px 8px 0px #b0b0b087,
        0px 0px 6px 0px #8d8d8d;
      transition: all 0.4s ease-out;
    }
  }
}

.at-sidebar__link,
.at-sidebar__label {
  color: $link-water !important;
  display: inline-flex;
  min-height: 40px;
  align-items: center;
  width: 100%;
  padding: 10px 20px 6px !important;
  border-radius: 2px;
  @include at-font(semibold);
  font-size: 11px !important;
  margin: 2px 0px;

  &:hover span {
    text-shadow: 0px 0px 2px #fbfbfb;
    transition: all 0.2s ease-in-out;
  }
}

.at-sidebar__link {
  .at-sidebar__dropdown &:hover {
    margin-left: 25px;
    padding-left: 20px !important;
    transition: all 0.4s ease-in-out;
    width: calc(100% - 28px);
  }
}

.active {
  &.at-sidebar__link span,
  &.at-sidebar__label span {
    text-shadow: 0px 0px 2px #fbfbfb;
    transition: all 0.2s ease-out;
  }
}

.at-sidebar__link-disabled {
  cursor: not-allowed;
  color: rgb(128, 128, 128) !important;

  &:hover {
    background-color: $limed-spruce;
  }
  svg {
    fill: rgb(128, 128, 128) !important;
  }
}

.at-sidebar__chevron {
  font-size: 12px;
  transform: rotate(0deg);
  transition: transform 0.2s ease;
}

.at-sidebar__chevron-cont {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0px, -50%);
}

.at-sidebar__icon {
  width: 20px;
  height: 20px;
  min-width: 20px;
  fill: #8d8d8d;
  margin-right: 10px;
}

.at-sidebar__avatar-cont {
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  img {
    width: 38px;
    height: 38px;
  }

  h4 {
    color: #ffff;
    font-weight: 500;
    margin-left: 15px;
  }
}

.at-sidebar__sidebar,
.arrow--active {
  opacity: 1;
}

.at-sidebar-mobile__section {
  margin-bottom: 20px;
  border-radius: 2px;
  padding: 10px 0px;
}

.at-sidebar-mobile__inner-cont {
  margin-top: 120px;
}

.at-sidebar_link_menu-cont {
  border-radius: 4px;
  text-align: start;
  padding: 8px 0px 8px 15px;
  margin: 1px 5px;
  width: 95%;
  display: flex;
  align-items: start;
  cursor: pointer;
  overflow: hidden;
  position: relative;

  &:hover {
    background-color: #575757;
  }

  &.active {
    background-color: #f0631d;

    .at-sidebar__icon {
      fill: white;
    }
  }

  .at-sidebar__chevron {
    color: white;
  }
}

// New Accordion
.at-sidebar__parent-cont {
  border-radius: 4px;
  text-align: start;
  padding: 8px 0px 8px 15px;
  margin: 1px 5px;
  width: 95%;
  display: flex;
  align-items: start;
  cursor: pointer;
  overflow: hidden;
  position: relative;

  &:hover {
    background-color: #575757;
  }

  &.active {
    border-left: 4px solid #f0631d;
    background-color: #262626;

    .at-sidebar__icon {
      fill: #f0631d;
    }
  }

  .at-sidebar__chevron {
    color: white;
  }
}

.at-sidebar__child-cont {
  border-radius: 4px;
  text-align: start;
  padding: 8px 0px 8px 40px;
  margin: 2px 5px;
  width: 95%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #575757;
  }

  &.active {
    background-color: #f0631d;

    .at-sidebar__icon {
      fill: white;
    }
  }
}

.at-new-sidebar__list {
  max-height: 0px;
  overflow: hidden;
  padding: 1px 0px;
  position: relative;
  top: 2px;
}

.at-sidebar__name {
  display: inherit;
  font-size: 14px;
  font-weight: 500;
  color: #d1d5db;
  margin-right: 25px;
}

.at-sidebar__link-label {
  padding: 10px 20px 10px 45px;
  font-size: 14px;
  font-weight: 500;
  color: #8d8d8d;
}

.at-sidebar__search {
  margin: 0px 10px;
  border-color: #4b5563;
  fill: #4b5563;
  height: 40px;
  background-color: transparent;

  & input {
    color: white;
    font-size: 12px;
    font-weight: 500;
    margin-left: 5px;
  }

  & input::placeholder {
    color: #6B7280;
  }
}

.at-sidebar__no-result {
  font-size: 14px;
  color: #d1d5db;
  text-align: center;
  display: block;
}

//Temporary Information
.at-sidebar__temp-info {
  color: white;
  padding: 12px;
  font-size: 12px;
  line-height: 150%;
  border-radius: 8px;
  margin: auto 12px 12px 6px;
  border: 1px solid #1d4ed8;
  background: rgba(59, 130, 246, 0.3);

  button {
    width: 100%;
    padding: 8px 0px 0px;
    color: #60a5fa;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
  }
}
