@import "../../../../stylesheets/atlas/prerequisite";

.at-sales__tabs {
  margin-bottom: 0px;
  padding: 16px 16px 0px;

  @media screen and (max-width: 992px) {
    padding: 0px;
  }
}

.at-details__container-bg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: url("https://atlasfrontendassets.s3.ap-southeast-1.amazonaws.com/bg-white-short.jpg") !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;

  > div {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  label {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #1f2937;
  }

  p {
    font-size: 14px;
    color: #1f2937;
  }

  @include max-screen($sidebar-breakpoint) {
    gap: 12px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    > div {
      width: 100% !important;
    }
  }
}

.at-details__timeline {
  label {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 104px;
      right: 0;
      top: 5px;
      height: 30px;
      border-left: 1px solid #f0631d;
      z-index: 0;
    }

    &:last-child::after {
      height: 15px;
    }
  }

  span {
    display: flex;
    justify-content: end;
    width: 120px;
    font-weight: 400;

    &::after {
      content: " ";
      display: block;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      margin: auto 12px;
      background-color: #f0631d;
    }
  }
}

.at-details__item {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #111827;
  padding: 12px 0px;

  &:not(:last-child) {
    border-bottom: 1px solid #d1d5db;
  }

  label {
    font-weight: 500;
    min-width: 140px;
    max-width: 140px;
    margin: 6px 0px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
  }

  svg {
    width: 16px;
    height: 16px;
    min-width: 16px;
    margin-right: 10px;
    fill: #f0631d;
  }

  @media screen and (max-width: 576px) {
    flex-wrap: wrap;
  }
}

.at-details__cobroke {
  display: flex;
  flex-direction: column;
  gap: 12px;

  > div {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  label {
    font-size: 14px;
    font-weight: 600;
  }

  p {
    font-size: 14px;
    color: #1f2937;
  }

  span {
    font-weight: 700;
    color: #f0631d;
    font-size: 14px;
    margin-right: 8px;
    min-width: fit-content;
  }
}

.at-details__title-tag {
  font-size: 12px;
  width: fit-content;
  padding: 4px 12px 4px 4px;
  color: black;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 25px;
  background-color: white;
  border: 1px solid #d1d5db;
}

.at-sale__float-button-cont {
  display: flex;
  align-items: center;
  margin: 10px 16px;
  background-color: #e5e7eb;
  border-radius: 8px;
  width: fit-content;

  @include max-screen($sidebar-breakpoint) {
    margin: 12px 0px;
  }
}

.at-sale__ranking {
  display: flex;
  align-items: center;
  gap: 8px;

  .at-sales__ranking-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 0px;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
    }
  }

  @include max-screen($sidebar-breakpoint) {
    flex-direction: column;

    .at-sales__ranking-info {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      padding: 8px;
      gap: 8px;

      > div {
        flex-direction: row;
      }
    }

    img {
      display: none;
    }
  }
}
