//userIndoList.js
.at-user_info-content {
  word-break: break-all;
}

.at-NDA-appointment-action {
  display: flex;
  justify-content: space-between !important;
  flex-wrap: wrap;
  padding: 0.5rem;

  @media screen and (max-width: 565px) {
    padding-bottom: 50px;
  }
}

.at-download-cp58__document {
  font-size: 14px;
  font-weight: 500px;
  margin-bottom: 12px;

  svg {
    width: 18px;
    height: 18px;
    fill: #3b82f6;
    margin-right: 10px;
  }
}
