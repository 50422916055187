.at-lead-table__lead-source {
  p {
    margin-bottom: 4px;
    color: #1f2937;
  }

  .p:first-child {
    font-weight: 500;
  }
}

.at-lead-table__status-tab {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #d1d5db;

  .at-lead-table__status-selected {
    color: #f0631d;
    border-bottom: 2px solid #f0631d;
  }

  button {
    font-size: 14px;
    font-weight: 500;
    color: #374151;
    padding: 14px 28px;
  }

  @media screen and (max-width: 992px) {
    overflow: hidden;
    background: #ffffff;
    border: 1px solid #d1d5db;
    border-radius: 8px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);

    > div {
      width: 100%;
    }

    button {
      width: 100%;
      padding: 14px;
    }
  }
}

.at-lead-table__checkbox-column {
  width: 10% !important;
  grid-column: 1/-1;
  margin: auto 0px;

  @media screen and (max-width: 576px) {
    padding-top: 0px;
  }
}
