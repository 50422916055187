.at-card-container {
  position: relative;
  min-height: 300px;

  @include min-screen(600px) {
    min-height: 500px;
  }
}

// .at-container--loader {
//   position: absolute;
//   top: 0px;
//   left: 0px;
//   min-height: 300px;
//   width: 100%;
//   pointer-events: none;

//   @include min-screen(600px) {
//     min-height: 500px
//   }
// }

.trans-at-loader-enter-active,
.trans-at-loader-leave-active {
  transition: all 0.5s ease;
}
.trans-at-loader-enter,
.trans-at-loader-leave-to {
  opacity: 0;
}

.trans-at-content-enter-active,
.trans-at-content-leave-active {
  transition: all 0.5s ease;
}

.trans-at-content-enter-to,
.trans-at-content-leave {
  transform: translate(0%, 0);
  opacity: 1;
}
.trans-at-content-enter,
.trans-at-content-leave-to {
  transform: translate(0%, -10px);
  opacity: 0;
}
