.at-drive-table_list {
  > div {
    display: flex;
    flex-direction: column;
  }
  .at-drive_table-row_cont {
    background-color: #fafafa;
    border-top: 1px solid #dbe2e6;

    &:nth-child(2n) {
      background-color: white;
    }
  }
  .at-drive_table-content_cont {
    p,
    span {
      font-size: 0.8rem;
    }
    .at-drive_table-row_cont {
      transition: background-color 0.3s;
      &:hover {
        background-color: #f0f0f0;
      }
    }

    @media screen and (max-width: 567px) {
      border-radius: 8px;
      border: 1px solid #d1d5db;
    }
  }

  [class^="at-drive_table-row__item"] {
    padding: 12px 16px !important;
    display: flex;
    align-items: center;
  }

  .at-drive_table-row__item-checkbox {
    width: fit-content;
    min-width: 50px;
    max-width: 50px;
  }

  .at-drive_table-row__item-favourite {
    width: fit-content;
    min-width: 50px;
    max-width: 50px;

    > svg {
      width: 1.8rem;
      margin: auto;
    }
  }

  .at-drive_table-row__item-title {
    width: 53%;

    .at-drive_table-row_item-icon {
      display: flex;

      > svg {
        fill: #f5b97b;
      }
    }

    > p {
      margin-left: 1rem;
      display: inline-block;
    }
  }

  .at-drive_table-row__item-date {
    width: 16%;
  }

  .at-drive_table-row__item-size {
    text-align: right;
    width: 13%;
    > span,
    > p {
      width: 100%;
    }
  }

  .at-drive_table-row__item-actions {
    width: 10%;
    display: flex;
    margin-left: auto;
  }

  .at-drive_table-row__item-actions_responsive {
    bottom: 0;
    right: 0;
    display: none;
    padding: 0 0.4rem !important;
  }
}

// Responsive List Mode
.menu-expand {
  @media (max-width: 565px) and (min-width: 992px) {
    .at-drive-table_list {
      .at-drive_table-row__item-title {
        width: 40%;
      }
      .at-drive_table-row__item-date {
        width: 22%;
      }
      .at-drive_table-row__item-size {
        width: 23%;
      }
      .at-drive_table-row__item-actions {
        width: 10%;
      }
    }
  }
}

@media (max-width: 991px) {
  .at-drive-table_list {
    .at-drive_table-header_row {
      display: none;
    }

    .at-drive_table-content_cont {
      .at-drive_table-row {
        display: grid;
        padding: 12px 0px;
        grid-template-rows: 20px 15px;
        grid-template-columns: 50px 20px 50px 6rem calc(100% - 12rem);

        &.at-drive-without_favourite {
          grid-template-columns: 3rem 6rem calc(100% - 12rem);
        }
      }

      .at-drive_table-row__item-checkbox {
        grid-area: checkbox;
      }

      .at-drive_table-row__item-favourite {
        display: contents;
        grid-area: favourite;

        > svg {
          grid-row: 1/-1;
        }
      }

      .at-drive_table-row__item-title {
        display: contents;

        .at-drive_table-row_item-icon {
          grid-area: icon;

          > svg {
            width: 32px;
            height: 32px;
            margin: auto;
            grid-row: 1/-1;
          }
        }

        > p {
          grid-area: name;
          margin-left: 0px;
          margin-top: 0px;
          font-size: 14px;
          font-weight: 500;
          padding-right: 2rem;
          color: #111827;
        }
      }

      .at-drive_table-row__item-date {
        display: contents;
        width: 70%;

        > p {
          grid-area: date;
          font-size: 10px;
          font-weight: 400;
          color: #374151;
        }
      }

      .at-drive_table-row__item-size {
        display: contents;
        text-align: left;

        > p {
          grid-area: size;
          font-size: 10px;
          font-weight: 400;
          color: #374151;
        }
      }
      .at-drive_table-row__item-actions {
        display: none;
      }

      .at-drive_table-row__item-actions_responsive {
        display: initial;
        bottom: auto;
        right: 1.2rem;

        &.at-drive-action-only_favourite {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .at-drive-table_list {
    .at-drive_table-content_cont {
      .at-drive_table-row {
        grid-template-areas:
          "checkbox favourite icon name name"
          "checkbox favourite icon date size";

        &.at-drive-without_favourite {
          grid-template-areas:
            "icon name name"
            "icon date size";
        }
      }

      .at-drive_table-row__item-title {
        .at-drive_table-row_item-icon {
          > svg {
            width: 32px;
            height: 32px;
            margin: auto;
          }
        }
      }

      .at-drive_table-row__item-date {
        width: 70%;
      }
    }
  }
}

@media screen and (max-width: 567px) {
  .at-drive-table_list {
    .at-drive_table-content_cont {
      .at-drive_table-row_cont_0 {
        border-top: 0px;
      }

      .at-drive_table-row {
        padding: 12px 0px;
        grid-template-areas:
          "checkbox favourite icon name name"
          "checkbox favourite icon date size";
      }

      .at-drive_table-row__item-title {
        .at-drive_table-row_item-icon {
          > svg {
            width: 32px;
            height: 32px;
          }
        }
      }
      .at-checkbox {
        padding: 0;
      }

      .at-drive_table-row__item-actions_responsive.at-drive-action-only_favourite {
        display: initial;
      }
    }
  }
}
