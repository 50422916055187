@import "../../atlas/prerequisite";

.at-global_network-listing {
  max-width: 220rem;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 599px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 1399px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1599px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: 2399px) {
    grid-template-columns: repeat(5, 1fr);
  }
  .menu-expand & {
    @media (min-width: 1400px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: 1800px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (min-width: 2500px) {
      grid-template-columns: repeat(5, 1fr);
    }
    @media (min-width: 2900px) {
      grid-template-columns: repeat(6, 1fr);
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.at-global_network-cont {
  display: inline-block;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  background: white;
  overflow: hidden;
  box-shadow:
    0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 2px 1px 0px rgba(16, 24, 40, 0.06),
    0px 1px 1px 0px rgba(0, 0, 0, 0.08);
}

.at-card--gooseberry {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.at-card--gooseberry_title {
  font-size: 16px;
  font-weight: 500;
  color: #111827;
  margin-bottom: 10px;
}

.at-card--gooseberry__photo-background {
  display: flex;
  padding: 12px;
  grid-area: 1/1/1/1;
  width: 100%;
  height: 100%;
  background-color: #f9fafb;
  background-repeat: repeat;
  background-size: contain;
  position: relative;
  margin: auto;
  padding: 10px;
  border-bottom: 1px solid #d1d5db;
}

.at-card--gooseberry__photo {
  position: relative;
  left: 0px;
  margin: auto;

  border: 0;
  grid-area: 1/1/1/1;
  object-fit: contain;
  max-height: 11rem;
  filter: drop-shadow(0 0 0.75rem rgba(16, 24, 40, 0.1));
}

.at-card--gooseberry__tooltip {
  width: max-content;
  max-width: 18em;
}

.at-card--gooseberry__avatar {
  background-color: $grey-6;
  background-size: cover;
  background-position: center;
  border: 2px solid $atlas-white;
  z-index: 1;
  top: 37%;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  right: 12px;
  position: absolute;
  @include box-shadow(2);
}

.at-card--gooseberry__lower {
  width: 100%;
  margin-top: auto;
  padding: 15px 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include min-screen(768px) {
    padding: 16px;
    border-radius: 0px 0px 4px 4px;
  }
}

.at-card--gooseberry__detail {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    width: 14px;
    height: 14px;
    min-width: 14px;
    fill: #f0631d;
    margin-right: 10px;

    @include min-screen(768px) {
      width: 16px;
      height: 16px;
      min-width: 16px;
    }
  }

  @include min-screen(768px) {
    font-size: 14px;
  }
}

.card--gooseberry__detail-actions {
  display: flex;
  grid-gap: 8px;
}

.at-card--gooseberry__actions {
  display: flex;
  grid-gap: 0.5rem;
  width: 100%;
  margin-top: 16px;

  button {
    width: 100%;
  }

  svg {
    width: 16px;
    height: 16px;
    min-width: 16px;
    fill: #3b82f6;
    margin-right: 5px;
  }
}
