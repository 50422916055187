@import "../../stylesheets/atlas/prerequisite";
@import "../../stylesheets/components/styles";

.at-select {
  position: relative;
  display: inline-block;
}

.at-select__chevron-container {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  font-size: 10px;
}

.at-select__chevron {
  transform: rotate(0deg);
  transition: transform 0.3s ease;

  &.expand {
    transform: rotate(180deg);
  }
}

.at-select__selected-item {
  &:focus-within {
    border-color: #3b82f6;
  }

  &.expand {
    background-color: $white;
    border: 1px solid $tangerine;
    box-shadow: 0px 0px 0px 2px rgba($tangerine, 1);
  }

  .at-select--error & {
    border: 2px solid $valencia;
  }

  .at-multi_select-input_wrapper {
    div:focus {
      background-color: transparent;
    }
  }
}

.at-select__dropdown-list {
  z-index: 4;
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  border: 2px solid #d1d5db;
  transform: translateY(30px);
  top: 0px;
  left: 0px;
  transition:
  height 0.3s ease,
  opacity 0.3s ease,
  transform 0.3s ease;
  opacity: 0;
  height: 0px;
  overflow: auto;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @include box-shadow(3);
  max-height: 300px;
  flex-wrap: wrap;

  &.expand {
    transform: translateY(50px);
    opacity: 1;
    height: fit-content !important;
  }
}

.at-multi_select-dropdown {
  border: 2px solid #d1d5db;
  padding: 5px;
}

.at-select__dropdown-item {
  height: $select-height;
  display: flex;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 3px;
  width: 100%;

  &:hover {
    background-color: #ffedd5 !important;
  }

  &:active {
    background-color: #ffedd5 !important;
  }

  &:last-of-type {
    border: 0px;
  }

  @include min-screen(600px) {
    font-size: 14px;
  }
}

.at-multi_select-dropdown_item {
  padding-left: calc(16px + 15px) !important;
  font-size: 14px !important;

  &:hover {
    background-color: #ffedd5 !important;
  }

  &:active {
    background-color: #ffedd5 !important;
  }

  &.at-select__selected-placeholder {
    padding-left: 10px !important;
  }
}

.at-select__dropdown-placeholder {
  color: #6B7280 !important;
}

.at-select__dropdown-section-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.at-select__dropdown-title {
  height: $select-height;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 14px;
  border-bottom: 1px solid #d1d5db;
  width: 100%;
}

.at-select__dropdown-content {
  @extend .at-select__dropdown-item;
  padding-left: 30px;
}