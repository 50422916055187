.at-video-creator__thumbnail-column {
  min-width: 0;

  .at-video-creator__thumbnail-wrapper {
    position: relative;
    aspect-ratio: 16 / 9;
    width: auto;
    height: 100%;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid #d1d5db;

    .at-video-creator__thumbnail {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .at-video-creator__thumbnail-overlay {
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.5s;

      &:hover {
        background-color: rgba(0, 0, 0, 0.25);
      }

      .at-video-creator__play-icon {
        width: 40px;
        height: 40px;
        color: #fff;
      }
    }
  }
}

.at-video-creator__title-icon {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  fill: #F0631D;
}

.at-video-creator__dialog-content {
  overflow-x: hidden !important;
  padding: 1rem !important;
}

.at-card__overlay .at-video-creator__dialog-content.mb-0 {
  margin-bottom: 0 !important;

  &.at-modal_dialog-content-minHeight {
    min-height: calc(100vh - 120px) !important;
  }
}

.at-video-creator__help-container {
  font-size: 13px;
}

.at-video-creator__video-buttons {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: 0;
  left: 0;
  padding: 12px;

  .at-video-creator__play-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px;
    font-size: 14px;
    border-radius: 8px;
    color: #1f2937;
    background-color: #FFF;
    border: 1px solid #D1D5DB;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
  }

  .at-video-creator__process-btn {
    padding: 10px;
    background-color: #F0631D;
    border-radius: 50%;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
}

.at-video-creator__video-details {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);

  h6, p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  h6 {
    color: #FFF;
    font-size: 16px;
    text-transform: capitalize;
  }

  p {
    color: #F9FAFB;
    font-size: 12px;
  }
}

.at-video-creator__video-container,
.at-video-creator__video-settings-header {
  position: static;
}

.at-video-creator__video-player {
  background-color: #000;
  width: 100% !important;
  height: auto !important;
  aspect-ratio: 16 / 9;
  position: relative;
  border: 1px solid #9ca3af;
  border-radius: 8px;
}

.at-video-creator__confirmation-modal {
  border-radius: 0.5rem;
}

.at-video-creator__outdate_tag {
  background-color: red;
  width: 100px;
  text-align: center;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 10px;
  font-weight: 700;
  color: white;
  position: absolute;
  bottom: 5px;
  right: 0;
}

.at-video-creator__outdate_tag:hover {
  color: white;
}

.at-table__refresh-btn {
  color: white;
  padding: 5px;
  background-color: #f18e06;
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: 500;
}

@media screen and (min-width: 1250px) {
  .at-video-creator__video-card {
    min-width: 300px;
  }
}

@media screen and (min-width: 1550px) {
  .at-video-creator__video-card {
    min-width: 400px;
  }
}

@media screen and (max-width: 576px) {
  .at-video-creator__thumbnail-column {
    padding: 0px;

    .at-table-head__title {
      display: none;
    }
  }
}

@media screen and (min-width: 800px) {
  .at-video-creator__thumbnail-column {
    min-width: 234px;

    .at-video-creator__thumbnail-wrapper {
      width: auto;
      height: 120px;
    }
  }
}

@media screen and (min-width: 992px) {
  .at-video-creator__video-settings-header {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .at-video-creator__video-container {
    position: sticky;
    top: 76.8px;
  }

  .at-card__overlay .at-modal_dialog-content.p-lg-0 {
    padding: 0 !important;
  }
}
