.subsales-letter-input {
  width: 150px;
  max-width: 400px;
  border: 1px solid #0275bc;
  border-radius: 0.4em;
  padding: 0px 8px;
  height: 22px;

  &:hover:not(:disabled),
  &:focus-within:not(:disabled) {
    border: 2px solid #3b82f6;
    box-shadow: 0px 0px 0px 2px #3b82f6;
  }

  &:disabled {
    background-color: #f3f4f6;
    color: #374151;
  }
}

.subsales-datepicker {
  width: 150px;
  max-width: 150px;
  border: 1px solid #0275bc;
  padding-left: 4px;
  padding-right: 4px;
  margin-right: 5px;

  &:disabled {
    background-color: #f3f4f6;
    color: #374151;
  }
}

.subsales-letter-points {
  padding-bottom: 3px;
  .subsales-letter-subpoints {
    padding-left: 30px;
    padding-bottom: 5px;

    .subsales-letter-subpoints-sub {
      padding-left: 60px;
      padding-bottom: 5px;
    }
  }
}

.subrent-letter-table {
  margin-left: 30px;
  thead {
    background: rgba(0, 0, 0, 0.4);
    tr {
      td {
        padding: 10px;
      }
    }
  }
  tbody {
    tr {
      border: 1px solid #eee;
      td {
        padding: 10px;
      }
    }
  }
}

.at-form__mngmt-status-table {
  margin: auto;

  @media screen and (max-width: 992px) {
    margin: 0px;
  }
}
