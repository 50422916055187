.at-agent-approval-profile_photo {
  width: 200px;
  height: 200px;
  border: 5px solid rgb(241, 142, 5);
  border-radius: 5px;
  object-fit: cover;
}

.at-agent-approval-title {
  font-family:
    Open Sans,
    Arial,
    sans-serif !important;
  font-weight: 600 !important;
  color: #354650 !important;
}

.at-agent-approval-content {
  font-family:
    Open Sans,
    Arial,
    sans-serif !important;
  color: #354650 !important;
}
