.at-landmark-mgmt__state-container {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid #D1D5DB;
  background: #FFF;

  img {
    height: 150px;
    min-height: 150px;
  }
}

.at-landmark-mgmt__state-box {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  @media screen and (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.at-landmark-mgmt__state-add-container {
  width: fit-content;
  background-color: white;
  position: absolute;
  padding: 3px;
  left: 40%;
  top: 30%;
  border-radius: 25px;
}

.at-landmark-mgmt__state-add {
  background-color: orange;
  padding: 5px 15px;
  border-radius: 25px;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}

.at-landmark-mgmt__title {
  color: #111827;
  font-size: 18px;
  font-weight: 600;
}
