:root {
  --at-stat-card-height: 160px;
  --at-chart-card-height: 400px;
  --space: 20px;
  --table-row-height: 135px;
  --mobile-sidebar-padding: 20px;
  --blackberry-padding: 10px;

  @include min-screen(768px) {
    --blackberry-padding: 15px;
  }

  @include min-screen($pers-sales-breakpoint-mobile) {
    --table-row-height: 75px;
    --mobile-sidebar-padding: 50px;
  }
}
