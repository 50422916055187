$color-white: rgba(255, 255, 255, 0.9);
$color-grey: rgba(0, 0, 0, 0.7);
$color-coral: rgb(238, 109, 77);
$color-navy: rgba(61, 90, 128, 0.8);
$color-primary: rgba(241, 142, 3, 0.8);
$color-success: rgba(32, 182, 167, 0.8);

@mixin header-card {
  padding: 1.1rem 0.8rem;
  border-radius: 0.3rem;
  color: $color-white;
  transition: transform 100ms ease-in-out;

  @media (hover) {
    &:hover {
      transform: translateY(-1%);
    }
  }
}

@keyframes fill {
  0% {
    transform: translateX(-80%);
  }

  100% {
    transform: translateX(0%);
  }
}

.at-presentation-table-content {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 12px;

  @media (min-width: 1920px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  @media (max-width: 1200px) {
    .menu-expand & {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: 680px) {
    grid-template-columns: minmax(0, 1fr);
  }
}

.at-presentation-card {
  position: relative;
  background-color: #fff;
  border-radius: 0.8rem;
  border: 1px solid #d1d5db;
  height: 100%;

  .at-presentation-card__header-transaction_number {
    height: 1.5rem;
    padding: 0.1rem 1rem 0.1rem 2rem;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-left: -1.5rem;
    border-radius: 1rem;
    background-color: #00000052;
  }

  .at-presentation-card__header-cont {
    gap: 0.5rem;
    background-color: #2463eb;
    color: #fff;
    padding: 0.8rem 0.7rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;

    .at-presentation-card__title-cont {
      display: flex;
      justify-content: space-between;
    }

    .at-presentation-card__header-action {
      margin-bottom: 0.4rem;
      display: flex;
      justify-content: space-between;
      border-radius: 50%;
      overflow: hidden;

      svg {
        width: 2em;
        height: 2em;
        border-radius: 0.3rem;
        background-color: $color-white;
        cursor: pointer;
        padding: 0.5rem;
        color: #3983f6;

        @media (hover) {
          &:hover {
            filter: brightness(0.5);
          }
        }
      }
    }

    .at-presentation-card__header-desc {
      display: flex;
      color: $color-white;
      justify-content: space-between;

      .at-presentation-info {
        margin-top: 0.2rem;
        font-size: 0.8rem;
        display: flex;
        font-weight: 500;
        align-items: center;
        flex-wrap: wrap;

        svg {
          width: 1rem;
          height: 1rem;
          margin-right: 8px;
        }
        &:first-child {
          margin-top: 0px !important;
        }
      }
    }

    > :nth-child(2) {
      flex-basis: 35%;
      @media (max-width: 1200px) {
        .menu-expand & {
          flex-basis: 40%;
        }
      }
    }
  }

  .at-presentation-card__agent-cont {
    .at-presentation-card__agent-info-cont {
      @include header-card();
      border-radius: 0px;
      background-color: #1c4ed8;

      :is(.at-presentation-card__name, .at-presentation-card__info) {
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .at-presentation-card__name {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0.3rem;
        text-transform: capitalize;
      }
      .at-presentation-card__info {
        svg {
          width: 1rem;
          height: 1rem;
          margin-right: 8px;
        }
        span {
          font-size: 0.8rem;
          font-weight: 500;
        }
      }
    }

    .at-presentation-card__agent-photo {
      border-radius: 0.3rem;
      object-fit: cover;
      object-position: top;
      flex-basis: 35%;
      max-width: 35%;
      width: 4rem;
      height: 8rem;

      @media (max-width: 1200px) {
        .menu-expand & {
          flex-basis: 40%;
          max-width: 40%;
        }
      }
    }
  }

  .at-presentation-card__content {
    height: 20.5rem;
    padding: 1rem;
    -webkit-transition: opacity 2s ease-in;
    -moz-transition: opacity 2s ease-in;
    -o-transition: opacity 2s ease-in;
    -ms-transition: opacity 2s ease-in;
    transition: opacity 2s ease-in;

    .at-presentation-card__content-nav {
      border-radius: 8px;
      display: flex;
      flex-wrap: wrap;
      background-color: #d1d5db;

      .at-presentation-card__content-tabs {
        position: relative;
        border-radius: 8px;
        font-weight: 500;
        padding: 0.5rem;
        font-size: 14px;
        width: 50%;
        transition: all 0.35s linear;
      }

      .at-presentation-card__content-active-tabs {
        background-color: #ffffff;
        box-shadow:
          0px 1px 3px 0px rgba(16, 24, 40, 0.1),
          0px 2px 1px 0px rgba(16, 24, 40, 0.06),
          0px 1px 1px 0px rgba(0, 0, 0, 0.08);
        border: 1px #d1d5db solid;
      }
    }

    .at-presentation-card__content-listing-pagination_cont {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.at-presentation-card__content-null-listing {
  font-size: 0.8rem;
  color: $color-grey;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80%;
  margin-top: 1rem;
}

//listing-card
.at-presentation-listing-card {
  &:first-child {
    margin-top: 0.8rem;
  }
  background-color: #fff;
  padding: 0.6rem 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d1d5db;

  .at-presentation-listing-card-info {
    font-size: 12px;
    color: $color-grey;
  }
  .at-presentation-listing-card-name {
    font-size: 14px;
    color: $color-grey;
    font-weight: 550;
    margin-bottom: 0.3rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .at-presentation-listing-card-icon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.6rem;
    border-radius: 50%;
    background-color: #ffedd5;
    padding: 0.4rem;

    svg {
      width: 16px;
      height: 16px;
      color: #f0631e;
      fill: #f0631e;
    }
  }
  [data-tab="Properties"] {
    svg {
      color: $color-coral;
      fill: $color-coral;
    }
  }
}
