@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.at-video-templates__thumbnail-column {
  min-width: 0;
}

.at-video-templates__thumbnail-wrapper {
  position: relative;
  aspect-ratio: 16 / 9;
  width: auto;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid #d1d5db;

  .at-video-templates__thumbnail {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .at-video-templates__thumbnail-overlay {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.25);
    }

    .at-video-templates__play-icon {
      width: 40px;
      height: 40px;
      color: #fff;
    }
  }
}

.at-video-templates__title-icon {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  fill: #F0631D;
}

.at-video-templates__video-thumbnail-wrapper {
  width: 100%;
  height: auto;
}

.at-video-templates__dialog-content {
  overflow-x: hidden !important;
  padding: 1rem 1rem 2rem 1rem !important;

  .at-form-input .dropdown-menu .dropdown-item {
    display: block !important;
  }

  .at-input_footer {
    margin-bottom: 0 !important;
  }
}

.at-video-templates__upload {
  height: auto !important;
  aspect-ratio: 16 / 9;

  .at-empty-state {
    border: none;
    background-color: transparent;
  }
}

.at-video-templates__video-player {
  background-color: #000;
  width: 100% !important;
  height: auto !important;
  aspect-ratio: 16 / 9;
  position: relative;
  border: 1px solid #9ca3af;
  border-radius: 8px;
}

.at-video-templates__play-button {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #3b82f6;

  svg {
    height: 18px;
    width: 18px;
    margin-left: 8px;
    margin-right: 4px;
  }
}

@media screen and (min-width: 800px) {
  .at-video-templates__thumbnail-column {
    min-width: 234px;
  }

  .at-video-templates__thumbnail-wrapper {
    width: auto;
    height: 120px;
  }

  .at-video-templates__video-thumbnail-wrapper {
    width: 100%;
    height: auto;

    .at-video-templates__thumbnail-overlay .at-video-templates__play-icon {
      width: 48px;
      height: 48px;
    }
  }
}
