.at-status_badge {
  width: fit-content !important;
  height: fit-content;
  display: flex;
  align-items: center;
  color: white;
  max-width: 200px;
  font-weight: 500;
  font-size: 10px;
  padding: 5px 10px;
  border-radius: 15px;
  border: 1.5px solid;

  @media (min-width: 1500px) {
    font-size: 14px;
  }

  @media (max-width: 767px) {
    font-size: 12px;
    width: 150px;
    margin: 0px;
  }
}

.at-status_badge-responsive {
  margin: auto;

  @media (max-width: 990px) {
    margin: 0px;
  }
}

.at-status_badge-remarks {
  margin-top: 8px;
  color: #1f2937;
  font-size: 12px;
  line-height: 150%;
  border-radius: 4px;
  padding: 4px 4px 4px 8px;
  border-left: 3px solid #f04438;
  background: #f3f4f6;
}

.at-assigned-div {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: wrap;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.at-assigned-div ::-webkit-scrollbar {
  display: none;
}

.at-assigned-card {
  background: linear-gradient(to right, #f46b45, #eea849);
  max-height: 230px;
  position: relative;
  width: 100%;
  padding: 10px 15px;
  margin-top: 10px;
  transition: transform 150ms;

  > [class*="MuiTypography-subtitle1"] h6 {
    font-size: clamp(0.9rem, 1rem, 1.1rem);
  }

  h6,
  p {
    margin-bottom: 0px;
    color: #ffff;
    text-transform: none;
  }

  input {
    border-radius: 4px;
    padding: 5px;
  }
}

.at-assigned-empty-card {
  background: linear-gradient(to right, #0f2027, #203a43, #2c5364) !important;
  min-height: 130px;
}

@media screen and (min-width: 800px) {
  .at-assigned-card:focus-within {
    transform: scale(1.02);
  }
}
