.at-card__overlay {
  & {
    .at-modal_dialog-content {
      margin-bottom: 65px !important;
    }

    .at-modal_dialog-content-minHeight {
      min-height: calc(100vh - 185px) !important;
    }
  }

  .at-modal_dialog-header~.at-modal_dialog-content {
    margin-top: 57px;
  }
}

.at-modal_dialog-lg-responsive {
  min-width: 1200px;

  @media (max-width: 1200px) {
    max-width: 800px !important;
    min-width: 100% !important;
  }
  @media (max-width: 767px) {
    min-width: 100%;
  }
}

.at-modal_dialog-md-responsive {
  .at-card__overlay {
    height: 100%;
  }

  @media (max-width: 767px) {
    width: 100%;
    min-width: 100%
  }
  @media screen and (min-width: 800px) {
    .at-card__overlay {
      min-width: 800px;
    }
  }
}
.at-modal_dialog-sm-responsive {
  @media screen and (min-width: 565px) {
    .at-card__overlay {
      min-width: 500px;
      max-width: 500px;
    }
  }
}

.at-modal_dialog-md-responsive,
.at-modal_dialog-sm-responsive {
  @media (max-width: 565px) {
    width: 100%;
    max-height: 100% !important;
  }
}

.at-modal_dialog-md-height_constant {
  @media(min-width: 771px) and (min-height: 660px) {  
    .at-card__overlay {
      min-height: 600px;
    }
  }

  .at-modal_dialog-content {
    @media(min-width: 771px) {
      min-height: calc(600px - 65px);
      max-height: calc(700px - 65px) !important;
    }
    @media (max-height: 772px) {
      min-height: calc(100vh - 170px) !important;
      max-height: calc(100vh - 245px) !important;
    }
  }
}

.at-modal_dialog-md-fit {
  min-height: calc(100vh - 56px) !important;
  min-width: 100vw;
  @media (min-width: 1280px) {
    min-width: 1280px !important;
  }

  .at-card__overlay {
    min-height: calc(100vh - 56px);
  }
}

.at-modal_dialog-scrollPaper {
  border-radius: 8px !important;
  max-height: calc(100vh - 56px) !important;

  &.at-modal_dialog-overflow_visible {
    overflow: visible !important;
    border-radius: 8px;

    .at-card__overlay {
      overflow: visible !important;
      border-radius: 8px;

      .at-modal_dialog-container-footer {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
    .at-modal_dialog-content {
      overflow: visible !important;
    }
  }
}

.at-modal_dialog-content-minHeight {
  min-height: calc(100vh - 185px) !important;
}

.at-modal_dialog-header {
  position: absolute;
  width: 100%;
  padding: 12px 12px 12px 20px !important;
  background-color: #f9fafb;
  border-bottom: 1px solid #d1d5db;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  h2 {
    margin: 0px;
    color: #111827;
  }

  @media screen and ( max-width: 576px ) {
    justify-content: space-between;
    
    > div { max-width: 80%; }

    h2 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.at-modal_dialog-content {
  overflow: auto !important;
  padding: 20px 20px 30px 20px !important;
  max-height: calc(100vh - 185px) !important;
  height: 100%;

  &.at-modal_dialog-no_footer {
    margin-top: 57px !important;
    margin-bottom: 0px !important;
    padding-bottom: 20px !important;
  }

  @media screen and (max-width: 576px) {
    padding: 20px 20px 60px 20px !important;
  }
}

.at-modal_dialog-footer {
  min-height: 50px;
  padding: 12px 12px 12px 20px;
  border-top: 1px solid #d1d5db;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.at-modal_dialog-container-footer {
  display: flex;
  position: absolute;
  border-top: 1px solid #d1d5db;
  min-height: 50px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: #f9fafb;
  padding: 12px 12px 12px 20px;
}

.at-access_case_file-password_modal {
  > div:first-child {
    background-color: transparent;
  }

  .at-confirmation_modal-paper {
    border-radius: 8px;
    border: 1px solid #D1D5DB;
    box-shadow: initial;
    border-radius: var(--bootstrap-spacing-2, 8px);
    border: 1px solid #D1D5DB;
    background: #FFFFFF;

    /* Shadow/Large */
    box-shadow: 0px 24px 38px 0px rgba(0, 0, 0, 0.04), 0px 9px 46px 0px rgba(16, 24, 40, 0.06), 0px 11px 15px 0px rgba(16, 24, 40, 0.10);
  }
}

.at-modal_dialog-md_size {
  width: 1280px;
}