@import "src/stylesheets/atlas/prerequisite";

.detail-content-block {
  background: white;
  height: 100%;
  position: relative;
}

.at-project-video-paper {
  background-color: unset !important;
  box-shadow: unset !important;
  position: absolute !important;

  max-height: 600px !important;
  height: 490px;
  max-width: 770px !important;

  @media (max-width: 768px) {
    max-height: 360px !important;
    max-width: 540px !important;
  }
  @media (max-width: 600px) {
    // max-height: 300px !important;
    max-width: 495px !important;
  }
  @media (max-width: 576px) {
    max-height: 310px !important;
    max-width: 430px !important;
  }
  @media (max-width: 420px) {
    max-height: 280px !important;
    max-width: 310px !important;
  }
}

.at-video__frame__container {
  width: 658px;
  height: 76%;
  max-width: 660px !important;
  @media (max-width: 1200px) {
    max-height: 397px !important;
  }
  @media (max-width: 768px) {
    max-height: 255px !important;
    max-width: 450px !important;
  }
  @media (max-width: 600px) {
    max-height: 230px !important;
    max-width: 400px !important;
  }
  @media (max-width: 576px) {
    max-height: 190px !important;
    max-width: 330px !important;
  }
  @media (max-width: 420px) {
    max-height: 178px !important;
    max-width: 310px !important;
  }
}

.at-project-hover-list {
  border-top: unset !important;
  transition: linear 1s !important;

  &:hover {
    border-top: 1px solid #ebebeb !important;
    transition: linear 1s !important;
  }
}

.project-featured-tag {
  border-radius: 8px;
  background-color: #f0631d;
  color: $atlas-white;
  font-weight: 600;
  font-size: 14px;
  padding: 5px 10px;
}

.project-image-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include max-screen(950px) {
    justify-content: center;
  }
}
.at-project-details__label {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  font-size: 14px;
  flex-direction: column;

  @include min-screen(768px) {
    flex-direction: row;
  }

  ul {
    font-size: 14px;
    list-style-type: disc;

    li {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
}

.at-project-details__label-name {
  margin-right: 5px;
  font-size: 12px;
  color: $grey-5;

  @include min-screen(768px) {
    width: 180px;
    font-size: 14px;
  }
}

.at-project__detail-cont {
  max-width: 1080px;
  height: 100%;
  margin: auto;
}

.at-project-unit__detail-status {
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 25px;
  padding: 5px 10px;
  color: white;
  font-size: 14px;
  font-weight: 500;
}

.at-project__detail-title {
  padding: 4px;
  margin-bottom: 12px;

  p {
    font-size: 14px;
    color: #374151;
    font-weight: 400;
    line-height: 150%;
  }
  h4 {
    font-size: 22px;
    font-weight: 600;
    line-height: 120%;
    margin-bottom: 4px;
    color: #111827;
  }
}

.at-project-details__tab {
  @include max-screen(570px) {
    button {
      padding: 0px !important;
      font-size: 12px !important;
    }
  }
}

.at-project-unit__tab-cont {
  display: flex;
  margin-left: 1px;
  @include max-screen(570px) {
    order: 2;
  }
}

.at-project-unit__storey-plan {
  margin-left: auto;
  display: flex;
  align-items: center;

  button {
    background-color: #f08e04;
    height: 40px;
    color: white;
    font-weight: 500;
    font-size: 14px;
  }

  @include max-screen(570px) {
    order: 1;
    width: 100%;
    margin-left: 0px;
    margin-bottom: 5px;
  }
}

.at-project-unit__table-status {
  font-weight: 500;
  font-size: 14px;
}

.at-project-unit__tab {
  padding: 10px;
  width: 100px;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-right: 5px;
  p,
  span {
    font-weight: 500;
    color: rgb(73, 73, 73);
  }

  @include max-screen(570px) {
    width: 65px;
    padding: 10px 5px;
  }
}

.at-project-unit__tab-selected {
  border: 2px solid grey;
  border-bottom: none;
}

.at-project-unit__help-text ul {
  list-style-type: decimal;
  margin-left: 20px;
  font-size: 13px;
}

.at-project-details__frame-cont {
  margin: 0px 10px;

  .at-step-idenitfier__progress-div {
    transform: translate(0px, 27px);
    background-color: #3b82f6;

    @media screen and (min-width: 1400px) {
      width: calc(100% - 200px) !important;
    }

    @media screen and (max-width: 576px) {
      width: 2px !important;
    }
  }

  .at-profile__card-grid-controller {
    @media (min-width: 1650px) {
      grid-template-columns: repeat(4, 1fr) !important;
    }
    @media (min-width: 2200px) {
      grid-template-columns: repeat(4, 1fr) !important;
    }
  }
}

.at-project-unit__reservation-dateicker {
  width: 150px;
  max-width: 150px;
  border: 1px solid #a0a0a0;
  padding: 10px 0px;
  text-align: center;
  border-radius: 5px;
  margin-right: 5px;
}

.at-project-unit__reservation_title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  padding-right: 5px;
  white-space: pre;
  height: 22px;
  text-align: left;
}

.at-project-unit__reservation_unit {
  margin-left: 10px;
  p {
    font-size: 16px;
  }

  svg {
    width: 14px;
    height: 14px;
  }
}

.at-project-unit__detail {
  border-bottom: 2px solid #ffe8c487;
  padding-bottom: 10px;
  font-weight: 600;
  display: flex;
  margin-bottom: 15px;

  span {
    margin-left: auto;
    color: #dd9425;
  }

  svg {
    width: 14px;
    height: 14px;
  }
}
