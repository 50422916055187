@import "src/stylesheets/atlas/prerequisite"; 
@import "src/assets/stylesheets/new_atlas/index.scss";

.at-cdd-table-head {
  display: flex;
  flex-direction: column;

  &:nth-child(1) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .at-table__expand {
    display: none;
  }

  .at-table-row__item {
    align-items: center;
    cursor: auto;

    @include min-screen($sidebar-breakpoint) {
      @include at-font(500);
      font-size: inherit;
    }
  }
  .at-column-responsive_right,
  .at-column-responsive_center {
    flex-direction: row;
    justify-content: center;
  }
  .at-column-responsive_right {
    justify-content: flex-end !important;
    align-items: center !important;
  }
}

.cdd-title_header {
  background: white;
  border-radius: 8px 8px 0 0;
  padding: 12px 16px;
  border: 1px solid #d1d5db;

  @media screen and (max-width: 992px) {
    border-radius: 8px; 
  }
}

.cdd-header_body {
  display: flex;
  align-items: center;
  gap: 12px;

  @media screen and (max-width: 992px) {
    overflow: auto;
    justify-content: space-between;
  }
} 

.cdd-header_tab {
  border-left: 1px solid #d1d5db;
  border-right: 1px solid #d1d5db;

  @media screen and (max-width: 992px) {
    margin-top: 16px;
    margin-bottom: 16px !important;
    border-bottom: 1px solid #d1d5db !important;
  }
}

.m-top-round-corner {
  @media screen and (max-width: 992px) {
    border-radius: 8px;
  }
}

.cdd-passport-ic_img {
  width: 365px;
  height: 474px;
  position: relative;
  border: 3px solid #f18e03;
  border-radius: 5px;

  @media screen and (max-width: 415px) {
    width: 100%;
    height: 100%;
  }
}


.at-cdd-field_col {
  .at-cdd-last-col:last-child {
    margin-bottom: 0px !important;
  }
  .at-cdd-last-2-col:nth-last-child(-n+2) {
    margin-bottom: 0px !important;
  }
  .at-cdd-last-3-col:nth-last-child(-n+3) {
    margin-bottom: 0px !important;
  }
  .at-cdd-3-col {
    margin-bottom: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .at-cdd-field_col .at-cdd-3-col:nth-child(-n+2) {
    margin-bottom: 12px !important;
  }
  .at-cdd-field_col .at-cdd-last-2-col:nth-last-child(2) {
    margin-bottom: 12px !important;
  }
  .at-cdd-field_col .at-cdd-last-3-col:nth-last-child(-n+3) {
    margin-bottom: 12px !important;
  }
}

.at-cdd-director_col {
  padding: 16px 3px;
  border-bottom: 1px solid #d1d5db;
  border-top: 1px solid #d1d5db;
}

.at-cdd-shareholder_col {
  padding: 16px 3px;
  border-bottom: 1px solid #d1d5db;
}

