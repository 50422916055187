.at-cobroke-dialog-md {
  max-width: 800px !important;
}

.at-cobroke_action {
  width: 30px;
  height: 30px;
  margin: 5px 5px 5px 0px;
  padding: 5px;
  background-color: #f18e05;
  fill: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.at-cobroke__revise-cont {
  padding: 12px;
  background: #f9fafb;
  border: 1px solid #d1d5db;
  border-radius: 8px;

  .at-cobroke__revise-icon {
    width: 32px;
    height: 32px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 4px 8px;
    margin-left: 0px;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.at-cobroke__revise-input {
  width: 80px;
  margin-left: auto;
  margin-right: 10px;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px;
}

.at-cobroke__revise-confirmation {
  p {
    margin-top: 10px;
    color: grey;
    font-size: 14px;
  }

  svg {
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }
}

.at-cobroke__request-confirmation-detail-card {
  background: #f3f4f6;
  border-radius: 8px;
  font-size: 14px;
  height: 100%;
  padding-bottom: 10px;

  hr {
    width: 95%;
  }

  label {
    margin-top: 15px;
    padding-left: 20px;
    font-size: 14px;
    font-weight: 600;
    color: #111827;
  }
}

.at-cobroke__request-no-property {
  width: 60px;
  height: 60px;
  padding: 12px;
  background-color: #d1d5db;
  border-radius: 50%;
  margin: 0px 20px;

  svg {
    fill: #9ca3af;
  }
}

.at-cobroke__revise-alert {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.at-cobroke__commission-container {
  background-color: #f5f5f5;
  width: 350px;
  font-size: 12px;
  margin: auto;

  @media (max-width: 768px) {
    width: 300px;
    font-size: 10px;
  }
}

.cobroke_agent-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;

  @media (max-width: 768px) {
    > svg {
      width: 15px;
      height: 15px;
    }
    > span {
      font-size: 12px;
    }
  }
}

.at-cobroke__agent-column {
  align-items: center;
  @media screen and (max-width: 768px) {
    width: 100% !important;
    padding-top: 0px;
    .at-table-head__title {
      display: none;
    }
  }
}

.at-subsales_listing__view-details,
.at-subsales-listing__view-cobroke-settings {
  td,
  th {
    font-size: initial;
    background-color: initial;
    color: initial;
    border: initial;
  }
}

.at-cobroke__pending-badge {
  color: white;
  width: 16px;
  height: 16px;
  font-size: 10px;
  font-weight: 500;
  margin-left: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ea580c;
}

.at-cobroke__tab-description {
  font-size: 14px;
  color: #374151;
}

.at-cobroke__project-partner-instruction {
  h4 {
    font-size: 18px;
    font-weight: 600;
    color: #111827;
  }

  label {
    font-size: 14px;
    font-weight: 600;
  }

  p {
    font-size: 14px;
  }

  .at-step-idenitfier__progress-div {
    width: calc(100% - 14vw);
    transform: translate(0px, 30px);
    background-color: #3b82f6;

    @media screen and (max-width: 576px) {
      width: 2px;
    }
  }
  .at-step-idenitfier__progress-button svg {
    fill: white;
    width: 20px;
    height: 20px;
  }

  .at-step-idenitfier__progress-number {
    width: 36px;
    height: 36px;
    color: white;
    font-weight: 600;
    background-color: #3b82f6;
  }

  .at-step-idenitfier__progress-button p {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    margin-top: 5px;
  }
}

.at-project-partner__commision-percent {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  width: 200px;
  min-width: 80px;
  margin: 0px 5px;
  overflow-wrap: break-word;

  p {
    width: 54px;
    height: 54px;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-radius: 50%;
  }

  &:nth-child(1) p {
    color: #1d4ed8;
    border-color: #93c5fd;
    background-color: #dbeafe;
  }

  &:nth-child(2) p {
    color: #c2410c;
    border-color: #fec84b;
    background-color: #fef0c7;
  }

  &:nth-child(3) p {
    color: #027a48;
    border-color: #6ce9a6;
    background-color: #d1fadf;
  }

  @media screen and (max-width: 576px) {
    font-size: 12px;
  }
}

.at-project-partner__action {
  label {
    font-size: 12px;
    font-weight: 500;
  }

  p {
    font-size: 14px;
    margin: 5px 0px;
  }

  button {
    font-size: 14px;
    padding: 6px 12px;
  }

  @media screen and (max-width: 768px) {
    button {
      flex: 1 1 0;
    }
  }
}

.at-project-partner__success-deal {
  color: #027a48;

  svg {
    fill: #027a48;
  }
}

.at-project-partner__fail-deal {
  color: #b42318;

  svg {
    fill: #b42318;
  }
}

.at-project-partner__no-deal {
  color: #374151;

  svg {
    fill: #374151;
  }
}

.at-project-partner__rate-partner-detail {
  display: flex;
  padding: 20px;
  background-color: #d1d5db;

  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  p {
    margin-left: 10px;
  }
}

.at-project-partner__rate-partner-project {
  padding: 20px;
  margin-top: 5px;
  border-left: 5px solid #3b82f6;
}

.at-project-partner__rate-partner-liked,
.at-project-partner__rate-partner-liked-selected,
.at-project-partner__rate-partner-disliked-selected,
.at-project-partner__rate-partner-disliked {
  padding: 12px;
  width: 60px;
  height: 60px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f3f4f6;
  svg {
    fill: #9ca3af;
  }
}

.at-project-partner__rate-partner-liked:hover {
  background-color: #0ea5e9 !important;
  svg {
    fill: #ffffff !important;
  }
}

.at-project-partner__rate-partner-disliked:hover {
  background-color: #f04438;
  svg {
    fill: #ffffff;
  }
}

.at-project-partner__rate-partner-liked-selected {
  box-shadow: 0px 0px 0px 4px rgba(14, 165, 233, 0.25);
  background-color: #0ea5e9;
  svg {
    fill: #ffffff;
  }
}

.at-project-partner__rate-partner-disliked-selected {
  box-shadow: 0px 0px 0px 4px rgba(240, 68, 56, 0.25);
  background-color: #f04438;
  svg {
    fill: #ffffff;
  }
}

.at-profile-closer-image {
  position: relative;
  display: inline-block;
}

.project-closer-badge {
  position: absolute;
  right: 22px;
  bottom: 0;
  background: #f0631d;
  text-align: center;
  border-radius: 25px;
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 600;
}

.at-cobroke__project_name_input {
  display: flex;
  height: 40px;
  align-items: center;
  position: relative;
  border-radius: 8px;
  border: 1px solid #9ca3af;
  background: white;
  overflow: hidden;

  button {
    order: 2;
    height: 40px;
    width: 50px;
    background-color: #d1d5db;
  }
}

.at-cobroke__project_name {
  font-size: 14px;
  color: #374151;
  padding: 12px 6px;
  border-radius: 8px;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: #d1d5db;
  }

  &.at-cobroke__project_name-selected {
    background-color: #bfdbfe;
  }
}

// .at-cobroke__timeline-cont {
//   .at-cobroke__timeline:last-child{
//     .timeline-vertical-line {
//       display: none;
//     }
//   }
// }

.at-cobroke__timeline {
  position: relative;
  margin-bottom: 16px;
}
