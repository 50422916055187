.event-details {
  font-size: 14px;
  margin-bottom: 5px;
}

.training_tabs {
  padding-top: 10px;
  @media screen and (max-width: 1500px) {
    padding-left: 10px;
  }
  @media screen and (max-width: 670px) {
    button {
      border-radius: 0.25rem !important;
      margin-bottom: 5px;
    }
  }
}

.training-panel {
  margin-top: 0px !important;

  .panel-summary {
    background: #fb923c;
    min-height: 48px !important;
  }
  .panel-summary-content {
    margin: 0px !important;

    .at-form-input__title {
      font-size: 16px;
      color: #ffffff;
    }
  }
  .panel-expand {
    display: block;
    padding: 24px 24px 24px;

    .outline-box {
      outline: 1px solid #c2c2c2;
      padding: 15px;
      margin-left: 0;
      margin-right: 0;
    }
  }
  .panel-details {
    display: grid;
    padding: 24px 24px 24px;

    .outline-box {
      outline: 1px solid #c2c2c2;
      padding: 15px;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .panel-disabled {
    cursor: auto !important;
  }
}

.training-table__header {
  display: flex;
  align-items: center;
  padding: 12px 16px;

  @media screen and (max-width: 576px) {
    padding: 0px 0px 12px 0px;
  }
}

.training-table__no-data > div {
  width: 48px !important;
}
