.at-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #f3f4f6;
  border-radius: 8px;
  padding: 20px;

  @include max-screen(600px) {
    padding: 16px;
  }

  .at-dialog &:not(.at-section_card) {
    @include max-screen(992px) {
      padding: 1rem 1rem 6rem !important;
    }
  }
}

.at-card--new {
  background: #fff;
  border-radius: 8px !important;
  border: 1px solid #d1d5db;
  box-shadow: none !important;
}

.at-card__title {
  @include at-font(600);
  display: block;
  font-size: 22px;
  color: #111827;
  word-break: break-word;

  svg {
    width: 35px;
    height: 35px;
    padding: 8px;
    fill: #f0631d;
    background-color: #ffedd5;
    border-radius: 50%;
    margin-right: 0.6rem;
  }

  @include min-screen(600px) {
    font-size: 23px;
  }

  @include max-screen(600px) {
    font-size: 17px;
  }
}

.at-card__header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
  grid-gap: 1rem;
}

.at-card__subtitle {
  @include at-font(500);
  display: block;
  font-size: 20px;
  word-break: break-all;
}

.at-card__body {
  position: relative;
  height: 100%;
}

.at-card--stat {
  height: var(--at-stat-card-height);
  background-color: #16232c;
  padding: 0px;
}

.at-card--stat__numbers {
  position: absolute;
  left: 20px;
  top: 35px;

  @include min-screen(600px) {
    top: 50px;
  }
}

.at-card--stat__footer {
  position: absolute;
  bottom: 10px;
  right: 20px;
  color: #959595;
  font-size: 12px;
  @include at-font(400);
  font-style: italic;
}

.at-card--stat__footer-mobile {
  color: $grey-3;
  font-size: 10px;
  margin: 2px 10px 2px auto;

  @include min-screen(1080px) {
    font-size: 12px;
  }
}

.at-card--stat__category {
  @include at-font(500);
  font-size: 12px;
  color: $grey-4;
  max-width: 250px;

  @include min-screen(1080px) {
    max-width: auto;
    font-size: 15px;
  }
}

.at-card--stat__title {
  color: $atlas-white;
  font-weight: 400;
  font-size: 35px;

  @include min-screen(1080px) {
    font-size: 43px;
  }
}

.at-card--stat__icon-cont {
  width: 80px;
  height: 80px;
  fill: $atlas-white;
  border-radius: 5px;
  @include flexcenter;
  position: absolute;
  top: -20px;
  right: 20px;
}

.at-card--stat__icon {
  transform: scale(1.3);
  display: flex;
}

.at-card--chart {
  min-height: var(--at-chart-card-height);
  background-color: $mirage;
  color: $atlas-white;
  justify-content: center;
}

.at-card--chart__header {
  margin-bottom: 20px;
  margin-left: 40px;
}

.at-card--chart__title {
  @include at-font(500);
  display: inline-block;
  font-size: 25px;
}

.at-card--chart__chart {
  height: 245px;

  .ct-label {
    @include at-font(600);
    font-size: 14px;
  }
}

.at-card--chart__footer {
  min-height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.at-card__options {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @include min-screen(600px) {
    align-items: center;
    flex-direction: row;
  }

  > .at-checkbox {
    margin-bottom: 20px;

    @include min-screen(600px) {
      margin-bottom: 0;
    }
  }
}
