.tui-image-editor-submenu {
  display: none !important;
}

.tui-image-editor-controls {
  display: none !important;
}

.tui-image-editor {
  left: 0 !important;
}

.tui-image-editor-container.right .tui-image-editor-main-container {
  right: 0 !important;
}
