@import "./pre/main";
@import "./dashboard/main";
@import "./atlas/atlas";

button {
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;

  &:focus,
  &:active {
    outline: 0 !important;
  }
}

a {
  cursor: pointer;
}

// testing modal styling for react modal
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 400ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  z-index: 3;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ck.ck-editor__main > .ck-editor__editable {
  height: 400px;
}
