.at-party-title_info {
    color: var(--Gray-900, #111827);
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
}

.at-access_file-party-agent_info {
    svg {
        width: 20px;
        height: 20px;
        fill: #F0631D;
    }
}

.at-access_file-party-info_listing-cont {

}

.at-access_file-party-info_listing {
    display: flex;
    padding-bottom: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    border-bottom: 1px solid #D1D5DB;
}