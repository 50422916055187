$color-coral: rgb(238, 109, 77);
$color-white: rgba(255, 255, 255, 0.9);

.at-presentation-pagination {
  display: flex;
  flex-wrap: wrap;
}

.at-presentation-text {
  font-size: 0.8rem;
  margin-top: 1.2rem;
  display: block;
}

.at-presentation-pagination__button {
  display: inline-block;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-right: 5px;
  margin-top: 0.5rem;
  transition: background-color 0.2s ease;

  @media (min-width: 400px) {
    width: 1.3rem;
    height: 1.3rem;
    font-size: 0.8rem;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: darken(white, 5%);
  }

  &.active {
    background-color: $color-coral;
    color: $color-white;
    // @include at-presentation-font(600);
  }
}

.at-presentation-pagination__button-arrow {
  &.disabled {
    opacity: 0.1;
    pointer-events: none;
  }
}

.at-presentation-pagination__arrow {
  transition: background-color 0.2s ease;
  pointer-events: none;
  z-index: 1;
  width: 12px;
  height: 12px;
}
