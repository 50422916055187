.at-list {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: $mirage;
  color: $atlas-white;
  @include box-shadow(2);
}

.at-list__item {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $limed-spruce;
  align-items: center;
  min-height: 60px;
  padding: 20px;

  &:last-of-type {
    border: 0px;
  }
}
