@import "../../atlas/prerequisite";

.at-announcement__content {
  padding: 20px 0px;
}

.at-announcement__image {
  max-height: 300px;
}

.at-announcement__creator {
  font-style: italic;
}

.at-announcement__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  @include min-screen(600px) {
    flex-direction: row;
  }
}

.at-announcement__publish-status {
  display: flex;
  align-items: center;
  min-width: 160px;

  @include min-screen(600px) {
    margin-left: 20px;
  }
}

.at-announcement__publish-tick {
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: $tangerine;
  margin-right: 10px;

  > .svg-icon-tick {
    fill: $atlas-white;
    width: 20px;
    height: 20px;
  }

  .not-published & {
    opacity: 0.2;
  }
}
