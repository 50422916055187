@media screen and (min-width: 992px) {
  .at-table-row {
    grid-gap: 0 !important;
    gap: 0 !important;
  }
}

.at-table-row__item {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.at-table-row__item__responsive {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;

  @media screen and (min-width: 992px) {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.at-table-row__item__responsive__mobile {
  display: none !important;

  @media screen and (max-width: 992px) {
    padding-top: 8px !important;
    display: flex !important;
    border-radius: 8px;

  }
}

.at-table-row__item__responsive__hidden {
  display: none !important;

  @media screen and (min-width: 992px) {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
}

.at-table-row__item__table {
  display: none !important;
  max-width: calc(3 * 1fr);
  
  @media screen and (max-width: 992px) {
    display: grid !important;
    border-radius: 8px;
    overflow: auto;
    border: 1px solid #ccc;
    grid-template-columns: repeat(3, 1fr);

  }

  @media screen and (max-width: 448px) {
    border-radius: 8px;
    overflow: auto;
    border: 1px solid #ccc;
    grid-template-columns: repeat(2, 1fr);

  }

  @media screen and (max-width: 328px) {
    border-radius: 8px;
    overflow: auto;
    border: 1px solid #ccc;
    grid-template-columns: 1fr;

  }

  .table-cell {
    display: flex;
    text-align: center;
    gap: 4px;
    flex-direction: column;
    padding: 10px;
    border-radius: 0;
    box-shadow: inset 0 0 0 1px #ccc; // Add inner border using box-shadow
  }
}

.at-card.h-100 {
  overflow-x: auto;

  @media screen and (max-width: 992px) {
    padding: 1rem !important;
  }
}

.at-email-campaigns__dialog {
  .modal-content {
    overflow: hidden;

    .modal-header {
      background-color: #f9fafb;
      border-bottom-color: #d1d5db;

      .modal-title {
        font-size: 20px;
        font-weight: 600;
      }
    }

    .modal-body {
      overflow-y: auto;
    }

    .modal-footer {
      border: none;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      padding-top: 0;
    }
  }

  .at-email-campaigns__dialog-paper {
    width: 100% !important;

    @media screen and (max-width: 575px) {
      margin: 8px !important;
    }
  }

  .at-email-campaigns__md-dialog-paper {
    max-width: 960px !important;
  }

  .at-email-campaigns__lg-dialog-paper {
    max-width: 1280px !important;
  }
}

.at-email-campaigns__status-icon {
  display: none;

  @media screen and (min-width: 992px) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.5rem;
    margin-right: 0.5rem;
    border-radius: 50%;
    width: 28px;
    height: 28px;
  }
}

.label {
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.title {
  font-weight: 500;
  text-transform: none;
}

.expansion-panel {
  border: 1px solid rgba(0, 0, 0, 0.125);
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0 !important;
  cursor: default;

  &:not(:last-child) {
    border-bottom: 0;
  }

  &::before {
    display: none;
  }

  .expansion-panel-summary {
    cursor: default;
    padding: 0;

    .expansion-panel-summary-content {
      margin: 0;
    }

    .expansion-panel-summary-wrapper {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1.25rem 8px 1fr 8px auto;
      grid-template-columns: 1.25rem 1fr auto;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      gap: 8px;
      width: 100%;
      padding: 1.5rem;
      cursor: default;

      .check-icon {
        width: 1.25rem;
        height: 1.25rem;
        fill: #e0e0e0;
        margin-top: 6px;

        &.is-checked {
          fill: #28a745;
        }
      }

      .button-text {
        display: none;

        @media screen and (min-width: 768px) {
          display: inline;
        }
      }

      .button-icon {
        display: block;

        @media screen and (min-width: 768px) {
          display: none;
        }
      }

      .close {
        border-radius: 50%;
        border: 1px solid gray;
        width: 32px;
        height: 32px;
      }

      .expansion-panel-summary-text {
        max-width: calc(100vw - 180px);
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media screen and (min-width: 768px) {
          max-width: 480px;
        }
      }
    }
  }

  .expansion-panel-details__from,
  .expansion-panel-details__subject {
    padding: 0 1.5rem;
    width: 100%;
    gap: 3rem;

    > * {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }

    .tiny-text {
      position: absolute;
      width: 100%;
      top: -16px;
      left: 0;
      font-size: 0.6rem;
    }

    @media screen and (max-width: 767px) {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
      gap: 1rem;
    }
  }

  .expansion-panel-details__to {
    display: block;
  }
}

.phone-wrapper {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;

  .phone-frame {
    position: relative;
    border: 1px solid gray;
    border-bottom: 0;
    border-top-left-radius: 3.5rem;
    border-top-right-radius: 3.5rem;
    width: 100%;
    max-width: 360px;
    height: 100%;
    padding: 1.5rem 1.25rem 0 1.25rem;
    margin: 0 auto;

    .left-buttons {
      position: absolute;
      top: 50%;
      left: -3px;
      -webkit-transform: translateY(-35%);
      -ms-transform: translateY(-35%);
      transform: translateY(-35%);
      height: 10rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      gap: 1rem;

      div {
        background-color: gray;
        width: 2px;
        border-radius: 2px;

        &:first-child {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
        }

        &:not(:first-child) {
          -webkit-box-flex: 2;
          -ms-flex: 2;
          flex: 2;
        }
      }
    }

    .right-button {
      position: absolute;
      top: 50%;
      right: -3px;
      -webkit-transform: translateY(-25%);
      -ms-transform: translateY(-25%);
      transform: translateY(-25%);
      width: 2px;
      height: 5rem;
      background-color: gray;
      border-radius: 2px;
    }

    .phone-screen {
      border: 1px solid gray;
      border-bottom: 0;
      border-top-left-radius: 2.5rem;
      border-top-right-radius: 2.5rem;
      width: 100%;
      height: 100%;
      background-color: rgba(227, 227, 227, 0.2);
      position: relative;
      z-index: 0;

      .phone-top {
        position: absolute;
        top: -1px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        border: 1px solid gray;
        border-top: none;
        width: 132px;
        height: 28px;
        background-color: #fff;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        z-index: 1;

        .phone-speaker {
          position: absolute;
          top: 3px;
          left: 50%;
          -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          transform: translateX(-50%);
          background-color: gray;
          width: 2.5rem;
          height: 0.35rem;
          border-radius: 1rem;
        }

        .phone-selfie-camera {
          position: absolute;
          top: 0;
          left: 70%;
          background-color: gray;
          width: 0.65rem;
          height: 0.65rem;
          border-radius: 1rem;
        }
      }

      .status-bar {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0.5rem 1rem 0;

        .time {
          font-size: 0.8rem;
          margin-left: 0.75rem;
        }

        .icons {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          gap: 0.25rem;
        }
      }

      .app-bar {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0.5rem;

        svg {
          width: 20px;
          height: 20px;
        }

        p {
          font-weight: 600;
        }
      }

      .list-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        padding: 0.5rem;
        gap: 0.35rem;

        &.opacity-50 {
          opacity: 0.5;
        }

        .blue-avatar {
          background-color: #447df7;
        }

        .purple-avatar {
          background-color: #6d1b7b;
        }

        .email-text {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
          text-align: left;

          .email-header {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            gap: 8px;

            .email-name {
              width: 100%;
              max-width: 150px;
              white-space: nowrap;
              overflow: hidden;
              -o-text-overflow: ellipsis;
              text-overflow: ellipsis;
            }

            .email-name,
            .email-date {
              font-size: 13px;
            }
          }

          .email-subject,
          .email-preview {
            font-size: 11px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
    }
  }

  @media screen and (max-width: 440px) {
    .phone-frame {
      padding: 0;
      border: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      .left-buttons,
      .right-button {
        display: none;
      }

      .phone-screen .phone-top {
        display: none;
      }
    }
  }
}

.at-email-campaigns__preview-summary {
  .svg-icon-info {
    width: 16px;
    height: 16px;
    margin-left: 0.25rem;
  }
}

.at-email-campaigns__email-template-card {
  background-color: #fff !important;
  padding: 0.25rem !important;
  position: relative;
  margin-bottom: 0.5rem;

  img {
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center top;
    object-position: center top;
    min-height: 250px;
  }

  .at-email-campaigns__email-template-card-buttons {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    place-items: center;
    background-color: rgba(255, 255, 255, 0.7);

    @media screen and (min-width: 768px) {
      opacity: 0;
      -webkit-transition: opacity 0.3s;
      -o-transition: opacity 0.3s;
      transition: opacity 0.3s;

      &:hover {
        opacity: 1;
      }
    }
  }

  svg {
    width: 18px;
    height: 18px;
    margin-right: 0.5rem;
  }
}

.at-email-campaigns__tabs {
  margin-left: 0 !important;
  padding-left: 0.25rem;
}

.at-email-campaigns__email-preview {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  .email-preview__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 0.75rem;
    border-bottom: 1px solid #dee2e6;

    .btn-group {
      display: none;

      @media screen and (min-width: 576px) {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
      }

      .desktop-btn,
      .mobile-btn {
        display: -ms-grid;
        display: grid;
        place-items: center;
        border: 1px solid #dee2e6;
        padding: 0.25rem;
        width: 32px;
        height: 32px;
        background-color: #fff;

        &.active svg {
          fill: #1d62f0;
        }
      }

      .desktop-btn {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-right: none;
      }

      .mobile-btn {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
    }
  }

  .email-preview__body {
    min-height: 60vh;
    max-height: 80vh;

    .iframe {
      border: none;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }

    #u_body {
      pointer-events: none;
    }

    .device-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      height: 100%;
      overflow: hidden;
      padding: 16px 0;

      .marvel-device-wrapper > div {
        margin: 0 auto;
      }

      .screen > div {
        width: 100%;
        height: 100%;
      }

      .device-email-body {
        width: 100%;
        height: 100%;
        background-color: #fff;

        .iframe {
          border: none;
          width: 100%;
          height: 100%;
          pointer-events: none;
        }
      }
    }
  }
}

.at-email-campaigns__send-test-form {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  overflow: hidden;
  background-color: #fff;
  padding: 1rem;
}

.at-email-campaigns__schedule-campaign-form__card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow:
    0px 1px 3px 0px #1018281a,
    0px 2px 1px 0px #1018280f,
    0px 1px 1px 0px #00000014;
  box-shadow:
    0px 1px 3px 0px #1018281a,
    0px 2px 1px 0px #1018280f,
    0px 1px 1px 0px #00000014;
  width: 200px;
  height: 150px;

  &.active {
    border: 2px solid #000;
  }

  svg {
    width: 40px;
    height: 40px;
    fill: #1d62f0;
  }
}

.at-email-campaigns__preview-dialog {
  .email-preview__body {
    pointer-events: none;

    .iframe {
      height: 60vh;
    }
  }
}

.at-email-campaigns__timeline {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: #d1d5db;
    top: 0;
    left: 20px;
    z-index: 0;
  }

  &::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: #d1d5db;
    bottom: 0;
    left: 12.5px;
    border-radius: 50%;
    z-index: 0;
  }

  .timeline-point {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ea580c;
    z-index: 1;
    margin-right: 20px;

    .svg-icon-bell {
      fill: #fff;
      font-size: 12px;
      width: 20px;
      height: 20px;
    }
  }

  .card {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .card-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 16px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;

    .clock-icon {
      width: 16px;
      height: 16px;
      margin-right: 0.25rem;
    }
  }

  .card-body {
    padding: 1.25rem;

    .at-email-campaigns__activity-changes {
      list-style: none;

      .at-email-campaigns__activity-change-item:before {
        content: "✓ ";
      }
    }
  }
}

.at-email-campaigns__template-thumbnail {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center top;
  object-position: center top;
  // min-height: 300px;
}

.at-email-campaigns__create-list-header {
  border-bottom: 1px solid lightgray;
  padding: 0.75rem 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  .close-button {
    position: relative;
    right: -1rem;
  }
}

.at-email-campaigns__create-list-body {
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid lightgray;
}

.at-email-campaigns__create-list-footer {
  padding: 0.75rem 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.at-email-campaigns__form-input {
  width: 100%;
  margin-bottom: 0;
  padding: 2px 10px;
}

.at-email-campaigns__select-category {
  width: 100%;
  margin-bottom: 1rem;

  @media screen and (min-width: 576px) {
    width: 25vw;
  }
}

.at-email-campaigns__rbt {
  .rbt-input-multi {
    background-color: transparent;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    font-size: 14px;
    width: 100% !important;
    padding-left: 5px;
    border: none;

    .rbt-input-main {
      color: rgba(#38474f, 0.3);
      font-style: italic;
      -webkit-transition: color 0.3s ease;
      -o-transition: color 0.3s ease;
      transition: color 0.3s ease;
    }

    &.focus {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  .dropdown-menu {
    padding: 0.5rem 0;
    font-size: 0.9rem;
  }
}

.at-email-campaigns__summary-item {
  box-shadow: none;
  border: 1px solid #ccc;

  label {
    font-size: 14px !important;
    font-weight: 600 !important;
  }

  p {
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  .summary-item__disabled {
    color: #374151;
    font-size: 14px;
    font-weight: 400;
  }
}

.at-email-campaigns__image-container {
  position: relative;
  display: inline-block;
}

.at-email-templates__card-media {
  display: block;
}

.at-email-campaigns__button-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.at-email-campaigns__image-container:hover .at-email-campaigns__button-container {
  opacity: 1;
}

.at-email-campaigns__loader {
  opacity: 1;
}