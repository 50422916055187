.status-tip-div :is(p, strong) {
  font-size: 14px;
  margin-right: 5px;

  strong {
    font-weight: 700;
  }
  @media screen and (min-width: 1400px) {
    font-size: 0.9rem;
  }
}
