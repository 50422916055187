.chat-feed-container {
  background-color: #fff;
  max-height: 30rem;
  min-height: 20rem;
  border: 1px solid #e5e5e5;
  border-bottom: 0px;
  height: fit-content;
  padding: 15px;
  overflow-y: auto;
}
