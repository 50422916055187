.at-assigned-div {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: wrap;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.at-assigned-div ::-webkit-scrollbar {
  display: none;
}

.at-assign-card {
  background-image: url("https://atlasfrontendassets.s3.ap-southeast-1.amazonaws.com/agent_card_bg.jpg");
  border: 1px solid #d1d5db;
  box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.08),
    0px 2px 1px rgba(16, 24, 40, 0.06),
    0px 1px 3px rgba(16, 24, 40, 0.1);
  border-radius: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0px 20px;

  svg {
    width: 16px;
    height: 16px;
    fill: #f0631d;
    margin-right: 10px;
  }
}

.at-assign-card-cont {
  .at-assign-avatar {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    object-fit: cover;
    margin: 20px;
    margin-left: 0px;
  }

  .at-assign-badge {
    font-weight: 500;
    font-size: 14px;
    padding: 2px;
  }

  label {
    font-weight: 600;
    font-size: 18px;
    color: #111827;
    width: 100%;
  }

  p {
    font-size: 14px;
    color: #111827;
    margin-bottom: 5px;
  }
}

.at-new-sales__details {
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  background-image: url(https://i.imgur.com/pydM0yr.png) !important;
  background-repeat: no-repeat !important;
  background-size: 100% auto !important;

  .at-new-sales__details-middle {
    display: grid;
    margin: 16px 0px;
    gap: 16px;
    grid-template-columns: repeat(4, 1fr);
  }

  .at-new-sales__details-bottom {
    display: grid;
    row-gap: 12px;
    column-gap: 16px;
    margin-bottom: 0px;
    grid-template-columns: repeat(3, 1fr);
  }

  .at-new-sales__details-item {
    padding-bottom: 12px;

    &:not(:nth-last-child(-n + 3)) {
      border-bottom: 1px solid #d1d5db;
    }
  }

  h1 {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #111827;
    font-size: 22px;
    font-weight: 600;
  }

  label {
    color: #1f2937;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
  }

  p {
    color: #1f2937;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
  }
}

.at-new-sales__timeline {
  position: relative;
  margin-bottom: 8px;
}

@media screen and (max-width: 576px) {
  .at-project-listing__project-details-cont {
    background-size: 240% auto !important;
    background-position: 90% 0%;
  }

  .at-new-sales__details-middle,
  .at-new-sales__details-bottom {
    grid-template-columns: 1fr !important;
  }

  .at-new-sales__details-item {
    border-bottom: 1px solid #d1d5db !important;

    &:last-child {
      border-bottom: none !important;
    }
  }
}
