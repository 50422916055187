@import "src/stylesheets/atlas/prerequisite";

.detail-content-block {
  background: white;
  height: 100%;
  position: relative;
}

.at-project-video-paper {
  background-color: unset !important;
  box-shadow: unset !important;
  position: absolute !important;

  max-height: 600px !important;
  height: 490px;
  max-width: 770px !important;

  @media (max-width: 768px) {
    max-height: 360px !important;
    max-width: 540px !important;
  }
  @media (max-width: 600px) {
    // max-height: 300px !important;
    max-width: 495px !important;
  }
  @media (max-width: 576px) {
    max-height: 310px !important;
    max-width: 430px !important;
  }
  @media (max-width: 420px) {
    max-height: 280px !important;
    max-width: 310px !important;
  }
}

.at-video__frame__container {
  width: 658px;
  height: 76%;
  max-width: 660px !important;
  @media (max-width: 1200px) {
    max-height: 397px !important;
  }
  @media (max-width: 768px) {
    max-height: 255px !important;
    max-width: 450px !important;
  }
  @media (max-width: 600px) {
    max-height: 230px !important;
    max-width: 400px !important;
  }
  @media (max-width: 576px) {
    max-height: 190px !important;
    max-width: 330px !important;
  }
  @media (max-width: 420px) {
    max-height: 178px !important;
    max-width: 310px !important;
  }
}

.at-project-hover-list {
  border-top: unset !important;
  transition: linear 1s !important;

  &:hover {
    border-top: 1px solid #ebebeb !important;
    transition: linear 1s !important;
  }
}

.project-featured-tag {
  border-radius: 5px;
  background-color: $atlas-primary;
  color: $atlas-white;
  font-weight: 600;
  font-size: 14px;
  padding: 5px 10px;
}

.project-image-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include max-screen(950px) {
    justify-content: center;
  }
}
.at-project-details__label {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  font-size: 14px;
  flex-direction: column;

  @include min-screen(768px) {
    flex-direction: row;
  }

  ul {
    font-size: 14px;
    list-style-type: disc;

    li {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
}

.at-project-details__label-name {
  margin-right: 5px;
  font-size: 12px;
  color: $grey-5;

  @include min-screen(768px) {
    width: 180px;
    font-size: 14px;
  }
}
