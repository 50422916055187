@import "../../stylesheets/atlas/prerequisite";

.at_module_header--cont {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 20px;
  border-bottom: 1px solid #d1d5db;
  grid-gap: 1rem;

  @include max-screen($sidebar-breakpoint) {
    padding: 20px 16px;
  }
}

.at_module_header--title-cont {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 12px;

  .module-header-icon svg {
    width: 40px;
    height: 40px;
    background-color: #ffedd5;
    border-radius: 12px;
    padding: 8px;
    fill: #f0631d;
    color: #f0631d;
  }

  p {
    font-weight: 600;
    color: #111827;
    font-size: 22px;

    @media screen and (max-width: 576px) {
      font-size: 20px;
      flex: 1 0 0;
    }
  }
}

.at-module_header--action-cont {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;

  @media screen and (max-width: 576px) {
    width: 100%;
  }
}

.at-module_header--subsection {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.at-module_header--description {
  font-size: 14px;
  color: #374151;
  line-height: 1.5;
}