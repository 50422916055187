@import "src/stylesheets/atlas/prerequisite";

.at-atlas_store_ticket-container {
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-columns: 1fr;
  grid-gap: 12px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  @include min-screen(1600px) {
    grid-template-columns: 1fr 1fr;
  }
}

.at-coupon-redeemed_image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  position: absolute;
  bottom: -20px;
  right: 0px;
  mix-blend-mode: multiply;

  @include max-screen(768px) {
    left: 10px;
    right: auto;
  }
}
