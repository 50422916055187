@import "mixins";

$color-dark: rgba(36, 42, 56, 1);

.at-subsales_listing__view-recommendation {
  grid-area: recommendation;
  position: relative;
  padding: 16px;

  .at-subsales_listing__view-recommendation_title {
    @include at-subsales-title();
    display: block;
    margin-bottom: 0.8rem;

    svg {
      width: 30px;
      height: 30px;
      cursor: pointer;
      margin-left: 0.8rem;

      &:hover {
        color: #f18e03;
      }
    }
  }

  .at-subsales_listing__view-recommendation_body {
    position: relative;
    margin-top: 0.5rem;
    padding: 10px;
    border-radius: 8px;
    background-color: #f3f4f6;

    .at-subsales_listing__view-recommendation_body-scroll {
      display: flex;
      overflow-x: scroll;
      scroll-behavior: smooth;
      overflow-y: hidden;
      padding-bottom: 10px;

      &::-webkit-scrollbar {
        display: none;
      }

      > div:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}

.at-subsales_listing__view-recommendation-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 1rem;

  @media (min-width: 1820px) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  @media (min-width: 1620px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  @media (max-width: 1168px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (max-width: 1400px) {
    .menu-rcc-expand & {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
  @media (max-width: 1100px) {
    .menu-rcc-expand & {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: 680px) {
    grid-template-columns: minmax(0, 1fr);
  }
}
