@import "src/stylesheets/atlas/prerequisite";

.at-status__bar {
  text-align: left;

  @include min-screen(990px) {
    text-align: center;
  }
}

.at-table-sum {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
  margin-bottom: 12px;

  @media screen and (max-width: 850px) {
    flex-direction: column;
  }
}

.at-table-col {
  background: white;
  display: inline-flex;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  box-shadow:
    0 1px 3px 0 rgba(16, 24, 40, 0.1),
    0 2px 1px 0 rgba(16, 24, 40, 0.06),
    0 1px 1px 0 rgba(0, 0, 0, 0.08);
}

.at-table-sum-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
}

.at-table-sum-col:first-child {
  flex: 20%;
}

.at-table-sum-col:last-child {
  flex: 70%;
}

.at-sum-box-col {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 12px;

  .at-sum-box-col__label {
    font-weight: 500;
    font-size: 14px;
    color: #374151;
  }

  .at-sum-box-col__value {
    font-weight: 600;
    font-size: 20px;
  }
}

.at-sum-icon {
  background: #6875f5;
  padding: 8px;
  border-radius: 12px;
}

.at-sale-progress-bar {
  width: 100%;
  border-radius: 25px;
  margin-top: 10px;

  @media screen and (min-width: 990px) {
    margin-top: 0;
    width: 60%;
  }
}

.at-target__box {
  display: flex;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  background: #f9fafb;
  box-shadow:
    0 1px 3px 0 rgba(16, 24, 40, 0.1),
    0 2px 1px 0 rgba(16, 24, 40, 0.06),
    0 1px 1px 0 rgba(0, 0, 0, 0.08);
}

.at-target__cont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;

  div {
    align-self: center;
  }
}

.wb-kit-fill {
  width: 100% !important;
}

.at-campaign_stat__container {
  height: 100%;

  .campaign_stat__label {
    color: #111827;
    font-size: 18px;
    font-weight: 600;
    line-height: 120%;
    margin-bottom: 16px;
  }

  .at-campaign__card {
    background: white;
    min-height: 100px;
    height: 100%;
    overflow: auto;
    max-height: 800px;

    @include max-screen(767px) {
      max-height: 600px;
    }
  }
}

.at-campaign-stats-bar {
  display: flex;
  width: 90%;
  margin-bottom: 12px;
  float: left;
  border-radius: 8px 0 0 8px;
  border: 1px solid #37415124;
  border-right: 1px solid transparent;
  background: #f3f4f6;
  overflow: hidden;
}

.at-campaign-stats-bar__label {
  position: absolute;
  top: 0;
  left: 0;
  text-align: left;
  z-index: 1;
  width: 88%;
  float: left;

  > div {
    display: flex;
    padding: 16px;
    height: 62px;
    align-items: center;
  }

  .at-campaign-stats-bar__label-title {
    font-size: 16px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .at-campaign-stats-bar__label-ratio {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .at-campaign-stats-bar__percent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    margin-left: auto;

    span {
      font-size: 14px;
      font-weight: 600;
      justify-content: right !important;
    }
  }
}

.at-campaign-stats-bar__info {
  cursor: pointer;
  display: flex;
  width: 10%;
  background: white;
  float: left;
  border-radius: 0 8px 8px 0;
  border: 1px solid #37415124;
  border-left: 1px solid transparent;
  height: 62px;
  align-items: center;
  justify-content: center;
}

.at-campaign-target_tooltip {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  overflow-wrap: break-word;
}

.at-campaign-tracker__tab-options {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  border-bottom: 1px solid #d1d5db;

  .at-campaign-tracker__tab-selected {
    color: #f0631d;
    border-bottom: 2px solid #f0631d;
  }

  button {
    font-size: 14px;
    font-weight: 500;
    color: #374151;
    padding: 0px 12px 12px;
    border-bottom: 2px solid transparent;
  }
}

.at-compaign-tacker__progress-column {
  width: 60%;
  text-align: "-moz-center";

  @media screen and (max-width: 990px) {
    width: 100%;
  }
}

.at-campagin-tracker__stat-cont {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 16px;
  grid-gap: 8px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}
