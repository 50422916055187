.info-tabs {
  display: flex;
  align-items: end;
  margin-bottom: 16px;
  border-bottom: 1px solid #d1d5db;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  svg {
    min-width: 16px;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    fill: #374151;
  }

  .at-tab__button {
    font-size: 14px;
    font-weight: 500;
    color: #374151;
    display: flex;
    justify-content: center;
    min-width: 80px;
    align-items: center;
    padding: 0px 12px 12px;
    border-bottom: 2px solid transparent;

    div {
      font-size: 10px;
      color: white;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-left: 10px;
      background-color: #6b7280;
    }

    &:hover:not(.at-tab__selected) {
      border-bottom: 2px solid #d1d5db;
    }
  }

  .at-tab__selected {
    color: #f0631d;
    border-bottom: 2px solid #f0631d;

    div {
      background-color: #f0631d;
    }

    svg {
      fill: #f0631d;
    }
  }

  @media screen and (max-width: 992px) {
    background: #ffffff;
    min-width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 8px;
    padding-top: 0px !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
    word-break: keep-all;
    
    > div {
      width: 100%;
    }

    .at-tab__button {
      width: 100%;
      min-width: auto;
      height: 45px;
      padding: 10px 16px;
      justify-content: center;
    }
  }
}

.at-tab__selected.secondary {
  color: #c2420b;
  background: #fed8aa !important;
  border-bottom: 2px solid #f0631c;

  svg {
    fill: #c2420b !important;
    color: #c2420b !important;
  }
}

// duo.js
.at-tab_duo-outline {
  width: fit-content;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  background-color: #e5e7ea;
  border-radius: 8px;

  .at-tab_duo__button {
    height: 40px;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
  }
  .at-tab_duo__selected {
    border: 1px solid #d1d5db;
    background-color: #ffffff;
    box-shadow: 1px 1px 2px 1px #1018280f;
  }

  @media (max-width: 449px) {
    display: flex;
    width: 100%;

    .at-tab_duo__button {
      flex: 1 1 50%;
    }
  }
}

.at-tab_underline-cont {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 575px) {
    overflow: initial;
  }
}

.at-tab_underline-outline {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 1rem;
  border-bottom: 1px solid #dbdde1;

  @media (max-width: 575px) {
    border-radius: 0.6rem;
    border: 1px solid #dbdde1;
    background-color: #ffffff;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  
    &::-webkit-scrollbar {
      display: none;
    }
  }

  span {
    text-align: left;
  }

  svg {
    min-width: 16px;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    fill: #374151;
    color: #374151;
  }

  .at-tab_underline__button {
    padding: 0 12px 12px 12px;
    display: flex;
    align-items: center;
    grid-gap: 0.1rem;
    border-bottom: 1px solid #dbdde1;
    font-size: 14px;

    @media (max-width: 575px) {
      padding-top: 12px;
    }
  }
  .at-tab_underline__selected {
    color: #f0621e !important;
    fill: #f0621e !important;
    border-bottom: 3px solid #f0621e;

    svg {
      fill: #f0621e !important;
      color: #f0621e !important;
    }
  }
}
