@import "../../../../stylesheets/index";

.lead-card {
  .panel-summary {
    background: $tangerine;
    min-height: 60px;
  }
  .panel-summary-content {
    margin: 10px 0;

    .at-form-input__title {
      color: $white;
    }
  }
  .panel-expand {
    display: block;
    padding: 24px 24px 24px;

    .outline-box {
      outline: 1px solid #c2c2c2;
      padding: 15px;
      margin-left: 0;
      margin-right: 0;
    }
  }
  .panel-details {
    display: grid;
    padding: 24px 24px 24px;

    .outline-box {
      outline: 1px solid #c2c2c2;
      padding: 15px;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .panel-disabled {
    cursor: auto !important;
  }
}

.at-leads-grid_height_fixed {
  min-height: 67px;
}

.at-collapse-button_round {
  border-radius: 50%;
  background-color: rgba(245, 245, 245, 1) !important;
  color: rgba(0, 0, 0, 0.35) !important;

  &:hover {
    background-color: rgba(240, 240, 240, 1) !important;
    color: rgba(0, 0, 0, 0.4) !important;
  }
}

.at-lead-assign_button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #f18e05;
  margin-left: 5px;
  padding: 0px 3px 4px;
  color: #f18e05;

  &:hover {
    color: #f18e05cc;
    border: 2px solid #f18e05cc;
  }
  &:focus {
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}

.at-lead-icons {
  width: 35px;
  height: 35px;
  margin-right: 20px;

  & svg {
    width: 25px;
    height: 25px;
  }
}

.at_lead-table__tab {
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 10px;
  align-items: center;

  @media (max-width: 450px) {
    margin: 0 -1rem;
  }

  button {
    margin-right: 10px;
    font-size: 10px;
    max-height: 30px;
  }

  @include min-screen(1140px) {
    button {
      margin-bottom: 0px;
    }
  }
}

.at-lead-setting__account {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  flex-wrap: wrap;
  border: 1px solid #d1d5db;
  justify-content: space-between;

  h2 {
    font-size: 14px;
    font-weight: 500;
  }
}

.at-lead-setting__account-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 12px;
  width: 100%;

  p {
    color: #111827;
    font-size: 14px;
  }

  span {
    font-size: 12px;
    color: #6b7280;
    font-style: italic;
  }
}

.at-lead-setting__account-action {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #d1d5db;
}

.at-lead-setting__facebook-icon {
  width: 32px;
  height: 32px;
  min-width: 32px;
  padding: 6px;
  fill: #3b82f6;
  background: #eff6ff;
  border-radius: 50%;
  margin-right: 10px;
}

.at-view-lead__buyer-info-action {
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    width: 20px;
    height: 20px;
    min-width: 20px;
    margin-right: 10px;
  }

  @include max-screen(576px) {
    flex-wrap: wrap;
  }
}

.at-view-lead__buyer-info-bg {
  background-image: url("https://atlasfrontendassets.s3.ap-southeast-1.amazonaws.com/bg-white-short.jpg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.at-view-lead__detail-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #111827;
  padding-bottom: 12px;

  label {
    font-weight: 500;
    min-width: 140px;
    max-width: 140px;
    margin: 6px 0px;
  }

  svg {
    width: 16px;
    height: 16px;
    min-width: 16px;
    margin-right: 10px;
    fill: #f0631d;
  }

  @media screen and (max-width: 576px) {
    flex-wrap: wrap;
  }
}

.at-update-lead__lead-detail {
  padding: 12px;
  background: #f3f4f6;
  border-radius: 8px;
  margin: 0px;

  .at-update-lead__lead-detail-source {
    padding: 6px 12px;
    border-left: 2px solid #d1d5db;

    @media screen and (max-width: 767px) {
      border-left: none;
      border-top: 2px solid #d1d5db;
    }
  }
}
