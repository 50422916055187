@import "../../atlas/prerequisite";

$hierarchy-bp: 850px;

.agent-name {
  font-size: 16px;
}

.agent-commission-container {
  display: inline-flex;
  margin-left: 10px;
  flex-direction: column;
  gap: 5px;

  @include min-screen($hierarchy-bp) {
    margin-left: 0;
    flex-direction: row;
    gap: 0px;
  }
}

.agent-commission {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #1f2937;
  min-width: 130px;
  flex-wrap: wrap;
  padding-left: 10px;
  cursor: pointer;

  > span {
    font-size: 14px;
  }

  .svg-icon-call,
  .svg-icon-person,
  .svg-icon-team {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .svg-icon-call {
    fill: #6b7280;
  }

  .svg-icon-person {
    fill: #3b82f6;
  }

  .svg-icon-team {
    fill: #f0631d;
  }

  @media screen and (max-width: $hierarchy-bp) {
    padding-left: 0px;

    &:nth-child(2) {
      ::before {
        font-weight: 400;
        content: "Personal Sales: ";
      }
    }

    &:nth-child(3) {
      ::before {
        font-weight: 400;
        content: "Group Sales: ";
      }
    }
  }
}

.agent-filter-tag {
  margin: auto;

  span {
    font-size: 12px;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 8px;
    color: white;
    background-color: #ea580c;
  }

  @media screen and (max-width: $hierarchy-bp) {
    order: 1;
    margin: 0px;
    width: 100%;
  }
}

.agent-expand-icon {
  display: flex;
  align-items: flex-end;
  height: 50px;
  position: absolute;
  background-color: white;
  transform: translate(-27px, -13px);
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
  }

  @include max-screen($hierarchy-bp) {
    background-color: #f3f4f6;
  }

  @include min-screen($hierarchy-bp) {
    transform: translateX(-10px);

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.agent-badge {
  display: inline-flex;
  align-items: center;
  color: white;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 8px;
  border-radius: 10px;
  margin-right: 5px;

  svg {
    width: 12px;
    height: 12px;
    fill: white;
    margin-right: 5px;
  }
}

.tree-parent {
  padding-left: 10px;

  @include min-screen($hierarchy-bp) {
    padding-left: 15px;
  }

  &:first-child {
    padding-left: 0px;
  }
}
.tree-branch {
  position: relative;
}

.agent-name-container {
  display: inline-flex;
  align-items: center;
  margin-left: 5px;
  padding: 5px 0px;
  position: relative;
  cursor: pointer;

  @include min-screen(550px) {
    margin-right: 40px;
  }
  @include min-screen($hierarchy-bp) {
    flex-direction: row;
    margin: 5px;
    margin-left: 20px;
    padding: 10px 0px;
  }
  @media screen and (max-width: $hierarchy-bp) {
    order: 2;
  }
}

.agent-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #d1d5db;

  &:hover {
    background-color: #f3f4f6;
  }

  @media screen and (max-width: $hierarchy-bp) {
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    padding: 12px 8px;
    background: #ffffff;
    border: 1px solid #d1d5db;
    box-shadow:
      0px 1px 1px rgba(0, 0, 0, 0.08),
      0px 2px 1px rgba(16, 24, 40, 0.06),
      0px 1px 3px rgba(16, 24, 40, 0.1);
    border-radius: 8px;
    margin-left: 5px;
    margin-top: 10px;
    transform: translateX(5px);
  }
}

.agent-italic {
  font-style: italic;
}

.agent-commission-icon {
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-1px);
  }

  @include max-screen($hierarchy-bp) {
    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.agent-details-tooltip {
  position: absolute;
  padding: 10px;
  width: 250px;
  background-color: $grey-7;
  border-radius: 5px;
  left: 150px;
  z-index: $zindex-modal;

  @media screen and (max-width: 480px) {
    & {
      top: 108px;
      left: 0px;
    }
  }

  .tooltip-text {
    color: $grey-1;
    font-size: 12px;

    &.tooltip-text-title {
      font-weight: 600;
    }
  }

  .tooltip-hr {
    background: #fff;
    margin: 0;
    margin-top: 3px;
    margin-bottom: 3px;
  }
}
