.at-email-templates__container {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.at-email-templates__card {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  height: 100%;
  padding: 0 !important;
  border-radius: 8px;
  background-color: #fff !important;
  border: 1px solid #d1d5db;

  .at-email-templates__image-container {
    position: relative;
    display: inline-block;
    color: #3B82F6;
  }
  
  .at-email-templates__duplicate-icon {
    cursor: pointer;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
  }

  .at-email-templates__duplicate-container {
    display: flex;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 32px;
    height: 32px;
    padding: 4px;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #D1D5DB;
    background: #ffffff;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
  }

  .at-email-templates__card-media {
    object-fit: contain;
    object-position: center top;
    min-height: 150px;
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid #d1d5db;
    background-color: #e7e7e7;
  }

  .at-email-templates__card-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 16px;
    gap: 12px;
  }

  .at-email-templates__card-title {
    color: #111827;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
  }
}

.at-email-template__btn-float-group {
  display: flex;
  align-items: center;
  background-color: #E5E7EB;
  border-radius: 8px;
  width: 'fit-content';
  
  svg {
    width: 20px;
    height: 20px;
    fill: #374151;
  }
}

.at-email-templates__form__card {

  .at-input_uploader-cont .filepond--drop-label label::after {
    content: "PNG, JPG, JPEG or GIF (min. 640 x 480)"
  }

  .at-email-templates__typeahead {
    border-radius: 8px;
    border: 1px solid #9ca3af;
    background: #fff;
    padding: 0px 10px;

    .rbt-input-multi {
      display: flex;
      min-height: 40px;
      max-height: 100px;
      background-color: transparent;
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
      font-size: 14px;
      font-style: normal;
      width: 100% !important;
      padding-left: 5px;
      border: none;
      overflow: auto;

      .rbt-input-wrapper {
        overflow: visible;
      }

      .rbt-token {
        margin-bottom: 0;
        line-height: normal;
        color: rgb(28, 78, 216);
        fill: rgb(28, 78, 216);
        background-color: #dbe9fe;
        border-color: rgb(146, 197, 253);
        border-radius: 4px;

        .rbt-token-remove-button {
          top: 0;
        }
      }

      .rbt-input-main {
        color: rgba(#38474f, 0.3);
        font-style: italic;
        -webkit-transition: color 0.3s ease;
        -o-transition: color 0.3s ease;
        transition: color 0.3s ease;
      }

      &.focus {
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }
  }

  .button {
    text-transform: none;
    height: 42px;
  }

  .mobile-button-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 8px;

    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  .email-preview {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 8px;
    overflow: hidden;

    .email-header {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.23);
      
      p { font-size: 12px; }
    }

    .email-body {
      pointer-events: none;

      .iframe {
        min-height: 40vh;
        max-height: 80vh;
      }
    }
  }

  .device-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 474px;
    overflow: hidden;

    .marvel-device-wrapper > div {
      margin: 0 auto;
    }

    .screen {
      overflow: auto !important;

      > div {
        width: 100%;
        height: 100%;
      }
    }

    .device-email-body {
      width: 100%;
      height: 100%;
      background-color: #fff;
      pointer-events: none;
    }
  }
}

.iframe {
  border: none;
  width: 100%;
  height: 100%;
}

.at-email-templates__dialog {
  .at-email-templates__dialog-paper {
    width: 100% !important;

    @media screen and (max-width: 575px) {
      margin: 8px !important;
    }
  }

  .modal-header {
    background-color: #f9fafb;
    border-bottom-color: #d1d5db;

    .modal-title {
      font-size: 20px;
      font-weight: 600;
    }
  }

  .modal-footer {
    border: none;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding-top: 0;
  }
}

.at-email-templates__search-form {
  @media screen and (min-width: 576px) {
    .form-control {
      min-width: 320px;
    }
  }
}

.at-email-template__loader {
  opacity: 1;
}
