.at-datepicker {
  width: 100%;
  display: flex;
  margin-bottom: 0px;
  flex-direction: column;

  div.container--input {
    margin-top: 0 !important;
  }

  > section {
    width: 100%;
    margin: 0px 0px 10px 0px;
    align-items: center;

    > .title {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 5px;

      @include min-screen(1080px) {
        width: auto;
      }
    }

    > .form-group {
      margin: 0;
      flex: 1;
      max-width: 270px;
    }

    @include min-screen(1080px) {
      margin: 0px 10px 0px 0px;
    }
  }

  @include min-screen(1080px) {
    flex-direction: row;
    align-items: flex-end;

    > section {
      width: 33%;
    }
  }
}

.at-datepicker_actions {
  display: flex;

  @include max-screen(1080px) {
    margin-left: auto;
  }
}

.at-datepicker__select {
  min-width: 200px;
}

.at-datepicker__btn {
  height: 38px;
  margin-bottom: 1px;
  max-width: 100px;
}
