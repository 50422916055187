@import "src/stylesheets/atlas/prerequisite";
@import "react-big-calendar/lib/css/react-big-calendar.css";

.at-card--meeting-room-header {
  font-size: 18px;
  font-weight: 600;
  margin-top: 16px;
  margin-bottom: 16px;
}

.at-card--meeting-room-status {
  padding: 8px 0;
  text-align: center;
  align-items: center;
  align-self: stretch;
  color: white;
}

.at-card--meeting-room_day_view_block {
  display: flow;
  overflow: scroll;
  overflow-x: hidden;
  padding: 8px 8px 4px 8px;
  max-height: 150px;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  column-gap: 12px;

  .at-day-view-booking-time {
    width: 45px;
    margin-right: 12px;
    margin-bottom: 4px;
    text-align: center;
    color: #374151;
    font-size: 10px;
    font-weight: 400;
    align-self: center;
  }
  .at-day-view-div_line {
    position: relative;
    width: 1px;
    height: 100%;
    background: #d1d5db;
  }
  .at-day-view-div_dot {
    position: absolute;
    top: 35%;
    left: -260%;
    width: 7px;
    height: 7px;
    background: #3b82f6;
    border-radius: 50%;
  }
  .at-day-view-booking-name {
    margin-left: 12px;
    overflow: hidden;
    color: #111827;
    font-size: 14px;
    font-weight: 500;
    align-self: center;
  }
}

.at-card--meeting-room_booking-caption {
  margin-left: 10px;
  text-align: left;
}

.at-card-booking-form_room_name {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 4px;
}

.at-card-booking-form_room_detail {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.at-card-booking-form_duration_value {
  font-size: 22px;
  font-weight: 600;
  line-height: 120%;
}

.at-card-booking-form_duration_label {
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
}

.at-card--meeting__detail {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 8px;

  svg {
    width: 14px;
    height: 14px;
    min-width: 14px;
    fill: #f0631d;
    margin-right: 4px;

    @include min-screen(768px) {
      width: 16px;
      height: 16px;
      min-width: 16px;
    }
  }
}

.at-table-col__meeting-room-info {
  width: calc(100% - 70px);
}

.at-calendar-cont {
  padding: 16px;
  border-radius: 8px;
  background: #f9fafb;
}

.rbc-time-view {
  border: none !important;
}

.rbc-time-header {
  display: none !important;
}

.at-calendar-header_date {
  font-size: 16px;
  line-height: 24px;
  color: #2563eb;
  font-weight: 600;
  margin-bottom: 8px;
}

.rbc-label {
  font-size: 14px;
  font-weight: 400;
}

.rbc-today {
  background-color: inherit !important;
  border-radius: 3px; /* Optional: add a border-radius for the today cell */
}

.rbc-time-gutter {
  text-align: left !important;
}

.rbc-event {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #3b83f6;
}

.rbc-day-slot .rbc-event-content {
  font-size: 14px;
  font-weight: 500;
}

.rbc-day-slot.rbc-event {
  border: none;
}

.at-card-booking-form_pricing {
  display: flex;
  padding: 20px;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid #D1D5DB;

  > div {
    display: flex;
    padding: 12px 0px;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    border-bottom: 1px solid #D1D5DB;
  }

  svg:not(.at-checkbox__tick) {
    width: 16px;
    height: 16px;
    fill: #F0631D;
  }

  label {
    color: #111827;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
  }

  span {
    color: #1F2937;
    font-size: 14px;
    line-height: 150%;
  }
}

.at-meeting_hub-booking-date_input.grid-half-col {
  @media (min-width: 767px) and (max-width: 1100px) {
    grid-column: span 12;
  }
}