@import "src/assets/stylesheets/atlas/prerequisite";
@import "src/assets/stylesheets/new_atlas/index.scss";

.at-support-group-btn {
  width: 40px;
  height: 40px;

  &.telegram {
    bottom: 0px;
    position: relative !important;
  }
}

.at-support-btn-boundary {
  height: 100vh;
  right: 5px;
  padding: 20px 0;
  position: fixed;
  bottom: 0px;
}

.at-tooltip {
  font-size: 10px;
  display: none;

  @media (hover) {
    display: block;
  }
}

.at-support-pointer {
  content: "";
  width: 10px;
  height: 10px;
  transform: rotate(45deg) translate(16px, 10px);
  background-color: #f3f4f6;
  position: absolute;
  top: 0px;
  left: -9px;
  transition: ease-in-out 0.2s;
}

.at-support-paper {
  background-color: #f3f4f6 !important;
  transform: translateX(-49px) !important;
  box-shadow: none !important;
}

.box {
  background-color: rgba(255, 255, 255, 0.4) !important;
  border-radius: 3px;
  width: 70px;
  height: 70px;
  padding: 10px;
  float: right;
  position: relative;
  transition: background-color 0.2s ease-in-out;
  -webkit-transition: background-color 0.3s ease-out;
  -moz-transition: background-color 0.3s ease-out;
  -o-transition: background-color 0.3s ease-out;

  .at-whatsapp-btn {
    border-radius: 20px;
    transition: box-shadow 0.3s ease-in-out;
  }
}

.box:hover,
.box:active {
  background-color: rgba(255, 255, 255, 0.6) !important;

  .at-whatsapp-btn {
    border-color: #9ecaed;
    box-shadow: 0 0 10px #9ecaed;
  }
}

.cursor-y {
  cursor: pointer;
}

.at-icon-responsive {
  height: 27px !important;
  width: 27px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 840px) {
  .at-whatsapp-btn {
    top: 0;
    bottom: initial;
  }
}

@media (max-width: 600px) {
  .at-icon-responsive {
    svg {
      height: 20px !important;
      width: 20px !important;
    }
  }
  .at-close-responsive {
    height: 24px !important;
    width: 24px !important;
    svg {
      height: 14px !important;
    }
  }
}

/* The switch - the box around the slider */
.at-toggle {
  position: relative;
  display: inline-block;
  min-width: 36px;
  height: 29px;
  margin-right: 10px;
}

/* Hide default HTML checkbox */
.at-toggle .at-toggle__input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.at-toggle-slider {
  height: 20px;
  width: 36px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #bd2130;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.at-toggle-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.at-toggle__input:checked + .at-toggle-slider {
  background-color: #28a745;
}

.at-toggle__input:focus + .at-toggle-slider {
  box-shadow: 0 0 1px #28a745;
}

.at-toggle__secondary {
  .at-toggle-slider {
    background-color: #d1d5db;
  }

  .at-toggle__input:checked + .at-toggle-slider {
    background-color: #3b82f6;
  }

  .at-toggle__input:focus + .at-toggle-slider {
    box-shadow: 0 0 1px #3b82f6;
  }
}

.at-toggle__input:checked + .at-toggle-slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.at-toggle-slider.at-toggle__round {
  border-radius: 34px;
}

.at-toggle-slider.at-toggle__round:before {
  border-radius: 50%;
}

.at-prev-button {
  height: max-content;
  width: max-content;
  padding: 5px 10px;
  box-shadow: -1px 5px 5px -2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: -1px 5px 5px -2px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  background-color: rgba(255, 255, 255, 0.4);
  transition: background-color 200ms ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.6);
  }
}

.at-share_btn_mono {
  circle {
    fill: #7f7f7f;
  }
}

.at-btn__dropdown--paper {
  background-color: #fff !important;
  border: 1px solid #d1d5db !important;
  border-radius: 5px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, .051) !important;
  cursor: pointer;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 5px;
  min-width: fit-content !important;
  padding-bottom: 5px;
  position: absolute !important;
  min-height: fit-content !important;
  max-height: calc(137px * 5) !important;
  padding-bottom: 0px !important;
  margin-top: 5px !important
}

.at-btn__dropdown--list_root {
  min-width: 300px;
  margin: 5px 0px 0px 0px;
  padding: 0px 0px 5px 0px;
}

.at-btn__dropdown--list {
  display: block !important;
  margin-top: 5px !important;
  padding: 10px !important;
  text-align: center !important;
  text-align: left !important;
  width: 100% !important;
  font-size: 14px !important;
  margin-top: 0px !important;
  font-family: Inter, serif !important;
}

.at-btn_dropdown-icon {
  width: 32px !important;
  min-width: 32px !important;
  height: 32px !important;
  border: 1px solid #D1D5DB !important;
  box-shadow: initial !important;
  background-color: #FFFFFF !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 50% !important;
  padding: 0 !important;
}