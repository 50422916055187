@import "mixins";

.at-subsales_listing__view {
  margin-bottom: 16px;
  display: grid;
  overflow-wrap: break-word;
  grid-gap: 16px;
  grid-template-columns: 100%;
  margin-bottom: 8rem;
  padding: 16px;

  @media (min-width: 992px) {
    grid-template-columns: calc((100% - 350px) - 10px) 350px;
    grid-template-areas:
      "header header"
      "left right";
  }

  @media (min-width: 1200px) {
    grid-template-columns: calc((100% - 400px) - 10px) 400px;
    grid-template-areas:
      "header header"
      "left left right"
      "listingTeam listingTeam";
  }

  @media (min-width: 1500px) {
    grid-template-columns: calc((100% - 10px) * 0.6) calc((100% - 10px) * 0.4);
  }
}

.at-subsales_listing__view-header {
  position: relative;
  grid-area: header;
  padding: 16px;
  row-gap: 8px;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto !important;
  background-image: url("https://i.imgur.com/pydM0yr.png");

  display: grid;
  -webkit-box-align: center;
  align-items: start;
  -webkit-box-pack: justify;
  justify-content: space-between;
  grid-template-rows: auto auto;
  grid-template-columns: 65%;
  grid-template-areas:
    "topLeft topRight"
    "middleLeft middleLeft"
    "bottom bottom";
  border-bottom: 1px solid #dadada;

  @media (max-width: 799px) {
    margin: 0px;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: none;
    grid-template-areas:
      "topLeft"
      "topRight"
      "middleLeft"
      "bottom";
    row-gap: 8px;
    background-size: 240% auto !important;
    background-position: 90% 0%;
  }
}

.at-subsales_listing__view-header-topleft {
  grid-area: topLeft;

  @media (max-width: 799px) {
    width: 100%;
    grid-area: topLeft;
  }
  .at-subsales_listing__view-header-topleft_content {
    @media (max-width: 799px) {
      width: 100%;
      grid-area: middleLeft;
    }

    .at-subsales_listing__view-address_title {
      @include at-subsales-title();
    }

    .at-subsales_listing__view-address_subtitle {
      margin: 6px 0px;
      color: #374151;
      font-size: 14px;
      font-weight: 400;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 1.1rem;
      text-transform: capitalize;
    }
  }
}

.at-subsales_listing__view-header-right {
  grid-area: middleLeft;
  align-items: center;
  display: flex;
  flex-direction: row;

  @media (max-width: 799px) {
    width: 100%;
    grid-area: middleLeft;
    flex-wrap: wrap;
    margin-top: 0px;
  }
  @media (min-width: 992px) {
    grid-area: middleLeft;
    margin-right: 0px;
  }

  .at-subsales_listing__price {
    align-items: baseline;

    @media (min-width: 992px) and (max-width: 1279px) {
      display: flex;
      flex-direction: row;
      -webkit-box-align: center;
      align-items: center;
    }
    @media (min-width: 992px) {
      margin-left: 0px;
    }
    @media (max-width: 799px) {
      flex: 1 1 0%;
    }
    @media (max-width: 419px) {
      flex: 1 1 100%;
      text-align: center;
    }
  }

  .at-subsales_listing__feature_property {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-end;

    @media (max-width: 349px) {
      flex-wrap: wrap;
    }

    .at-subsales_listing__feature-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 20px;
      margin-bottom: 5px;

      svg {
        width: 32px;
        height: 32px;
        margin-right: 8px;
        padding: 8px;
        border-radius: 50%;
        background-color: #ffedd5;
      }
    }
  }

  .at-subsales_listing_content {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
    font-style: normal;
    color: #111827;

    @media (max-width: 575px) {
      font-size: 12px;
    }
  }

  .at-subsales_listing_title {
    color: #4b5563;
    white-space: nowrap;
    font-size: 12px;
    line-height: 1.5;
    font-weight: 500;
    font-style: normal;
    @media (max-width: 575px) {
      font-size: 10px;
    }
  }
}

.at-subsales_listing__view-header-button_cont {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: auto;
  grid-area: bottom / bottom / bottom / bottom;
  flex: 1 1 0%;
  position: relative;
  grid-gap: 0.5rem;

  svg {
    width: 16px;
    min-width: 16px;
    height: 16px;
  }

  @media (min-width: 992px) {
    flex: 0 0 auto;
  }
  @media (min-width: 992px) and (max-width: 1279px) {
    grid-area: bottom;
  }
  @media (min-width: 1280px) {
    grid-area: topRight;
  }
}

.at-subsales_listing__view-left_column {
  display: contents;
  grid-gap: 16px;

  @media (min-width: 992px) {
    grid-area: left;
    display: flex;
    flex-direction: column;
  }

  .at-subsales_listing__view-photo_slider_cont {
    position: relative;
    padding: 16px;
    background-color: white;
    border: 1px solid #d1d5db;
    border-radius: 8px;

    .slick-list {
      .slick-track {
        height: 100% !important;
        overflow: hidden;

        .slick-slide {
          img,
          .at-subsales_listing__view-img_cont {
            display: none !important;
            opacity: 0;
            transition: opacity 0.2s;
          }
          &.slick-current.slick-active {
            .at-subsales_listing__view-img_cont,
            img {
              display: block !important;
              left: 0 !important;
              opacity: 1;
            }
          }
        }
      }
    }

    .at-subsales__slick-slider-arrow {
      display: flex !important;
      justify-content: center;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      font-weight: 700;
      transition: all 0.5s;
      align-items: center;
      z-index: 1;

      svg {
        width: 2rem;
        height: 2rem;
        fill: white;
      }

      &::before {
        content: "";
      }
    }
    .at-subsales__slick-slider-arrow.slick-next {
      right: 0.4rem;
    }
    .at-subsales__slick-slider-arrow.slick-prev {
      left: 0.4rem;
    }

    .at-subsales_listing__view-img_cont {
      grid-area: gallery;
      width: calc(100% - 1px) !important;
      border-radius: 8px;
      overflow: hidden;
      min-height: 200px;
      background-color: white;
      transition: all 0.01s;
      .at-subsales_listing__view-img {
        height: 350px;
        max-height: 350px;
        object-fit: cover;

        img {
          height: 350px;
        }

        @media (max-width: 575px) {
          max-height: 250px;
        }
      }
    }

    .at-subsales_listing__view-slider_tab {
      background-color: #d1d5db;
      border-radius: 8px;
      width: fit-content;
      margin: auto;
      margin-top: 8px;
      button {
        height: 40px;
        padding: 2px 10px;
        color: #1f2937;
        font-size: 14px;
        font-weight: 500;
        transition:
          border 0.2s,
          color 0.2s,
          background-color 0.2s;

        &.at-selected-button {
          background-color: #ffffff;
          border: 1px solid #d1d5db;
          box-shadow:
            0px 1px 1px rgba(0, 0, 0, 0.08),
            0px 2px 1px rgba(16, 24, 40, 0.06),
            0px 1px 3px rgba(16, 24, 40, 0.1);
          border-radius: 8px;
        }
      }
    }
  }

  .at-subsales_listing__view-description {
    overflow-anchor: none;
    padding: 16px;
    background-color: white;
    border: 1px solid #d1d5db;
    border-radius: 8px;

    h2 {
      font-size: 24px;
      margin-bottom: 8px;

      @media (max-width: 575px) {
        font-size: 18px;
        margin-bottom: 8px;
      }
    }

    .at-subsales_listing__view-description_tab {
      margin: 16px 0px;
      border-bottom: 1px solid #d1d5db;

      button {
        margin: 0px 8px 0px 0px;
        padding: 8px 16px;
        font-size: 14px;
        font-weight: 500;
        color: #374151;
        transition:
          color 0.3s,
          border 0.3s;

        &.at-subsales_listing_view-selected_description {
          color: #f0631d;
          border-bottom: 2px solid #f0631d;
        }
      }
    }

    .at-subsales_listing__view-description-content {
      font-size: 14px;
      line-height: 1.5;
      font-weight: 400;
      font-style: normal;

      .at-subsales_listing__view-description_eng,
      .at-subsales_listing__view-description_zh {
        opacity: 0.1;
        transition: opacity 0.2s;

        &.at-subsales_listing_view-selected_description {
          white-space: break-spaces;
          opacity: 1;

          tr {
            @media (max-width: 419px) {
              display: flex;
              flex-direction: column;
            }

            td:first-child {
              background-color: #f8f8f8;
            }
          }
        }
        @media (max-width: 575px) {
          font-size: 14px;
        }
      }

      p {
        line-height: 1.5rem;
      }
      .at-subsales_listing__view-description_zh {
        margin-top: 8px;
      }
    }
  }
}

.at-subsales-listing__view-title {
  font-size: 18px;
  font-weight: 600;
  color: #111827;
  margin-bottom: 16px;

  svg,
  .at-subsales-listing__view-title-img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #ffedd5;
    padding: 6px;
  }
}

.at-subsales_listing__view-photo_modal {
  background-color: #ffffffaa !important;
  backdrop-filter: blur(4px);
  box-shadow:
    0px 11px 15px -7px rgba(255, 255, 255, 0.12),
    0px 24px 38px 3px rgba(255, 255, 255, 0.12),
    0px 9px 46px 8px rgba(255, 255, 255, 0.12) !important;
  @media (max-width: 799px) {
    margin: 0 !important;
  }

  .slick-track {
    overflow: hidden;
    .at-subsales_listing__view-img_cont {
      position: relative;
      max-height: 90vh;
      height: 85vh;
    }
    img {
      position: absolute;
      object-fit: contain;
      height: 100%;

      @media (max-width: 799px) {
        max-height: 100%;
        min-width: 100vw;
      }
    }
  }
  .slick-slide {
    opacity: 0.5 !important;
    transition: 0.3s;
    .at-subsales_listing__view-img_cont,
    img {
      display: none !important;
      opacity: 0;
      transition: all 0.2s;
    }
    &.slick-active {
      opacity: 1 !important;
      .at-subsales_listing__view-img_cont {
        display: flex !important;
        width: 100%;
        opacity: 1;
        justify-content: center;
        align-items: center;

        image-rendering: pixelated;
      }
      img {
        display: block !important;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
        opacity: 1;
        background-color: #ffffff55;
      }
    }
  }
  .at-subsales__slick-slider-arrow {
    display: flex;
    width: 2rem;
    height: 5rem;
    min-height: 5rem;
    justify-content: center;
    align-items: center;
    background-color: #00000033;
    color: #fafdfe;
    font-weight: 700;
    z-index: 1;

    &:hover {
      background-color: #00000055;
    }

    &::before {
      font-size: 5rem;
      line-height: 0;
      max-height: 2rem;
      height: 2rem;
      flex: 0 0 50%;
    }
  }

  .at-subsales__slick-slider-arrow.slick-next {
    &::before {
      content: "›";
    }
    right: 1px;
  }
  .at-subsales__slick-slider-arrow.slick-prev {
    &::before {
      content: "‹";
      font-size: 5rem;
    }
    left: 1px;
  }
}

.at-subsales_listing__view-media-link {
  a {
    display: block;
    color: #2563eb !important;
  }
  li {
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    padding-top: 8px;
    line-height: 1.5;
    color: #2563eb;
    text-decoration: underline;

    svg {
      width: 16px;
      height: 16px;
      fill: #2563eb;
      margin-right: 8px;
    }

    @media (min-width: 992px) {
      line-height: inherit;
      padding-right: 4px;
    }
    @media (max-width: 575px) {
      font-size: 14px;
    }
  }
}

.at-subsales_listing__view-right_column {
  display: contents;
  grid-gap: 16px;

  @media (min-width: 992px) {
    grid-area: right;
    display: flex;
    flex-direction: column;
  }

  .at-subsales_listing__view-facilities {
    grid-area: facilities;
    padding-left: 24px;
    padding-right: 24px;

    @media (max-width: 799px) {
      margin: 0px;
      padding: 1rem;
      background-color: #fafdfe;
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.12);
    }

    ul {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding-left: 0px;
      list-style-type: none;
      margin: 0px;
      column-gap: 24px;

      @media (max-width: 319px) {
        display: flex;
        flex-direction: column;
      }

      li {
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        padding: 8px 0px;
        line-height: 1.5;
        list-style: none;
        border-bottom: 1px solid #dadada;

        @media (min-width: 992px) {
          line-height: inherit;
          padding-right: 4px;
        }
        @media (max-width: 575px) {
          font-size: 14px;
        }
      }
    }
  }

  .at-subsales-listing__view-details-item {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 12px;
    border-bottom: 1px solid #dadada;
    flex-wrap: wrap;
    grid-gap: 0.5rem;

    label {
      font-size: 14px;
      font-weight: 500;
      color: #111827;
      min-width: 140px;
      width: 140px;

      svg {
        width: 16px;
        height: 16px;
        fill: #f0631d;
        margin-right: 8px;
      }
    }
    p {
      font-size: 14px;
    }
  }

  .at-subsales_listing__view-details,
  .at-subsales_listing__view-media-link,
  .at-subsales_listing__view-published_platforms,
  .at-subsales_listing__view-features,
  .at-subsales_listing__view-facilities,
  .at-subsales_listing__view-listing_agents,
  .at-subsales-listing__view-cobroke-settings {
    padding: 16px;
    background-color: white;
    border: 1px solid #d1d5db;
    border-radius: 8px;
  }

  .at-subsales-listing__view-cobroke-settings {
    .at-subsales-listing__view-cobroke-settings-item {
      display: inline-grid;
      width: 32%;
      height: 100%;
      grid-template-rows: 1fr 1fr 1fr;
      font-size: 18px;
      font-weight: 600;
      color: #111827;
      text-align: center;
      padding: 12px 8px;
      border: 1px solid #d1d5db;
      border-radius: 8px;
      grid-gap: 0.5rem;
      align-items: center;

      svg {
        width: 32px;
        height: 32px;
        padding: 6px;
        border-radius: 50%;
        margin: auto;
      }

      label {
        font-size: 12px;
        font-weight: 500;
        color: #374151;
        margin-bottom: auto;
      }
    }
  }
  .at-subsales_listing__view-other_facilities {
    margin-top: 1.5rem;
  }

  .at-subsales_listing__view-features {
    grid-area: subsalesFeatures;
  }

  .at-subsales_listing__view-details {
    grid-area: subsalesDetails;
    margin-top: 0px;
  }
  .at-subsales_listing__view-published_platforms {
    grid-area: subsalesPublishedPlatforms;

    .at-subsales_listing_view-published_platforms-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 12px;
      margin-top: 12px;
      background: #ffffff;
      border: 1px solid #d1d5db;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
      border-radius: 8px;
      font-size: 15px;
      font-weight: 500;
      color: #1f2937;

      img {
        width: 20px;
        height: 20px;
        margin-right: 0.5rem;
      }

      svg {
        width: 20px;
        height: 20px;
        fill: #374151;
      }
    }
  }

  .at-subsales_listing__view-listing_agents {
    grid-area: listingAgent;
    .at-subsales_listing__view-listing_agents-cont {
      display: grid;
      grid-template-columns: 125px calc(100% - 126px);
      grid-template-areas:
        "profilePicture agentDetailCont"
        "profilePicture agentDetailCont"
        "profilePicture agentDetailCont"
        "agentContact agentContact";
      margin: auto;

      @media (min-width: 992px) and (max-width: 1199) {
        grid-template-areas:
          "profilePicture"
          "agentDetailCont"
          "agentContact";
        grid-template-columns: minmax(10rem, 1fr);
        grid-gap: 0.5rem;
      }
      @media (max-width: 449px) {
        grid-template-areas:
          "profilePicture"
          "agentDetailCont"
          "agentContact";
        grid-template-columns: minmax(10rem, 1fr);
        grid-gap: 0.5rem;
      }

      .at-subsales_listing__view-agent_avatar {
        grid-area: profilePicture;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
        object-position: top;
        margin: auto;

        @media (min-width: 992px) and (max-width: 1199) {
          width: 150px;
          height: 150px;
          max-width: initial;
        }
        @media (max-width: 449px) {
          width: 200px;
          height: 200px;
          max-width: initial;
        }
        @media (max-width: 379px) {
          width: 150px;
          height: 150px;
          max-width: initial;
        }
      }

      .at-subsales_listing__view-agent_info {
        grid-area: agentDetailCont;

        @media (min-width: 992px) and (max-width: 1199) {
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        @media (max-width: 449px) {
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        [class^="at-subsales_listing__view-agent_"] {
          margin-left: 10px;
          width: fit-content;
        }
      }

      .at-subsales_listing__view-agent_name {
        margin-bottom: 0.3rem;
      }
      .at-subsales_listing__view-agent_email,
      .at-subsales_listing__view-agent_contact {
        word-break: break-word;
      }
    }
  }

  .at-subsales-listing__view-agent_btn {
    display: flex;
    grid-gap: 0.5rem;
    width: 100%;

    @media (max-width: 575px) {
      flex-direction: column;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      flex-direction: column;
    }
  }
}
