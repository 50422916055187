.at-event__checkout {
  width: 900px;

  @media screen and (max-width: 992px) {
    width: 85vw;
  }

  @media screen and (max-width: 576px) {
    width: 100%;
  }
}

.at-event__checkout-paper {
  @media screen and (max-width: 576px) {
    margin: 5px !important;
    max-height: calc(100% - 5vh) !important;
  }
}

.at-event__checkout-event-title {
  text-align: center;

  h3 {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 5px;
  }
  p {
    color: grey;
    font-weight: 500;
    font-size: 16px;
  }
}

.at-event__checkout-event-date_time_cont {
  span {
    vertical-align: middle;
  }

  svg,
  .at-event__checkout-event-date,
  .at-event__checkout-event-time {
    margin: 0 0.2rem;
  }
}

[class^="at-event__checkout-event-ticket-container"] {
  position: relative;
  border: 2px solid #ebebeb;
  border-radius: 10px;
  transition: all 0.3s ease-in;
  overflow: hidden;

  .at-card__title {
    font-weight: 500;
    text-align: center;
    margin-bottom: 0.5rem;
  }
}

.at-event__checkout-event-ticket-container_enabled {
  cursor: pointer;
  &:hover {
    border: 2px solid #dd9334;
    background-color: #fdf2e1;
    box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.28);

    .svg-icon-tick {
      opacity: 1 !important;
    }
  }

  &.active {
    cursor: default !important;
    border: 2px solid #dd9334;
    background-color: #fdf2e1;
  }
}

.at-event__checkout-event-ticket-container_disabled {
  cursor: no-drop;
  border: #bebebe;

  .at-event__checkout-event-ticket-banner {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #80808065;
    backdrop-filter: blur(0.5px);
    z-index: 1;
  }

  .at-event__checkout-event-ticket {
    p {
      color: #8d8982;

      &:last-child {
        color: #545454;
      }
    }
  }

  .at-event__checkout-event-sold_out_icon {
    svg {
      z-index: 1;
      max-width: 6rem;
      min-width: 6rem;
      max-height: 6rem;
      min-height: 6rem;
      margin: auto;
      transition: all 0.3s ease-in-out;
    }
  }
}

.at-event__checkout-event-selected_icon {
  svg {
    color: #2ecc71;
    background-color: #ffffff;
    border-radius: 50%;
    max-width: 3rem;
    min-width: 3rem;
    max-height: 3rem;
    min-height: 3rem;
    margin: auto;
    transition: all 0.3s ease-in-out;
  }
}

.at-event__checkout-event-ticket {
  padding: 5px 10px;
  margin: 5px;
  transition: all 0.4s ease-in-out;

  p {
    font-size: 16px;
    font-weight: 500;
    color: #212527;
    transition: all 0.1s linear;

    &:last-child {
      color: #8d8982;
    }
  }
}

.at-event__checkout-event-ticket-total {
  display: flex;
  flex-wrap: wrap;
  font-weight: 600;
  margin-top: 0.8rem;
  p {
    font-size: 20px;
    font-weight: 500;
  }
}

.at-event__checkout-bank-in {
  color: grey;
  margin-top: 10px;

  .filepond--drop-label {
    cursor: pointer;

    label {
      cursor: pointer;
    }
  }
}

.at-event__checkout-payment-option {
  min-width: 300px;
  border-radius: 10px;
  border: 3px solid #cbcbcb;
  padding: 3px;
  color: #000;
  display: flex;
  align-items: center;
  font-weight: 500;
  height: 50px;
  flex: 1 1;
  transition: all 0.3s ease-in-out;
  justify-content: center;

  svg {
    fill: #000;
  }

  &:hover:not(.at-event__checkout-payment-option-selected) {
    border: 3px solid #3b82f6;
    background-color: #fff;
  }
}

.at-event__checkout-payment-option-selected,
.at-event__checkout-payment-option:active {
  border: 3px solid #3b82f6;
  color: black;
  background-color: #fff;

  svg {
    fill: black;
  }
}
