@import "../../atlas/prerequisite";

.at-pagination {
  display: flex;
  flex-wrap: wrap;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 16px;
  border: 1px solid #d1d5db;
}

.at-pagination__button {
  width: fit-content;
  min-width: 30px;
  height: 30px;
  font-size: 12px;
  color: #1f2937;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  transition: background-color 0.2s ease;
  border-right: 1px solid #d1d5db;

  @include min-screen(900px) {
    min-width: 40px;
    height: 40px;
    font-size: 14px;
  }

  &:last-child {
    border-right: 0px;
  }

  &:hover {
    background-color: #f9fafb;
  }

  &.active {
    background-color: #f0631d;
    color: white;
    @include at-font(600);
  }
}

.at-pagination__button-arrow {
  svg {
    fill: #1f2937;
  }

  &:hover svg {
    fill: #111827;
  }

  &.disabled {
    color: #d1d5db;
    pointer-events: none;
    svg {
      fill: #d1d5db;
    }
  }
}

.at-pagination__arrow {
  transition: background-color 0.2s ease;
  pointer-events: none;
  z-index: 1;
  width: 12px;
  height: 12px;

  @include max-screen(576px) {
    width: 10px;
    height: 10px;
  }
}
