[class^="svg-icon"] {
  display: inline-block;
}

.svg-icon-bell {
  @include sprite-size(33px, 38px);
}
.svg-icon-book {
  @include sprite-size(32px, 26px);
}
.svg-icon-cake {
  @include sprite-size(32px, 32px);
}
.svg-icon-calculator {
  @include sprite-size(32px, 44px);
}
.svg-icon-calendar {
  @include sprite-size(33px, 36px);
}
.svg-icon-cart {
  @include sprite-size(33px, 33px);
}
.svg-icon-chevron {
  @include sprite-size(32px, 20px);
}
.svg-icon-cross {
  @include sprite-size(34px, 33px);
}
.svg-icon-disabled {
  @include sprite-size(33px, 33px);
}
.svg-icon-envelope {
  @include sprite-size(34px, 33px);
}
.svg-icon-exchange {
  @include sprite-size(32px, 25px);
}
.svg-icon-house {
  @include sprite-size(33px, 26px);
}
.svg-icon-hierarchy-sales {
  @include sprite-size(33px, 35px);
}
.svg-icon-list {
  @include sprite-size(33px, 26px);
}
.svg-icon-logoff {
  @include sprite-size(33px, 25px);
}
.svg-icon-mail {
  @include sprite-size(32px, 26px);
}
.svg-icon-megaphone {
  @include sprite-size(33px, 34px);
}
.svg-icon-mobile {
  @include sprite-size(32px, 54px);
}
.svg-icon-performance {
  @include sprite-size(33px, 36px);
}
.svg-icon-person {
  @include sprite-size(20px, 20px);
}
.svg-icon-phone {
  @include sprite-size(32px, 32px);
}
.svg-icon-project-sales {
  @include sprite-size(33px, 31px);
}
.svg-icon-recruit-growth {
  @include sprite-size(33px, 32px);
}
.svg-icon-sales-history {
  @include sprite-size(33px, 39px);
}
.svg-icon-star {
  @include sprite-size(22px, 21px);
}
.svg-icon-statistic {
  @include sprite-size(33px, 33px);
}
.svg-icon-team-sales {
  @include sprite-size(33px, 34px);
}
.svg-icon-team {
  @include sprite-size(33px, 32px);
}
.svg-icon-time {
  @include sprite-size(33px, 33px);
}
.svg-icon-query {
  @include sprite-size(21px, 32px);
}
.svg-icon-voucher {
  @include sprite-size(33px, 33px);
}
.svg-icon-zoom_meeting {
  @include sprite-size(30px, 32px);
  fill: rgb(63, 126, 245);
}
.svg-icon-user-person {
  @include sprite-size(33px, 33px);
}
