@import "src/stylesheets/atlas/atlas.scss";

.input-group-addon.input-group-prepend {
  i {
    margin: auto;
  }
  margin-right: 1px;
  padding: 0px 10px;
}
.input-group.has-right-slot input {
  border-right: 0;
}
div.has-label {
  display: block;
}
div.container--input {
  display: flex;
  width: 100%;
  flex-direction: column;

  .el-input__inner {
    border: 0px;
    height: 30px;
    width: 100%;
    padding-left: 0px !important;

    &::placeholder {
      color: #6B7280 !important;
    }
    &:focus {
      &::placeholder {
        color: #6b728080 !important;
      }
    }
  }
}

.el-picker-panel.el-date-picker {
  z-index: 9999 !important;
}

.form-group {
  & input:focus {
    background-color: #ffffff;
    border: 1px solid #aaaaaa;
    @include box-shadow(none);
    outline: 0 !important;
    color: #333333;
  }

  &.has-success input,
  &.has-error input,
  &.has-success input:focus,
  &.has-error input:focus {
    @include box-shadow(none);
  }

  &.has-success:focus input {
    border-color: #87cb16;
    color: #87cb16;
  }
  &.has-error input {
    border: 1px solid #fb404b;
    color: #fb404b;
  }
  &.has-error input:focus {
    border: 2px solid #fb404b;
  }

  & + .form-control-feedback {
    border-radius: 6px;
    font-size: 14px;
    margin-top: -7px;
    position: absolute;
    right: 10px;
    top: 50%;
    vertical-align: middle;
  }
}

.at-error-input {
  color: #fb404b !important;
}
