@import "src/stylesheets/atlas/prerequisite";

.at-order-number {
  display: inline-block;
  padding: 4px 10px;
  color: rgb(255, 255, 255);
  border-radius: 12px;
  background-color: rgb(135, 203, 22);
  margin-top: 10px;
  text-align: center;
  font-size: 0.8em;
}

.at-order_mngmt-expansion-panel {
  margin-top: 0px !important;
  margin-bottom: 20px !important;

  .at-order-panel-summary {
    background: $tangerine;
    min-height: 48px !important;
  }
  .at-order-panel-summary-content {
    margin: 0px !important;

    .at-form-input__title {
      font-size: 16px;
      color: $white;
    }
  }
  .at-order-panel-expand {
    display: block;
    padding: 24px 24px 24px;

    .outline-box {
      outline: 1px solid #c2c2c2;
      padding: 15px;
      margin-left: 0;
      margin-right: 0;
    }
  }
  .at-order-panel-details {
    display: grid;
    padding: 0;

    .outline-box {
      outline: 1px solid #c2c2c2;
      padding: 15px;
      margin-left: 0;
      margin-right: 0;
    }

    .at-order-personal-details {
      padding: 10px;
      display: grid;
      overflow-wrap: anywhere;
    }
  }
  .at-order-panel-summary {
    background: #f18e05;
    min-height: 48px !important;
  }
  .at-order-panel-summary-content {
    margin: 0px !important;

    .at-form-input__title {
      font-size: 16px;
      color: #ffff;
    }
  }

  .panel-disabled {
    cursor: auto !important;
  }
}

.btn-clear {
  text-align: left;
  @include max-screen(600px) {
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
}

.profile-card {
  padding: 12px;
  width: 100%;
  border: 1px lightgray solid;

  @include min-screen(600px) {
    max-width: 550px;
  }
  @include max-screen(992px) {
    max-width: 400px;
    text-align: center;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 7px;
    font-size: 14px;
  }
}

.at-order-img__block {
  display: flex;
  justify-content: center;

  & img {
    width: 100px;
    object-fit: contain;
    border: 2px solid #f18e05;
    border-radius: 15px;
    margin: auto;
    background-position: top;
  }
}

.at-order-personal__info {
  margin-top: 20px;
  text-align: center;

  & p {
    font-size: 13px;
  }
}

.at-order__order-item {
  width: 100%;
  padding-right: 0;

  .at-table-row.grid-controller {
    padding: 20px 30px;
  }
  .at-order-mngmt-table-content {
    @media (max-width: 575px) {
      margin: 0 -1rem;
    }
  }
}

@media screen and (max-width: 576px) {
  .at-order__order-item {
    order: 2;
  }
  .at-order__agent-info {
    order: 1;
  }
}

.at-order__order-detail_cont {
  .at-card {
    box-shadow:
      0 1px 9px rgba(0, 0, 0, 0.06),
      0 1px 8px rgba(0, 0, 0, 0.1);
  }
  .at-card--profile_card .at-card--profile_card-img {
    top: 1rem;
  }
  .at-card--profile_card-top,
  .at-card--profile_card-btm {
    > div {
      display: flex;
    }
    p {
      width: 30%;
      display: inline-block;
      max-width: 22rem;

      @media (max-width: 767px) {
        width: 50%;
      }

      @media (max-width: 565px) {
        width: 100%;
      }
    }
  }
}

.at-order-activity {
  > div {
    margin: 1rem;
  }

  .timeline::before {
    content: none;
  }
}
