.at-analytic-centre__summary-card {
  width: 50%;

  p {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    margin-top: 10px;
    font-weight: 500;
  }

  span {
    width: 100px;
    font-weight: 400;
  }

  @media screen and (max-width: 991px) {
    width: 100%;
  }
}

.at-analytic-centre__graph-cont {
  border: 1px solid #dbe2e6;
  border-radius: 8px;
  background: white;
  padding: 20px;
  margin-top: 20px;
}

.at-analytic-centre__badge {
  margin: 5px;
  background: #4b5563;
  display: flex;
  align-items: center;
  color: white;
  border-radius: 4px;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 500;
}
