.paper {
  background-color: #eaf3f9 !important;
}

.btn-tooltip {
  @media (max-width: 600px) {
    display: none;
  }
}

.popper {
  opacity: 1 !important;
}

.tool-tip {
  font-size: 14px !important;
  max-width: 500px !important;
}

.snackbar-root {
  max-width: 100% !important;
}

.at-switch-button__checked {
  transform: translateX(50px) !important;
}

.at-switch-button__bar {
  height: 27px !important;
  top: 38% !important;
  left: 54% !important;
  width: 70px !important;
  border-radius: 2em !important;
}

.at-switch-button__icon {
  width: 30px !important;
  height: 30px !important;
}

.at-switch-button__active-icon {
  color: #f18e06 !important;
}

.at-switch-button__active-bar {
  background-color: #f18e06 !important;
}

.at-switch-button__active-label {
  left: 33px !important;
  color: #ffff !important;
}

.at-switch-button__inactive-label {
  right: -5px !important;
  color: rgba(255, 255, 255, 0.5) !important;
}

.at-subsale_listing__container {
  display: grid;
  grid-gap: 0.6rem;
  grid-template-columns: repeat(auto-fill, minmax(21rem, 1fr));

  @media (max-width: 419px) {
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  }
}

.media {
  display: block !important;
}

.at-listing__admin-platform-btn {
  color: #f0631d;
  width: 32px;
  position: fixed;
  right: 30px;
  bottom: 50px;
  border-radius: 50%;
  background-color: white;
  transition: all 0.2s;
  z-index: 10;
}