@import "src/stylesheets/atlas/prerequisite";

.at-namecard-mngmt__container {
  background-color: $mirage;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 20px;

  @include min-screen(768px) {
    padding: 20px;
    grid-gap: 20px;
  }
}

.at-namecard-mngmt-title {
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.at-namecard-mngmt-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  h4 {
    font-size: 16px;
    font-weight: 600;
  }
}

.at-namecard-mngmt__item {
  display: grid;
  min-height: 280px;
  gap: 15px;
  padding: 10px;
  background-color: #ffffff;
  margin-bottom: 10px;
  grid-template-columns: repeat(6, 1fr);

  @include min-screen(991px) {
    &:nth-child(2n) {
      background-color: #f3f4f6;
    }
  }

  @include max-screen(768px) {
    grid-template-columns: 1fr;
  }
}

.at-namecard-mngmt__image {
  @media (max-width: 768px) {
    justify-content: center !important;
    background-color: #ffffff;
  }
  display: flex !important;

  @media (min-width: 768px) {
    align-items: center !important;
  }
}

.at-namecard-mngmt__img-cont {
  margin: auto;
  width: 300px;
  height: 315px;
  border: 2px solid;
  border-radius: 3px;
  object-fit: cover;

  @media (max-width: 576px) {
    min-width: 100%;
  }
}

.at-namecard-mngmt__title-cont {
  grid-column: 1/-1;
  grid-row: 1;
  display: flex;
  justify-content: space-between;

  @include max-screen(768px) {
    grid-row: 1/2;
    grid-column: 1/2;
  }
}

.at-namecard-mngmt__details-cont {
  border: 1px solid #d1d5db;
  grid-column: 1/-1;
  z-index: 2;
  overflow: auto;

  p {
    font-size: 13px;
    font-weight: 500;
    @include max-screen(768px) {
      font-size: 15px;
    }
  }

  @include max-screen(768px) {
    grid-row: span 1;
    grid-column: 1/2;
    max-height: 100%;
  }
}

.at-namecard-mngmt-table-content {
  padding: 20px !important;
}

.at-namecard-mngmt-list-content_title {
  padding-right: 10px;
  font-weight: 600;
}
