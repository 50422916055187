@import "../../../../../stylesheets/atlas/prerequisite";

.at-subsales-search_form {
  @media (max-width: 767px) {
    margin: 0 -15px;
  }
}

.at-subsales__item {
  position: relative;
  border-radius: 4px;
  border: 1px solid #d1d5db;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .at-subsales-photo_slider {
    div {
      height: 12rem;
    }
    .at-subsales__slick-initialized {
      height: 100%;
    }

    .at-subsales__slick-slider-arrow {
      z-index: 1;
      top: 55%;
      display: flex;
      align-items: center;
      height: fit-content;
    }
    .at-subsales__slick-slider-arrow.slick-prev {
      left: 0.4rem;
    }
    .at-subsales__slick-slider-arrow.slick-next {
      right: 0.4rem;
    }
  }
}

.at-subsales-agent_info-img {
  width: 50px;
  height: 50px;
  position: absolute;
  border-radius: 50%;
  border: 1px solid white;
  z-index: 1;
  top: 10px;
  right: 10px;
  object-fit: cover;
}

.at-subsales-slider_item {
  gap: 0px;
  padding: 0px;
  margin: auto;
  transition:
    box-shadow 0.25s,
    transform 0.25s;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 3px 0px;

  &:hover {
    transform: translate(0px, 0.5px);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 6px 0px;
  }
}

.at-subsales__footer {
  .at-subsales__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ecedf0;
    position: relative;
    width: 100%;
    height: 60px;
    padding: 0 0.5rem;
    border-top: 1px solid #e9e8e8;

    svg {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }

    & .at-subsales-actions-request {
      width: max-content;
      font-size: 0.7rem;
      color: #ffffff;
      padding: 0.2rem 0.4rem;

      svg {
        width: 1rem;
        margin-right: 0.3rem;
      }
      border-radius: 0.12rem;
    }
  }

  .at-subsales-published_platforms {
    display: inline-flex;
    flex-wrap: wrap;
  }
}

.at-subsales__image {
  position: relative;
  z-index: 2;
}

.at-subsales-available_date {
  span {
    color: #374151 !important;
    font-weight: 500 !important;
    font-size: 14px;
    margin-left: 10px;
    font-family: sans-serif;

    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  .at-subsales__icon {
    margin-right: 10px;
    align-items: center;
    svg {
      width: 14px;
      height: 14px;
      fill: #748088 !important;
      margin-left: 0.2rem;
    }
  }
}

.at-subsales__status {
  display: flex;
  align-items: center;
  padding-top: 8px;
  margin: 10px;
  margin-top: 0px;
  border-top: 1px solid #d1d5db;
}

.at-subsales__info {
  display: flex;
  flex-wrap: wrap;
  font-size: 10px;
  margin: 0.3rem 0;

  & span {
    display: flex;
    align-items: center;
    padding: 5px 8px;
    background: #4b5563;
    border-radius: 4px;
    color: white;
    margin: 1px 1px;
    font-size: 12px;
    font-weight: 500;
    font-family: sans-serif;
    @media (max-width: 767px) {
      font-size: 10px;
    }
  }
}
.at-subsales__specs {
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  & .at-subsales_tooltip-content {
    display: none;
  }

  & .at-subsales_tooltip-title,
  & .at-subsales_tooltip-content {
    color: #1f2937 !important;

    @media (max-width: 575px) {
      display: inline-block !important;
    }
  }
}

[class^="at-subsales-project_icon"] {
  width: fit-content;
  & span {
    font-size: 12px !important;
  }
}

.at-subsales__details-upper_cont {
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: 3;
  margin-top: -1px;
}

.at-subsales__details-lower_cont {
  min-height: calc(100% - 12rem);
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  z-index: 3;

  @media (max-width: 576px) {
    justify-content: flex-end;
  }
}

.at-subsales__details-information {
  margin: 12px 16px;
  height: calc(100% - 3rem);
}

.at-subsales__img-cont {
  position: relative;
  height: 12.5rem;

  @media (max-width: 420px) {
    padding: 0px;
  }

  img {
    margin: auto;
    object-position: 50% 50%;
    border-bottom: 0px;
  }
}

.at-subsales__gradient_foreground {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  scale: 1.2;
  background-color: #ece6dd;
  z-index: -1;
  filter: blur(0.8rem);
  background-size: cover;
  background-position: 50% 8%;
}

[class*="at-subsales__props"] {
  min-height: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 5px !important;
  margin-top: 2px;
  flex: 0 0 100%;

  .at-subsales__icon {
    margin-right: 10px;
    margin-bottom: 2px;
    align-items: flex-start;
  }
  .at-subsales__props-feature .at-subsales__icon {
    align-items: flex-end;
  }

  & span {
    color: #212529 !important;
    font-weight: 400 !important;
    font-size: 14px;
    font-family: sans-serif;

    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  & svg {
    width: 14px;
    height: 14px;
    fill: #6b7280 !important;

    @media (max-width: 575px) {
      margin: 0px 5px 0px 0px !important;
      width: 20px !important;
    }
    @media (max-width: 767px) {
      width: 15px;
      height: 13px;
    }
  }

  @media (max-width: 575px) {
    & div {
      margin: 2px 0px !important;
    }
  }
}

.at-subsales__props-built_up__land_area,
.at-subsales-available_date {
  display: inline-flex;

  &:not(.at-subsales__props-built_up__land_area) span {
    min-width: 100px;
  }
}

.at-subsales__actions-favourite {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  position: absolute;
  right: 10px;
  top: 10px;

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: darken(#ffffff, 5%);
  }
}

.at-subsales__code {
  position: relative;
  padding: 2px 8px;
  background-color: #000000;
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  border-radius: 4px;
  display: inline-block;
  transform: translate(8px, 8px);
}

.at-subsales__address {
  margin: 6px 0px;
  color: #374151;
  font-size: 12px;
  font-weight: 500;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.1rem;
  text-transform: capitalize;
}

.at-subsales__price {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 500;
  color: #c2410c;
}

.at-subsales__title {
  max-height: 1.7rem;
  font-size: 16px;
  color: #111827;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 150%;
  width: 100%;
  flex: 0 0 15%;
}

.at-subsales__actions-view {
  font-size: 14px;
  font-weight: 500;
  color: #3b82f6;

  svg {
    width: 16px;
    height: 16px;
    margin-left: 10px;
    fill: #3b82f6;
  }
}

.at-subsales__dropbear-modal-root {
  cursor: pointer;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #d1d5db;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.0509803922);
  position: absolute !important;
  padding-bottom: 5px;
  bottom: 15px;
  right: 10px;
  z-index: 999;
}

.tooltip-arrow {
  background-color: #000000ee !important;
  font-size: 14px !important;
  padding: 0.5rem !important;
  position: relative;

  &.bottom::after {
    content: " ";
    position: absolute;
    top: -20px; /* At the top of the tooltip */
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #000000ee transparent;
  }

  &.top::after {
    content: " ";
    position: absolute;
    bottom: -19px; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: #000000ee transparent transparent transparent;
  }
}
