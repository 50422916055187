.at-template__motd-image {
  max-height: 80vh;
  object-fit: fill;
  border-radius: 25px;
}

.at-template__motd-overlay {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 99;
}

.at-at-template__motd-play-btn-container {
  position: absolute;
  bottom: 10%;
  display: flex;
  justify-content: center;
  width: 100%;
  left: 0px;

  //For Convention 2023
  flex-wrap: wrap;
}

.at-template__motd-play-btn {
  width: 200px;
  border-radius: 25px !important;
  margin: auto;
  height: 50px;
  background-color: #f18e05;
  font-weight: 700 !important;
  font-size: 20px !important;
}

.at-template__motd-covention-btn {
  width: auto;
  border-radius: 25px !important;
  margin: auto;
  height: 50px;
  background-color: #f18e05;
  font-weight: 700 !important;
  margin-top: 5px;
  margin-left: 5px;
  font-size: 14px !important;
}

.at-template__motd-close-btn {
  margin: auto;
  margin-top: 10px;
  height: 30px;
  padding: 0 20px !important;
  border-radius: 25px !important;
  background-color: red;
  font-size: 15px !important;
  transition: font-weight ease-in-out 0.1s !important;
}

.at-template__motd-close-btn:hover {
  font-weight: 700 !important;
}

@media screen and (min-width: 610px) {
  .at-template__motd-play-btn:hover {
    border: 3px solid #f18e05 !important;
    background-color: transparent;
    color: #f18e05;
  }

  .at-template__motd-covention-btn:hover {
    background-color: transparent;
    color: white;
  }
}

@media screen and (max-width: 610px) {
  .at-template__motd-play-btn {
    width: 170px;
    height: 35px;
    font-size: 15px !important;
  }

  .at-template__motd-covention-btn {
    width: 170px;
    height: 35px;
    font-size: 10px !important;
  }
}
