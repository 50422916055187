@import "../../../stylesheets/atlas/prerequisite";

.project-listing-container {
  .at-card {
    padding: 0 !important;
    @include min-screen(1000px) {
      padding: 0;
    }
  }
}

.at-project__container {
  max-width: 250rem;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  border-radius: 8px;
}

.at-project__search_button {
  background-color: #f18e05 !important;
  color: #fff !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  padding: 0.375rem 0.75rem !important;
  margin-top: 15px !important;

  &:hover {
    background-color: #d87f04 !important;
  }
}

.at-project__details-document {
  padding: 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 0px !important;
  height: 100%;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
}

.at-project__details-icon-cont {
  max-width: 36px;
  min-width: 36px;
  min-height: 36px;
  max-height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 8px;

  svg {
    width: 20px;
    height: 20px;
    fill: white;
  }
}

.at-project-gallery {
  @include min-screen(767px) {
    width: 300px;
    height: 188px;
  }
  width: 100%;
  padding: 10px;
}

.at-collapse-button {
  background-color: rgba(232, 232, 232, 0.3) !important;
  border-radius: unset !important;
  width: 100%;

  &:hover {
    background-color: rgba(128, 128, 128, 0.3) !important;
  }
}

.table-footer {
  padding-left: 10px;
  padding-right: 10px;
}
