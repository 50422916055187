.at-step-idenitfier__progressive {
  display: flex;
  justify-content: space-around;
  position: relative;

  @media screen and (max-width: 576px) {
    display: block;

    .at-step-idenitfier__progress-button {
      display: flex;
      position: relative;
      align-items: center;
      width: fit-content;
      z-index: 1;

      p {
        display: block !important;
        margin-left: 10px;
        text-align: left;
        font-size: 12px;
      }
    }

    .at-step-idenitfier__progress-div {
      width: 2px;
      height: calc(100% - 5vh);
      position: absolute;
      background-color: #d1d5db;
      top: 0px;
      left: 27px;
    }
  }
}

.at-step-idenitfier__progress-button {
  color: black;
  margin: 12px 16px;
  z-index: 1;
  width: 100%;

  .at-step-idenitfier__progress-number {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    color: black;
    margin: auto;
    display: flex;
    background-color: #dbeafe;
    justify-content: center;
    align-items: center;
  }

  p {
    font-weight: 500;
    text-align: center;
    font-size: 14px;
  }

  svg {
    width: 25px;
    height: 25px;
    fill: #60a5fa;
  }

  @media (max-width: 990px) {
    .at-step-idenitfier__progress-number {
      width: 50px;
      height: 50px;
    }

    p {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 576px) {
    p {
      display: none;
    }
  }
}

.at-step-identifier__selected {
  background-color: #3b82f6 !important;
  svg {
    fill: white;
  }
}

.at-step-idenitfier__progress-div {
  width: calc(100% - 23vw);
  height: 2px;
  background-color: #d1d5db;
  margin-top: auto;
  transform: translate(-10px, 50px);
  position: absolute;

  @media (max-width: 990px) {
    width: 68vw;
    transform: translate(0px, 35px);
  }

  @media screen and (max-width: 576px) {
    width: 65vw;
  }
}

.at-step-idenitfier__progress-bar {
  height: inherit;
  background-color: #3b82f6;
  content: "";
}