// Code that shouldn't exist exists here because they are not easy to handle at the moment

// Feedback Delete - When clicking on feedback delete this will appear

.swal2-confirm,
.swal2-cancel {
  @extend .at-btn;
}

.swal2-confirm {
  @extend .at-btn--danger;
  margin-right: 10px;
}

.swal2-cancel {
  @extend .at-btn--success;
}
