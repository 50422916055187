@import "../../atlas/prerequisite";
$navbar-color: $limed-spruce;

.at-navbar {
  height: $navbar-height;
  display: flex;
  align-items: center;
  padding: 8px 20px 8px 20px;
  justify-content: space-between;
  position: relative;
  top: 0;
  z-index: $zindex-navbar;
  background: #ffffff;
  border-bottom: 1px solid #d1d5db;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  transition: all 0.4s ease-in-out;

  //Stretches the navbar so that when the sidebar hides itself, there won't be a white space
  &::after {
    content: "";
    background-color: $navbar-color;
    display: table-header-group;
    width: 300px;
    position: absolute;
    left: -300px;
    height: $navbar-height;
  }
}

.at-navbar__hamburger {
  font-size: 25px;
  width: 25px;
}

.at-topbar-opt {
  display: inline-flex;
  position: relative;
  top: 3px;
}

.at-topbar-opt__icon {
  pointer-events: none;

  &.svg-icon-list {
    height: 15px;
  }
}

.at-topbar-opt__btn {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  > svg {
    width: 100%;
    transition:
      fill 0.2s ease,
      transform 0.2s ease;
    fill: #374151;
  }

  &:hover {
    svg {
      fill: lighten($lochmara, 5%);
    }
  }
  &:active {
    svg {
      fill: lighten($lochmara, 10%);
    }
  }

  &.btn--active {
    svg {
      fill: $lochmara;
      transform: translateY(-2px);
    }
    &:hover {
      svg {
        fill: lighten($lochmara, 10%);
      }
    }
  }
}

.at-topbar-opt__btn-profile-img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
  background-color: #f3f4f6;
}

.at-topbar-opt__btn--logout {
  &:hover {
    svg {
      fill: $sunset-orange;
    }
  }
  &:active {
    svg {
      fill: lighten($sunset-orange, 5%);
    }
  }
}

.at-topbar-opt__btn--spa {
  &:hover {
    svg {
      fill: $tangerine;
    }
  }
  &:active {
    svg {
      fill: lighten($tangerine, 5%);
    }
  }

  &.btn--spa-default {
    svg {
      fill: $tangerine;
    }
    &:hover {
      svg {
        fill: lighten($tangerine, 10%);
      }
    }
  }
}

.at-topbar-opt__noti-num {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #ea580c;
  color: white;
  border-radius: 10px;
  height: 17px;
  padding: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  @include box-shadow(1);
}

.at-topbar-display_name {
  color: #1f2937;
  font-weight: 500;
  text-transform: none;
  height: 2.1rem;
  max-width: 25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 2rem;
  text-align: right;

  @media (max-width: 575px) {
    font-size: 0.8rem;
    max-width: 7rem;
  }
}

.at-navbar__sidebar-active {
  transition: color 0.2s ease;
  position: relative;
  display: flex;
  opacity: 1;
  pointer-events: initial;
  transition:
    opacity 0.6s ease,
    transform 0.4s ease 0.2s;
  transform: translateX(0px);

  &:hover {
    .at-navbar__hamburger {
      color: $grey-6;
    }
  }

  &:active {
    .at-navbar__hamburger {
      color: $grey-4;
    }
  }

  @include min-screen($sidebar-breakpoint) {
    transform: translateX(-300px);
    opacity: 0;
    pointer-events: none;
    display: none;
  }
}

.at-navbar__hide-sidebar {
  color: $atlas-white;
  display: none;
  &:hover {
    color: $white;
  }

  @include min-screen($sidebar-breakpoint) {
    display: flex;
  }
}

.at-topbar__dropbear {
  position: absolute;
  top: 65px;
  right: 25px;
  left: 25px;
  border-radius: 5px;
  overflow: hidden;
  max-height: 82vh;
  @include box-shadow(2);

  @include min-screen(475px) {
    width: 400px;
    left: initial;
  }

  @media (max-width: 576px) {
    max-height: 85vh;
  }
}

.at-topbar__dropbear--announce,
.at-topbar__dropbear--noti {
  z-index: 5;
  .at-dropbear-panel__header {
    background-color: $lochmara;
  }
}

// styling for modal clicking on the user photo
.at-topbar__dropbear--modal-root {
  cursor: pointer;
  position: absolute !important;
  top: 60px;
  right: 25px;
  display: block !important;
}

.at-topbar__dropbear-paper {
  min-width: 130px;
  margin: 0px !important;
}

.at-topbar__dropbear-backdrop {
  background-color: transparent !important;
}

.at-topbar__dropbear--support-modal-root {
  top: 65px;
  right: 115px;
}

.at-topbar__dropbear--list {
  padding: 2px;

  .at-toggle-slider {
    background-color: #d1d5db;
  }

  button {
    padding: 12px;
    display: block;
    width: 100%;
    display: flex;
    font-size: 14px;
    align-items: center;

    &:hover {
      background-color: $grey-1;
    }
  }

  hr {
    margin: 5px;
  }
}

.at-dropbear-panel__header {
  display: flex;
  flex-direction: column;
  border-radius: 4px 4px 0px 0px;
  padding: 12px 16px;
  color: $atlas-white;
}

.at-dropbear-panel__title {
  font-size: 20px;
  @include at-font(bold);
  text-transform: capitalize;
}

.at-dropbear-panel__body {
  background-color: $white;
  color: $outer-space;
  border-radius: 0px 0px 4px 4px;
  max-height: 60vh;
  overflow: auto;

  @media (max-width: 576px) {
    max-height: 55vh;
  }
}

.at-dropbear-panel__no-news {
  display: flex;
  min-height: 200px;
  justify-content: center;
  align-items: center;
  display: none;

  > p {
    text-align: center;
  }
}

.at-dropbear-panel__info-title {
  font-size: 13px;
  @include at-font(semibold);
}

.at-dropbear-panel__info {
  padding: 8px 10px;
  border-bottom: 1px solid darken($grey-1, 10%);
  cursor: pointer;

  &:hover {
    background-color: lighten($grey-1, 10%);
  }

  &:last-of-type {
    border-bottom: 0px;
  }

  &.info-read {
    background-color: $grey-1;

    &:hover {
      background-color: darken($grey-1, 10%);
    }
  }
}

.at-dropbear-panel__main {
  display: flex;
  align-items: flex-start;
}

.at-dropbear-panel__link {
  display: block;
  color: $atlas-white;
  cursor: pointer;
  font-size: 15px;

  &:hover,
  &:focus,
  &:active {
    color: $botticelli;
  }
}

.at-dropbear-panel__image-container {
  width: 65px;
  margin-right: 10px;
}

.at-dropbear-panel__image {
  max-width: 65px;
}

.at-dropbear-panel__content {
  font-size: 12px;
  overflow: hidden;
  width: 100%;
  word-break: break-word;
}

.at-dropbear-panel__date {
  font-style: italic;
  font-size: 11px;
  display: block;
  text-align: right;
  color: $atlas-black;
}

.at-dropbear-panel__no-content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.at-dropbear-panel__detail {
  flex: 1;
}

.at-dropbear-panel__header-close {
  width: 35px;
  height: 35px;
  display: flex;
  padding: 0px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: transparent;
  z-index: 5;

  > svg {
    fill: $atlas-white;
    width: 15px;
    height: 15px;
  }
}
// Transitions
.dropbear-trans-enter-active,
.dropbear-trans-leave-active {
  transition:
    opacity 0.4s ease,
    transform 0.4s ease;
}

.dropbear-trans-enter,
.dropbear-trans-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
