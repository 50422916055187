@import "../../../stylesheets/atlas/prerequisite";

.form-action {
  display: flex;
  flex-flow: row wrap;
  grid-gap: 10px;

  @include max-screen(630px) {
    flex-direction: column;
  }
}

.legend-sign {
  width: 17px;
  height: 17px;
  border-radius: 5px;
  border: 1px #7f7f7f solid;
}

.at-blocker-tutorial__cont {
  ol {
    padding-left: 30px;
    margin-top: 10px;
  }

  li {
    margin-top: 5px;
  }

  b {
    font-weight: 600;
  }

  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
}

.due-diligence__form-btn {
  width: 20px;
  height: 20px;
  padding: 2px;
  margin-left: 10px;
  cursor: pointer;
  color: white;
  border-radius: 50%;
}

.due-diligence__form-container {
  margin-top: 10px;
  border: 2px solid rgb(218, 218, 218);
  border-radius: 5px;
  padding: 15px;
}

.at-participant__assign {
  position: relative;
}

.at-redirect__link {
  color: #3b82f6 !important;
  margin-left: 4px;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    font-weight: 500;
  }
}