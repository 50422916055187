.at-subsales_claims-commission_deduct-cont {
  @media (max-width: 1200px) {
    flex: 0 0 100% !important;
    max-width: 100%;
  }
}

.at-subsales_claims-commission_deduct-item {
  top: 0% !important;
  right: 0% !important;
  transform: initial !important;
  position: relative !important;
  transition: all linear 0.3s;
  @media (max-width: 1200px) and (min-width: 768px) {
    flex: 0 0 80%;
    max-width: 80%;
  }
  @media (max-width: 1200px) {
    div {
      width: 100%;
    }
  }
}

.at-subsales_claims-profile_card-referral_internal_agent {
  max-width: 100% !important;
  transition: all 1s ease-in-out;

  @media (min-width: 767px) {
    max-width: 70% !important;
    p {
      text-align: left;
    }
  }
  @media (min-width: 1200px) {
    max-width: 50% !important;
  }
  @media (min-width: 1200px) {
    max-width: 800px !important;
  }
}

.at-subsales_claims-profile_name {
  font-size: 1rem;
  font-weight: 500;
}

.at-subsales_claim-form {
  .at-status_badge {
    @media (max-width: 992px) {
      margin: auto;
    }
  }
}

.at-party-internal_agents-cont {
  .at-form_field-col-4 {
    @media (max-width: 1199px) {
      max-width: calc(50% - 8px);
      flex: 1 1 100%;
    }
    @media (max-width: 771px) {
      max-width: calc(50% - 8px);
      flex: 1 1 100%;
    }
  }
}
