$font_size_list-desktop: (
  "large": $header_title_large-desktop,
  "base": $header_title_base-desktop,
  "small": $header_title_small-desktop,
);

$font_size_list-mobile: (
  "large": $header_title_large-mobile,
  "base": $header_title_base-mobile,
  "small": $header_title_small-mobile,
);

$font_weight_list: (400, 500, 600);

@each $size, $value in $font_size_list-desktop {
  [class^="at-fs_#{$size}"] {
    font-size: $value !important;
    line-height: 120%;
  }
  @each $weight in $font_weight_list {
    .at-fs_#{$size}-#{$weight} {
      font-weight: $weight;
    }
  }
}

@each $size, $value in $font_size_list-mobile {
  [class^="at-fs_#{$size}"] {
    @media (max-width: 575px) {
      font-size: $value !important;
    }
  }
}

$box_shadow-sizes: (
  "xs": $box_shadow-xs,
  "sm": $box_shadow-sm,
  "md": $box_shadow-md,
  "lg": $box_shadow-lg,
);

@each $size, $box_shadow in $box_shadow-sizes {
  .at-shadow_box-#{$size} {
    box-shadow: $box_shadow;
  }
}

[class*="at-new_container"] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 16px;
}

.at-new_container {
  &-12 {
    padding: 12px;
    grid-gap: 12px;
  }
  &-16 {
    grid-gap: 16px;
    padding: 16px;
  }
  &-20 {
    grid-gap: 16px;
    padding: 20px;
  }
}

.at-card_content-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 23.4px;
}
