@import "../../atlas/prerequisite";

.at-profile__avatars {
  display: flex;
  flex-direction: column;

  @include min-screen(780px) {
    justify-content: flex-start;
  }
}

.at-profile__sub-avatars {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  background-color: $grey-1;
  border-radius: 4px;
  width: 300px;
  margin-left: 20px;

  > .at-image-upload {
    margin: 10px;
    width: 80px;

    @include min-screen(768px) {
      width: 130px;
    }
  }
}

.at-profile__avatar-images {
  display: flex;
  align-items: center;
  flex-direction: column;

  @include min-screen(768px) {
    flex-direction: row;
  }
}

.at-profile__main-avatar {
  width: 200px !important;
  height: 200px !important;
  margin-bottom: 16px;
  object-fit: cover;

  @include min-screen(768px) {
    margin-bottom: 0px;
    margin-right: 20px;
  }
}

.at-avatar-note {
  color: $grey-4;
  font-style: italic;
  font-size: 12px;
}

//Grid Control
.grid-control {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: minmax(20px, 1fr);

  @include min-screen(600px) {
    grid-template-columns: repeat(12, 1fr);
  }
}
.grid-half-col {
  @include min-screen(600px) {
    grid-column: span 6;
  }
  .menu-expand & {
    @media (min-width: 991px) and (max-width: 1199px) {
      grid-column: span 12;
    }
  }
}
.grid-third-col {
  @include min-screen(600px) {
    grid-column: span 4;
  }
  .menu-expand & {
    @media (min-width: 992px) and (max-width: 1199px) {
      grid-column: span 6;
    }
  }
}
.grid-full-col {
  grid-column: 1/-1;
}
