$white: #ffffff;
$grey-1: #e0e0e0;
$grey-2: #c0c0c0;
$grey-3: #a0a0a0;
$grey-4: #808080;
$grey-5: #606060;
$grey-6: #404040;
$grey-7: #202020;
$black: #000000;

//Grey
$mirage: #111827;
$limed-spruce: #38474f;

//Blue
$lochmara: #0075bc;
$picton-blue: #34ace0;
$jelly-bean: #227093;
$dodger-blue: #2d95f4;
$bright-turquoise: #19ddea;

//Green
$turquoise: #33d9b2;
$deco: #c0d98a;
$shamrock: #2ecc71;

//Orange
$tangerine: #f0631d;
$tangerine-dark-less: darken($tangerine, 10%);
$tangerine-dark: darken($tangerine, 15%);
$sunset-orange: #ff5252;
$yellow-orange: #ffb142;

//White
$link-water: #eaf3f9;
$botticelli: #ccdde8;

//Dark
$outer-space: #2c3a3a;

//Red
$valencia: #d93335;
$alizarin-crimson: #eb3134;

//Yellow
$kournikova: #ffda79;
$sundance: #ccae62;

//Purple
$east-bay: #474787;
$moody-blue: #706fd3;
$strikemaster: #9d5896;

//Design Generals
$atlas-white: $link-water;
$atlas-black: $limed-spruce;

//Sidebar color order

$sidebar-col-0: $strikemaster;
$sidebar-col-1: $moody-blue;
$sidebar-col-2: $dodger-blue;
$sidebar-col-3: $picton-blue;
$sidebar-col-4: $bright-turquoise;
$sidebar-col-5: $turquoise;
$sidebar-col-6: $deco;
$sidebar-col-7: $kournikova;
$sidebar-col-8: $yellow-orange;
$sidebar-col-9: $tangerine;
$sidebar-col-10: $sunset-orange;
$sidebar-col-11: $alizarin-crimson;
$sidebar-col-12: $valencia;

$pers-sales-breakpoint: 767px;
$pers-sales-breakpoint-mobile: 800px;

$sidebar-breakpoint: 991px;
$recruit-strategy-bp: 800px;
$calendar-bp: 750px;
$navbar-height: 60px;
$sidebar-width: 260px;
$select-height: 45px;

$zindex-modal: 6;
$zindex-sidebar: 6;
$zindex-navbar: 5;
$zindex-loader: 1301;
$zindex-infobox-btn: 3;
