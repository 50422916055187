.at-rea-mngm__card-cont {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .at-form__content {
    display: flex;
    align-items: center;
    flex: 1 1 150px;
  }

  svg {
    width: 48px;
    height: 48px;
    padding: 8px;
    border-radius: 12px;
  }

  label {
    font-size: 14px;
    font-weight: 500;
    color: #374151;
  }

  p {
    font-size: 20px;
    color: #111827;
    font-weight: 500;
  }

  @media screen and (max-width: 767px) {
    display: grid;
  }
}
