.at-add-motd__card-container {
  display: flex;
  flex-wrap: wrap;
  overflow-y: hidden;
}

.at-motd-mngm__card {
  padding: 0;
  flex: 1 1 100px;
  overflow: visible !important;
}

.at-motd-mngm__card-content {
  margin: 20px;
  padding: 20px;
  min-width: 300px;
}

.at-motd-mngm__card-image {
  width: 300px;
  height: 300px;
  margin: auto;
}

.at-motd-mngm__card-image-button {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  top: 30%;
}

.at-motd-mngm__card-date-picker {
  z-index: 99;
}

.at-motd-mngn__card-option-btn {
  align-items: center;
  min-width: 30px;
  min-height: 30px;
  padding: 2px;
  margin: auto 2px;
  border: 2px solid;
  background-color: #ffff;
  border-radius: 50%;
  display: flex;

  svg {
    width: 20px !important;
    height: 20px !important;
    margin: auto;
  }
}

.at-motd-mngm__card-empty-date-picker {
  border-color: red;
}

.at-motd-mngn__card-expired {
  opacity: 0.7;
  .at-motd-mngn__card-delete {
    color: #dc9f9f;
  }
}

.at-add-motd__image-display {
  width: fit-content;
}

.at-motd-mngm__preview-image {
  width: 80vh;
  object-fit: fill;
  border-radius: 25px;
}

.at-motd-mngm__preview-overlay {
  position: absolute;
  display: flex;
  top: 0;
  width: calc(100% - 5px);
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 99;
}

.at-add-motd__preview-play-btn-container {
  position: absolute;
  bottom: 18%;
  display: flex;
  justify-content: center;
  width: 100%;
  left: 0px;
}

.at-motd-mngm__preview-play-btn {
  width: 200px;
  border-radius: 25px !important;
  margin: auto;
  height: 50px;
  background-color: #f18e05;
  font-size: 20px !important;
  font-weight: 700 !important;
}

.at-motd-mngm__preview-play-btn:hover {
  border: 3px solid #f18e05 !important;
  background-color: transparent;
  color: #f18e05;
}

.at-motd-mngm__preview-close-btn {
  width: fit-content;
  margin: auto;
  margin-top: 10px;
  height: 30px;
  padding: 0 20px;
  border-radius: 25px;
  background-color: red;
  font-size: 15px !important;
  transition: font-weight ease-in-out 0.1s;
}

.at-motd-mngm__preview-close-btn:hover {
  font-weight: 700 !important;
}

@media screen and (max-width: 610px) {
  .at-add-motd__preview-play-btn-container {
    bottom: 30%;
  }

  .at-motd-mngm__preview-play-btn {
    width: 170px;
    height: 35px;
    font-size: 15px !important;
  }
}
