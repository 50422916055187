.Toastify__toast--info {
  border-radius: 8px;
  border: 1px solid #60A5FA;
  background: #DBEAFE;
  margin: 8px 4px;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.08), 0px 1px 18px 0px rgba(16, 24, 40, 0.06), 0px 3px 5px 0px rgba(16, 24, 40, 0.10);
}

.Toastify__toast--success {
  border-radius: 8px;
  border: 1px solid #32D583;
  background: #D1FADF;
  margin: 8px 4px;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.08), 0px 1px 18px 0px rgba(16, 24, 40, 0.06), 0px 3px 5px 0px rgba(16, 24, 40, 0.10);
}

.Toastify__toast--warning {
  border-radius: 8px;
  border: 1px solid #FDB022;
  background: #FFEDD5;
  margin: 8px 4px;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.08), 0px 1px 18px 0px rgba(16, 24, 40, 0.06), 0px 3px 5px 0px rgba(16, 24, 40, 0.10);
}

.Toastify__toast--error {
  border-radius: 8px;
  border: 1px solid #F97066;
  background: #FEE4E2;
  margin: 8px 4px;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.08), 0px 1px 18px 0px rgba(16, 24, 40, 0.06), 0px 3px 5px 0px rgba(16, 24, 40, 0.10);
}

.Toastify__toast-body {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0px 0px 0px 2px;

  .at-toast-body__message_type {
    color: #111827;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }

  .at-toast-body__message_details {
    color: #1F2937;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}

.Toastify__close-button > svg {
  width: 16px;
  height: 16px;
}

.at-japan-agreement__table {
  width: 100%;

  th {
    width: 20%;
    height: 40px;
    text-align: center;
    background-color: lightgray;
  }

  td {
    width: 20%;
    height: 40px;
    text-align: center;
  }
}