.form-admin-description-img {
  background-image: url(https://www.iqiglobal.com/img/form-number-sample.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 20vh;
  @media (min-width: 576px) {
    height: 170px;
  }
}
