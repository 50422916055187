@import "../../atlas/prerequisite";

.at-infobox {
  position: relative;
  transition: all 0.5s ease;
}

.at-infobox__expand {
  position: absolute;
  top: 9px;
  left: 8px;
  line-height: 0px;
  z-index: $zindex-infobox-btn;
  width: 25px;
  height: 25px;
  padding: 5px;
  background-color: #3b82f6;
  border-radius: 8px;
}

.at-infobox__plus-minus {
  display: flex;
  color: white;
}

.at-infobox__textbox {
  width: 100%;
  z-index: 2;
  padding: 12px 40px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.5s ease;
  color: #374151;
  border: 1px solid #d1d5db;
  background-color: #f9fafb;
  border-radius: 8px;

  p {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.at-infobox-enter-active,
.at-infobox-leave-active {
  transition: opacity 0.2s;
}
.at-infobox-enter,
.at-infobox-leave-to {
  opacity: 0;
}
