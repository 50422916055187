@import "../../../../stylesheets/atlas/prerequisite";
@import "assets/stylesheets/new_atlas/index.scss";

.at-order_history-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  fill: #f0631d;
}

.at-order_history-table_cont {
  background-color: transparent !important;
  box-shadow: initial !important;
  padding: 0rem !important;
}

.at-order_history-table_content_cont {
  color: #111827;

  @media (max-width: 767px) {
    padding: 0rem;
  }
}

.at-order_history-table_header_action,
.at-order_history-table_content_action {
  width: 250px;
  transition:
    transform 0.2s ease,
    -webkit-transform 0.2s ease;
}

.at-chevron_up,
.at-chevron_down {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  fill: #3b82f6;
}

.at-chevron_up {
  transform: rotate(180deg);
}

.at-order-title {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.at-order-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  h4 {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
  }
}

.at-order__item {
  display: grid;
  color: white;
  min-height: 220px;
  gap: 15px;
  padding: 10px;
  background-color: $limed-spruce;
  margin-bottom: 10px;
  grid-template-rows: 40px 1fr;
  grid-template-columns: repeat(6, 1fr);

  @include max-screen(768px) {
    grid-template-rows: 40px 200px 1fr;
    grid-template-columns: 1fr;
  }
}

.at-order__image {
  grid-row: span 2;
  grid-column: 1/2;
  z-index: 2;

  @include max-screen(768px) {
    grid-row: 2/3;
    grid-column: 1/2;
    padding: 0px;
  }
}

.at-order__title-cont {
  grid-column: 2/-1;
  grid-row: 1;

  @include max-screen(768px) {
    grid-row: 1/2;
    grid-column: 1/2;
  }
}

.at-order__details-cont {
  background-color: $atlas-white;
  grid-column: 2/-1;
  grid-row: 2/-1;
  padding: var(--blackberry-padding);
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    font-size: 1rem;
    font-weight: 500;
    @include max-screen(768px) {
      font-size: 15px;
    }
  }

  @include max-screen(768px) {
    grid-row: span 1;
    grid-column: 1/2;
    max-height: 100%;
  }
}

.at-order__img-cont {
  max-height: 205px;
  height: 100%;
  min-width: 180px;
  width: 100%;
  border: 2px solid;
  border-radius: 3px;
  object-fit: cover;
}

.at-order-list-title {
  font-style: italic;
  color: rgba(0, 0, 0, 0.4);
}

.at-order_history-table_header_cont,
.at-order_history__container-desktop {
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 12px;

  @media (max-width: 767px) {
    display: none !important;
  }
}

.at-order_history-table_header,
.at-order_history-table_content {
  @media (min-width: 992px) {
    &.at-column-responsive_center {
      display: flex;
      text-align: center;
      justify-content: center;
    }
    &.at-column-responsive_right {
      display: flex;
      text-align: right;
      justify-content: flex-end !important;
    }
  }
}

.at-order_history-table_content {
  color: #111827;
  padding: 16px;
  height: 64px;
  display: flex;
  align-items: center;

  p {
    font-size: 14px;
  }
}

.at-order_history-table_header {
  color: #4b5563;
  font-size: 14px;
  font-weight: 600;
  height: 45px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
}

.at-order_history__container-mobile {
  display: flex;
}

.at-order_history-item-img {
  width: 70px;
  height: 70px;
  border-radius: 8px;
  object-fit: cover;
  margin-right: 12px;
}

.at-order_history-tickets_cont {
  transition: 0.2s all ease-in-out;
  height: 0;
  overflow: hidden;

  &.at-show {
    height: fit-content;
    padding: 0px 16px 16px 16px;
  }
}

// desktop view
.at-order_history__container-desktop {
  border: 1px solid #d1d5db;

  > .at-order_history-table_content_cont {
    display: flex;
  }
}

// mobile view
.at-order_history__container-mobile {
  margin-bottom: 0.5rem;
  border-radius: 6px;
  background-color: #ffffff;
  overflow: hidden;
  border: 1px solid #d1d5db;

  label,
  p {
    color: #111827;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
  }

  p {
    color: #1f2937;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
  }

  @media (min-width: 768px) {
    display: none;
  }

  .at-order_history-table_content_cont {
    padding-bottom: 0rem;
  }

  .at-order_history-top_content {
    display: flex;
    padding: 16px;
    flex-direction: column;
  }
}

.at-order-status_badge {
  position: absolute;
  right: 0.8rem;
  bottom: 0.5rem;
  width: fit-content !important;
  display: flex;
  align-items: center;
  color: white;
  max-width: 150px;
  font-weight: 500;
  font-size: 10px !important;
  padding: 5px 10px;
  border-radius: 15px;
  border: 1px solid;

  @media (min-width: 1500px) {
    font-size: 14px !important;
  }

  @media (max-width: 767px) {
    font-size: 12px !important;
    width: 150px;
    margin: 0px;
  }

  @media (max-width: 349px) {
    position: static;
    margin-bottom: 0.3rem;
  }
}
