.at-cal__overlay {
  background-color: rgba($mirage, 0.9);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: $zindex-modal;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
}

.at-cal__overlay-modal {
  background-color: $atlas-white;
  width: 90%;
  max-width: 800px;
  border-radius: 5px;
  padding: 15px;
  position: relative;
  margin: 150px 0px;

  @include min-screen($calendar-bp) {
    width: 80%;
    padding: 40px;
    position: relative;
  }
}

.at-cal__overlay-cross-cont {
  position: absolute;
  top: -50px;
  right: 0px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    width: 20px;
    height: 20px;
  }
}

.at-cal__overlay-cross {
  fill: $atlas-white;
  transform: scale(0.7);
}

.at-cal__overlay-image-cont {
  border: 5px solid $mirage;
  padding: 5px;
  margin-bottom: 20px;
  max-height: 60vh;
  min-height: 200px;
  overflow: auto;
  @include box-shadow(1);
}

.at-cal__overlay-image {
  width: 100%;
}

.at-cal__overlay-detail {
  @include box-shadow(1);
  padding: 15px;
  background-color: $limed-spruce;
  color: $atlas-white;
  border-radius: 5px;

  strong {
    @include at-font(600);
    min-width: 100px;
    display: inline-block;
    margin-right: 10px;
    font-size: 17px;
    margin-bottom: 5px;

    @include min-screen($calendar-bp) {
      min-width: 100px;
    }
  }

  > p {
    margin-bottom: 10px;
    font-size: 14px;

    @include min-screen($calendar-bp) {
      font-size: 16px;
    }
  }
}

.at-cal__overlay-detail-text {
  color: red;
}

.at-card__overlay {
  min-width: 500px;

  @include max-screen(850px) {
    min-width: 100%;
  }
}
