@import "src/stylesheets/index";

.at-card.at-drive-table_list,
.at-card.at-drive-table_grid {
  padding: 0px;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  background: #fff;
}

//Table Row
.at-drive-tooltip {
  font-size: 2rem;
}
.at-drive_table-row__item-icon {
  padding: 1rem;

  p,
  span {
    color: #212529;
  }
}
.at-drive_table-row {
  display: flex;
  flex-direction: row;
  height: auto;
  padding: 0px;
}

.drive_grid-controller {
  display: flex;
  align-items: center;

  @media (max-width: 899px) {
    display: grid;
  }
}

.at-drive_table-header_row {
  background-color: $white !important;
  border-bottom: 1px solid #d1d5db;

  .at-table-row_item_cont {
    span {
      font-size: 14px;
      font-weight: 500;
      color: #4b5563;
    }
  }
}

.at-drive_table-content_cont {
  overflow: hidden;
  background-color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  p {
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

//index.js
.at-drive_table-top_row-drive {
  border: initial;
}

[class^="at-btn-layout_icon"] {
  width: 2rem;
  height: 2rem;
}

[class^="at-btn-layout_icon-grid"] {
  svg {
    width: 2rem;
    height: 2rem;
    fill: #212529;
  }
}

[class^="at-btn-layout_icon-list"] {
  svg {
    width: 1.8rem !important;
    height: 1.8rem !important;
    fill: #212529;
  }
}

.at-btn-layout_icon-grid-active,
.at-btn-layout_icon-list-active {
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-sizing: content-box;
  padding: 0.2rem;
}

//header.js
.drive_table-header_row {
  font-weight: 600;
}

//content.js
.at-drive_table-row_cont {
  position: relative;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  background-color: #fafafa;

  @include min-screen($pers-sales-breakpoint) {
    margin: -1px 0px 0px 0px;
    @include box-shadow(0);
  }
  .at-drive_table-row__item-favourite {
    svg {
      cursor: pointer;
      transition: all 0.3s;
    }
  }
  .at-drive_table-row__item-title {
    svg {
      width: 24px;
      height: 24px;
      cursor: pointer;

      &:not(.at-drive_table-row_item-icon_folder) {
        margin-top: 2px;
      }
    }
  }

  .at-drive_table-row__item-actions_responsive {
    height: 1.2rem;
    transition:
      all 0.4s ease-out,
      background-color 0.5s,
      width 0.2s;
    position: absolute;

    &:hover {
      background-color: #607986;
      box-shadow: inset 0px 0px 2px 4px #7795a396;
      color: #eaeaea;
    }
    &:active > p {
      position: relative;
      overflow: hidden;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: 50%;
        background-color: #ffffff33;
        animation: ripple 0.2s ease-in-out forwards alternate;
      }
    }

    @keyframes ripple {
      0% {
        width: 0rem;
        height: 0rem;
      }
      50% {
        width: 6rem;
        height: 6rem;
      }
    }

    input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 1;
      opacity: 0;
      cursor: pointer;
      height: 100%;
    }

    .open-iqi_drive-dropdown_menu {
      display: block;
      opacity: 1;
      z-index: 1;
    }

    & ul {
      height: fit-content;
      position: absolute;
      padding-left: 0;
      padding-top: 0;
      margin-top: 0;
      list-style: none;
      overflow: hidden;
      transition: all 0.4s ease-out;
      width: 12rem;
      top: 1.2rem;
      right: 0;
      background-color: #ffffff;
      z-index: -1;
      border: 3px solid #ffe3ba;
      opacity: 0;
      box-shadow:
        2px 2px 4px -2px rgba(0, 0, 0, 0.35),
        1px 1px 1px 1px #00000011;

      &:hover {
        border: 3px solid #eeeeee;
      }

      .at-drive_table-row__item-action-favourite {
        display: none !important;

        @media (max-width: 567px) {
          display: flex !important;
        }
      }

      li {
        border-radius: 2px;
        position: relative;
        display: flex;
        grid-gap: 1rem;
        margin-left: 35px;
        line-height: 1.5;
        width: 100%;
        margin: 0;
        transition: all 0.2s;
        box-shadow: 2px 2px 4px -2px rgba(0, 0, 0, 0.35);
        padding: 0rem 1.5rem !important;
        color: #f18e05;
        cursor: pointer;

        &:hover {
          border-inline: 10px solid #ffc900;
        }

        svg {
          width: 1.5rem;
          height: 1.8rem;
        }
        p {
          line-height: 1.8rem;
        }
      }
    }
  }
}

.at-drive_table-top_bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  gap: 8px;
  flex: 1 0 0;

  .at-checkbox {
    padding-left: 0.5rem;
  }

  .at-drive-table__download-desktop {
    display: block;
  }
}

.at-drive-table__download-mobile {
  display: none;
}

.at-drive_table-top_bar-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.at-drive_table-top_bar-directory_path_back_icon {
  width: 32px;
  height: 32px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #d1d5db;
  transition: border 0.3s;
  margin-right: 12px;

  &:hover {
    border: 1px solid #d1d5db;
  }

  svg {
    width: 16px;
    height: 16px;
    fill: #374151;
  }
}

.at-drive_table-top_bar-path_name {
  font-size: 18px;
  font-weight: 600;
  color: #212529;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-wrap: wrap;
  gap: 8px;

  svg {
    width: 20px;
    height: 20px;
    fill: #6b7280;
    margin: 0px 4px;
  }
}

.at-drive_table-top_bar-row_item-directory_path {
  column-gap: 0.5rem;
  grid-column: 1/-1;
  grid-row: 1;

  .at-drive-path_symbol {
    font-weight: 900;
    color: #55555599;
    & {
      vertical-align: middle;
      display: inline-flex;
    }
  }
}

.at-drive_table-sorting-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-top: 1px solid #d1d5db;
  border-bottom: 1px solid #d1d5db;

  > div {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
  }

  @media screen and (max-width: 567px) {
    border: 0px;
    padding: 0px;
    flex-wrap: wrap;
    background-color: transparent;
    margin-bottom: 16px;

    .at-drive_table-sort-by {
      display: none;
    }
  }

  .at-drive-table_list & {
    @media (min-width: 992px) {
      display: none;
    }
  }
}

[class^="at-drive_table-top_bar-row_item-sorting-button_"] {
  background-color: #e2e6ea;
  cursor: pointer;
  border-radius: 0.15rem;
  width: max-content;
  padding: 0.3rem 0.5rem;
  margin-right: 5px;
  transition: all 0.3s linear;

  &:hover {
    background-color: #cbd1d6;
  }

  > strong {
    margin-right: 7px;
  }
}

.at-drive_table-top_bar-row_item-show_favourite_buttons {
  display: flex;
  cursor: pointer;
  width: 140px;
  align-items: center;
  height: 3rem;
  grid-column: 1;

  span {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
  }
}

.at-drive_float-cont {
  display: flex;
  align-items: center;
  background-color: #e5e7eb;
  border-radius: 8px;
}

@media screen and (max-width: 567px) {
  .at-card.at-drive-table_list,
  .at-card.at-drive-table_grid {
    border: 0px;
    background-color: transparent;
  }

  .at-drive-table__checkbox {
    color: #374151;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
    padding: 6px 12px;
    border: 1px solid #d1d5db;
  }

  .at-drive_table-top_bar {
    padding: 0px;
    flex-wrap: wrap;
    margin-bottom: 16px;

    .at-drive-table__download-desktop {
      display: none;
    }

    .at-drive_table-top_bar-right {
      width: 100%;
      justify-content: space-between;
    }
  }

  .at-drive-table__download-mobile {
    display: block;
  }
}
