@import "src/stylesheets/atlas/prerequisite";
@import "react-big-calendar/lib/css/react-big-calendar.css";

@import "src/stylesheets/atlas/prerequisite";
@import "react-big-calendar/lib/css/react-big-calendar.css";

.at-request_list__container {
  display: grid;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: left;
  padding: 0;
  list-style: none;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  @media (max-width: 1020px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: 419px) {
    justify-content: center;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .at-request__item {
    flex: 0 1 300px;
    height: auto;
    gap: 12px;
    position: relative;
    border-radius: 8px;
    border: 1px solid #d1d5db;
    background: #ffffff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @media screen and (max-width: 800px) {
      flex: 1 0 100%; 
    }

    .at-request-form__container {
      display: flex;
      text-align: center;
      width: 100%;
      height: 100%;
      min-height: 160px;
      padding: 20px 12px;
      cursor: pointer;

      flex-direction: column;
      align-items: center;
      gap: 12px;

      span {
        font-family: Inter;
        color: #1f2937;
        font-size: 12px;
        font-weight: 400;
        line-height: 150%;
      }
    
      label {
        font-family: Inter;
        color: #111827;
        font-size: 14px;
        font-weight: 600;
        line-height: 150%;
        cursor: pointer;
      }

      &:hover {
        background: #f3f4f6;
      }
    }
  }
}

