.at-confirmation_modal-responsive {
  margin: 5px !important;
  @media (min-width: 768px) {
    min-width: 480px;
  }

  .at-confirmation_modal-paper {
    min-width: 480px;
    @media (max-width: 767px) {
      margin: 0;
      min-width: 300px;
    }
  }
}

.at-confirmation_card-responsive {
  min-width: 300px !important;

  @media (min-width: 768px) {
    min-width: 480px !important;
  }
}

.at-confirmation_modal-icon {
  width: 56px;
  height: 56px;
  margin: auto;
  border-radius: 50%;
  margin-bottom: 10px;
  margin-top: 10px;
  overflow: hidden;

  svg {
    padding: 12px;
  }
  &.info {
    background-color: #dbe9fe;
    svg {
      fill: #3c82f6;
      color: #3c82f6;
    }
  }
}
