.at-video-player__container {
  width: 650px;
}

.at-video-player_close {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;
}

.at-video-player_player {
  margin-top: 10px;
  width: 95%;
  overflow: hidden;
  background-color: #000;
  max-height: 673px;
}

.at-video-player_remotion-player {
  margin-top: 10px;
  width: auto !important;
  height: auto !important;
  max-width: 635px;
  overflow: hidden;
  background-color: #000;
  aspect-ratio: 16 / 9;
}

@media screen and (max-width: 680px) {
  .at-video-player__container {
    width: 95vw;
  }

  .at-video-player_player,
  .at-video-player_remotion-player {
    width: 92vw !important;
    max-height: 600px;
  }
}
