.at-mycart-card_table-item_cont {
  border-bottom: 1px solid #d1d5db;
  padding: 0.5rem;

  &:last-child {
    border-bottom: none;
  }
}

.at-mycart-card_table-img {
  width: 120px;
  height: 120px;
  border-radius: 8px;
  object-fit: cover;

  @media screen and (max-width: 771px) {
    width: 70px;
    height: 70px;
    min-height: 70px;
  }
}

.at-mycart-card_table-delete {
  display: flex;
  align-items: center;
  color: #ef4444;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  cursor: pointer;

  svg {
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
}

.at-mycart-table {
  p {
    color: #1f2937;
    font-size: 14px;
    line-height: 150%;
  }

  h4 {
    color: #111827;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
  }

  h1 {
    color: #111827;
    font-size: 18px;
    font-weight: 600;
    line-height: 150%;
  }
}
