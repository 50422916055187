@import "../../atlas/prerequisite";
@import "../styles.scss";

.at-radio__input {
  display: none;

  &:checked {
   ~ .at-radio__label-disabled > .at-radio__checker {
      border: 1px solid #9CA3AF;
      &::after {
        background-color: #9ca3af;
      }
    }

    ~ .at-radio__label > .at-radio__checker {
      border: 1px solid #3B82F6;
      &::after {
        background-color: #3b82f6;
      }
    }
  }
}

.at-radio__label {
  cursor: pointer;
  min-height: 22px;
  margin: auto 0px;

  &:hover {
    .at-radio__checker {
      border-color: rgba(#3b82f6, 0.5);
    }
    .at-radio__checker::after {
      background-color: rgba(#3b82f6, 0.5);
    }
    .at-document__checker {
      fill: #12b76a;
    }
  }

  &:active {
    .at-radio__checker::after {
      transform: scale(1.2);
      background-color: #3b82f6;
    }
    .at-document__checker {
      fill: #12b76a;
    }
  }
}

.at-radio__label-disabled {
  cursor: no-drop;
  min-height: 22px;

  .at-radio__checker::after {
    background-color: transparent;
  }
  .at-radio__checker {
    border-color: rgba(#9ca3af, 0.5);
  }
}

.at-radio-container {
  flex: 1;
}

.at-radio-container,
.at-radio__label-disabled,
.at-radio__label {
  display: flex;
  align-items: flex-start;
}

.at-radio-set--horizontal {
  display: flex;
  flex-direction: column;

  @include min-screen(600px) {
    flex-direction: row;
  }
}

.at-radio__checker {
  width: 16px;
  height: 16px;
  display: inline-block;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #9CA3AF;
  margin: auto;
  margin-right: 10px;
  transition:
    background-color 0.3s ease,
    border-color 0.3s ease,
    transform 0.3s ease;

  &::after {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transition: background-color 0.3s ease,;
  }
}

.at-radio__content {
  flex: 1;
  font-size: 14px;
  margin: auto;
}

.at-radio__required {
  color: red;
  font-size: 12px;
  font-weight: 500;
}

.at-radio__upload {
  color: #12b76a;
  margin-left: 10px;

  svg {
    width: 18px;
    height: 18px;
    fill: #12b76a;
    margin-right: 5px;
  }
}
