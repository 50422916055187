.coupon-mngmt {
  margin-top: 0px !important;

  .panel-summary {
    background: #fb923c;
    min-height: 48px !important;
  }
  .panel-summary-content {
    margin: 0px !important;

    .at-form-input__title {
      font-size: 16px;
      font-weight: 600;
      color: #ffff;
    }
  }
  .panel-disabled {
    cursor: auto !important;
  }
}
