@import "src/assets/stylesheets/new_atlas/index.scss";

.at-btn-custom-blue {
  background-color: #2c6cf6 !important;
  padding: 10px 80px 10px 80px !important;
  width: 450 !important;
  border-radius: 5px !important;
  font-size: 15px !important;
  text-transform: initial !important ;
}

.country-code-container {
  background-color: white;
  padding: 0;
  border-radius: 10px;
  margin: 0px;

  > div {
    padding: 10px 0px;
    border-bottom: 1px solid grey;
    display: flex;
    align-items: center;
  }

  p {
    font-size: 14px;
  }

  > div > p:first-child {
    color: #cc6600;
    margin-right: 12px;
    width: 50px;
  }
}

// Confirmation Form
.at-form_mngmt-tab_container {
  display: flex;
  justify-content: space-between;
  grid-gap: 0.5rem;
  margin-bottom: 1rem;

  @media (max-width: 1200px) {
    flex-direction: column;
    grid-gap: 1rem;
  }
}

.at-form_mngmt-header {
  width: 100%;
  text-align: left;
}

.at-form_mngmt-btn_container {
  display: flex;
  margin-top: 0.5rem;
  grid-gap: 0.5rem;

  @media (max-width: 1200px) {
    margin-left: auto;
  }
}

[class^="at-form_mngmt-btn_"] {
  transition: all ease-in-out 0.2s;

  > svg {
    fill: #f18e05;
    color: #f18e05;
  }
}

.at-form_mngmt-btn_contract,
.at-form_mngmt-btn_formlist {
  width: 3.5rem;
  height: 3.5rem;
  padding: 0.2rem;

  &:hover {
    background-color: #f2f2f2;

    svg {
      fill: #e49932;
      color: #e49932;
    }
  }
}

.at-form_mngmt-btn_contract-active,
.at-form_mngmt-btn_formlist-active {
  width: 3.5rem;
  height: 3.5rem;
  padding: 0.2rem;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.at-form_mngmt_cont {
  border: 0.18rem solid #c6c6c6;
  border-radius: 0.2rem;
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0.5rem;
  width: fit-content;
  max-width: 600px;
  .form-group {
    max-width: 400px;
  }
}

.at-form_mngmt-collapsed_cont {
  width: 100%;
  border: 1px solid $gray-300;
  border-radius: 0.4rem;
  padding: 1rem;
  margin: 1rem 0 0;
  display: grid;
  flex-wrap: wrap;
  grid-gap: 1rem;
  max-width: 800px;

  & .at-form_mngmt-form_collapsed:not(:last-child) {
    .at-input_footer {
      margin-bottom: 0 !important;
    }
  }

  .form-group {
    max-width: 400px;
  }
}

.at-form_comm_cont {
  border-radius: 8px;
  padding: 1rem;
  display: none;
  flex-wrap: wrap;
  grid-gap: 0.5rem;
  width: fit-content;
  max-width: 600px;
  .form-group {
    max-width: 400px;
  }

  @media (max-width: 772px) {
    display: block;
    max-width: 100%;
  }
}

.at-form_comm-desktop_col {
  .at-form_comm_cont-desktop {
    border-radius: 8px;
    padding: 1rem;
    display: block;
    flex-wrap: wrap;
    grid-gap: 0.5rem;
    width: 100%;
    max-width: 100%;

    @media (max-width: 772px) {
      display: none;
    }
  }
}

.at-form_comm_breakdown_agent-cont {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: white;
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: 1px solid #d1d5db;
  margin-top: 1rem;
}

.agent-comm-input {
  width: 60px;
  align-items: center;
  position: relative;
  border-radius: 8px;
  border: 1px solid #bec3cb;
  padding: 10px 14px;
}

.agency-fees-input {
  width: 100px;
  align-items: center;
  position: relative;
  border-radius: 8px;
  border: 1px solid #bec3cb;
  padding: 10px 14px;
}

.at-form_comm_breakdown_cobroke-cont {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: white;
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: 1px solid #d1d5db;
  border-top: white;
}

.max-width-grey {
  background-color: #f3f4f6;
  width: inherit;
}

.flex-max-width-grey {
  display: flex;
  background-color: #f3f4f6;
  max-width: inherit;
  width: inherit;
}

.at-form_mngmt-form_list,
.at-form_mngmt-form_collapsed {
  display: inline-flex;
  flex-wrap: wrap;
  height: fit-content;

  .at-form-input,
  .form-group {
    margin: 0;
  }

  > h2,
  > div {
    width: 100%;
    text-align: start;
  }

  p {
    text-align: left;
  }

  .at-form-input__title {
    text-align: left;
  }

  .el-date-editor {
    display: block;
  }
}

li.at-form_mngmt-form_collapsed {
  width: 100%;

  &:last-child {
    .at-input_footer {
      margin-bottom: 1rem !important;
    }
  }
}

.at-form_mngmt-form_list {
  width: calc(50% - 0.5rem);

  &.at-form_list-full_col {
    width: 100%;
  }

  &:not(:last-child):not(:has(.at-form_mngmt-form_collapsed)) {
    .at-input_footer {
      margin-bottom: 0 !important;
    }
  }

  .at-form__content.at-new_container-16 .at-form_mngmt-form_collapsed:not(:last-child) {
    .at-input_footer {
      margin-bottom: 0 !important;
    }
  }

  .at-form_mngmt-collapsed_cont {
    margin-top: 0.5rem !important;
  }  
  
  h2 {
    position: relative;
  }

  .container--input .el-input__inner {
    padding-left: 0px !important;
  }

  input {
    background-color: transparent !important;
    width: 100%;
  }

  .at-form_mngmt-label_icon {
    color: #00000040;
    margin-left: 0.5rem;
    width: 20px;
    height: 20px;
    margin-top: -8px;
    margin-bottom: -6px;
  }

  @media (max-width: 1400px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1500px) {
    .at-form_mngmt-side_menu-expand & {
      width: 100%;
    }
  }
}

.at-form_mngmt-icon_automated_signal {
  border-radius: 3px;
  animation: calculatorBlink 1200ms infinite;
  animation-direction: alternate;
  -webkit-animation: calculatorBlink 1200ms infinite; /* Safari and Chrome */
}
　 .form_mngmt-datepicker {
  width: 150px;
  max-width: 150px;
  border-radius: 3px;
  border: 1px solid #0275bc;
  padding-left: 4px;
  padding-right: 4px;
  margin-right: 5px;
  transition: all 0.2s ease-in-out;

  &:hover {
    border: 1px solid #6c7b85;
  }
}

@keyframes calculatorBlink {
  from {
    color: #ffffff;
  }
  to {
    color: #f18e05;
  }
}

.at-form__mngmt-card {
  background: rgb(243, 244, 246);
  padding: 10px;

  @media screen and (max-width: 992px) {
    padding: 1.2rem 1.2rem 6rem;
  }
}

.at-form__mngmt-card-padding {
  scroll-behavior: smooth;

  @media screen and (max-width: 450px) {
    padding: 5px !important;
  }
}

.at-form_mngmt-claim-cont {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  margin-bottom: 10px;
  background: white;
  text-align: end;
  align-items: center;
}

.at-form_mngmt-claim-badge {
  font-size: 14px;
  color: white;
  width: fit-content;
  font-weight: 500;
  background-color: #ffc826;
  margin-right: 10px;
  padding: 5px 10px;
  border-radius: 25px;
}

.at-btn_prev {
  &::before {
    content: "Prev: ";
  }
  @media (max-width: 767px) {
    &::before {
      content: "Prev";
    }
  }
}

.at-btn_next {
  &::before {
    content: "Next: ";
  }

  @media (max-width: 767px) {
    &::before {
      content: "Next";
    }
  }
}

.at-btn_prev,
.at-btn_next {
  @media (max-width: 767px) {
    p {
      display: none;
    }
  }
}

.at-confirmation_form-unlock_request_info {
  display: flex;
  font-size: 14px;
  align-items: center;
  
  @media (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
  }

  > label {
    width: 128px;
    margin-bottom: 0px;
    line-height: 24px;
  }
  > p {
    font-size: 14px;
  }
}

.at-subsales-ta-select {
  &::before {
    border-bottom: 0px !important;
  }
  &::after {
    border-bottom: 0px !important
  }
}

.at-confirmation_form-tenancy-container_checkbox {
  align-items: start !important;
  margin-bottom: 1rem;

  .at-checkbox {
    margin-top: 0px;
    margin-right: 8px;
  }

  &.at-confirmation_form-tenancy-container_checkbox-has_fields {
    .at-checkbox {
      margin-top: 4px !important;
    }
  }
}

.at-confirmation_form-tenancy-checkbox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  
  .at-subsales-ta-select {
    max-height: 17px;
  }
  .at-subsales-ta-select-select {
    font-size: 14px !important;
  }
  .at-form-input, input {
    min-height: 25px !important;
    max-height: 25px !important;
  }

  @media screen and (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
    text-wrap: wrap;
  }
}

.at-subsales-ta-select-item {
  font-size: 14px !important;
}

.at-redirect__link {
  color: #3b82f6 !important;
  margin-left: 4px;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    font-weight: 500;
  }
}