@mixin at-subsales-title {
  font-size: 20px;
  line-height: 1.3;
  font-weight: 500;
  color: #111827;
  @media (max-width: 575px) {
    font-size: 16px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
  }
  @media (max-width: 799px) {
    font-size: 20px;
  }
}
