$color-white: rgba(255, 255, 255, 0.9);
$color-grey: hsla(0, 0%, 0%, 0.8);
$color-coral: rgba(238, 109, 77, 1);
$color-navy: rgba(36, 115, 218, 0.8);
$color-primary: hsla(35, 98%, 48%, 0.9);
$color-success: rgba(32, 182, 167, 0.9);
$color-blue: rgba(151, 194, 216, 0.8);

@keyframes fadein {
  from {
    opacity: 0;
    transform: translateY(8%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
    transform: translateY(8%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
    transform: translateY(8%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
    transform: translateY(8%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
    transform: translateY(8%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
