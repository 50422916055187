.at-jiqi-cafe {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  img {
    width: 200px;
    height: 200px;
    min-width: 200px;
    object-fit: cover;
  }

  p {
    color: #1f2937;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    margin-bottom: 0px;
  }
}
