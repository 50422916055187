.at-drive-table_grid {
  .at-drive_table-content_cont {
    display: grid;
    padding: 16px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 12px;

    .at-drive_table-row_cont {
      border-radius: 8px;
      background-color: white;
      border: 1px solid #d1d5db;

      &:hover {
        transform: translateY(-2px);
      }
    }
    .at-drive_table_height-control {
      display: flex;
    }

    .at-drive_table-row {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      flex-direction: column;
      padding: 1rem;
    }

    p {
      font-weight: 500;
    }

    .at-drive_table-row__item-checkbox {
      .at-checkbox {
        position: absolute;
        top: 15px;
        left: 10px;

        .at-checkbox__span-box {
          width: 16px;
          height: 16px;
          border: 1px solid #9ca3af;

          .at-checkbox__tick.svg-icon-tick {
            max-width: 12px;
            height: 12px;
          }
        }
        .at-checkbox__input:checked ~ .at-checkbox__span-box {
          border: 1px solid #3b82f6;
        }
      }
    }

    .at-drive_table-row__item-favourite {
      width: fit-content;
      position: absolute;
      top: 0;
      right: 0;
      padding: 1rem;

      > svg {
        width: 24px;
        height: 24px;
        fill: #f18e05;
      }
    }

    .at-drive_table-row__item-title {
      width: inherit;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      margin-bottom: 4px;

      .at-drive_table-row_item-icon {
        display: inline-block;
        margin: auto;
        padding: 8px;

        > svg {
          width: 60px !important;
          height: 60px !important;
        }
      }

      > p {
        width: 100%;
        font-size: 14px;
        text-align: center;
      }
    }

    .at-drive_table-row__item-date,
    .at-drive_table-row__item-size {
      margin: auto;

      p {
        font-size: 12px;
        font-weight: 400;
        color: #4b5563;
      }
    }

    .at-drive_table-row__item-actions {
      display: none;
    }

    .at-drive_table-row__item-actions_responsive {
      top: 0.5rem;
      right: 0.1rem;
      height: initial;

      > p {
        width: 2.5rem;
        height: 1.5rem;
        text-align: center;
      }

      &.at-drive-action-only_favourite {
        display: none;
      }
      .at-drive_table-row__item-action-favourite {
        display: none !important;
      }
    }

    @media screen and (max-width: 567px) {
      padding: 0px;
      background-color: transparent;
    }
  }
}
