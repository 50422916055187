@import "asset";

.at-transaction-summary {
  color: $color-grey;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  flex: 1;

  svg {
    margin-left: auto;
    width: 2.2rem;
    cursor: pointer;
    color: $color-grey;
    transform: scaleY(-1);

    &:hover {
      color: $color-coral;
    }
  }

  .at-transaction-summary_header {
    font-weight: 600;
    font-size: 0.8rem;
    display: none;
  }
  .at-transaction-summary_body {
    > div {
      p {
        font-weight: 400;
        font-size: 0.8rem;
        margin-bottom: 0.35rem;
      }
      span {
        font-weight: 500;
        margin-top: 0.7rem;
        background-color: $color-grey;
        text-align: center;
        color: $color-white;
        border-radius: 0.2rem;
        display: block;
        padding: 0.3rem 0.5rem;
      }
      strong {
        font-size: 1rem;
      }
    }
    div:nth-last-of-type(1) {
      margin-top: 0.8rem;
    }
  }
}
