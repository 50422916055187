@import "../../stylesheets/atlas/prerequisite";

.eye-visibility {
  width: 16px !important;
  height: 16px !important;
  position: absolute;
  right: 10px;
  z-index: 99;
  cursor: pointer;
  bottom: 12px;
}

.at-username-input,
.at-login-pwd-form {
  @include max-screen(420px) {
    font-size: 0.75rem !important;
  }
}

.at-login-pwd-form {
  min-width: 267px !important;

  @include max-screen(420px) {
    min-width: 217px !important;
  }
}
