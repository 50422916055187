@import "../atlas/prerequisite";
@import "src/assets/stylesheets/new_atlas/index.scss";

.at-form-input__label {
  position: absolute;
  padding: 4px 10px;
  bottom: -24px;
  right: 6px;
  font-size: 12px;
  width: fit-content;
  @include at-font(600);
  border-radius: 0px 0px 5px 5px;
}
.at-form-input__label--success {
  background-color: $shamrock;
  color: $white;
}
.at-form-input__label--error {
  background-color: $valencia;
  color: $white;
}
.at-form-input__label--disabled {
  background-color: $gray-100;
  color: #c0c0c0;
  border: 1px solid $gray-300;
}
.at-form-input__calendar {
  width: 18px;
  height: 18px;
  z-index: 0;
  fill: #374151;
  position: absolute;
  right: 10px;
}

.at-form-input__required {
  position: absolute;
  font-size: 10px;
  font-style: italic;
  font-weight: 500;
  bottom: -15px;
  right: 6px;
  color: $grey-3;
}

.at-form-label {
  font-size: 14px;
  font-weight: 500;
  color: #1f2937;
  margin-bottom: 5px;
}

.fade-enter-active,
.fade-leave-active {
  transition:
    opacity 0.3s,
    transform 0.3s;
}
.fade-enter,
.fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
