@import "../atlas/prerequisite";

.login-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;

  h2 {
    font-size: 32px;
    font-weight: 500;
    margin: 20px 0px;
  }

  @media screen and (max-width: 992px) {
    h2 {
      font-size: 28px;
      margin: 0px;
    }
  }

  @media screen and (max-height: 700px) {
    h2 {
      margin: 0px;
    }
  }
}

.login-form-container {
  width: 100%;
  max-width: 600px;
  padding: 25px 20px;
  justify-content: center;

  @media screen and (max-width: 992px) {
    padding: 15px 20px;
  }
}

.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  color: #555;
  text-align: center;
}

.pad-ver {
  display: flex;
  padding-bottom: 20px;
  text-align: end;

  a {
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    color: #6b7280;
  }

  @media screen and (max-width: 992px) {
    padding-bottom: 5px;
  }
}

.at-login__back-to {
  color: #ea580c;
  cursor: pointer;
  width: 100%;
  max-width: 600px;
  margin: auto;
  text-align: initial;
  display: flex;
  padding: 20px;

  p {
    font-weight: 500;
    font-size: 14px;
    color: #ea580c;
  }
}

.login-panel {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
