@import "src/stylesheets/atlas/prerequisite";

.at-cal_events__overlay-image_cont {
  position: relative;
  max-height: 60vh;
  min-height: 8rem;
  margin: -15px -15px 24px;
}

.at-cal_events__overlay-image {
  width: 100%;
  height: 100%;
  max-height: 60vh;
  object-fit: contain;
}

.at-cal_events__overlay-avatar_cont {
  position: absolute;
  width: 100%;
  height: 0;
  display: grid;
  justify-content: center;
  align-items: center;
  top: 30%;
  transition: top 0.2s linear;

  @media (max-width: 767px) {
    top: clamp(20%, 12vw, 34%);
  }
  @media (max-width: 600px) {
    top: clamp(20%, 13vw, 28%);
  }
  @media (max-width: 420px) {
    top: clamp(10%, 3vw, 20%);
  }
  @media (max-width: 379px) {
    top: clamp(0%, 0vw, 10%);
  }

  svg {
    color: rgb(255, 238, 105);
    width: clamp(10rem, 24vw, 17rem);
    height: clamp(10rem, 24vw, 17rem);
    z-index: 0;
    grid-area: 1 / 1 / auto / auto;

    @media (max-width: 767px) {
      width: clamp(10rem, 28vw, 15rem);
      height: clamp(10rem, 28vw, 15rem);
    }
  }

  i {
    position: absolute;
    display: block;
    left: 50%;
    top: 1rem;
    width: 8px;
    height: 15px;
    background: red;
    opacity: 0;
    z-index: 2;
  }

  @for $i from 1 through 50 {
    i:nth-of-type(#{$i}) {
      transform: translate3d(random(500) - 250 + px, random(300) - 50 + px, 0)
        rotate(random(360) + deg);
      background: hsla(random(360), 100%, 50%, 1);
      animation: bang 1.5s ease forwards;
      animation-iteration-count: 1;
      opacity: 0;
    }
  }

  @keyframes bang {
    from {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
}

.at-cal_events__overlay-avatar {
  border-radius: 50%;
  border: 0.2rem solid #f18e05;
  width: clamp(5rem, 12vw, 8rem);
  height: clamp(5rem, 12vw, 8rem);
  object-fit: cover;
  z-index: 1;
  grid-column: 1;
  grid-row: 1;
  margin: auto;

  @media (max-width: 767px) {
    width: clamp(5rem, 15vw, 8rem);
    height: clamp(5rem, 15vw, 8rem);
  }
}

.at-cal_events__overlay-detail {
  h2 {
    font-size: 18px;
    font-weight: 600;
    color: #111827;
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #d1d5db;
  }

  @media (max-width: 576px) {
    & .text-row > strong,
    & .text-row > span,
    & .text-row > a {
      font-size: 16px !important;
    }
  }

  & .text-row {
    border-bottom: 1px solid #d1d5db;
    margin-bottom: 8px;
    padding-top: 4px;
    padding-bottom: 8px;
    min-width: 12rem;
  }
  & .text-row:first-child {
    border-top: initial;
  }

  strong {
    @include at-font(600);
    min-width: 160px;
    width: 160px;
    max-width: 160px;
    color: #1f2937;
    display: inline-block;
    font-size: 14px !important;
    margin-right: 10px;
    margin-bottom: 5px;

    @include min-screen($calendar-bp) {
      min-width: 100px;
      width: 100px;
      max-width: 100px;
    }
  }

  span {
    font-size: 14px !important;
  }

  > p {
    color: #1f2937;
    margin-bottom: 10px;

    @include min-screen($calendar-bp) {
      font-size: 14px;
    }
  }
}

.at-cal_events__overlay-label {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;

  &.event {
    .at-calendar__day-affair_icons {
      background-color: #dbeafe;
      fill: #3b82f6;
    }
    .at-cal_events__overlay-event_meta_label {
      background-color: #2563eb;
    }
  }
  &.paid-event {
    .at-calendar__day-affair_icons {
      background-color: #ffedd5;
      fill: #f0631d;
    }
    .at-cal_events__overlay-event_meta_label {
      background-color: #ea580c;
    }
  }
  &.training {
    .at-calendar__day-affair_icons {
      background-color: #ede9fe;
      fill: #8b5cf6;
    }
    .at-cal_events__overlay-event_meta_label {
      background-color: #7c3aed;
    }
  }
  &.paid-training {
    .at-calendar__day-affair_icons {
      background-color: #fce8f3;
      fill: #e74694;
    }
    .at-cal_events__overlay-event_meta_label {
      background-color: #d61f69;
    }
  }
}

.at-calendar__day-affair_icons {
  width: 30px;
  height: 30px;
  display: flex;
  padding: 5px;
  margin-right: 8px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.at-cal_events__overlay-event_meta_label {
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  color: white;
  margin-right: 8px;
  align-items: center;
}

.at-cal_events__overlay-ticket {
  position: relative;
  width: 40%;
  overflow: hidden;
  display: flex;

  @include max-screen(991px) {
    width: 100%;
  }

  .at-cal_events__overlay-ticket-left {
    display: flex;
    flex-direction: column;
    width: 50px;
    height: 100%;
    position: absolute;
    z-index: 0;
    border-radius: 8px;

    .top,
    .bottom {
      flex: 1;
      width: 100%;
      background-color: #f0631d;
    }

    .top {
      border-radius: 5px 5px 0 0;
    }

    .bottom {
      border-radius: 0 0 5px 5px;
    }

    .rip {
      position: relative;
      height: 20px;
      flex-shrink: 0;
      margin: 0 20px;
      background-color: #f0631d;

      &::before {
        position: absolute;
        content: "";
        width: 30px;
        height: 30px;
        border: 10px solid transparent;
        border-top-color: #f0631d;
        border-right-color: #f0631d;
        transform: translate(-57%, -50%) rotate(45deg);
        border-radius: 50%;
        top: 50%;
      }

      &::before {
        left: -18px;
      }

      &::after {
        right: -80px;
        transform: translate(-50%, -50%) rotate(225deg);
      }
    }
  }

  .at-cal_events__overlay-ticket-left-scanned {
    .top,
    .bottom,
    .rip {
      background-color: #d1d4d8;
    }

    .rip::before {
      border-top-color: #d1d4d8;
      border-right-color: #d1d4d8;
    }
  }

  .at-cal_events__overlay-ticket-right {
    width: calc(100% - 10px);
    height: 100%;
    left: 10px;
    position: absolute;
    background-color: #fff;
    border-radius: 0px 8px 8px 0px;
    border-top: 1px solid #d1d5db;
    border-right: 1px solid #d1d5db;
    border-bottom: 1px solid #d1d5db;
  }

  .at-cal_events__overlay-ticket-content {
    display: flex;
    margin-left: 10px;
    z-index: 3;
    padding: 10px;
    width: 100%;
    position: relative;
    align-items: center;

    p {
      color: #111827;
      font-size: 16px;
      font-weight: 700;
      line-height: 150%;
      margin-left: auto;
    }

    h2 {
      color: #111827;
      font-size: 16px;
      font-weight: 600;
      line-height: 150%;
    }

    svg {
      width: 40px;
      height: 40px;
    }

    .at-status_badge {
      position: absolute;
      top: 10px;
      right: 30px;
    }

    @media (max-width: 575px) {
      padding: 5px;
    }
  }
}
