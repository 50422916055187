@import "../../../../stylesheets/atlas/prerequisite";
@import "assets/stylesheets/new_atlas/index.scss";

.name-card__container {
  p {
    margin-bottom: 5px;
    font-size: 14px;
  }
  .name_card__container_desc {
    @media screen and (min-width: 960px) {
      border-left: 1px solid rgba(0, 0, 0, 0.1);
    }
    @media screen and (max-width: 960px) {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}

.at-product__item {
  display: grid;
  color: white;
  min-height: 200px;
  cursor: pointer;
  background-color: $limed-spruce;

  gap: 15px;
  padding: 10px;
  grid-template-rows: 20px 1fr;
  grid-template-columns: repeat(6, 1fr);

  @include max-screen(768px) {
    grid-template-rows: 20px 200px 1fr;
    grid-template-columns: 1fr;
  }
}

.at-product__image {
  grid-row: span 2;
  grid-column: 1/2;
  z-index: 2;

  @include max-screen(768px) {
    grid-row: 2/3;
    grid-column: 1/2;
    padding: 0px;
  }
}

.at-product__title-cont {
  grid-column: 2/-1;
  grid-row: 1;

  @include max-screen(768px) {
    grid-row: 1/2;
    grid-column: 1/2;
  }
}

.at-product__details-cont {
  background-color: $atlas-white;
  grid-column: 2/-1;
  grid-row: 2/-1;
  padding: 15px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  max-height: 170px;
  overflow: auto;

  p {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 12px;
    @include max-screen(768px) {
      font-size: 15px;
    }
  }

  @include max-screen(768px) {
    grid-row: span 1;
    grid-column: 1/2;
    max-height: 100%;
  }
}

.at-product__img-cont {
  max-height: 200px;
  height: 100%;
  min-width: 180px;
  width: 100%;
  border: 2px solid;
  border-radius: 3px;
  object-fit: cover;
  margin-right: 10px;
}

.at-product__title {
  font-size: 18px;
  padding-bottom: var(--blackberry-padding);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.at-product__button-cont {
  text-align: end;
  margin-top: 10px;
}

.product-grid {
  min-height: 100px;
  background-color: unset;
  padding: 5px;
}

.add-to-cart-action {
  display: flex;
  gap: 12px;
  margin-top: 16px;

  .at-product__quantity-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    padding: 8px 12px;

    svg {
      width: 20px;
      height: 20px;
      fill: #f0631d;
    }

    &:disabled {
      svg {
        fill: #d1d5db;
      }
    }
  }

  button {
    width: 100%;
    display: block;
  }
}

.at-variation-box {
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 100%;
  gap: 2px;
  margin-bottom: 12px;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  background-color: #f9fafb;
  text-align: left;
  transition:
    all 0.2s ease-out,
    border 0s linear;

  &:hover {
    background-color: darken(#f9fafb, 10%);
  }
  &:focus:not(:disabled),
  &:active:not(:disabled),
  &.active:not(:disabled),
  &.selected:not(:disabled) {
    border: 1px solid #f0631d;
    background-color: #ffedd5;
  }
}

.event-details {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin: 12px 0;
  gap: 10px;

  label {
    color: #111827;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
  }

  span {
    color: #1f2937;
    font-size: 16px;
    line-height: 150%;
    word-break: break-all;
  }
}

.event-details-icon {
  font-size: 12px;
  padding: 6px;
  border-radius: 50%;
  background-color: #ffedd5;
  color: #f0631d;
  display: flex;
  align-items: center;

  svg {
    width: 12px;
    height: 12px;
    fill: #f0631d;
  }
}
