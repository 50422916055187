.at-pv--detail-info {
  h1 {
    color: #111827;
    font-size: 18px;
    font-weight: 600;
  }

  label {
    color: #4b5563;
    font-size: 12px;
    font-weight: 500;
  }

  p {
    color: #111827;
    font-size: 14px;
  }

  @media screen and (max-width: 720px) {
    .at-pv__download-button {
      width: 100%;
      margin-top: 12px;
    }
  }
}

.at-pv__stat-cont {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(3, 1fr);

  .at-form__content {
    padding: 12px;
  }

  @media screen and (max-width: 720px) {
    grid-template-columns: 1fr;
  }
}

.at-pv--detail-download-btn {
  background: black;
  color: cornflowerblue;
  border-radius: 50%;
  width: 40px;
  padding: 5px;
  height: 40px;
}
