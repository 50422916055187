.atlas-dialog-custom {
  width: 800px !important;
  max-width: 800px !important;
}

.atlas-card-custom {
  width: 800px;
  max-width: 800px;
  @media screen and (max-width: 1080px) {
    max-width: 100%;
  }
}

.atlas-upload_status_modal-list {
  border: 1px solid #c2c2c2;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
  cursor: auto;

  & .atlas-upload_status_modal-list-status {
    font-size: 8px;
    font-style: italic;
  }
}

.at-bankslip__container {
  max-width: 800px;

  label {
    font-size: 14px;
    font-weight: 500;
    color: #1f2937;
    line-height: 21px;
    margin-bottom: 5px;
  }

  .at-step-idenitfier__progress-div {
    width: 150px;
    transform: translateY(25px);
  }
  .at-step-idenitfier__progress-number {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 32px;
    height: 32px;
    background: #ffffff;
    border: 2px solid #d1d5db;
  }

  .at-step-identifier__selected {
    background-color: white !important;
    border: 2px solid #3b82f6;
  }
}

.at-bankslip__upload {
  background: #f9fafb;
  border: 1px dashed #d1d5db;
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 12px;
}

.at-bankslip__time {
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  padding: 8px 15px;
  font-size: 14px;
}

.at-bankslip__input {
  border: 1px solid #e0e0e0;
  background-color: white;
  width: 100%;
  height: 50px;
  padding: 5px;
}

.react-datepicker__input-container {
  width: 100%;
}

.btn-lg {
  @media (max-width: 767px) {
    font-size: 14px;
    padding: 14px;
  }
}

.at-document__checker {
  width: 18px;
  height: 18px;
  fill: #9ca3af;
  margin-right: 5px;
}

.at-document__radio-control {
  .at-radio__label,
  .at-radio__label-disabled {
    margin: 0px;
  }
}
