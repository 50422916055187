@import "asset";

@mixin at-recommendation-card-default {
  padding: 1rem 0.8rem;
  border-radius: 0.5rem;
  min-width: 16.8rem;
  width: 16.8rem;
  max-width: 16.8rem;
  height: max-content;
}

@mixin accent-color {
  &[data-status] {
    span {
      background-color: $color-coral;
    }
  }

  &[data-status="Sent"] {
    span {
      background-color: $color-primary;
    }
  }

  &[data-status*="Not"] {
    span {
      background-color: $color-blue;
    }
  }

  &[data-status*="Accepted"] {
    span {
      background-color: $color-success;
    }
  }

  &[data-status*="Rejected"] {
    span {
      background-color: $color-coral;
    }
  }
}

.at-recommendation-empty-card {
  @include at-recommendation-card-default();
  cursor: pointer;
  height: unset;
  background: $color-coral;
  display: grid;
  place-content: center;
  text-align: center;

  > * {
    color: $color-white;
    font-weight: 550;
  }
  > h4 {
    font-size: 3.5rem;
    display: block;
    position: relative;
  }
  > small {
    font-size: 0.9rem;
  }
}

.at-recommendation-card-grid {
  max-width: none !important;
  min-width: auto !important;
  width: 100% !important;
}

.at-recommendation-card {
  @include at-recommendation-card-default();
  position: relative;
  font-size: 14px;
  overflow: hidden;
  background-color: white !important;
  border-radius: 8px;
  min-height: 280px;
  display: flex;
  flex-direction: column;
  border: 1px solid #d1d5db;
  box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.08),
    0px 2px 1px rgba(16, 24, 40, 0.06),
    0px 1px 3px rgba(16, 24, 40, 0.1);
  flex: 1;

  > * {
    webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.5s; /* Firefox < 16 */
    -ms-animation: fadein 0.5s; /* Internet Explorer */
    -o-animation: fadein 0.5s; /* Opera < 12.1 */
    animation: fadein 0.5s;
  }

  .at-recommendation-card-transaction {
    color: $color-grey;
    display: flex;
    align-items: center;

    .at-recommendation-card-transaction__avatar {
      border-radius: 8px;
      object-fit: cover;

      height: 80px;
      width: 80px;
      margin-right: 0.5rem;
    }

    .at-recommendation-card__transaction-info {
      width: max-content;
      text-align: center;
      padding: 5px 8px;
      cursor: pointer;
      background-color: $color-primary;
      border-radius: 0.3rem;
      box-shadow: -1px 5px 5px -2px #bdafaf;

      > svg {
        color: $color-white;
        width: 2rem;
      }
    }

    .at-recommendation-card__transaction-total {
      display: flex;
      flex: 1;
      align-items: center;
      background-color: #14b8a6;
      position: relative;

      border-radius: 8px;
      padding: 0.4rem 0.8rem;
      margin-bottom: 0.3rem;
      color: $color-white;

      > h4 {
        line-height: 1;
        font-weight: 550;
        margin-right: 0.6rem;
        color: white;
      }
      > :nth-child(2) {
        p {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }

  .at-recommendation-card-status {
    text-align: center;
    padding: 8px;
    font-size: 14px;
    font-weight: 500;
  }
}
