.at-app-theme_color-box {
  float: left;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  border: 1px solid #afafaf;
}

.at-app-theme__tooltip-image {
  min-width: 600px;
  opacity: 1;
}

.at-app-theme__upload-photo-container {
  margin-left: 190px;
  width: fit-content;
}

.at-app-theme__mobile-preview-overlay {
  position: absolute;
  width: calc(100% - 5px);
  top: 0;
  height: 100%;
  flex-wrap: wrap;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.at-app-theme__mobile-preview-container {
  position: relative;
  width: 300px;
  margin: auto;
  background: black;
  border-radius: 25px;
  border: 5px solid black;
}

.at-app-theme__mobile-preview-header {
  padding: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  p {
    color: #fb923c;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    justify-content: center;
  }
}

.at-app-theme__mobile-preview-tab {
  display: flex;
  p {
    display: flex;
    justify-content: center;
    color: grey;
    padding: 10px;
    font-size: 12px;
  }
  .selected-tab {
    color: #fb923c;
    border-bottom: 2px solid #f18e05;
  }
}

.at-app-theme__mobile-preview-user-info {
  p {
    color: white;
    display: flex;
    font-size: 12px;
    img {
      width: 20px;
      margin-right: 5px;
    }
  }
  .username {
    font-size: 15px;
    font-weight: 600;
    margin-top: 20px;
    color: #f18e05;
  }
  .message-of-the-day {
    border: 1px solid #f18e05;
    border-radius: 5px;
    padding: 5px;
    align-items: center;
    font-size: 10px;
    margin-top: 20px;
  }
}

.at-app-theme__mobile-preview-user-image {
  border-radius: 50%;
  padding: 20px 20px;
}

.at-app-theme__mobile-preview-section-conatiner {
  margin: 5px;
  display: flex;
  background-position: center;
  background-size: cover;
  color: white;
  .at-app-theme__mobile-preview-item-conatiner {
    text-align: center;
    padding: 0;
  }
  svg {
    width: 20px;
    height: 20px;
  }
  p {
    font-size: 10px;
    text-align: center;
  }
}

.at-app-theme__mobile-preview-footer {
  display: flex;
  padding: 10px;
  max-height: 50px;
  background-color: #2b2925;
  border-radius: 0 0 20px 20px;
  .at-app-theme__mobile-preview-scanner {
    background: orange;
    border-radius: 50%;
    padding: 10px;
    width: 50px;
    bottom: 22vh;
    height: 50px;
    transform: translateY(-30px);
  }
}

@media screen and (max-width: 610px) {
  .at-app-theme__upload-photo-container {
    margin-left: 0;
  }
}
