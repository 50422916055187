@import "../../atlas/prerequisite";

.at-sidebar-mobile__section {
  margin: 0px;
  padding: 0px;
  border-radius: 2px;
}

.at-sidebar-mobile__inner-cont {
  margin-top: 1rem;
}

//New Side bar
.at-new-sidebar-mobile {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 6;
  width: 90%;
  min-width: 200px;
  background-color: black;
  overflow: auto;
  transform: translateX(-1000px);
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
  opacity: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &.active {
    transform: translateY(0px);
    opacity: 1;
    z-index: 100;
    pointer-events: initial;
  }

  @include min-screen($sidebar-breakpoint) {
    display: none;
  }
}

.at-new-sidebar-mobile::-webkit-scrollbar {
  display: none;
}

.at-new-sidebar-mobile__btn_close {
  width: 24px;
  height: 24px;
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  background-color: #374151;
  top: 15px;
  left: 15px;
  position: absolute;
}

.at-new-sidebar__avatar-cont {
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-bottom: 10px;

  img {
    width: 32px;
    height: 32px;
  }

  h4 {
    color: #ffff;
    font-size: 16px;
    font-weight: 500;
    margin-left: 14px;
  }
}

.at-sidebar__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.75;
  z-index: 6;

  @include min-screen($sidebar-breakpoint) {
    display: none;
  }
}
