@import "src/assets/stylesheets/new_atlas/index.scss";

.at-empty-state {
  padding: 40px 16px;
  border: 1px solid $gray-300;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #ffffff;

  .title {
    color: #111827;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-top: 20px;
  }

  .description {
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
    color: #1f2937;

    > b {
      margin-left: 5px;
    }
  }
}

.no-border {
  border: none;
}

.at-empty-state__icon {
  padding: 20px;
  background-color: #d1d5db;
  border-radius: 50%;
  width: fit-content;
  margin: auto;

  svg {
    width: 50px;
    height: 50px;
    fill: #6b7280;
  }
}

.at-empty-state__iconmd {
  padding: 16px;
  background-color: #d1d5db;
  border-radius: 50%;
  width: fit-content;
  margin: auto;

  svg {
    width: 32px;
    height: 32px;
    fill: #6b7280;
  }
}