@import "../../atlas/prerequisite";

.at-blackberry__item {
  width: 100%;
  display: flex;
  overflow: hidden;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: initial;
  transition: 0.2s;
  border-radius: 8px;
  border: 1px solid #d5d5d5;
  background-color: white;

  @include min-screen(420px) {
    grid-template-rows: initial;
  }
  @include min-screen(992px) {
    grid-gap: 0px;
    flex: 0 0 calc(50% - 8px);
    max-width: 50%;
  }
  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media (min-width: 992px) {
    flex: 0 0 100%;
    max-width: 100%;
    .menu-expand & {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  @media (min-width: 1000px) {
    flex: 0 0 calc(50% - 8px);
    max-width: 50%;
  }
  @media (min-width: 1100px) {
    .menu-expand & {
      flex: 0 0 calc(50% - 8px);
      max-width: 50%;
    }
  }
  @media (min-width: 1500px) {
    flex: 0 0 calc(33.333% - 8px);
    max-width: 33.333%;
  }
  @media (min-width: 1900px) {
    .menu-expand & {
      flex: 0 0 calc(33.333% - 8px);
      max-width: 33.333%;
    }
  }
  @media (min-width: 2800px) {
    flex: 0 0 calc(25% - 8px);
    max-width: 25%;
    .menu-expand & {
      flex: 0 0 calc(25% - 8px);
      max-width: 25%;
    }
  }
  @media (min-width: 3200px) {
    flex: 0 0 calc(16.667% - 8px);
    max-width: 16.667%;
    .menu-expand & {
      flex: 0 0 calc(20% - 8px);
      max-width: 20%;
    }
  }
  @media (min-width: 3800px) {
    .menu-expand & {
      flex: 0 0 16.667%;
      max-width: 16.667%;
    }
  }
}

.at-blackberry__image {
  width: 150px;

  .at-blackberry__img-cont {
    position: relative;
    height: 100%;
    width: 150px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;

    > img {
      height: 100%;
      width: 100%;
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
      object-fit: cover;
      transition: 0.2s;
    }

    @include max-screen(450px) {
      height: 60%;
      width: 100px;
    }
  }
}

.at-blackberry__specs {
  grid-column: 1;
  grid-row: 4;
  z-index: 2;
  padding: 0px var(--blackberry-padding) var(--blackberry-padding);

  @include min-screen(768px) {
    padding-top: var(--blackberry-padding);
    grid-column: 1/2;
    grid-row: 3;
  }
}

.at-blackberry__title-cont {
  grid-column: 1;
  grid-row: 1;
  overflow: hidden;
  margin-bottom: 6px;

  @include min-screen(767px) {
    grid-column: 2/-1;
    grid-row: 1;
  }
}

.at-blackberry__title__text {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.5px;
  color: #374151;

  @media (max-width: 1399px) {
    height: 1.2rem !important;
    -webkit-line-clamp: 1 !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  @media (max-width: 991px) {
    width: 150px;
    height: 2rem !important;
  }
}

.at-blackberry__title__price {
  color: black;
  margin-top: 1rem;
  font-size: 12px;
  span {
    font-weight: 500;
    font-size: 16px;
    color: #c2410c;
  }
}
.project-list-status {
  font-size: 8px;
}

.at-blackberry__details-cont {
  position: relative;
  width: 100%;
  grid-column: 2 / span 4;
  grid-row: 1;
  z-index: 2;
  padding: 12px;
  // border-bottom-right-radius: 3px;
  // border-top-right-radius: 3px;
  // background-color: #233B48;
  transition: all 0.2s;
  color: #1f56ab;

  @media (min-width: 600px) and (max-width: 766px) {
    grid-column: 3/-1;
    grid-row: 1;
  }
  @include min-screen(992px) {
    grid-column: 3/-1;
    grid-row: 1 / span 2;

    .menu-expand & {
      grid-column: 2/-1;
      grid-row: 1 / span 2;
    }
  }

  p {
    @include min-screen(767px) {
      font-size: 13px;
    }
    @include max-screen(575px) {
      font-size: 12px;
    }
  }

  .at-blackberry__details-cont-top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 3px;

    > :first-child {
      flex-basis: 70%;

      @media (min-width: 1000px) {
        .menu-expand & {
          flex-basis: 60%;
        }
      }
    }
  }

  .at-blackberry__actions {
    position: absolute;
    top: 8px;
    right: 8px;

    & .at-blackberry__actions-icon {
      width: 25px;
      height: 25px;
      padding: 2px;
      border-radius: 50%;
      fill: #f18e05;
      background-color: #fff;
      cursor: pointer;
    }
    > :not(:first-child) {
      margin-left: 3px;
    }
  }
}

[class^="at-blackberry__spec-list_item-"] {
  display: flex;
  font-size: 0.8rem;
  white-space: nowrap;
  & > span {
    display: flex;
    align-items: center;
  }
  .at-blackberry-specs_cont-top & {
    background-color: #2463eb;
    padding: 5px 10px;
    margin-right: 5px;
    margin-top: 5px;
    border-radius: 8px;
    svg {
      width: 14px;
      min-width: 14px;
      height: 14px;
    }
    .at-blackberry__spec-text {
      font-size: 11px;
    }
  }
  .at-blackberry-specs_cont-middle & {
    flex: 0 0 100%;
    white-space: pre-wrap;
    max-width: 100%;
    padding: 2px 0px;
    border-radius: 8px;
    svg,
    .at-blackberry__spec-text {
      color: #7cb9e3;
      font-size: 12px;
    }
  }
  .at-blackberry-specs_cont-bottom & {
    padding-bottom: 5px;
    display: flex;
    svg {
      color: #6b7280;
      margin-right: 5px;
    }
    .at-blackberry__spec-text {
      color: #111827;
      font-size: 12px;
      margin-right: 20px;
    }
  }
  svg {
    width: 14px;
    min-width: 14px;
    height: 14px;
    margin-right: 5px;
    color: white;
  }
  .at-blackberry__spec-text {
    font-weight: 500;
    color: white;
    font-size: 10px;
  }
}

.at-blackberry-specs_cont-bottom {
  margin-bottom: 90px;

  @include max-screen(450px) {
    margin-bottom: 52px;
  }

  @include min-screen(1101px) {
    margin-bottom: 80px;
  }
}

.at-project-list-location {
  height: 4.4em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;

  @media (max-width: 1199px) {
    height: 1.6em !important;
    -webkit-line-clamp: 1 !important;
  }
}

.at-blackberry__category,
.at-blackberry__proptitle,
.at-blackberry__proptype {
  border-radius: 8px;
  position: absolute;
  display: flex;
  padding: 2px 4px;

  @include min-screen(767px) {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > svg {
    width: 22px;
    height: 22px;
    display: none;

    @include min-screen(767px) {
      display: block;
    }
  }

  > span {
    display: inline-block;
    font-size: 8px;
    font-weight: 500;
    width: 55px;

    @include min-screen(767px) {
      display: none;
    }
  }
}

.at-blackberry__category {
  left: 5px;
  top: 5px;
  > span {
    color: $atlas-white;
  }

  @include min-screen(767px) {
    left: 5px;
    bottom: -10px;
    top: initial;
  }
}

.at-blackberry__proptitle {
  left: 5px;
  top: 25px;
  > span {
    color: $atlas-white;
  }

  @include min-screen(767px) {
    left: 45px;
    bottom: -10px;
    top: initial;
  }
}

.at-blackberry__proptype {
  left: 5px;
  top: 45px;
  > span {
    color: $atlas-black;
  }

  @include min-screen(767px) {
    left: 85px;
    bottom: -10px;
    top: initial;
  }
}

.at-blackberry__tenure {
  position: absolute;
  bottom: -10px;
  right: 5px;
}

.at-blackberry__props {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;

  @include min-screen(767px) {
    display: flex;
    margin: 0px;
  }

  span,
  sub {
    color: $atlas-black;
    @include min-screen(767px) {
      color: $atlas-white;
    }
  }

  svg {
    width: 20px;
    height: 20px;
    fill: $atlas-black;
    @include min-screen(767px) {
      fill: $atlas-white;
    }
  }
}

.at-blackberry__title {
  font-size: 1rem;
  line-height: 1.4em;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  overflow: hidden;
  max-width: calc(100% - 1.5rem);
  @include max-screen(575px) {
    font-size: 14px !important;
  }
  @include max-screen(991px) {
    -webkit-line-clamp: 1 !important;
    height: 1.6em !important;
  }
  @include max-screen(1399px) {
    font-size: 16px;
    -webkit-line-clamp: 2;
  }
}

.at-blackberry__hr {
  margin: 10px 0px;
  border-color: #65b8a32b !important;
  border: 0.1px solid;
}

.at-blackberry__net-price {
  padding: 5px;

  @include min-screen(767px) {
    padding: 10px;
  }
}

.align-super {
  vertical-align: super;
}

.at-blackberry__bottom {
  position: absolute;
  bottom: 12px;

  @include max-screen(450px) {
    width: 95vw;
    left: -94px;
  }
}

.at-blackberry__available {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #f0631de0;
  p {
    font-size: 10px;
    color: $white;
    text-align: center;
    padding: 5px 0px;
    font-weight: 500;
  }
}
