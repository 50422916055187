@import 'src/stylesheets/atlas/prerequisite';

.at-booking-detail__cont {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 16px;
  margin: 16px 0px;

  h2 {
    color: #111827;
    font-size: 18px;
    font-weight: 600;
    line-height: 150%;
  }

  label {
    color: #111827;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
  }

  p {
    color: #1F2937;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0px;
  }

  .at-booking-detail-icon {
    width: 16px;
    height: 16px;
    fill: #F0631D;
  }

  @include max-screen(576px) {
    display: flex;
    flex-direction: column;
  }
}

.at-mybooking-subject-col {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;

  @media (max-width: 799px) { 
    display: none;
  }
}

.at-mobile-subject-col {
  display: none;

  @media (max-width: 799px) { 
    display: block;
  }
}

.at-booking-reject-box {
  align-self: stretch;
  text-align: left;
  background: #F3F4F6;
  border-left: 4px solid #F04438;
  padding: 4px 6px;
  margin-top: 0.5rem;
  border-radius: 4px;

  @include max-screen(990px) {
    display: none;
  }
}

.at-booking-reject-form {
  align-self: stretch;
  text-align: left;
  background: #F3F4F6;
  border-left: 4px solid #F04438;
  padding: 4px 6px;
  margin-top: 0.5rem;
  border-radius: 4px;
  width: fit-content
}

.at-booking-subject-purpose-row {

  svg {
    width: 16px;
    height: 16px;
    fill: #3B82F6;
  }
}