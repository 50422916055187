.at-calendar--list {
  .at-calendar__day-header {
    display: none;
  }
  .at-calendar__day-set {
    grid-template-columns: 1fr;
  }

  .at-calendar--list-event-mobile {
    justify-content: start;
    transform: translateY(-10px);
  }

  .at-calendar__list-affair-cont {
    position: unset;
    z-index: 1;
    width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    border: 1px solid #d1d5db;

    @media (max-width: 575px) {
      margin-top: 0px;
    }
  }

  .at-calendar__list-affair {
    display: flex;
    padding: 12px;
    align-items: center;
    align-self: stretch;
    border-radius: 0px;
    margin-bottom: 0px;
    background-color: white;
    cursor: pointer;

    &:not(:first-child) {
      border-top: 1px solid #d1d5db;
    }

    &:hover {
      background-color: #f0f1f2;
    }

    .at-calendar__day-affair_icons {
      width: 30px;
      height: 30px;
      display: flex;
      padding: 5px;
      margin-right: 10px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }

    .at-calendar__day-affair_list_row_cont {
      display: flex;
      flex-direction: column;
    }
  }

  .at-calendar__day-label {
    width: 100% !important;
    color: #a1a1a1;
    font-style: italic;
    font-weight: 600;
    font-size: 0.6rem;
    display: none;
  }

  .at-calendar__day-affair_meta_label {
    padding: 2px 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
  }

  .at-calendar__affair--load-more {
    display: none;
  }

  .at-calendar__day {
    min-height: 50px;
    margin-bottom: 12px;
    height: auto;
    border: unset;
    grid-gap: 12px;
    padding: 0px !important;

    .at-calendar__day-affair_name {
      font-size: 15px;
      font-weight: 600;
      white-space: normal;
      margin-top: 4px;

      @media (max-width: 768px) {
        white-space: break-spaces;
      }
      @media (max-width: 575px) {
        font-size: 14px;
      }
    }

    .at-calendar__day-affair_duration {
      font-size: 14px;
      color: #374151;

      @media (max-width: 575px) {
        font-size: 12px;
      }
    }

    .at-calendar__day-affair_location {
      font-size: 15px;

      @media (max-width: 575px) {
        font-size: 11px;
      }

      svg.svg-icon-location {
        width: 23px;
        height: 32px;
        fill: #f2991e;

        @media (max-width: 575px) {
          width: 22px;
        }
      }

      svg {
        margin-right: 10px;

        @media (max-width: 575px) {
          min-width: 16px !important;
          width: 16px !important;
          height: 22px;
        }
      }
      > span {
        @media (max-width: 768px) {
          white-space: break-spaces;
        }
      }
    }

    & .at-calendar__day-affair_icon-text {
      color: #ffffff;
      font-size: 13px;
      font-weight: 500;

      @media screen and (max-width: 575px) {
        font-size: 12px;
      }
    }

    > span {
      margin-right: 20px;
      font-size: 16px;
      color: #111827;
    }
  }

  .at-calendar__day--not-this-month {
    display: none;
  }

  .at-calendar__day-display {
    display: none;
  }
}

//By event type
.at-calendar--list {
  .at-calendar__list-affair-event {
    .at-calendar__day-affair_meta_label {
      background-color: #2563eb;
    }
    .at-calendar__day-affair_icons {
      background-color: #dbeafe;
    }
    svg {
      fill: #3b82f6;
    }
  }

  .at-calendar__list-affair-paid-training {
    .at-calendar__day-affair_meta_label {
      background-color: #d61f69;
    }
    .at-calendar__day-affair_icons {
      background-color: #fce8f3;
    }
    svg {
      fill: #e74694;
    }
  }

  .at-calendar__list-affair-paid-event {
    .at-calendar__day-affair_meta_label {
      background-color: #ea580c;
    }
    .at-calendar__day-affair_icons {
      background-color: #ffedd5;
    }
    svg {
      fill: #f0631d;
    }
  }

  .at-calendar__list-affair-training {
    .at-calendar__day-affair_meta_label {
      background-color: #7c3aed;
    }
    .at-calendar__day-affair_icons {
      background-color: #ede9fe;
    }
    svg {
      fill: #8b5cf6;
    }
  }

  .at-calendar__list-affair-birthday {
    .at-calendar__day-affair_meta_label {
      background-color: #7c3aed;
    }
    .at-calendar__day-affair_icons {
      background-color: #ccfbf1;
    }
    svg {
      fill: #14b8a6;
    }
  }
}
