.at-share-modal__button {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.at-share-modal__button-container {
  font-size: 12px;
  text-align: center;
  max-width: 60px;
  text-align: center;
}

.at-share-modal__cop-button {
  color: #1b60ff;
  font-size: 14px;
  margin-left: 10px;
  font-weight: 500;
}
