@import "src/assets/stylesheets/new_atlas/index.scss";

.table {
  background: #fff;

  thead {
    background: #f18e05;
    font-weight: 600;
    font-size: 11px;

    tr {
      color: #fff;

      th {
        color: #fff;
        text-transform: none;
      }

      td {
        font-weight: 600;
      }
    }
  }
  td {
    text-align: left;
    vertical-align: top;
  }
}

// CreateClaim
.form-description-img {
  background-image: url(https://www.iqiglobal.com/img/form-number-sample.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 20vh;
  @media (min-width: 576px) {
    height: 170px;
  }
}

.at-table-row__item {
  align-items: start;
}

.at-message__text-content {
  margin: 0px;

  p,
  span,
  font,
  div {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
}

.at-subsales_claims-no-admin-assigned {
  color: #c2410c;
  max-width: 500px;
  background: #ffedd5;
}

.at-subsales_claims-submission-status {
  color: white;
  width: fit-content;
  font-weight: 500;
  font-size: 10px;
  padding: 5px 10px;
  border-radius: 15px;
}

.at-subsales_claims-intenal_agent_card_content {
  display: flex;
  flex-direction: row;

  @media (max-width: 1199px) {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;

    .at-btn {
      margin: auto;
    }
  }
}

.react-datepicker__time-container {
  width: 200px !important;

  .react-datepicker__time-box {
    width: 200px !important;
  }
}

.at-subsales_claims-message_editor {
  .ck-content.ck-editor__editable {
    height: fit-content;
    max-height: 20rem;
    min-height: 10rem;
  }
}

.at-subsales_claims-agency-fee-cont {
  padding: 16px;
  font-size: 14px;
  border: 1px solid #d1d5db;
  border-radius: 8px;

  .at-card__title {
    font-size: 16px;
  }

  svg:not(.at-checkbox__tick) {
    width: 20px;
    color: #f0631c;
    fill: #f0631c;
  }

  &-disabled {
    padding: 16px;
    font-size: 14px;
    border-radius: 2px;
    border-left: 4px solid var(--Blue-500, #3B82F6);
    background: var(--Blue-50, #EFF6FF);
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;

    .at-card__title {
      font-size: 16px;
    }

    .at-form-input__title {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0;
    }
    svg {
      width: 20px;
      color: #3B82F6;
      fill: #3B82F6;
    }
  }
}

.at-subsales_claims-stamping-fee-cont-disabled {
  padding: 16px;
  font-size: 14px;
  border-radius: 2px;
  border-left: 4px solid var(--Blue-500, #3B82F6);
  background: var(--Blue-50, #EFF6FF);
  display: flex;
  padding: 16px;

  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;

  .at-card__title {
    font-size: 16px;
  }

  .at-form-input__title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
  }
  svg {
    width: 20px;
    color: #3B82F6;
  }
}

.at-subsales_claims-cobroke-card {
  background-image: url("https://atlasfrontendassets.s3.ap-southeast-1.amazonaws.com/agent_card_bg.jpg");
  border: 1px solid #d1d5db;
  box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.08),
    0px 2px 1px rgba(16, 24, 40, 0.06),
    0px 1px 3px rgba(16, 24, 40, 0.1);
  border-radius: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0px 20px;

  svg {
    width: 18px;
    height: 18px;
    fill: #9ca3af;
    margin-right: 10px;
  }
}

.at-subsales_claims-cobroke-agent {
  .at-subsales_claims-cobroke-avatar {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    object-fit: cover;
    margin: 20px;
    margin-left: 0px;
  }

  .at-subsales_claims-cobroke-badge {
    font-weight: 500;
    font-size: 13px;
    padding: 2px;
    margin-bottom: 0.5rem;
  }

  label {
    font-weight: 600;
    font-size: 18px;
    color: #111827;
    width: 100%;
  }

  p {
    font-size: 13px;
    color: #111827;
    margin-bottom: 5px;
  }
}

.at-subsales_claims-timeline {
  position: relative;
  margin-bottom: 15px;
}

.at-message__coversation-cont {
  width: 425px;
  max-width: 425px;
  width: -webkit-fit-content;
}

.subsaleclaim-claimform-agencyfees {
  background-color: #eff6ff;
  border-radius: 8px;
  padding: 20px 15px;
  font-weight: 600;
}

.at-subsaleclaim-claimform-total_agency_fees {
  background-color: #fdf6ec;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  padding: 15px;

  .at-form_fields_cont {
    grid-gap: 0;
  }

  h2 {
    font-size: 14px;
    font-weight: 600;
  }
  span {
    font-size: 14px;
    font-weight: 600;
  }

  svg {
    width: 20px;
    height: 20px;
    margin: auto 1rem auto 0rem;
    fill: #f0631c;
    color: #f0631c;
  }
}

.at-claim_submission-participants_cont {
  display: flex;
  grid-gap: 1rem;
  flex-direction: column;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  padding: 20px;

  .at-form-input__title {
    font-size: 18px;
  }

  .at-claim_submission-participant_item_cont:not(:last-child) {
    border-bottom: 1px solid #d1d5db;
    padding-bottom: 16px;
  }
}
.at-claim_submission-participant_item_cont {
  display: flex;
  grid-gap: 8px;
  flex-direction: column;
}
.at-claim_submission-participant {
  .at-checkbox__label-text {
    width: 250px;
  }
  .at-form-input__title {
    @media (min-width: 772px) {
      margin-bottom: 0px;
    }
  }
  @media (max-width: 771px) {
    flex-direction: column;
    
    .at-checkbox {
      width: fit-content;
    }
    > div {
      width: 100% !important;
      min-width: 100% !important;
    }
  }
}

.at-party-checkbox {
  .at-checkbox__span-box {
    margin: 0;
  }
}


.at-form_number-typeahead-head {
  color: var(--text-900, #111827);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 60%;
}

.at-form_number-typeahead-highlight-party {
  display: flex;
  grid-gap: 4px;
}
.at-form_number-typeahead-highlight-address {
  color: var(--text-700, #374151);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 200%;
}

.at-form_number-typeahead-highlight-owner,
.at-form_number-typeahead-highlight-buyer {
  width: fit-content;
  display: flex;
  padding: 2px 8px;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  background: var(--Blue-100, #DBEAFE);

  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.at-form-input .dropdown-menu {
  padding: 4px 4px !important;
  
  .dropdown-item {
    display: flex;
    padding: var(--bootstrap-spacing-2, 8px) 12px;

    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    transition: all 0.3s ease;

    &:hover {
      border-radius: 4px;
      background: #E9ECEF;
    }
  }
}