.at-alert-box__container {
  border: 1px solid #fb923c;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  // margin-bottom: 16px;
  background-color: #fff7ed;

  p,
  span {
    font-size: 14px;
    color: #1f2937;
  }

  .at-alert-box__icon {
    color: #dc6803;
  }
}

.at-alert-box__icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  min-width: 30px;
}

.at-alert-box__container-info {
  border: 1px solid #60a5fa;
  background: #eff6ff;

  .at-alert-box__icon {
    color: #3b82f6;
  }
}

.at-alert-box__container-success {
  border: 1px solid #32d583;
  background: #ecfdf3;

  .at-alert-box__icon {
    color: #039855;
  }
}

.at-alert-box__container-danger {
  border: 1px solid #f04438;
  background: #fef3f2;

  .at-alert-box__icon {
    color: #d92d20;
  }
}

.at-alert-box__close {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }
}
