$gray-50: #f9fafb;
$gray-100: #f3f4f6;
$gray-200: #e5e7eb;
$gray-300: #d1d5db;
$gray-400: #9ca3af;
$gray-500: #6b7280;
$gray-600: #4b5563;
$gray-700: #374151;
$gray-800: #1f2937;
$gray-900: #111827;

$primary-50: #fff7ed;
$primary-100: #ffedd5;
$primary-200: #fed7aa;
$primary-300: #fdba74;
$primary-400: #fb923c;
$primary-500: #f0631d;
$primary-600: #ea580c;
$primary-700: #c2410c;
$primary-800: #9a3412;
$primary-900: #7c2d12;

$secondary-50: #eff6ff;
$secondary-100: #dbeafe;
$secondary-200: #bfdbfe;
$secondary-300: #93c5fd;
$secondary-400: #60a5fa;
$secondary-500: #3b82f6;
$secondary-600: #2563eb;
$secondary-700: #1d4ed8;
$secondary-800: #1e40af;
$secondary-900: #1e3a8a;

$success-50: #ecfdf3;
$success-100: #d1fadf;
$success-200: #a6f4c5;
$success-300: #6ce9a6;
$success-400: #32d583;
$success-500: #12b76a;
$success-600: #039855;
$success-700: #027a48;
$success-800: #05603a;
$success-900: #054f31;

$warning-50: #fffaeb;
$warning-100: #fef0c7;
$warning-200: #fedf89;
$warning-300: #fec84b;
$warning-400: #fdb022;
$warning-500: #f79009;
$warning-600: #dc6803;
$warning-700: #b54708;
$warning-800: #93370d;
$warning-900: #792e0d;

$error-50: #fef3f2;
$error-100: #fee4e2;
$error-200: #fecdca;
$error-300: #fda29b;
$error-400: #f97066;
$error-500: #f04438;
$error-600: #d92d20;
$error-700: #b42318;
$error-800: #912018;
$error-900: #7a271a;

$menu-blue: #b1e5fc;
$menu-green: #b5e4ca;
$menu-orange: #ffbc99;
$menu-purple: #cabdff;
$menu-yellow: #fdd88b;

$new_font-color: #1f2937;

$header_title_large-desktop: 2rem;
$header_title_base-desktop: 1.5rem;
$header_title_small-desktop: 1.25rem;

$header_title_large-mobile: 1.75rem;
$header_title_base-mobile: 1.25rem;
$header_title_small-mobile: 1.125rem;

$box_shadow-xs: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
$box_shadow-sm:
  0px 1px 3px 0px rgba(16, 24, 40, 0.1),
  0px 2px 1px 0px rgba(16, 24, 40, 0.06),
  0px 1px 1px 0px rgba(0, 0, 0, 0.08);
$box_shadow-md:
  0px 3px 5px 0px rgba(16, 24, 40, 0.1),
  0px 1px 18px 0px rgba(16, 24, 40, 0.06),
  0px 6px 10px 0px rgba(0, 0, 0, 0.08);
$box_shadow-lg:
  0px 11px 15px 0px rgba(16, 24, 40, 0.1),
  0px 9px 46px 0px rgba(16, 24, 40, 0.06),
  0px 24px 38px 0px rgba(0, 0, 0, 0.04);
