@import "../../atlas/prerequisite";
.at-cal {
  display: grid;
  margin: -10px;
  position: relative;
  user-select: none;
  overflow: hidden;
  border: 1px solid #d1d5db;

  @include min-screen($calendar-bp) {
    margin: 0;
  }

  &.at-cal--list {
    margin: 0;
  }
}

.at-cal__month {
  @include at-font(600);
  background-color: $limed-spruce;
  color: $atlas-white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 20px;
}

.at-cal__month--previous,
.at-cal__month--next {
  display: flex;

  .svg-icon-chevron {
    fill: $atlas-white;
    cursor: pointer;

    &:hover {
      fill: darken($atlas-white, 10%);
    }
  }
}

.at-cal__month--previous {
  .svg-icon-chevron {
    transform: scale(0.6) rotate(90deg);
  }
}
.at-cal__month--next {
  .svg-icon-chevron {
    transform: scale(0.6) rotate(-90deg);
  }
}

.at-cal__day-header {
  @include at-font(500);
  margin-bottom: 5px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);

  .at-cal--list & {
    display: none;
  }
}

.at-cal__weekday {
  background-color: $tangerine;
  color: $atlas-white;
  text-align: center;
  padding: 10px 0px;
}

.at-cal__day-set-container {
  display: flex;
  flex-wrap: wrap;
}

.at-cal__day-set {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 1px;
  width: 100%;

  @include min-screen($calendar-bp) {
    grid-gap: 5px;
  }

  .at-cal--list & {
    grid-template-columns: 1fr;
  }
}

.at-cal__event-cont {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  overflow: hidden;
  position: relative;

  @include min-screen($calendar-bp) {
    max-height: 80px;
  }

  .at-cal--list & {
    max-height: initial;
  }
}

.at-cal__cal-event {
  .at-cal__event {
    width: 0;
    height: 0;
    padding: 3px;
    margin: 0px 1px 1px 0px;
    max-height: 35px;

    @include min-screen($calendar-bp) {
      display: block;
      width: 100%;
      height: auto;
      padding: 2px 5px;
      border-radius: 2px;
      margin: 0px 0px 2px 0px;
    }
  }
}

.at-cal__day-display-event {
  max-height: none;

  .at-cal__event {
    display: block;
    width: 100%;
    height: auto;
    padding: 2px 5px;
    border-radius: 2px;
    margin: 0px 0px 2px 0px;
  }
}

.at-cal__event {
  display: inline-block;
  background-color: darken($tangerine, 5%);
  color: $atlas-white;
  border-radius: 3px;
  cursor: pointer;
  font-size: 10px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: darken($tangerine, 10%);
  }

  .at-cal--list & {
    display: block;
    width: 100%;
    height: auto;
    font-size: 14px;
    margin: 0px 0px 2px 0px;
    padding: 2px 5px;
    border-radius: 2px;
  }
}

.at-cal__event--load-more {
  background-color: transparent;
  color: black;
  display: none;

  @include min-screen($calendar-bp) {
    display: block;
  }

  .at-cal--list & {
    display: none;
  }
  .at-cal__day--today & {
    color: white;
  }
}

.at-cal__day {
  display: flex;
  align-items: flex-start;
  background-color: white;
  opacity: 1;
  flex-direction: column;
  min-height: auto;
  margin: 0;
  padding: 0px;
  height: 60px;
  overflow: hidden;
  padding: 5px;
  cursor: pointer;
  position: relative;

  @include min-screen($calendar-bp) {
    height: 115px;
  }

  //Sundays
  &:nth-child(7n + 1) {
    color: red;
  }

  //last-row
  &:nth-last-child(-n + 7) {
    // background-color: red;

    .at-cal__day-display {
      top: initial;
      bottom: 5px;
      left: 5px;
      right: initial;
    }
  }

  // Thu, Fri, Sat
  &:nth-child(7n - 1),
  &:nth-child(7n + 5),
  &:nth-child(7n) {
    // background-color: yellow;

    .at-cal__day-display {
      top: 5px;
      right: 5px;
      left: initial;
    }

    // Last row of Thu, Fri, Sat
    &:nth-last-child(-n + 7) {
      // background-color: green;

      .at-cal__day-display {
        top: initial;
        bottom: 5px;
        right: 5px;
      }
    }
  }

  .at-cal--list & {
    flex-direction: row;
    min-height: 50px;
    padding: 20px 10px;
    margin-bottom: 10px;
    height: auto;
  }

  > span {
    font-size: 12px;
    line-height: 12px;

    .at-cal--list & {
      margin-right: 20px;
      font-size: 25px;
    }

    @include min-screen($calendar-bp) {
      line-height: 25px;
      width: 30px;
      margin-right: 0px;
      font-size: 15px;
    }
  }

  & ~ .at-cal__day {
    &:hover {
      background-color: darken(white, 2%);
    }
  }

  & ~ .at-cal__day--today {
    &:hover {
      background-color: darken($lochmara, 5%);
    }
  }
}

.at-cal__day--detail {
  overflow: visible;
}

.at-cal__day--today {
  background-color: $lochmara;
  position: relative;

  > span {
    color: $atlas-white;
    @include at-font(700);
  }

  & ~ .at-cal__day--not-this-month {
    background-color: rgba($limed-spruce, 0.2);
  }
}

.at-cal__day--past {
  background-color: #ebebeb;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba($atlas-white, 0.8);
    position: absolute;
    top: 0;
    left: 0;
  }
}

.at-cal__day--not-this-month {
  background-color: rgba($limed-spruce, 0.2);
  display: block;

  > span {
    opacity: 0.2;
  }

  .at-cal--list & {
    display: none;
  }

  &:hover {
    background-color: rgba($limed-spruce, 0.2) !important;
  }
}

@keyframes button_entrance {
  from {
    opacity: 0;
    transform: translateY(-50%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.at-cal__view-toggle {
  display: inline-flex;
  position: fixed;
  top: 90px;
  right: 25px;
  border-radius: 50%;
  width: 5rem;
  height: 4rem;
  justify-content: center;
  align-items: center;
  padding: 0px;
  z-index: 2;
  animation: button_entrance 0.3s ease 1s;
  animation-fill-mode: both;

  @include box-shadow(2);

  &:focus {
    @include box-shadow(2);
  }
  > svg {
    fill: white;
  }
  @include min-screen($calendar-bp) {
    top: 75px;
    right: 75px;
    width: 80px;
    height: 80px;
  }
  @include min-screen($sidebar-breakpoint) {
    top: 75px;
    right: 20px;
    width: 90px;
    height: 90px;
  }
}

.at-cal__day-display {
  background-color: white;
  position: absolute;
  color: $limed-spruce;
  padding: 10px;
  width: 200px;
  z-index: 2;
  top: 5px;
  left: 5px;
  right: initial;
  @include box-shadow(2);

  .at-cal--list & {
    display: none;
  }
}

// .birthday-enter-active, .birthday-leave-active {
//   transition: opacity .2s ease-in, transform .2s ease-in;
// }
// .birthday-enter, .birthday-leave-to {
//   opacity: 0;
//   transform: translateY(-10px)
// }
