@import "../../atlas/prerequisite";
@import "src/assets/stylesheets/new_atlas/index.scss";

// General Classes
.at-table {
  display: flex;
  flex-direction: column;

  @include min-screen($sidebar-breakpoint) {
    font-size: 12px;
  }
  @include min-screen(1400px) {
    font-size: 14px;
  }

  p {
    @include min-screen($sidebar-breakpoint) {
      font-size: inherit;
    }
  }
}

.at-table-row-cont-even {
  @include min-screen($sidebar-breakpoint) {
    background-color: #fafafa !important;
  }
}

.at-table-row-cont-odd {
  @include min-screen($sidebar-breakpoint) {
    background-color: $white !important;
  }
}

.at-table-row-cont {
  position: relative;
  margin-bottom: 15px;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  overflow: hidden;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  box-shadow:
    0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 2px 1px 0px rgba(16, 24, 40, 0.06),
    0px 1px 1px 0px rgba(0, 0, 0, 0.08);

  .at-table &:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  @include min-screen($sidebar-breakpoint) {
    margin: -1px 0px 0px 0px;
    border-radius: 0px;
    @include box-shadow(0);

    &:nth-child(2n) {
      background-color: $white;
    }
  }

  @include max-screen($sidebar-breakpoint) {
    margin-bottom: 16px;
    background-color: $white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: none;

    &:last-child {
      margin-bottom: 0px;
    }

    &:hover {
      background-color: $white !important;
      transform: none !important;
    }
  }

  &:hover {
    background-color: #f0f0f0 !important;
    transform: translateY(-2px);
  }
}

.at-table__empty-state {
  background-color: #fff;  border-top-width: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  @media (max-width: 991px) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;  
  }
}

.at-table-row {
  overflow: hidden;
  transition: height 0.3s ease;  grid-gap: 0.5rem;

  @include max-screen($sidebar-breakpoint) {
    padding: 16px;
    &:has(.at-table--checkbox-column) {
      .at-table-row__item,
      .at-table-row__item-label {
        padding-left: 20px;
      }
    }
  }

  @include min-screen($sidebar-breakpoint) {
    display: flex !important;
    flex-direction: row;
    height: auto;

    &.at-table-head {
      display: flex !important;
    }
  }
}

.at-tenancy_agreement-table {
  .at-table-row__item {
    @media (max-width: 991px) {
      margin-top: 0px !important;
    }
    @media (min-width: 992px) {
      min-height: 59px;
    }

  }
}

.at-table-row__item {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  word-break: break-word;
  padding-bottom: 0.3rem;
  min-width: 65px;
  grid-gap: 0.1rem;
  padding-top: 1.2rem;
  vertical-align: middle;

  &.at-column-responsive_right,
  & .at-column-responsive_right,
  & .at-column-responsive_right.at-custom-column {
    @media (min-width: 992px) {
      display: flex;
      width: 100%;
      align-items: flex-end !important;
      text-align: right;
      flex-direction: column;
      justify-content: center;

      button {
        margin-left: auto;
      }
    }
  }
  &.at-column-responsive_center,
  & .at-column-responsive_center,
  & .at-column-responsive_center.at-custom-column {
    @media (min-width: 992px) {
      display: flex;
      width: 100%;
      align-items: center;
      text-align: center;
      flex-direction: column;
      justify-content: center;

      p {
        text-align: center;
      }
      button {
        margin: 0.2rem auto;
      }
    }
  }
  .at-table-head__title {
    display: flex;
    align-items: center;
    padding-right: 0.4rem;
  }

  @media (max-width: 991px) {
    margin-top: 8px;
    max-width: 100% !important;

    > div,
    > p,
    > span {
      > span:not(.at-table-head__title) {
        margin-top: 5px;
      }
    }

    & span,
    p {
      font-size: 16px;
    }

    & .at-table-head__title,
    & .at-table-row__item-label {
      font-size: 14px;
    }

    &.at-table-head__hide-mobile {
      padding-top: 0px;
    }
  }

  @include min-screen($sidebar-breakpoint) {
    min-height: 50px;
    margin: -1px 0px 0px 0px;
    width: 100%;
    padding: 10px;
    align-items: flex-start;
  }

  &:first-of-type {
    margin-top: 0px;
  }
  &:last-of-type {
    margin-bottom: 0px;
  }

  &.grid-top-object {
    @include min-screen($sidebar-breakpoint) {
      background-color: rgba($tangerine, 0.2);
    }
    > p {
      @include at-font(500);
    }
  }

  > p {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: #1f2937;
    min-height: 22px;

    @include min-screen($sidebar-breakpoint) {
      white-space: normal;
    }
  }

  > p,
  > svg {
    margin-top: 5px;
  }
}

.at-table-row__item-label-click {
  display: flex;
  align-items: center;
  color: $tangerine;
  font-weight: 600;
}

.at-table-head {
  display: none;
  color: black;
  overflow: visible;

  &:nth-child(1) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  @include min-screen($sidebar-breakpoint) {
    display: flex !important;
    background: white;
    border: 1px solid #d1d5db;
    border-bottom: 0px;
  }

  .at-table__expand {
    display: none;
  }

  .at-table-row__item {
    align-items: center;
    cursor: auto;

    @include min-screen($sidebar-breakpoint) {
      @include at-font(500);
      font-size: inherit;
    }
  }
  .at-column-responsive_right,
  .at-column-responsive_center {
    flex-direction: row;
    justify-content: center;
  }
  .at-column-responsive_right {
    justify-content: flex-end !important;
    align-items: center !important;
  }
}

.at-table-head-checkbox_responsive {
  display: block;
  @media (max-width: 991px) {
    display: none;
  }
}

.at-table-tab-checkbox_responsive {
  display: none !important;

  @media (max-width: 991px) {
    display: flex !important;
    margin: 0px;
    margin-bottom: 4px;
    padding-bottom: 12px;
    border-bottom: 1px solid #d1d5db;
  }
}

.at-admin-table-tab-checkbox_responsive {
  display: none !important;

  @media (max-width: 991px) {
    display: flex !important;
    margin: 0px;
    margin-bottom: 4px;
    padding-bottom: 12px;
    border-bottom: 1px solid #d1d5db;
  }
}

.at-table__expand {
  position: absolute;
  background-color: red;
  width: auto;
  right: 0px;
}

.at-table-radio {
  display: none;

  &:checked {
    ~ .at-table__chevron {
      background-color: $tangerine;
      transform: rotate(180deg);
    }

    ~ .grid-top-object {
      grid-row: 2/3;
    }
  }
}

.at-table-label {
  width: calc(100% + 10px);
  min-height: calc(#{var(--table-row-height)} + 10px);
  position: absolute;
  cursor: auto;
  z-index: 1;
  top: -10px;
  margin-bottom: 0px;

  &:hover {
    ~ .at-table__chevron {
      transform: scale(1.1) rotate(180deg);
    }
  }

  @include min-screen($sidebar-breakpoint) {
    height: auto;
    pointer-events: none;
  }
}

.at-table__chevron {
  position: absolute;
  right: -10px;
  top: -10px;
  transform: rotate(0deg);
  transition:
    transform 0.2s ease,
    background-color 0.2s ease;
  pointer-events: none;
  display: block;
  background-color: $atlas-black;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  @include min-screen($sidebar-breakpoint) {
    display: none;
  }

  > .fa-chevron-up {
    color: white;
    font-size: 12px;
    position: relative;
    top: -1px;
  }
}

.at-table__name {
  font-size: 25px;
  @include at-font(600);

  @include min-screen($sidebar-breakpoint) {
    font-size: inherit;
    @include at-font(400);
  }
}

.at-table__height-control {
  overflow: hidden;
  transition: 0.3s ease-out height;
  position: relative;

  @include min-screen($sidebar-breakpoint) {
    min-height: auto;
    height: auto !important;
    overflow: initial;
  }
}

.at-table-row__item-label {
  position: absolute;
  font-size: 14px;
  color: #111827;
  @include at-font(600);
  top: 0px;
  left: 0px;
  display: block;
  text-align: start !important;

  @include min-screen($sidebar-breakpoint) {
    display: none;
  }

  .grid-top-object & {
    color: $tangerine;
  }
}

.at-table__select {
  width: 100%;
  margin-bottom: 10px;

  @include min-screen(600px) {
    width: 200px;
  }

  @include min-screen($sidebar-breakpoint) {
    margin-bottom: 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.at-table__select--sort {
  z-index: 2;
}
.at-table__select--page-count {
  z-index: 3;
}
.at-table__form-input {
  width: 100%;
  max-width: 410px;
}

.at-heading_card_table {
  .at-table__options {
    margin: 1rem 0rem 0rem;

    @media (max-width: 576px) {
      margin: 1rem -0.5rem 0rem;
    }
  }
  .at-table-head:nth-child(1) {
    border-radius: 0px;
  }
}

.at-table__options {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ~ .at-table__options {
    .at-table-head {
      border-top-left-radius: 0.6rem;
      border-top-right-radius: 0.6rem;
    }
  }
}

.at-table__actions {
  display: flex;
  align-items: center;
  align-content: center !important;
  flex-wrap: wrap;
  grid-gap: 0.5rem;

  > svg {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 5px;
    fill: $limed-spruce;
    transition: fill 0.2s ease;

    &:hover {
      fill: $tangerine;
    }

    &:active {
      fill: $tangerine-dark;
    }

    @include min-screen($sidebar-breakpoint) {
      width: 25px;
      height: 25px;
    }
  }

  @include max-screen($pers-sales-breakpoint-mobile) {
    display: none;
  }
}

.at-table-head__arrow-container {
  display: inline-flex;
  flex-direction: column;
  position: absolute;
  border-radius: 50%;
  z-index: 2;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  top: -0.6rem;
}

.at-table-head__title {
  word-break: initial;
  color: #1f2937;
  font-size: 14px;
}

.at-table-head__arrow {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  display: inline-block;
  transition: opacity 0.2s ease;
  cursor: pointer;
}

.at-table-head__arrow--up {
  transform: rotate(0deg);
  margin-bottom: 2px;
  border-bottom: 4px solid #3b82f6;
}

.at-table-head__arrow--down {
  transform: rotate(0deg);
  border-top: 4px solid #3b82f6;
}

.at-table__pagination {
  @media (min-width: 992px) {
    background-color: #fff;
    padding-bottom: 20px;
    border: 1px solid #d1d5db;
    border-top-width: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.at-table__pagination-info {
  color: #1f2937;
  font-size: 14px;
  margin: 16px auto;

  span {
    font-weight: 600;
  }
}

.at-pagination__cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  border-top: 0px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.at-table__help-text {
  border-radius: 10px;
  padding: 20px;
  background-color: white;
  box-shadow: 5px 8px 10px -10px;
  p {
    font-size: 14px;
    font-weight: 500;
  }
}

.at-table__text-ellipsis {
  display: flex;
  align-items: center;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
}

// Grid Controller
.grid-controller {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5rem 0 !important;

  @include min-screen($pers-sales-breakpoint-mobile) {
    grid-template-columns: 1fr 1fr;
  }

  @include min-screen($sidebar-breakpoint) {
    display: flex;
    gap: 0.5rem !important;
  }

  .at-table-row__item:first-child {
    grid-row: 1/2;
  }
}

.grid-control--pers-sales {
  grid-template-columns: 1fr 1fr;

  .at-table-row__item {
    grid-column: 1/-1;
  }
  .at-table-row__item:nth-child(1) {
    grid-column: 1/2;
  }
  .at-table-row__item:nth-child(2) {
    grid-column: 2/3;

    &.grid-top-object {
      grid-row: 1;
    }
  }

  @include min-screen($pers-sales-breakpoint-mobile) {
    grid-template-columns: repeat(5, 1fr);

    .at-table-row__item:nth-child(1).grid-top-object {
      + .at-table-row__item + .at-table-row__item {
        grid-column: 3/-1;
      }
    }
    .at-table-row__item:nth-child(2).grid-top-object {
      + .at-table-row__item {
        grid-column: 3/-1;
      }
    }

    .at-table-row__item:nth-child(3) {
      grid-column: 3/-1;

      &:not(.grid-top-object) {
        grid-column: 1/-1;
      }
    }

    .grid-top-object {
      grid-column: 3/-1;
    }
  }
}

.grid-item-full {
  grid-column: 1/-1;
}

.grid-top-object {
  grid-row: 2;
  grid-column: 1/-1;

  @include min-screen($pers-sales-breakpoint-mobile) {
    grid-row: 1/2;
    grid-column: 2;
  }

  &::after {
    color: $tangerine;
  }
}

.at-table-row-cont [class^="svg-icon"] {
  width: 20px;
  height: 20px;
}

.svg-icon-info {
  fill: #007bff;
}

.svg-icon-info--danger {
  fill: #f04438;
}

.at-table-icon-button {
  box-shadow: 0px 1px 1px #0000001a;
  border: 1px solid #d1d5db;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  background: white;
  align-items: center;
  padding: 8px 12px;
  width: 35px;
  height: 35px;

  &:disabled {
    opacity: 0.5;
  }

  svg {
    vertical-align: sub;
    min-width: 18px;
    min-height: 18px;
  }
}

.at-table-approve-button {
  border: 1px solid #d1d5db;
  display: flex;
  width: 32px;
  height: 32px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  box-shadow: 0px 1px 2px #0000001a;

  svg {
    vertical-align: sub;
  }

  @media screen and (max-width: 992px) {
    display: none;
  }
}

.at-table-row__item-empty {
  display: block;
  text-align: center;
  padding: 80px 0px;

  p {
    font-size: 18px;
    font-weight: 600;
  }

  span {
    margin-top: 5px;
    font-size: 14px;
  }

  button {
    margin-top: 10px;
    color: #2563eb;
  }
}

.at-table__search-cont {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px;
  border: 1px solid #d1d5db;
  box-shadow:
    0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 2px 1px 0px rgba(16, 24, 40, 0.06),
    0px 1px 1px 0px rgba(0, 0, 0, 0.08);
}

.at-table__search-action {
  background: #f9fafb;
}

.at-table__search-input {
  width: calc(100% - 120px);
  font-size: 14px;
  padding: 0px 15px;

  &:placeholder-shown {
    text-overflow: ellipsis;
  }
}

.at-table__search-icon {
  fill: black;
  width: 35px;
  margin-left: 5px;
  height: 35px;
  padding: 6px;
  border-radius: 50%;
  background: white;
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
}

.at-table-search__additional-btn {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  padding: 0px 10px;
  font-weight: 500;
  color: #1f2937;
  border: 1px solid #d1d5db;
  border-left: none;
  background: white;

  &:nth-child(1) {
    border-left: 1px solid #d1d5db;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:nth-last-child(1) {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.at-table-search__additional--selected {
  background: #d1d5db;
}

.at-table--checkbox-actions,
.at-table--checkbox-actions-sticky {
  display: flex;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  flex-direction: row;
  overflow: auto;
  width: 100%;
  padding: 8px 16px;
  flex-wrap: wrap;
  justify-content: space-between;

  @include max-screen($sidebar-breakpoint) {
    display: block;
    padding: 8px;
    background-color: white;
    border-radius: 8px;
    margin-bottom: 16px;
    border: 1px solid #d1d5db;
  }
}

.at-table--checkbox-actions-sticky {
  position: fixed;
  top: 60px;
  left: 0px;
  z-index: 2;
  border-radius: 0px;
}

.at-table--checkbox-column {
  width: 10%;

  @include max-screen($sidebar-breakpoint) {
    display: none;
  }
}

.at-table--checkbox-mobile {
  display: none !important;

  @include max-screen($sidebar-breakpoint) {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 20px;
    position: absolute;
    left: -12px;
    top: 0px;
  }
}

.at-table--radio-mobile {
  display: none !important;

  @include max-screen($sidebar-breakpoint) {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 20px;
    position: absolute !important;
    left: 0px;
    top: 0px;
  }
}

.at-table-head__hide-mobile {
  @include max-screen($sidebar-breakpoint) {
    .at-table-row__item-label {
      display: none;
    }
  }
}

.at-table-search__chevron {
  width: 14px;
  height: 14px;
  margin-left: 10px;
  transition: all 0.5s ease;
}

.at-table-row__mobile-action {
  display: flex;
  justify-content: space-evenly;
  border-top: 1px solid #d1d5db;
  padding: 10px 0px;

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  button {
    display: flex;
    align-items: center;
  }

  span {
    font-size: 14px;
    font-weight: 500;
  }

  svg {
    width: 18px;
    height: 18px;
  }

  @include min-screen($pers-sales-breakpoint-mobile) {
    display: none;
  }
}

.at-table__head-title {
  display: flex;
  align-items: center;
  padding: 12px;

  h4 {
    color: #111827;
    font-size: 18px;
    font-weight: 600;
    line-height: 130%;
  }

  button svg {
    width: 20px;
    height: 20px;
    fill: white;
    margin-right: 8px;
  }

  @media screen and ( max-width: 989px ) {
    padding: 0px;
    padding-bottom: 12px;
  }
}

.at-icon_svg-table_heading-title {
  display: flex;
  width: 32px;
  height: 32px;
  background-color: #ffedd5;
  color: #f0631e;
  fill: #f0631e;
  padding: 0.5rem;
  border-radius: 50%;

  & ~ h5 {
    line-height: 2rem;
  }
}

.at-table_heading {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  padding: 12px 16px;
  background-color: #ffffff;
  border: 1px solid #d1d5db;
  border-bottom: 0px;

  &:first-of-type {
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;  
  }
  flex-wrap: wrap;
  > div {
    align-items: center;
  }

  @media (max-width: 991px) {
    border: 0px;
    border-radius: 0;
    background-color: transparent;
    padding: 0px;
  }

  ~ .at-table__options {
    background-color: #ffffff;
    border: 1px solid #d1d5db;
    border-bottom: 0px;

    @media (max-width: 991px) {
      border: 0px;
      background-color: transparent;
    }
  }
  & ~ .at-table {
    &.at-table--set {
      @media (max-width: 991px) {
        margin-top: 16px;
      }
    }
    .at-table-head {
      border: 1px solid #d1d5db;
      border-bottom: 0px;
      background-color: #ffffff;

      @media (max-width: 991px) {
        border: 0px;
        background-color: transparent;
      }
    }
    .at-table-row-cont {
      border: 1px solid #d1d5db;

      .at-empty-state {
        border: 0px;
      }
    }

    .at-empty-state {
      border: 1px solid $gray-300;
      border-bottom-left-radius: 8px !important;
      border-bottom-right-radius: 8px !important;

      @media (max-width: 991px) {
        border: 0px;
        background-color: transparent;
        box-shadow: none;
        border-top-left-radius: 8px !important;
        border-top-right-radius: 8px !important;  
      }
    }
    .at-table-row-cont:last-of-type {
      border-bottom-left-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }
    .at-table-head {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    @media screen and (min-width: 991px) {
      .at-table-row-cont:nth-child(2n) {
        background-color: #fafafa;
      }
    }
  }
}

.at-table_heading-responsive {
  display: flex !important;
  color: black;
  overflow: visible;

  @media (max-width: 991px) {
    display: none !important;
    border-bottom: 0px;
  }
}

.at-table_heading-responsive-mt {
  @media (max-width: 991px) {
    margin-top: 1rem;
  }
}