.at-graphic__carousel {
  background-color: rgba(0, 0, 0, 0.4);
  margin: 20px 0;
  padding: 8px;

  .slick-prev,
  .slick-next {
    width: 1.5rem !important;
    height: 1.5rem !important;
    background-color: #fff !important;
    color: #f0631d !important;
    border-radius: 50% !important;
    z-index: 10 !important;

    &.slick-disabled {
      opacity: 0.4 !important;
      cursor: default !important;
    }

    &:hover {
      background-color: #fff !important;
      color: #f0631d !important;
    }

    &::before {
      content: "" !important;
    }
  }

  .slick-prev {
    left: 0.5rem !important;
  }

  .slick-next {
    right: 0.5rem !important;
  }

  .slick-slide {
    height: 200px !important;

    &:not(:last-child) {
      margin-right: 8px;
    }

    & > div {
      height: 100% !important;

      & > img {
        height: 100% !important;
        cursor: pointer;

        &.at-graphic__active-template {
          border: 2px solid #f18e03;
        }
      }
    }
  }
}

.at-graphic-text-dropdown {
  cursor: pointer;
  border-radius: 5px;
  background-color: white;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.45);
  position: absolute !important;
  min-width: 180px;
  top: 40px;
  left: 0px;
  z-index: 3;
  padding: 1rem 2rem;
}

.at-graphic-download_button {
  cursor: pointer;
  text-align: center;

  > p {
    font-size: 0.85rem;
    font-weight: 500;
  }

  > svg {
    color: rgb(255, 255, 255);
    border-radius: 0.4rem;
    padding: 0.8rem 0.4rem;
    background: #ee6d4d;
    width: 4rem;
    height: 4rem;
    backdrop-filter: opacity(0.8);
  }
}

.at-graphic__text-style-btn-group {
  display: inline-flex;
  gap: 0.5rem;
}

.at-graphic__text-style-icon-btn {
  width: 35px;
  height: 35px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #343a40;
  color: #fff;
  padding: 0.25rem;
}

@media screen and (min-width: 576px) {
  .at-graphic__carousel {
    .slick-next,
    .slick-prev {
      width: 2.5rem !important;
      height: 2.5rem !important;
    }

    .slick-left {
      left: 1rem !important;
      right: 1rem !important;
    }
  }
}
