.watermark {
  position: relative;
}

.watermark::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("./watermark.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  opacity: 0.2;
}

.cobroke-letter-table {
  tbody {
    vertical-align: top;
    width: 555px;
    .col-left {
      font-weight: bold;
    }
  }
}

.cobroke-term-table {
  border-collapse: separate;
  td {
    padding: 2px;
  }
  tbody {
    .col-left {
      padding-left: 6px;
      vertical-align: top;
      text-align: left;
    }
    .col-right {
      padding-left: 11px;
      text-align: justify;
    }
  }
}
