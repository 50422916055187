.at-team-project-sales_stat-cont {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 16px;
  grid-gap: 8px;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr 1fr;

    .at-card--stat__general-icon-cont {
      min-width: 40px;
      width: 40px;
      min-height: 40px;
      height: 40px;
    }

    > div:not(:nth-child(-n + 4)) {
      grid-column: 1 / span 2;
    }
  }
}
