@mixin flexcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin min-screen($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin max-screen($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin sprite-size($width, $height) {
  width: $width;
  height: $height;
}

@mixin placeholder($color, $opacity) {
  color: $color;
  opacity: 1;
}

@mixin input-size($padding-vertical, $padding-horizontal, $height) {
  padding: $padding-vertical $padding-horizontal;
  height: $height;
}
