.at-custom_ckeditor {
  position: relative;

  .at-custom_ckeditor-placeholder {
    user-select: none;
    text-align: start;
    padding: 1rem;
    position: absolute;
    z-index: 0;
    color: grey;
    cursor: inherit;
  }
}

.at-custom_ckeditor-hide_toolbar {
  .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
    background-color: #ffffff80;
    border: 1px solid #a0a0a0;
    border-radius: 0.6rem;
    min-height: 6rem;

    &:hover,
    &:focus-within {
      border: 2px solid #3b82f6;
      box-shadow: 0px 0px 0px 2px #3b82f6;
    }
  }

  .ck-editor__top.ck-reset_all {
    display: none;
  }
}

.at-custom_ckeditor-disabled {
  .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
    background-color: #a0a0a021;
    cursor: auto;
  }
}
