@import "../../../../../stylesheets/atlas/prerequisite";

.at-presentation__item {
  @include min-screen(1500px) {
    flex: 0 0 33.33333%;
  }
  @include min-screen(2600px) {
    flex: 0 0 20%;
    max-width: 20%;
  }
  @include min-screen(4200px) {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .at-presentation__slick-initialized {
    margin: 0.5rem;
    border-radius: 8px;
    border: 1px solid #d1d5d1;
    height: calc(100% - 1rem);
  }

  .at-presentation__slick-slider-arrow {
    z-index: 1;
    top: 35%;

    @media (min-width: 992px) {
      top: 30%;
    }
    @media (min-width: 1200px) {
      top: 35%;
      .at-presentation__item-menu-expand & {
        top: 30%;
      }
    }
    @media (min-width: 1400px) {
      top: 35%;
    }
    @media (max-width: 420px) {
      top: 135px;
    }
  }
}
.at-presentation-slider_item {
  border-radius: 8px;
  display: grid !important;
  color: white;
  grid-template-columns: minmax(6.25rem, 1fr);
  grid-template-rows: 9.5rem minmax(13rem, 14.6rem) auto;
  gap: 0px;
  padding: 0px;
  margin: auto;
  transition:
    box-shadow 0.25s,
    transform 0.25s;
  overflow: hidden;
}

.at-presentation__gradient_foreground {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  background: linear-gradient(#99999911, #00000022, #0000005e, #979797);
}

.at-presentation__image {
  position: relative;
  grid-column: 1;
  grid-row: 1/-1;
  z-index: 2;
}

.at-presentation__info {
  margin-top: 6px;
  font-size: 10px;

  & span {
    display: inline-block;
    font-size: 14px;
    color: #eaf3f9;
    font-family: sans-serif;
    border-radius: 8px;
    background-color: #4b5563;
    margin: 0.1rem;
    padding: 0.3rem 0.5rem;

    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
}
.at-presentation__specs {
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  span,
  div {
    font-size: 15px;
  }

  & .at-presentation_tooltip-content {
    display: none;
  }

  & .at-presentation_tooltip-title,
  & .at-presentation_tooltip-content {
    color: #eaf3f9 !important;

    @media (max-width: 575px) {
      display: inline-block !important;
    }
  }
}

[class^="at-presentation-project_icon"] {
  width: fit-content;
  & span {
    font-size: 12px !important;
  }
}

.at-presentation-project_icon-type {
  color: rgb(112, 111, 211);
  border: 2px solid #706fd3;
  & svg {
    fill: rgb(112, 111, 211) !important;
  }
}

.at-presentation-project_icon-property_type {
  color: #d93335;
  border: 2px solid #d93335;
  & svg {
    fill: #d93335 !important;
  }
}

.at-presentation-project_icon-price {
  color: #f18e05;
  border: 2px solid #f18e05;
  & svg {
    fill: #f18e05 !important;
  }
}

.at-presentation__details-upper_cont {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-column: 1;
  grid-row: 1/2;
  z-index: 3;
  margin-top: -1px;
}

.at-presentation__details-upper_cont-cont {
  display: flex;
  align-items: center;
  padding: 0.8rem 0.5rem;
  grid-gap: 0.2rem;
}

.at-presentation__details-action_cont {
  position: absolute;
  right: 5px;
  top: 0px;
  padding: 0.8rem 0.5rem;
  display: flex;
  grid-gap: 0.3rem;

  button {
    padding: 2px;
    width: 32px;
    height: 32px;
    margin-right: 2px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  @include max-screen(1000px) {
    button {
      padding: 2px;
      width: 24px;
      height: 24px;

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
}

.at-presentation__details-lower_cont {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-column: 1;
  grid-row: 2;
  padding: 10px;
  z-index: 3;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  justify-content: end;

  @media (max-width: 576px) {
    justify-content: flex-end;
  }
}

.at-presentation__img-cont {
  position: relative;
  height: 100%;

  @media (max-width: 420px) {
    padding: 0px;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 50% 0%;
    background-color: #f18e0522;
    border-bottom: 0px;
  }
}

.at-presentation__category,
.at-presentation__proptitle,
.at-presentation__proptype {
  border-radius: 8px;
  position: absolute;
  display: flex;
  padding: 2px 4px;

  @include min-screen(768px) {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > svg {
    width: 22px;
    height: 22px;
    display: none;

    @include min-screen(768px) {
      display: block;
    }
  }

  > span {
    display: inline-block;
    font-size: 8px;
    font-weight: 500;
    width: 55px;

    @include min-screen(768px) {
      display: none;
    }
  }
}

.at-presentation__category {
  left: 5px;
  top: 5px;
  > span {
    color: $atlas-white;
  }

  @include min-screen(768px) {
    left: 5px;
    bottom: -10px;
    top: initial;
  }
}

.at-presentation__proptitle {
  left: 5px;
  top: 25px;
  font-weight: 500;
  > span {
    color: $atlas-white;
  }

  @include min-screen(768px) {
    left: 45px;
    bottom: -10px;
    top: initial;
  }
}

.at-presentation__proptype {
  left: 5px;
  top: 45px;
  > span {
    color: $atlas-black;
  }

  @include min-screen(768px) {
    left: 85px;
    bottom: -10px;
    top: initial;
  }
}

.at-presentation__tenure {
  position: absolute;
  bottom: -10px;
  right: 5px;
}

[class*="at-presentation__props"] {
  min-height: 16px;
  display: flex;
  justify-content: flex-start;
  padding-right: 5px !important;
  margin-top: 2px;
  flex: 0 0 100%;
  flex-wrap: wrap;

  &:not(.at-presentation__props-built_up__land_area) {
    @media (min-width: 1100px) {
      flex: 0 0 100%;

      .at-presentation__item-menu-expand & {
        flex: 0 0 50%;
      }
    }
    @media (min-width: 1200px) {
      flex: 0 0 50%;
      .at-presentation__item-menu-expand & {
        flex: 0 0 100%;
      }
    }
  }

  .at-presentation__icon {
    margin-right: 10px;
    align-items: flex-start;
  }
  .at-presentation__props-feature .at-presentation__icon {
    align-items: flex-end;
  }

  & span {
    color: #eaf3f9 !important;
    font-weight: 400 !important;
    font-size: 14px;
    font-family: sans-serif;

    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  & svg {
    width: 14px;
    height: 14px;
    fill: #eaf3f9 !important;

    @media (max-width: 575px) {
      margin: 0px 5px 0px 0px !important;
      width: 20px !important;
    }
    @media (max-width: 767px) {
      width: 15px;
      height: 13px;
    }
  }

  @media (max-width: 575px) {
    & div {
      margin: 2px 0px !important;
    }
  }
}

.at-presentation__props-built_up__land_area,
.at-presentation__props-available_date {
  display: inline-flex;

  &:not(.at-presentation__props-built_up__land_area) span {
    min-width: 100px;
  }
}

.at-presentation__code {
  position: relative;
  background-color: #111111;
  color: #eaf3f9;
  font-weight: 500;
  font-size: 12px;
  display: inline-block;
  transform: translate(0px, -4px);
  padding: 0.2rem 0.6rem;
  border-radius: 0.3rem;
}

.at-presentation__price {
  display: block;
  color: #fa923c;
  width: fit-content;
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0.4rem 0rem;
  text-shadow: 1px 1px 0px #323232;

  span {
    text-shadow: 0px 0px 2px #000;
  }
}

.at-presentation__title {
  min-height: 28px;
  font-size: 18px;
  color: #eaf3f9;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 25px;
  width: 100%;
  font-weight: 600;
  text-shadow: 1px 1px 0px #474747e6;
}

.at-presentation__status {
  top: -4px;
  font-size: 12px !important;
  transform: translate(0px, -4px);
  display: inline-flex !important;
  padding: 0.3rem 0.4rem !important;
  border-radius: 0.3rem !important;
  border: 2px solid #000;
}

.at-presentation__hr {
  margin: 5px 0px;
  border-color: rgba(220, 220, 220, 1) !important;

  @include min-screen(768px) {
    margin: 10px 0px 10px 0px;
  }
}

.at-presentation__date {
  color: #697684;
  color: #eaf3f9;
  font-size: 14px;
}

.at-btn-details {
  width: 100px;
  margin-top: auto;
  transition: box-shadow 0.17s;

  &:hover {
    box-shadow: 0px 0px 0px 0.5px #88888855;
  }
}

.tooltip-arrow {
  position: relative;
  height: fit-content;
  width: max-content;
  max-width: 300px;

  &.bottom::after {
    content: " ";
    position: absolute;
    top: -20px; /* At the top of the tooltip */
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #000000ee transparent;
  }

  &.top::after {
    content: " ";
    position: absolute;
    bottom: -20px; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: #000000ee transparent transparent transparent;
  }
}

.at-presentation__agent-section {
  grid-column: 1;
  grid-row: 3/-1;
  border-top-width: 1px solid #ffff;
  background-color: rgba(255, 255, 255);
  padding: 16px;
  display: flex;
  z-index: 2;
  align-items: center;

  > img {
    object-fit: cover;
    width: 55px;
    height: 55px;
    min-height: 50px;
    border-radius: 50%;
    margin-right: 12px;
  }

  > div {
    color: #000;
    text-align: left;
    small {
      font-size: 12px;
      color: #6b7280;
      display: block;
    }
    strong {
      color: #111827;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 5px;
      line-height: 130%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .at-presentation__agent-section_sub-details {
      display: flex;
      align-items: center;
      p {
        font-size: 12px;
        margin-left: 4px;
        color: #1f2937;
        white-space: nowrap;
      }
      svg {
        width: 12px;
        height: 12px;
        fill: #f0631d;
      }
    }

    @media (max-width: 767px) {
      p {
        font-size: 0.65em;
      }
      strong {
        font-size: 0.7em;
      }
    }
  }
}
