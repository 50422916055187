body {
  min-height: 100%;
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: fill-available;
}

html,
body {
  height: 100%;
  line-height: 1;
  overflow: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
span,
strong {
  line-height: normal;
  margin: 0;
  font-family: "Inter", serif;
}

a {
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}

button {
  background-color: transparent;
  border: 0;
  padding: 0; //overwrite the user agent stylesheet's padding
  cursor: pointer;

  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: no-drop;
  }
}

img {
  max-width: 100%;
}

.dropdown-menu {
  font-size: 11px;
  padding: 6px 10px;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

input {
  border: 0px;
  padding: 0px;
}

svg {
  width: 100%;
  height: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea {
  outline: none;
}

label {
  margin: 0;
}

strong {
  @include at-font(500);
}

:focus {
  outline: none;
}
