@import "../../../../atlas/prerequisite";

.timeline-block {
  display: flex;

  .timeline-point {
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    border-radius: 50%;
    text-align: center;
    background: #ea580c;
    display: flex;
    z-index: 2;
    align-items: center;
    margin-right: 20px;
    justify-content: center;

    .svg-icon-bell {
      fill: #fff;
      font-size: 12px;
      width: 20px;
      height: 20px;
    }
  }

  .timeline-notification_line {
    width: 100%;
    height: 100%;
    position: relative;

    &::after {
      content: " ";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0.5rem;
      right: 2.35rem;
      border-right: 3px solid rgba(0, 0, 0, 0.1);
    }
  }

  .timeline-content {
    position: relative;
    width: 100%;

    .timeline-content-card {
      float: left;
      position: relative;
      padding: 16px;
      background: #ffffff;
      border: 1px solid #d1d5db;
      box-shadow:
        0px 1px 1px rgba(0, 0, 0, 0.08),
        0px 2px 1px rgba(16, 24, 40, 0.06),
        0px 1px 3px rgba(16, 24, 40, 0.1);
      border-radius: 8px;
      width: inherit;

      .timeline-content-header {
        font-size: 12px;
        padding-bottom: 15px;
        font-weight: 500;
        color: #4b5563;
      }

      .timeline-content-body {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

.notification-icon-disable-mobile {
  @include max-screen(768px) {
    display: none;
  }
}
