.at-training-datepicker {
  width: 100% !important;
  min-width: 100%;
}

.at-export-datepicker {
  .react-datepicker-wrapper {
    min-width: 100%;
    @media (min-width: 599px) {
      min-width: 70%;
    }
    .react-datepicker__input-container {
      min-width: 100%;
    }
  }
}
