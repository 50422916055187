@import "../../atlas/prerequisite";

.subsales-type-container {
  .subsales-type-col {
    text-align: center;
  }
}

.btn-subsales-type {
  max-width: 300px;
  white-space: initial;

  .svg-icon-house-sell {
    width: 100px;
    height: 100px;
    fill: $east-bay;
    margin-bottom: 10px;
  }

  .svg-icon-rent {
    width: 100px;
    height: 100px;
    fill: $shamrock;
    margin-bottom: 10px;
  }

  .btn-subsales-instruction {
    overflow-wrap: break-word;
  }
}

.subsales-progress {
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
  padding-top: 10px;
  padding-bottom: 10px;
  @media screen and (max-width: 360px) {
    flex-direction: column;
    padding-left: 10px;
  }
}

.subsales-info-tabs {
  background: rgba(0, 0, 0, 0.3);
  padding: 10px;

  @media (max-width: 450px) {
    margin: 0 -1rem;
  }
}

.subsales-claim-info-tabs {
  padding-top: 10px;
  @media screen and (max-width: 1500px) {
    padding-left: 10px;
  }
  @media screen and (max-width: 670px) {
    button {
      border-radius: 0.25rem !important;
      margin-top: 10px;
    }
  }
}

.subsales-reminder-days-input {
  max-width: 50px;
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid #000;

  &:disabled {
    background-color: #f3f4f6 !important;
    color: #374151;
    border: 1px solid #d1d5db;
    font-weight: 500;
    cursor: not-allowed;
  }
}
