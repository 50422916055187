@import "src/stylesheets/atlas/prerequisite";

.at-atlas_store_ticket_table-item_cont {
  position: relative;
  width: 49%;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  border-left: 0px;

  @include max-screen(991px) {
    width: 100%;
  }

  .at-atlas_store_ticket_table-item_left {
    display: flex;
    flex-direction: column;
    width: 50px;
    height: 100%;
    position: absolute;
    z-index: 0;
    filter: drop-shadow(2px 5px 5px rgba(0, 0, 0, 0.15));
    border-radius: 8px;

    .top,
    .bottom {
      flex: 1;
      width: 100%;
      background-color: #f0631d;
    }

    .top {
      border-radius: 5px 5px 0 0;
    }

    .bottom {
      border-radius: 0 0 5px 5px;
    }

    .rip {
      position: relative;
      height: 20px;
      flex-shrink: 0;
      margin: 0 20px;
      background-color: #f0631d;

      &::before {
        position: absolute;
        content: "";
        width: 40px;
        height: 40px;
        border: 15px solid transparent;
        border-top-color: #f0631d;
        border-right-color: #f0631d;
        transform: translate(-55%, -50%) rotate(45deg);
        border-radius: 50%;
        top: 50%;
      }

      &::before {
        left: -18px;
      }

      &::after {
        right: -80px;
        transform: translate(-50%, -50%) rotate(225deg);
      }
    }
  }

  .at-atlas_store_ticket_table-item_right {
    width: calc(100% - 8px);
    height: 100%;
    left: 8px;
    position: absolute;
    background-color: #fff;
  }

  .at-atlas_store_ticket_table-content {
    display: flex;
    margin-left: 8px;
    z-index: 3;
    padding: 12px;
    width: 100%;

    @media (max-width: 575px) {
      padding: 5px;
    }

    .at-ticket__img-cont {
      border: 2px solid;
      border-radius: 3px;
      object-fit: cover;
      margin-right: 10px;
    }
    .at-ticket__image {
      width: 160px;
      height: 160px;
      z-index: 2;
      border-radius: 8px;
      object-fit: cover;

      @include max-screen(768px) {
        width: 100px;
        height: 100px;
        grid-row: 2/3;
        grid-column: 1/2;
      }
    }
  }
}
