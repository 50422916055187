.at-settings_card {
  background-color: #f5f5f5;
  padding: 10px 10px 5px;
  border-radius: 5px;
  width: 100%;
  box-shadow: -1px 8px 8px -2px rgba(220, 203, 203, 1);
  -webkit-box-shadow: -1px 8px 8px -2px rgba(220, 203, 203, 1);
  -moz-box-shadow: -1px 8px 8px -2px rgba(220, 203, 203, 1);

  h5 {
    font-weight: 500;
  }
}

.at-settings_card-title {
  color: #1f2937;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
}

.at-settings_card-action {
  display: flex;
  margin-left: auto;
}

@media screen and (max-width: 600px) {
  .at-settings_card {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .at-settings_card-action {
    margin-left: 0px;
    margin-top: 5px;
  }
}
