@import "src/assets/stylesheets/new_atlas/index.scss";

.at-column-nested_table {
  overflow: initial !important;
  max-width: 48rem;

  @media (max-width: 1499px) {
    max-width: 24rem !important;
  }
  @media (max-width: 766px) {
    max-width: initial;
  }
}

.at-nested_table {
  @media (max-width: 1499px) {
    width: calc(100% + 2rem);
    margin: 0 -1rem;
  }
}

.at-nested_table-content {
  @media (max-width: 1499px) {
    display: grid;
    grid-gap: 0.5rem;
  }
}

.at-nested_table-header {
  background-color: white;
  border: 1px solid #dee2e6;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  font-weight: 600;

  @media (max-width: 1499px) {
    display: none;
  }

  .at-nested_table-row_item {
    color: black;
    font-size: 11px;
    vertical-align: center;
  }
}

.at-nested_table-content_row {
  display: flex;
  grid-gap: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  background-color: #ffffff;

  @media (max-width: 1499px) {
    flex-direction: column;
  }

  &:nth-child(1) {
    border-top: none;
  }

  .at-nested_table-second_layer & {
    background-color: inherit;
  }

  .at-nested_table-row_item {
    @media (max-width: 1499px) {
      width: 100% !important;
      padding: 0.1rem 0.5rem;

      .at-nested_table-second_layer & {
        padding: 0px 8px;
      }
    }
  }

  &:nth-last-child(1) {
    border-bottom: none;
  }

  .at-nested_table-item_label {
    display: block;
    font-size: 12px;
    font-weight: 500;
    color: #4b5563;
  }
}

.at-nested_table-row_item {
  font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
  padding: 12px 8px;
  width: 100%;
  -webkit-font-smoothing: antialiased;
}

.at-nested_table-second_layer {
  margin-top: 0.5rem;
  padding-left: 8px;
  border-left: 4px solid #2563eb;
}

.at-nested_table-admin-label {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  border-radius: 50%;
  background-color: #2563eb;
  font-size: 10px;
  display: flex;
  font-weight: 700;
  justify-content: center;
  color: white;
  margin-right: 5px;
}

.at-table-column_responsive_null_display {
  @media (max-width: 767px) {
    display: none;
  }
}

.btn-lg {
  @media (max-width: 767px) {
    font-size: 14px;
    padding: 14px;
  }
}

.at-message__text-content {
  margin: 0px;

  p,
  span,
  font,
  div {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
}

.at-document__bank-in-slip-detail {
  margin: 0.5rem 0px 0px;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  p {
    font-size: 12px;

    @media screen and (max-width: 1090px) {
      font-size: 10px;
    }
    @media screen and (max-width: 992px) {
      font-size: 12px;
    }
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
}

.react-datepicker__time-container {
  width: 200px !important;

  .react-datepicker__time-box {
    width: 200px !important;
  }
}

.at-unit_info-agent_cont {
  .at-icon_svg-table_heading-title {
    width: 32px;
    min-width: 32px;
    height: 32px;
  }
  @media (max-width: 1199px) {
    .at-form_field-col-6 {
      max-width: 100%;
      flex: 1 1 100%;
    }
  }
}

.at-unit_info-agent_detail {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
}
.at-unit_info-agent-icon_title {
  display: flex;
  grid-gap: 8px;
  flex-direction: column;
  align-items: flex-start;

  p {
    min-width: 170px;
    margin-bottom: 0px;
  }
}

.at-unit_info-submission_payout {
  @media (min-width: 992px) {
    > div {
      margin-bottom: 1rem !important;
    }
  }
}

.at-unit_info-form_field-item {
  display: flex;
  align-items: flex-start;
}

.at-form_field-icon_title {
  display: flex;
  grid-gap: 8px;
  align-items: center;

  .at-form-input__title {
    margin-bottom: 0;
  }
}

.at-unit_info-form_field {
  .at-icon_svg-listing-title,
  .at-icon_svg-listing-empty_title {
    width: 16px;
    min-width: 16px;
    height: 16px;

    fill: $primary-500;
  }
}
