@import "../variables";
.el-collapse-item__header,
.el-collapse-item__wrap {
  background-color: transparent !important;
}
.el-collapse-item {
  &.is-active {
    .el-collapse-item__arrow {
      transform: rotate(270deg) !important;
    }
  }
  .el-collapse-item__arrow {
    line-height: 48px;
    transform: rotate(90deg);
  }
  .el-collapse-item__header {
    font-size: 16px;
    font-weight: 400;

    &:hover,
    &:active,
    &:focus,
    &.focusing:focus:not(:hover) {
      color: $default-color;
    }
  }
  .el-collapse-item__content {
    box-shadow: inset 0 7px 10px -7px rgba(0, 0, 0, 0.14);
    border-top: 1px solid #e6ebf5;
  }
}

/* Collapse transition rewrite */
.collapse-transition {
  transition:
    0.25s height ease-in-out,
    0.25s padding-top ease-in-out,
    0.25s padding-bottom ease-in-out !important;
}
.horizontal-collapse-transition {
  transition:
    0.25s width ease-in-out,
    0.25s padding-left ease-in-out,
    0.25s padding-right ease-in-out !important;
}
