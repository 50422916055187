.at-day-range__dropdown-toggle {
  position: absolute;
  right: 0px;
  height: 100%;
  width: 60px;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  justify-content: space-between;
  background-color: #d1d5db;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-left: 1px solid #d1d5db;

  svg {
    width: 18px;
    height: 18px;
  }
}

.at-day-range__suggested-btn {
  font-size: 14px;
  color: #3b82f6;
}

.at-party__dropbear--modal-root {
  top: 45px !important;
  border: 1px #e1e1e1 solid;
  box-shadow:
    0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 2px 1px 0px rgba(16, 24, 40, 0.06),
    0px 1px 1px 0px rgba(0, 0, 0, 0.08) !important;

  .at-day-range__input-cont {
    min-width: 400px;
    padding: 10px 10px 0px 10px;
    @media screen and (max-width: 576px) {
      min-width: 300px;
    }
  }

  .btn-new {
    font-size: 14px;
    height: 33px;
    min-height: 33px;
  }

  .at-form-input {
    margin-bottom: 0px;
  }

  .at-form-input__input {
    height: 25px;
  }

  .at-select__selected-item {
    height: 33px !important;
  }

  .at-select__selected-item-select {
    padding: 0px;
  }
}
