@import "src/stylesheets/atlas/prerequisite";

.at-namecard-order-number {
  display: inline-block;
  padding: 4px 10px;
  color: rgb(255, 255, 255);
  border-radius: 12px;
  background-color: rgb(135, 203, 22);
  margin-top: 10px;
  text-align: center;
  font-size: 0.8em;
}
.at-namecard-expansion-panel {
  margin-top: 0px !important;
  margin-bottom: 20px !important;

  .at-namecard-panel-summary {
    background: $tangerine;
    min-height: 48px !important;
  }
  .at-namecard-panel-summary-content {
    margin: 0px !important;

    .at-form-input__title {
      font-size: 16px;
      color: $white;
    }
  }
  .at-namecard-panel-expand {
    display: block;
    padding: 24px 24px 24px;

    .outline-box {
      outline: 1px solid #c2c2c2;
      padding: 15px;
      margin-left: 0;
      margin-right: 0;
    }
  }
  .at-namecard-panel-details {
    display: grid;
    padding: 0;

    .outline-box {
      outline: 1px solid #c2c2c2;
      padding: 15px;
      margin-left: 0;
      margin-right: 0;
    }

    .at-namecard-personal-details {
      padding: 10px;
      display: grid;
      overflow-wrap: anywhere;
    }
  }
  .at-namecard-panel-summary {
    background: #fafafa;
    min-height: 48px !important;
  }
  .at-namecard-panel-summary-content {
    margin: 0px !important;

    .at-form-input__title {
      font-size: 16px;
      color: #2c3a3a;
    }
  }
  .panel-disabled {
    cursor: auto !important;
  }
}

.btn-clear {
  text-align: left;
  @include max-screen(600px) {
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
}

.profile-card {
  padding: 12px;
  width: 100%;
  border: 1px lightgray solid;

  @include min-screen(600px) {
    max-width: 550px;
  }
  @include max-screen(992px) {
    max-width: 400px;
    text-align: center;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 7px;
    font-size: 14px;
  }
}

.at-namecard-order-img__block {
  display: flex;
  justify-content: flex-start;

  & img {
    width: 100px;
    object-fit: contain;
    border: 3px solid #dbe2e6;
    border-radius: 15px;
    margin: auto;
    background-position: top;
  }
}

.at-namecard-personal__info {
  margin-top: 20px;
  text-align: center;

  & p {
    font-size: 13px;
  }
}

.at-namecard__order-item {
  padding-right: 0;
}

@media screen and (max-width: 576px) {
  .at-namecard__order-item {
    order: 2;
  }
  .at-namecard__agent-info {
    order: 1;
  }
}
