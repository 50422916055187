@import "../../../atlas/prerequisite";

.at-global-network__item {
  display: flex;
  flex-direction: column;
}

.at-global-network__img {
  background-color: $grey-1;
  height: 135px;
  width: 200px;
  margin-bottom: 20px;
}
