@import "../../atlas/prerequisite";

.at-image-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9fafb;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.2s ease;
  overflow: hidden;

  &:hover {
    border: 2px solid #3b82f6;
  }
}
