@import "src/assets/stylesheets/new_atlas/index.scss";

.at-form__label {
  display: flex;
  align-items: center;

  svg {
    width: 32px;
    height: 32px;
    color: #f0631c;
    fill: #f0631c;
    margin-right: 10px;
    margin: auto;
    border-radius: 50%;
    background-color: #ffedd5;
    padding: 8px;
    margin-right: 1rem;
  }

  .at-form__label-title {
    font-size: 18px;
    color: #111827;
    font-weight: 600;
  }

  .at-form__label-descrption {
    font-size: 14px;
    color: #374151;
  }
}

.at-form__content {
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.at-form__content-responsive {
  @media (max-width: 992px) {
    padding: 1rem;
    background: #ffffff;
    border: 1px solid #d1d5db;
    border-radius: 8px;
    margin-bottom: 1rem;
  }
}

.at-form_fields_cont {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
}

$numbers_of_gaps: (
    gap_num: 11,
    col_num: 12,
  ),
  (
    gap_num: 5,
    col_num: 6,
  ),
  (
    gap_num: 3,
    col_num: 4,
  ),
  (
    gap_num: 2,
    col_num: 3,
  ),
  (
    gap_num: 1,
    col_num: 2,
  ),
  (
    gap_num: 1,
    col_num: 2,
  ),
  (
    gap_num: 1,
    col_num: 2,
  ),
  (
    gap_num: 1,
    col_num: 2,
  ),
  (
    gap_num: 1,
    col_num: 2,
  ),
  (
    gap_num: 1,
    col_num: 2,
  ),
  (
    gap_num: 1,
    col_num: 2,
  );

// $keys: map-keys($gap_num);

@for $i from 1 through 11 {
  $gap_num: nth($numbers_of_gaps, $i);
  $gapNum: map-get($gap_num, "gap_num");
  $colNum: map-get($gap_num, "col_num");

  .at-form_field-col-#{ $i } {
    max-width: calc(((100% / 12 * $i) - ($gapNum * 16px / $colNum)));
    flex: 1 1 calc(((100% / 12 * $i) - ($gapNum * 16px / $colNum)));
  }
  @media (max-width: 767px) {
    .at-form_field-col-#{ $i } {
      max-width: 100%;
      flex: 1 1 100%;
    }
  }
}

.at-form_field-col-12 {
  max-width: 100%;
  flex: 1 1 100%;
}

// only for form column layout with regular division
// .at-form_field-reg_col-3 {
//   max-width: calc((100% / 12 * 3 ) - (3 * 16px / 4));
//   flex: 1 1 calc((100% / 12 * 3 ) - (3 * 16px / 4));
// }
// .at-form_field-reg_col-4 {
//   max-width: calc((100% / 12 * 4 ) - (2 * 16px / 3));
//   flex: 1 1 calc((100% / 12 * 4 ) - (2 * 16px / 3));
// }

// .at-form_field-reg_col-3,
// .at-form_field-reg_col-4 {
//   @media (max-width: 991px) {
//     max-width: calc((100% / 2) - 8px);
//     flex: 1 1 calc((100% / 2) - 8px);
//   }
//   @media (max-width: 767px) {
//     max-width: 100%;
//     flex: 1 1 100%;
//   }
// }

.at-form_fields_cont {
  .at-form-input.mb-3:not(:last-of-type),
  [class*="at-form_field-"]:not(:last-of-type) .at-input_footer {
    margin-bottom: 0 !important;
  }
}

.at-form_field-list-horizontal {
  display: grid;
  flex-wrap: wrap;
  padding: 1rem;
  grid-gap: 1rem;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));

  > p {
    padding-bottom: 12px;
    border-bottom: 1px solid $gray-300;
    font-size: 14px;
  }
}
.at-form_field-item_list {
  padding-bottom: 12px;
  border-bottom: 1px solid $gray-300;
  > p {
    font-size: 14px;
  }
}

.at-floating_panel {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  grid-gap: 0.5rem;
  width: 100vw;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.08),
    0px 2px 1px rgba(16, 24, 40, 0.06),
    0px 1px 3px rgba(16, 24, 40, 0.1);

  .btn-new {
    p {
      font-size: 14px;
    }
  }

  @media (max-width: 767px) {
    padding: 15px;
  }
  @media (min-width: 992px) {
    &.menu-expand {
      left: 260px;
    }
  }
}
