$gaps: ("0": 0rem, "1": 0.5rem, "2": 1rem, "3": 1.5rem, "4": 2rem, "10": 10px, "20": 20px, "30": 30px);

.rotate-left {
  transform: rotate(90deg);
}
.rotate-right {
  transform: rotate(-90deg);
}
.flex-1 {
  flex: 1;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.mb-100 {
  margin-bottom: 100px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-20 {
  margin-right: 20px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-50 {
  margin-top: 50px;
}
.ml-5 {
  margin-left: 5px;
}

.p-10 {
  padding: 10px;
}
.p-t-15 {
  padding-top: 15px;
}
.p-b-10 {
  padding-bottom: 10px;
}

.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}

.g-2 {
  gap: 8px;
}
.g-3 {
  gap: 12px;
}
.gy-3 {
  row-gap: 12px;
}

.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.h-40 {
  height: 40px;
}
.d-none {
  display: none !important;
}
.p-rel {
  position: relative;
}
.circle {
  border-radius: 50%;
}
.opacity-50 {
  opacity: 0.5 !important;
}

.overflow-visible {
  overflow: visible !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-align-center {
  display: flex;
  align-items: center;
}
.flex-justify-center {
  display: flex;
  justify-content: center;
}
.inline-flex-align-center {
  display: inline-flex;
  align-items: center;
}

@each $name, $value in $color-map {
  .color-#{$name} {
    color: $value !important;
  }
  .bg-#{$name} {
    background-color: $value !important;
  }
  .icon-#{$name} {
    fill: $value !important;
  }
  .border-#{$name} {
    border-color: $value !important;
  }
}

@each $font-weight in $font-weight-map {
  .fw-#{$font-weight} {
    font-weight: $font-weight !important;
  }
}

@each $name, $value in $border-radius-map {
  .bd-rd-#{$name} {
    border-radius: $value !important;
  }
}

@each $name, $value in $icon-size-map {
  .icon-size-#{$name} {
    width: $value !important;
    height: $value !important;
  }
}

@each $name, $value in $font-size-map {
  .fs-#{$name} {
    font-size: $value !important;
  }
}

@for $shadow-val from 0 to 5 {
  .box-shadow-#{$shadow-val} {
    @include box-shadow($shadow-val);
  }
}

.icon-circle {
  width: 35px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $tangerine;
  border-radius: 50%;

  > svg {
    fill: $atlas-white;
    width: 22px;
    height: 22px;
  }
}
.icon-circle--secondary {
  background-color: $lochmara;
}
.icon-circle--info {
  background-color: $picton-blue;
}
.icon-circle--big {
  width: 55px;
  height: 55px;

  > svg {
    width: 30px;
    height: 30px;
  }
}

.text-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  @include min-screen(600px) {
    flex-direction: row;
    margin-bottom: 5px;
  }

  > strong {
    font-size: 18px;
    display: inline-block;
    text-transform: capitalize;
    @include min-screen(600px) {
      font-size: 16px;
    }
    min-width: 150px;
  }

  > span {
    flex: 1;
    min-height: 20px;
    display: inline-block;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
.bullet-list {
  list-style-type: disc;
}

@each $index, $gap in $gaps {
  $i: $index;
  .grid_gap-#{ $i } {
    grid-gap: $gap;
  }
  .grid_gap-x-#{ $i } {
    grid-gap: $gap 0;
  }
  .grid_gap-y-#{ $i } {
    grid-gap: 0 $gap;
  }
}
