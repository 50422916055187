@import "../../atlas/prerequisite";
@import "../styles.scss";

.at-textarea {
  position: relative;
}

textarea {
  display: block;
  width: 100%;
  border: 0px;
  padding: 10px;
  border: 1px solid $grey-1;
  transition:
    border 0.2s ease,
    box-shadow 0.2s ease;
  box-shadow: 0px 0px 0px 2px transparent;
  border-radius: 2px;
  resize: none;
  margin-bottom: 30px;

  &:focus {
    &::placeholder {
      color: #6B728080;
    }
  }

  &::placeholder {
    color: #6B7280;
    transition: color 0.3s ease;
  }

  .at-select--error & {
    border: 1px solid $valencia;
    box-shadow: 0px 0px 0px 2px $valencia;
  }
}
