.at-due-diligence__description-link {
  display: grid;
  font-size: 10px;
  font-weight: 500;
  color: grey;
  margin-right: 12px;

  svg {
    fill: white;
    width: 25px;
    height: 25px;
    margin: auto;
  }
}

.at-apt_letter-closing_form-cont,
.at-authorz_form-closing_form-cont {
  .at-radio-set {
    .btn-radio.btn-radio-outline,
    .btn-radio.btn-radio-selected {
      justify-content: flex-start;
    }
  }
}

div.subsales-letter-input_wrapper {
  display: inline-block !important;
}