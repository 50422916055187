.at-meeting-room__image {
  display: flex;
  align-items: center;
  color: #1f2937;
  gap: 12px;

  img {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    object-fit: cover;
  }

  @media screen and (max-width: 768px) {
    max-width: 80%;

    img {
      position: absolute;
      top: 0px;
      right: 0px;
    }
  }
}

.at-meeting-room__table-title {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;

  h4 {
    color: #111827;
    font-size: 18px;
    font-weight: 600;
    line-height: 130%;
  }

  svg {
    width: 20px;
    height: 20px;
    fill: white;
  }

  @media screen and (max-width: 989px) {
    padding: 0px;
    padding-bottom: 12px;
  }
}

.at-meeting-room__calendar {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;

  label {
    width: 100%;
    color: white;
    font-size: 14px;
    font-weight: 500;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 12px;
    color: white;
    font-size: 12px;

    svg {
      width: 14px;
      height: 14px;
      fill: white;
    }
  }
}

.at-public-holiday__cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  .at-public-holiday__delete-btn .hide {
    visibility: hidden;
  }

  .btn-new span {
    display: none;
    color: #f04438;
  }

  @media screen and (max-width: 599px) {
    flex-direction: column;
    align-items: flex-start;

    .at-public-holiday__delete-btn {
      width: 100%;

      .hide {
        display: none;
      }
    }

    .btn-new span {
      display: flex;
    }
  }
}

.at-meeting-room__business-lounge {
  h2 {
    color: #111827;
    font-size: 18px;
    font-weight: 600;
    line-height: 150%;
  }

  p {
    color: #1F2937;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0px;
  }

  svg {
    width: 16px;
    height: 16px;
    fill: #F0631D;
  }
}

.at-meeting-room__item {
  display: flex;
  flex-direction: row;
  gap: 8px;

  .at-meeting-room__item-delete {
    margin-top: 24px;
  
    button {
      width: 40px;
      height: 40px;
    }
  
    svg {
      width: 20px;
      height: 20px;
    }
  
    p {
      display: none;
    }
    
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 16px;

    .row {
      margin: 0px;
      padding: 0px;

      section[class^="col"] {
        padding: 0px !important;
      }
    }

    .at-meeting-room__item-delete {
      margin-top: 0px;
      width: 100%;
  
      button {
        width: 100%;
      }
  
      p {
        color: #F04438;
        display: flex;
        font-weight: 500;
        margin-left: 4px;
      }
    }
  }
}
