.at-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition:
    color 0.12s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.4);

  &:disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
}

.at-btn--lg {
  font-size: 16px;
  border-radius: 6px;
  padding: 14px 30px;
  font-weight: 400;
}

.at-btn--icon {
  padding: 0.375rem 0.375rem;
}

.at-btn-group--primary {
  .at-btn--primary {
    border: 1px solid #9c5b00;
  }
}

.at-btn--primary {
  background-color: $tangerine;
  border: 0;

  &:focus {
    background-color: $tangerine;
  }
  &:hover {
    background-color: darken($tangerine, 5%);
  }
  &:active,
  &.active {
    background-color: darken($tangerine, 12%);
  }
  &:disabled {
    background-color: rgba(241, 142, 5, 0.65);
    cursor: not-allowed;
  }
}

.at-btn--secondary {
  background-color: $lochmara;
  border: 0;

  &:focus {
    background-color: $lochmara;
  }
  &:hover {
    background-color: darken($lochmara, 5%);
  }
  &:active,
  &.active {
    background-color: darken($lochmara, 12%);
  }
  &:disabled {
    background-color: rgba(0, 117, 188, 0.65);
    cursor: not-allowed;
  }
}

.at-btn--danger {
  background-color: $valencia;
  border: 0;

  &:focus {
    background-color: $valencia;
  }
  &:hover {
    background-color: darken($valencia, 5%);
  }
  &:active,
  &.active {
    background-color: darken($valencia, 12%);
  }
  &:disabled {
    background-color: rgba(217, 51, 53, 0.65);
    cursor: not-allowed;
  }
}

.at-btn--success {
  background-color: $shamrock;
  border: 0;

  &:focus {
    background-color: $shamrock;
  }
  &:hover {
    background-color: darken($shamrock, 5%);
  }
  &:active,
  &.active {
    background-color: darken($shamrock, 12%);
  }
  &:disabled {
    background-color: rgba(46, 204, 113, 0.65);
    cursor: not-allowed;
  }
}

.at-btn--purple {
  background-color: $east-bay;
  border: 0;

  &:focus {
    background-color: $east-bay;
  }
  &:hover {
    background-color: darken($east-bay, 5%);
  }
  &:active,
  &.active {
    background-color: darken($east-bay, 12%);
  }
  &:disabled {
    background-color: rgb(184, 184, 224);
    cursor: not-allowed;
  }
}

/* New UI Styling */
.btn-new,
.btn-radio,
.btn-float,
.btn-border,
.btn-float-purple,
.btn-new-sm,
.btn-new-lg {
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 8px 12px;
  grid-gap: 8px;
  border-radius: 8px !important;

  &:disabled {
    opacity: 0.8;
    cursor: no-drop;
  }
}

.btn-new {
  min-height: 40px;

  &:hover {
    background-color: darken(#ffffff, 5%);
  }
}

.btn-new-lg {
  min-height: 40px;
  font-size: 16px;
  padding: 10px 60px !important;

  @media screen and (max-width: 576px) {
    font-size: 15px;
    padding: 10px 30px !important;
  }
}

.btn-new-sm {
  padding: 12px 16px;
  font-size: 12px;
}

.btn-radio {
  text-align: left;
  background-color: #ffffff;
  padding: 10px 12px !important;

  &:disabled {
    color: #4B5563;
  }
  &:not(.btn-radio-selected):hover {
    border-color: #9ca3af;
  }
}

.btn-float {
  color: #1f2937;
  border-radius: 0px;
  background-color: #e5e7eb;
  padding: 10px 14px;
  grid-gap: 6px;
  line-height: 150%;
}

.btn-float-purple {
  color: white;
  background: #8b56e1;
}

.btn-float-selected {
  background: #ffffff;
  border: 1px solid #D1D5DB;
  border-radius: 8px;
}

.btn-float-purple-selected {
  color: #5818c6;
  background: #f4eeff;
}

.btn-radio-outline {
  border: 2px solid #d1d5db;
}

.btn-radio-selected {
  border: 2px solid #3b82f6;
}

.btn-new--success {
  color: white;
  background-color: #12b76a;
  transition: all 0.2s ease-out;

  &:hover {
    background-color: darken(#12b76a, 5%);
  }

  &:disabled {
    background-color: #89c8aa;
  }
}

.btn-new--primary {
  color: white !important;
  background-color: #f0631d !important;
  transition: all 0.2s ease-out !important;

  &:hover {
    background-color: darken(#f0631d, 5%) !important;
    color: #fff;
  }

  &:disabled {
    background-color: #ee9d75 !important;
  }
}

.btn-new--secondary {
  color: white;
  background-color: #3b82f6 !important;
  transition: all 0.2s ease-out;

  &:hover {
    background-color: darken(#3b82f6, 5%) !important;
  }

  &:disabled {
    background-color: #93c5fd !important;
  }
}

.btn-new--danger {
  color: white;
  background-color: #f04438;
  transition: all 0.2s ease-out;

  &:hover {
    background-color: darken(#f04438, 5%);
  }

  &:disabled {
    background-color: #fda29b;
  }
}

.btn-new--outline-secondary {
  color: #374151;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  padding: 8px 12px 8px 12px;
  border: 1px solid #d1d5db;
  transition: all 0.2s ease-out;

  &:hover {
    background-color: darken(#ffffff, 5%);
  }
}

.btn-new--outline-primary {
  color: #3b82f6;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  padding: 8px 12px 8px 12px;
  border: 1px solid #60a5fa;
  transition: all 0.2s ease-out;

  &:hover {
    background-color: darken(#eff6ff, 1%);
  }
}

.btn-new--outline-danger {
  color: #d92d20;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  padding: 8px 12px 8px 12px;
  border: 1px solid #d1d5db;
  transition: all 0.2s ease-out;

  &:hover {
    color: darken(#d92d20, 5%);
    background-color: darken(#ffffff, 5%);
  }
}

.btn-new--outline-grey {
  color: #6c717f;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  padding: 8px 12px 8px 12px;
  border: 1px solid #d1d5db;
  transition: all 0.2s ease-out;

  &:hover {
    color: darken(#6c717f, 5%);
    background-color: darken(#ffffff, 5%);
  }
}

.btn-new--social-media {
  width: 24px;
  height: 24px;
  min-width: 24px;
  padding: 4px;
  background: #ffffff;
  margin-left: 5px;
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }
}

.bottom-sticky__action-btn-cont {
  display: flex;
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 70px;
  padding: 16px 40px;
  margin-left: -35px;
  background-color: white;
  border-top: 1px solid #d1d5db;
}
