.at-tier {
  position: relative;
  overflow: hidden;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  label {
    color: #6b2007;
    font-weight: 500;
    line-height: 150%;
  }

  p {
    color: #530c02;
    font-size: 18px;
    font-weight: 500;
    line-height: 130%;
  }
}

.at-tier-current-badge {
  display: flex;
  padding: 4px 8px;
  align-items: flex-start;
  border-radius: 8px 0px;
  position: absolute;
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
  color: white;
  background: linear-gradient(165deg, #f0631d 38.14%, #ffb27b 100%);
}

.at-tier-current-rank {
  display: flex;
  align-items: center;
  padding: 24px 16px 0px;
  position: relative;
  z-index: 1;

  label {
    font-size: 16px;
    text-transform: uppercase;
  }

  p {
    font-size: 18px;
    font-weight: 600;
  }

  img {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    padding: 5px;
    background: linear-gradient(180deg, #f0631d 0%, #ffdfb1 100%);
  }
}

.at-tier-rank__badge {
  display: flex;
  padding: 2px 6px 2px 8px;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid #f0631d;
  background: #ea580c;
  color: white;
  font-size: 12px;
  font-weight: 500;
  width: fit-content;
  margin-left: 5px;
}

.at-tier-footer {
  padding: 16px;
  margin-top: auto;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: linear-gradient(90deg, #fab48c 0%, #ffddcf 100%);

  label {
    color: #6b2007;
    font-weight: 500;
    line-height: 150%;
    font-size: 12px;
  }

  p {
    color: #530c02;
    font-size: 18px;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
  }
}

.at-tier__progress-main {
  display: flex;
  align-items: center;
  padding: 16px;
  z-index: 1;
  position: relative;
}

.at-tier__progress-cont {
  width: 100%;
  height: 6px;
  margin-right: 20px;
  border-radius: 8px;
  margin-right: -12px;
  display: flex;
  align-items: center;
  background-color: #fff7ed;
  color: #fff7ed;
}

.at-tier__progress-bar {
  height: inherit;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: #f0631d;
  width: 100px;
  content: "";
}

.at-tier__progress-indicator {
  width: fit-content;
  position: relative;

  > div {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: white;
    filter: drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.24))
      drop-shadow(0px 0px 1px rgba(16, 24, 40, 0.06))
      drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.08));
    content: "";
  }

  p {
    padding: 2px 8px;
    width: 180px;
    font-size: 12px;
    border-radius: 4px;
    position: absolute;
    top: -30px;
    text-align: center;
    background-color: #ffffff;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);

    @media (max-width: 1000px) {
      font-size: 11px;
      width: 150px;
    }

    @media (max-width: 768px) {
      font-size: 10px;
      width: 120px;
    }
  }
}

.at-tier__all-levels {
  .at-tier__all-levels__item,
  .at-tier__all-levels__item--highlight {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    border-bottom: 1px solid #d1d5db;

    label {
      display: grid;
      font-size: 14px;
      font-weight: 500;
      color: #374151;
      line-height: 150%;
    }

    p {
      font-size: 20px;
      font-weight: 600;
      line-height: 120%;
      color: #111827;
    }
  }

  .at-tier__all-levels__item__badge {
    width: 60px;
    height: 60px;
    padding: 4px;
    border-radius: 50%;
    margin-right: 16px;
  }

  .at-tier__all-levels__item__percentage {
    border-radius: 25px;
    border: 1px solid #fdba74;
    margin-left: auto;
    color: #c2410c;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    padding: 2px 8px;
    background-color: #ffedd5;
  }

  .at-tier__all-levels__item--highlight {
    background-color: #fff7ed;
    .at-tier__all-levels__item__percentage {
      color: white;
      border-color: #ea580c;
      background-color: #ea580c;
    }

    .at-tier__all-levels__item__badge {
      padding: 2px;
      background: linear-gradient(180deg, #f0631d 0%, #ffdfb1 100%);
    }

    label:before {
      content: "Current Progress";
      text-align: center;
      font-size: 13px;
      font-weight: 500;
      padding: 2px 8px;
      color: white;
      border-radius: 4px;
      background-color: #ea580c;
    }
  }
}

.at-tier__background {
  position: absolute;
  bottom: -70px;
  right: 0px;
  z-index: 0;
}
