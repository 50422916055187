@import "src/assets/stylesheets/new_atlas/index.scss";

.party-card-title_label {
  text-align: left;
  background: #0275bc;
  padding: 5px 10px;
  color: #ffff;
  border-radius: 0.25rem 0.25rem 0px 0px;
  width: 265px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 410px) {
    width: 100% !important;
  }
}

.party-card-body {
  border: 1px solid $gray-300;
  box-shadow: none !important;
  height: calc(100% - 45px);
  border-radius: 0 8px 8px 8px !important;

  .party-card-body_content-container {
    display: grid;
    padding: 1rem;
    grid-gap: 1rem;
    width: calc(100% - 90px);
    grid-template-columns: repeat(auto-fill, minmax(40rem, 1fr));

    @media (min-width: 1300px) {
      grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    }
    @media (max-width: 1200px) {
      grid-template-columns: repeat(auto-fill, minmax(11rem, 1fr));
    }
    @media (max-width: 991px) {
      grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
    }
    @media (max-width: 575px) {
      grid-template-columns: 100%;
    }

    // grid-template-areas: 'partyName partyEmail partyIdentity partyContact';
    // grid-template-columns: repeat(4, 1fr);

    @media (max-width: 1200px) {
      // grid-template-areas:
      //   "partyName partyEmail"
      //   "partyIdentity partyContact";
      // grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 772px) {
      // grid-template-areas:
      //   "partyName"
      //   "partyEmail"
      //   "partyIdentity"
      //   "partyContact";
      // grid-template-columns: 1fr;
    }
    .party-card-body-name {
      grid-area: "partyName";
    }
    .party-card-body-email {
      grid-area: "partyEmail";
    }
    .party-card-body-identity {
      grid-area: "partyIdentity";
    }
    .party-card-body-contact {
      grid-area: "partyContact";
    }
  }

  label {
    width: 100%;
    color: #374151;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
  }

  p {
    font-size: 14px;
    word-break: break-word;
    line-height: 21px;
    // margin-bottom: 10px;
  }

  svg {
    width: 20px;
    height: 20px;
    fill: #9ca3af;
    margin-right: 10px;
  }
}

.party-card-index_label {
  padding: 3px 7px;
  font-size: 12px;
  border-radius: 50%;
  margin-right: 10px;
  color: white;
  width: 22px;
  height: 22px;
  background-color: #f18e06;
}

.party-card-status_label {
  text-align: center;
  margin-left: auto;
  writing-mode: vertical-lr;
  color: #ffff;
  padding: 7px;
}

.party-card__badge-title-icon {
  :is(svg, img) {
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    margin-right: 10px;
  }
  > svg {
    color: #f18e06;
    fill: #f18e06;
  }

  @media screen and (max-width: 480px) {
    > svg {
      width: 18px;
      height: 18px;
    }
  }
}

.at-subsales-contact_form_type-title-icon {
  > svg { 
    color: #f0631d;
    fill: #f0631d;
  }
}

.party-card_cont {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(35rem, 1fr));

  @media (max-width: 757px) {
    grid-template-columns: 100%;
  }
}

.party-card-new {
  .party-card-title_label {
    padding: 12px 16px;
    background: #1d4ed8;
    font-weight: 600;
    font-size: 16px;
    width: 300px;
    max-height: 45px;
  }

  .party-card-index_label {
    background-color: #f0631d;
  }

  .party-card-status_label {
    writing-mode: inherit;
    margin-left: auto;
    color: #ffff;
    min-height: 40px;
    display: flex;
    padding: 8px 16px;
    min-width: 90px;
    min-height: 40px;
    height: fit-content;
    justify-content: center;
    align-items: center;
    border-radius: 0px 2px 0px 16px;

    & p {
      font-weight: 500;
      font-size: 14px;
    }
  }
}

.party-card-action-button {
  background-color: white;
  margin-left: 10px;
  margin-bottom: 2px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #d1d5db;
  padding: 5px !important;

  svg {
    width: 20px;
    height: 20px;
    fill: #3b82f6;
    vertical-align: sub;
  }
}

.party-card-action-button-mobile {
  background-color: white;
  width: 100%;
  display: flex;
  padding: 8px;
  color: #2563eb;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  border-right: 1px solid #d1d5db;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    fill: #3b82f6;
    margin: auto 5px;
  }

  &:nth-last-child(1) {
    border-right: none;
  }
}

.party-card__due-diligence-btn {
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding: 5px 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  border: 1px solid #f19719;

  svg {
    margin-right: 10px;
    width: 14px;
    height: 14px;
    color: #f19719;
  }
}

.party-card__due-diligence-btn:hover {
  background: #f19719;
  color: white;

  svg {
    color: white;
  }
}
