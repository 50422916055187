.at-report__group-sales-option-container {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 650px) {
  .at-report__group-sales-option-container {
    display: block;
  }
  .at-report__group-sale-visual-btn {
    margin-top: 5px;
  }
}

.at-report__group-sale-visual-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  min-width: 35px;
  min-height: 35px;
  border-radius: 5px;
  border: 2px solid #f0631d;
}

.at-report__group-sale-branch-select {
  width: 200px;
  margin-bottom: 20px;
  margin-right: 48px;
}

.at-report__subsales-options {
  display: flex;
  align-items: center;
  width: calc(100% + 32px);
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: -16px;
  padding: 0px 16px;
  border-top: 1px solid #d1d5db;
  border-bottom: 1px solid #d1d5db;

  .at-report__subsales-selected {
    color: #f0631d;
    border-bottom: 2px solid #f0631d;
  }

  button {
    font-size: 14px;
    font-weight: 500;
    color: #374151;
    padding: 14px 28px;
  }

  @media screen and (max-width: 992px) {
    overflow: hidden;
    background: #ffffff;
    border: 1px solid #d1d5db;
    padding: 0px;
    width: 100%;
    margin: 16px auto;
    border-radius: 8px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);

    > div {
      width: 100%;
    }

    button {
      width: 100%;
      padding: 14px;
    }
  }
}
