@import "src/stylesheets/atlas/prerequisite";

$checkboxFilter: (
  ("events", #2563eb),
  ("trainings", #7c3aed),
  ("paid-trainings", #d61f69),
  ("team_birthday", #0d9488),
  ("paid-events", #ea580c)
);

.at-company__affairs-card {
  box-shadow: none;
  border-radius: 8px;

  @media (max-width: 575px) {
    margin: 0px -0.5rem !important;
  }
}

.at-company__affairs-content {
  background-color: #fff;
  border-top-right-radius: 7px;
  min-height: auto;
  @media (max-width: 767px) {
    border-top-left-radius: 7px;
    padding: 0.5rem;
  }
}

.at-company__affairs-header_tab {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  background: #fff;
  padding: 20px;
  box-shadow:
    0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 2px 1px 0px rgba(16, 24, 40, 0.06),
    0px 1px 1px 0px rgba(0, 0, 0, 0.08);

  @media screen and (max-width: 575px) {
    padding: 16px;
  }

  .at-company__affairs-form_control {
    @each $class, $color in $checkboxFilter {
      .at-company__affairs-checkbox-#{$class} {
        .at-checkbox__input:checked ~ .at-checkbox__span-box {
          border: 2px solid $color !important;
          background-color: $color !important;
        }
        &:hover .at-checkbox__input:not([checked]) .at-checkbox__tick {
          fill: rgba($color, 0.2) !important;
        }
        &:active .at-checkbox__span-box {
          border: 1px solid $color !important;
        }

        @media (max-width: 420px) {
          padding: 0 0.7rem;
        }
      }
    }

    @media screen and (max-width: 575px) {
      label {
        padding-left: 0px !important;
        margin-left: 0px;
      }
    }
  }

  .at-company__affairs-filter {
    p {
      font-size: 14px;
      font-weight: 500;
      padding-left: 5px;
      margin-bottom: 5px;

      @media screen and (max-width: 575px) {
        padding-left: 0px;
        margin-top: 5px;
      }
    }

    div {
      max-height: 40px;
      padding: 0px 0px 0px 3px;
      font-size: 14px;
    }

    > div {
      max-height: 50px;
      min-width: 200px;

      @media (max-width: 575px) {
        margin-left: 0px;
      }
    }

    @media (max-width: 767px) {
      width: 100%;

      div {
        width: 100%;
        min-width: 180px;
        padding: 0;
      }
    }
  }

  .at-company__affairs-tab {
    display: flex;
    flex-wrap: wrap;
    margin-left: 5px;
    grid-gap: 0.3rem 1rem;
  }
}

.at-cal {
  display: grid;
  margin: 0px;
  position: relative;
  user-select: none;
  border-radius: 8px;

  @include min-screen($calendar-bp) {
    margin: 0;
  }

  &.at-calendar--list {
    margin: 0;
    border: 0px;
  }
}

.at-calendar__month {
  @include at-font(600);
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  margin-bottom: 10px;
  gap: 16px;

  span {
    font-size: 20px;
    color: #111827;
    @include at-font(600);
  }

  @media (max-width: 575px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    span {
      font-size: 16px;
    }
  }
  @media (min-width: 1600px) {
    span {
      font-size: 1.5rem;
    }
  }
}

.at-calendar__month-filter {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 12px;

  @include max-screen(575px) {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    .at-form-input {
      width: 100%;
    }
  }
}

.at-calendar__month--previous,
.at-calendar__month--next {
  display: flex;

  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    fill: #374151;
    cursor: pointer;
  }
}

.at-calendar__month--previous {
  .svg-icon-chevron {
    transform: scale(0.6) rotate(90deg);
  }
}
.at-calendar__month--next {
  .svg-icon-chevron {
    transform: scale(0.6) rotate(-90deg);
  }
}

.at-calendar__day-header {
  @include at-font(500);
  display: grid;
  grid-template-columns: repeat(7, 1fr);

  > div {
    border-left: 1px solid #d1d5db;
    border-bottom: 1px solid #d1d5db;

    &:nth-child(1) {
      border-left: 0px;
    }

    @media (max-width: 992px) {
      border-top: 1px solid #d1d5db;
    }
  }
}

.at-calendar__weekday {
  color: #111827;
  text-align: center;
  padding: 10px 0px;

  @media (max-width: 575px) {
    font-size: 14px;
  }
  @media (min-width: 1600px) {
    font-size: 1.5rem;
  }
  @media (min-width: 3000px) {
    font-size: 1.6rem;
  }
}

.at-calendar__day-set-container {
  display: flex;
  flex-wrap: wrap;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  p {
    color: #111827;
    font-size: clamp(1rem, 1.8vw, 18px);
    line-height: clamp(1rem, 1.8vw, 18px);
    font-weight: 600;
  }

  span {
    font-size: 14px;
    font-weight: 500;
  }
}

.at-calendar__day-set {
  display: grid;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;

  > div {
    border-left: 1px solid #d1d5db;
    border-bottom: 1px solid #d1d5db;

    &:nth-child(7n + 1) {
      border-left: 0px;
    }

    &:nth-last-child(-n + 7) {
      border-bottom: 0px;
    }
  }
}

.at-calendar__affair-cont {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  position: relative;
  grid-gap: clamp(0.1rem, 0.15vw, 0.2rem);
}

.at-calendar__cal-affair {
  .at-calendar__affair {
    width: 0;
    height: min-content;
    padding: 2px 4px 2px 10px;
    border-radius: 4px;
    margin-bottom: 4px;

    @include min-screen($calendar-bp) {
      display: block;
      width: 100%;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }

  .at-calendar__affair--responsive-load {
    display: none;
    cursor: pointer;
    margin-left: auto;
    transition: color ease-in-out 0.5s;
    > svg {
      width: 1.5rem;
      height: 1.5rem;
      fill: #f0631d;

      &:hover {
        color: darken(#f0631d, 50%);
      }
    }
    @media (max-width: 767px) {
      display: block;
    }
  }
}

.at-calendar__day-display-affair {
  max-height: none;

  .at-calendar__affair {
    width: 100%;
    min-height: 38px;
    padding: 2px 5px;
    border-radius: 4px;
    margin: 0px 0px 2px 0px;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.at-calendar__affair {
  display: inline-block;
  color: #111827;
  border-radius: 3px;
  cursor: pointer;
  font-size: 10px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all ease-in-out 0.1s;

  &.at-calendar__affair-event {
    background-color: #dbeafe;
    border-left: 4px solid #3b82f6;
    &:hover {
      background-color: darken(#dbeafe, 10%);
    }
  }

  &.at-calendar__affair-paid-event {
    background-color: #ffedd5;
    border-left: 4px solid #f0631d;
    &:hover {
      background-color: darken(#ffedd5, 10%);
    }
  }

  &.at-calendar__affair-training {
    background-color: #ede9fe;
    border-left: 4px solid #8b5cf6;
    &:hover {
      background-color: darken(#ede9fe, 10%);
    }
  }

  &.at-calendar__affair-paid-training {
    background-color: #fce8f3;
    border-left: 4px solid #e74694;
    &:hover {
      background-color: darken(#fce8f3, 10%);
    }
  }

  &.at-calendar__affair-birthday {
    background-color: #ccfbf1;
    border-left: 4px solid #14b8a6;
    &:hover {
      background-color: darken(#ccfbf1, 10%);
    }
  }
}

.at-calendar__affair--load-more {
  background-color: transparent;
  color: #1f2937;
  display: none;

  &:hover {
    background-color: #e5e5e5;
  }

  @include min-screen($calendar-bp) {
    display: block;
  }
}

.at-calendar__toggle {
  display: flex;
  align-items: center;
  background-color: #e5e7eb;
  border-radius: 8px;
  width: fit-content;
  height: fit-content;

  @include max-screen($calendar-bp) {
    width: 100%;
    .btn-float {
      width: 50%;
    }
  }
}

.at-calendar__day {
  display: flex;
  align-items: flex-start;
  opacity: 1;
  flex-direction: column;
  margin: 0;
  height: clamp(7.7rem, 13.5vw, 19rem);
  overflow: hidden;
  padding: 5px;
  position: relative;
  transition: all linear 0.5s;

  @media (max-width: 767px) {
    height: clamp(3rem, 12.5vw, 19rem);
  }
  @media (min-width: 3000px) {
    height: clamp(20rem, 10vw, 25rem);
  }

  @include min-screen($calendar-bp) {
    padding: 10px;
  }

  .at-calendar__affair-day_text {
    padding: 0px;

    @media (min-width: 768px) {
      padding: 5px;
      margin-bottom: 0.5rem;
    }
  }

  //last-row
  &:nth-last-child(-n + 7) {
    .at-calendar__day-display {
      top: initial;
      bottom: 5px;
      left: 5px;
      right: initial;
    }
  }

  //Sunday
  &:nth-child(7n + 1) {
    .at-calendar__day-display {
      @media (max-width: 767px) {
        left: 10vw;
      }
    }
  }

  //Monday
  &:nth-child(7n + 2) {
    .at-calendar__day-display {
      @media (max-width: 767px) {
        left: -2vw;
      }
    }
  }

  //Tuesday
  &:nth-child(7n + 3) {
    .at-calendar__day-display {
      @media (max-width: 767px) {
        left: -18vw;
      }
    }
  }

  //Wednesday
  &:nth-last-child(7n - 3) {
    .at-calendar__day-display {
      @media (max-width: 767px) {
        left: -30vw;
      }
    }
  }

  //Thursday
  &:nth-child(7n + 5) {
    .at-calendar__day-display {
      right: 5px;
      left: auto;
      @media (max-width: 767px) {
        right: -18vw;
        left: auto;
      }
    }
  }

  //Friday
  &:nth-child(7n - 1) {
    .at-calendar__day-display {
      right: 5px;
      left: auto;
      @media (max-width: 767px) {
        right: -4vw;
        left: auto;
      }
    }

    // Last row of Thu, Fri
    &:nth-last-child(-n + 7) {
      .at-calendar__day-display {
        top: initial;
        bottom: 5px;
        right: 5px;
      }
    }
  }

  //Saturday
  &:nth-child(7n) {
    .at-calendar__day-display {
      right: 5px;
      left: auto;

      @media (max-width: 767px) {
        right: 10vw;
      }
    }

    &:nth-last-child(-n + 7) {
      .at-calendar__day-display {
        top: initial;
        bottom: 5px;
        right: 5px;
      }
    }
  }

  > span {
    color: #111827;
    font-weight: 500;

    @include min-screen($calendar-bp) {
      margin-right: 0px;
    }
  }
}

.at-calendar__day--detail {
  overflow: visible;
}

.at-calendar__day--today {
  .at-calendar__affair-day_text {
    color: #ffffff;
    width: 25px;
    height: 25px;
    min-width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #ea580c;

    @media screen and (max-width: 576px) {
      width: 15px;
      height: 15px;
      min-width: 15px;
    }
  }
}

.at-calendar__day--past {
  border: unset;

  > span {
    opacity: 0.2;
  }

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .at-calendar__affair-cont {
    opacity: 0.2;
  }
}

.at-calendar__day--not-this-month {
  display: block;

  > span {
    opacity: 0.2;
  }

  &:hover {
    background-color: rgba($limed-spruce, 0.2) !important;
  }
}

@keyframes button_entrance {
  from {
    opacity: 0;
    transform: translateY(-50%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.at-calendar__view-toggle {
  display: inline-flex;
  border-radius: 50%;
  height: 2.5rem;
  width: 3rem;
  min-width: 3rem;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
  z-index: 2;
  animation: button_entrance 0.3s ease 1s;
  animation-fill-mode: both;
  @include box-shadow(2);

  @include min-screen($calendar-bp) {
    width: 3rem;
    min-width: 3rem;
    height: 2.7rem;
  }

  > .svg-icon-calendar {
    width: 1.5rem;
    height: 1.8rem;

    @media (max-width: 767px) {
      width: 25px;
      height: 28px;
    }
  }

  &:focus {
    @include box-shadow(2);
  }
  > svg {
    fill: white;
  }
}

.at-calendar__day-display {
  background-color: white;
  position: absolute;
  color: #111827;
  padding: clamp(0.5rem, 1vw, 1rem);
  width: 300px;
  border-radius: 4px;
  top: 5px;
  left: 5px;
  right: initial;
  box-shadow:
    0 3px 1rem rgba(0, 0, 0, 0.2),
    0 3px 1rem rgba(0, 0, 0, 0.35);
  opacity: 1;
  z-index: 1;
  transition: opacity ease-in-out 0.3s;

  &.at-calendar__day-complete_list_hide {
    opacity: 0;
    z-index: -1;
  }
}

.at-calendar__ticket-qr__modal {
  overflow: auto !important;
  margin-top: 57px;
  padding: 0px !important;
  max-height: calc(100vh - 185px) !important;
}

.at-calendar__ticket-qr {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .at-calendar__ticket-qr__footer {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    margin-top: auto;
    padding: 16px;
    justify-content: center;

    .at-calendar__ticket-qr__footer-bg {
      position: absolute;
      top: 40%;
      width: 120vw;
      height: 60vw;
      z-index: 1;
      border-radius: 120%;
      background-color: #fff5dc;
    }

    img {
      width: 12rem;
      z-index: 2;
    }
  }

  canvas {
    margin: 25px;
  }

  p {
    color: #111827;
    font-size: 18px;
    font-weight: 600;
    line-height: 130%;
    margin: 16px 0px;
  }
}
