@import "../../../stylesheets/atlas/prerequisite";
.at-newsfeed-grid_cont {
  max-width: 800px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  justify-content: center;
  margin: 0px auto;

  @include max-screen(868px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.at-newsfeed-item_cont {
  .at-newsfeed-image {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 16 / 9;

    @include min-screen(868px) {
      &:not(:nth-of-type(1)) {
        width: 800px;
      }
    }
  }

  &:nth-of-type(1) {
    @include min-screen(868px) {
      grid-area: span 2 / span 2;
    }
    .at-newsfeed-image {
      @media screen and (min-width: 960px) {
        height: 450px;
        max-width: 800px;
      }
    }
  }
}

.at-newsfeed-item_card {
  display: flex;
  flex-direction: column;
  align-content: start;
  cursor: pointer;
  height: 100%;
  border-radius: 8px !important;
  border: 1px solid #d1d5db;
  box-shadow: none !important;
  > div {
    flex: 1 1 100%;

    &.at-newsfeed-item_details {
      background-color: #ffffff;
      color: white;
      max-width: 800px;
      padding: 12px;
      display: flex;
      border-top: 1px solid #d1d5db;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
.at-stat-container {
  .at-card__title_mobile {
    @include at-font(500);
    display: block;
    font-size: 20px;
    word-break: break-all;

    @include min-screen(600px) {
      font-size: 23px;
    }

    @include max-screen(600px) {
      padding-bottom: 20px;
    }
  }

  .at-card-mobile {
    @include max-screen(600px) {
      margin-top: 10px;
    }
  }
}

.at-card--stat__footer-mobile {
  @include max-screen(600px) {
    text-align: right;
  }
}

.at-stats__card-container {
  margin-bottom: 40px;

  @include min-screen(1460px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
.at-charts__card-container {
  margin-bottom: 40px;

  @include min-screen(1460px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.at-tasks__list {
  justify-content: space-between;
}

.at-tasks__list-title {
  margin-right: 20px;
}
.at-tasks__list-button {
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  margin: 0px 5px 0px 0px;
  border: 0;

  &:last-of-type {
    margin: 0;
  }
}

.at-tasks__list-buttons-cont {
  display: inline-flex;
}

.at-tasks__title {
  @include at-font(500);
  font-size: 23px;
  color: $atlas-white;
}

.at-card--stat-calendar {
  background-color: #dce6ff;
  .at-card--stat__icon {
    width: 25%;
  }
}
.at-card--stat-calendar-check {
  background-color: #dbffee;
  .at-card--stat__icon {
    width: 25%;
  }
}
.at-card--stat-conversion {
  background-color: #e1fcff;
  .at-card--stat__icon {
    width: 25%;
  }
}
.at-card--stat-conversion-amount {
  background-color: #d5c9e4;
  .at-card--stat__icon {
    width: 25%;
  }
}
.at-card--stat-cancelled {
  background-color: #feefdb;
  .at-card--stat__icon {
    width: 25%;
  }
}
.at-card--stat-cancelled-amount {
  background-color: #f2c1b8;
  .at-card--stat__icon {
    width: 25%;
  }
}
.at-card--stat-project-sales {
  background-color: #dce6ff;
  .at-card--stat__icon {
    width: 30%;
  }
}
.at-card--stat-subsales {
  background-color: #d5c9e4;
  .at-card--stat__icon {
    width: 35%;
  }
}
.at-card--stat-rental {
  background-color: #f2c1b8;
  .at-card--stat__icon {
    width: 30%;
  }
}
.at-card--stat-team-size {
  background-color: #dbffee;
  .at-card--stat__icon {
    transform: translate(24px, 24px);
  }
}

.at-card--stat-sold {
  background-color: #89abd959;
  .at-card--stat__icon {
    transform: translate(1px, 1px);
  }
}

.at-newsfeed-title {
  margin-bottom: 7px;
  color: #111827;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @include at-font(600);
  font-size: 18px;
  margin-right: 20px;
  vertical-align: middle;

  @include max-screen(450px) {
    font-size: 16px;
  }
}

.at-newsfeed-desc {
  margin-top: 7px;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.7em;

  @include min-screen(768px) {
    font-size: 1em;
  }
  @include max-screen(600px) {
    font-size: 0.75em;
  }
  @include max-screen(450px) {
    font-size: 0.65em;
  }
}

.statistic-tab__subsales {
  display: flex;
  text-align: left;
  margin-bottom: 30px;

  .nav-bar_button {
    margin-right: 10px;
    width: 150px;
  }
  .nav-bar_button.selected {
    background-color: #ffedd5 !important;
    color: #f0631d !important;
  }
}

.statistic-tabs {
  @include max-screen($sidebar-breakpoint) {
    margin-bottom: 16px;
  }
}

.at-statistic-card-mobile {
  padding: 12px;
  @include max-screen(420px) {
    margin: 0 -1rem !important;
    padding: 10px !important;
  }
}

.at-newsfeed-title_new_badge {
  padding: 2px 6px;
  width: fit-content;
  font-size: 14px;
  font-weight: 500;
  color: #c2410c;
  display: flex;
  align-items: center;
  background-color: #ffedd5;
  border: 1px solid #fdba74;
  border-radius: 25px;
}

.at-statistic__bar-container {
  border-radius: 25px;
  overflow: hidden;
  width: 100%;
  box-shadow: 3px 3px 5px #a7a1a1;
}

.at-statistic-project-container,
.at-statistic-subsales-container,
.at-statistic-general-container {
  display: grid;
  gap: 16px;
  margin-bottom: 16px;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: repeat(2, 1fr);

  h2 {
    font-size: 18px;
    font-weight: 600;
    color: #111827;
    margin-bottom: 16px;
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    color: #111827;
    margin-bottom: 10px;
  }

  > div {
    margin-bottom: 0px;
  }
}

.at-statistic-general-container {
  @include max-screen($sidebar-breakpoint) {
    display: block;

    > div {
      margin-bottom: 16px;
    }
  }
}

.at-statistic-subsales-container,
.at-statistic-project-container {
  @include max-screen(1200px) {
    display: block;

    > div {
      margin-bottom: 16px;
    }
  }
}

.at-statistic_leaderboard-title {
  width: 100%;
  text-align: center;
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 0px;
}

.at-statisic__leaderboard-bg {
  display: flex;
  justify-content: center;
  padding-bottom: 60px;
  background: linear-gradient(180deg, transparent 70%, white 70%);

  @include max-screen(600px) {
    background: linear-gradient(180deg, transparent 40%, white 40%);
  }
}

.at-statisic__leaderboard-cont {
  width: 100%;
  margin-top: 40px;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &:nth-child(1) > div {
    transform: translateY(-10px);
  }

  &:nth-child(2) > div {
    transform: translateY(-40px);
  }

  label {
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    color: #374151;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    font-size: 13px;
    font-weight: 600;
    line-height: 150%;
    color: #111827;
  }

  span {
    font-size: 12px;
    line-height: 150%;
    color: #f0631d;
  }

  .at-statistic_leaderboard-avatar {
    width: 60px;
    height: 60px;
    min-width: 60px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0px 0px 0px 4px #3b82f666;
  }

  @include min-screen(1700px) {
    &:nth-child(1) > div {
      transform: translateY(5px);
    }

    &:nth-child(3) > div {
      transform: translateY(20px);
    }
  }

  @include max-screen(1400px) {
    &:nth-child(1) > div {
      transform: translateY(25px);
    }

    &:nth-child(2) > div {
      transform: translateY(-30px);
    }

    &:nth-child(3) > div {
      transform: translateY(40px);
    }
  }

  @include max-screen(990px) {
    label {
      font-size: 12px;
    }

    p {
      font-size: 11px;
    }

    &:nth-child(1) > div {
      transform: translateY(-1vw);
    }

    &:nth-child(2) > div {
      transform: translateY(-5vw);
    }

    &:nth-child(3) > div {
      transform: translateY(2vw);
    }
  }

  @include max-screen(530px) {
    background-size: contain;
  }
}

.at-statistic_leaderboard-agent {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-top: 1px solid #d1d5db;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: white;
  }

  label {
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    color: #374151;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    font-size: 13px;
    font-weight: 500;
    line-height: 150%;
    color: #111827;
  }

  span {
    font-size: 12px;
    line-height: 150%;
    color: #f0631d;
  }
}

.at-statistic__bar-item p {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  height: 25px;
  align-items: center;
  justify-content: center;
  color: white;
}

.at-statistic__bar-tooltip {
  opacity: 1 !important;
}

.at-statisic__loader-container {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: transparent;
}

.at-statisic__loader {
  top: 40%;
}

.at-card--stat__general-icon-cont {
  min-width: 50px;
  min-height: 50px;
  max-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 8px;

  svg {
    width: 32px;
    height: 32px;
    fill: white;
  }
}

.at-card--stat__doughnut-cont {
  width: 200px;
  margin-left: auto;

  @include max-screen(567px) {
    margin: 12px auto;
  }
}

.at-card--stat__general-category {
  margin-left: 12px;
  margin-bottom: 12px;

  .at-card--stat-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
  }

  .at-team-sales__stat-desc {
    font-size: 12px;
    font-size: normal;
    font-weight: 400;
  }

  p {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: #374151;
    margin-bottom: 2px;
  }

  span {
    color: #111827;
    font-size: 20px;
    font-weight: 600;
  }

  &:has(.at-card--stat-dot) {
    span {
      margin-left: 13px;
    }
  }
}

.at-newsfeed-video-player {
  width: 100% !important;
  height: 460px !important;
  margin: auto;
  margin-bottom: 10px;
  border-radius: 5px;
  overflow: hidden;

  @media screen and (max-width: 800px) {
    min-height: 250px;
    height: 58vw !important;
  }
}
