@import "src/stylesheets/atlas/prerequisite";

.at-order-mngmt__container {
  background-color: $mirage;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 20px;

  @include min-screen(768px) {
    padding: 20px;
    grid-gap: 20px;
  }
}

.at-order-mngmt-title {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.at-order-mngmt-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  h4 {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
  }
}

.at-order-mngmt__item {
  display: grid;
  color: white;
  min-height: 280px;
  gap: 15px;
  padding: 10px;
  background-color: $limed-spruce;
  margin-bottom: 10px;
  grid-template-rows: 22px 1fr;
  grid-template-columns: repeat(6, 1fr);

  @include max-screen(768px) {
    grid-template-rows: 22px 1fr;
    grid-template-columns: 1fr;
  }
}

.at-order-mngmt__image {
  @media (max-width: 768px) {
    justify-content: center !important;
    background-color: #ffffff;
  }
  display: flex !important;

  @media (min-width: 768px) {
    align-items: center !important;
  }
}

.at-order-mngmt__img-cont {
  margin: auto;
  width: 300px;
  height: 315px;
  border: 2px solid;
  border-radius: 3px;
  object-fit: cover;

  @media (max-width: 576px) {
    min-width: 100%;
  }
}

.at-order-mngmt__title-cont {
  grid-column: 1/-1;
  grid-row: 1;
  display: flex;
  justify-content: space-between;

  @include max-screen(768px) {
    grid-row: 1/2;
    grid-column: 1/2;
  }
}

.at-order-mngmt__details-cont {
  background-color: $atlas-white;
  grid-column: 1/-1;
  z-index: 2;

  p {
    font-size: 13px;
    font-weight: 500;
    @include max-screen(768px) {
      font-size: 15px;
    }
  }

  @include max-screen(768px) {
    grid-row: span 1;
    grid-column: 1/2;
    max-height: 100%;
  }
}

.at-order-mngmt-table-content {
  padding: 20px !important;

  table {
    display: grid;
  }

  td {
    padding-bottom: 2px;
    min-width: 8rem;

    @include max-screen(768px) {
      display: block;
    }

    &:first-child {
      min-width: 13rem;
    }
  }
}

.at-order-mngmt-list-content_title {
  padding-right: 10px;
  font-weight: 600;
}

.at-order_table {
  .at-table-row__item {
    @media (max-width: 767px) {
      margin: 1rem;
      width: calc(100% - 2rem) !important;
    }
  }
}

.subtotal-section-mobile {
  display: none;

  @include max-screen(990px) {
    display: block;
  }
}

.subtotal-section-desktop {
  display: block;

  @include max-screen(990px) {
    display: none;
  }
}
