.at-team-sales__stat-cont {
  display: inline-flex;
  width: 100%;
  gap: 8px;

  @media screen and (max-width: 720px) {
    display: flow-root;
  }
}

.at-team-sales__sales-column {
  text-align: right;

  p {
    padding-right: 20%;
  }
}

@media screen and (max-width: 560px) {
  .at-team-sales__group-sales {
    margin-left: 0px;
    margin-top: 10px;
  }

  .at-team-sales__sales-column {
    text-align: left;
  }
}

.at-team-sales__subsales-filter {
  color: grey;
  font-weight: 500;
  font-size: 12px;
}
