@mixin at-font($f-weight: normal) {
  @if $f-weight == light {
    font-weight: 300;
  } @else if $f-weight == regular {
    font-weight: 400;
  } @else if $f-weight == semibold {
    font-weight: 500;
  } @else if $f-weight == bold {
    font-weight: 600;
  } @else if $f-weight == extrabold {
    font-weight: 700;
  } @else {
    font-weight: $f-weight;
  }
}
