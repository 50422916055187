@import "../../atlas/prerequisite";

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.at-loader-portal {
  opacity: 0;
}

.at-loader-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: $zindex-loader;
  display: block;
  justify-content: center;
  align-items: center;
  transition: initial !important;
  opacity: 0;

  @include min-screen($sidebar-breakpoint) {
    width: 100vw;
    left: 0;
  }

  .menu-shrink & {
    width: 100vw;
    left: 0px;
  }
}

.at-loader {
  width: 150px;
  height: 150px;
  opacity: 1;
  margin: auto;
  position: relative;
  top: calc(50vh - 100px);
}

.at-loader-message {
  width: fit-content;
  position: relative;
  top: calc(50vh - 82px);
  margin: auto;
  color: #fff;
  background-color: #00000010;
  border-radius: 7px;
  padding: 2px 4px;
  box-shadow: 0px 1px 5px 3px #00000009;
  padding: 0 1rem;
}

.at-loader--dark {
  border: 10px solid $limed-spruce;
  border-top: 10px solid $tangerine;
}
