.payment-container {
  max-width: 54rem;

  .ipay-logo {
    width: 100%;
    @media screen and (min-width: 767px) {
      width: 50%;
    }
    @media screen and (min-width: 1266px) {
      width: 50%;
    }
    @media screen and (min-width: 1366px) {
      width: 30%;
    }
  }

  .ipay-frame {
    width: 100%;
    border: 3px solid #f26822;
    border-radius: 15px;
    padding: 15px;
    margin-top: -25px;
  }

  .display-inline-block {
    display: inline-block;
  }
}

.form-control-label {
  font-size: 15px !important;
}
