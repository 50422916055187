.at-profile__digital-canvas {
  width: 65vw;
  height: 65vh;
  @media (max-width: 768px) {
    width: 100vw !important;
    height: 80vh !important;
  }
  padding: 24px !important;
  background-color: #eaf3f9;
}

.at-digital-sign_modal {
  @media (max-width: 768px) {
    max-width: 100% !important;
    max-height: 100% !important;
    margin: 0px !important;
  }
}
