@import "../../../../stylesheets/atlas/prerequisite";

.name-card__container {
  h4 {
    color: #111827;
    font-size: 18px;
    font-weight: 600;
    line-height: 150%;
  }

  p {
    color: #1f2937;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0px;
  }
}

.at-coupon__title-cont {
  display: flex;
  align-items: center;
  position: relative;

  > div {
    z-index: 2;
  }
}

.at-redeemed-stamp {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-left: auto;

  @include max-screen(768px) {
    width: 80px;
    height: 80px;
    position: absolute;
    right: 0px;
    z-index: 1;
  }
}

.at-coupon__item {
  display: flex;
  color: white;
  gap: 12px;
  grid-template-rows: 40px 1fr;
  grid-template-columns: repeat(6, 1fr);

  @include max-screen(768px) {
    grid-template-rows: 40px 200px 1fr;
    grid-template-columns: 1fr;
  }
  @include max-screen(450px) {
    grid-template-rows: 60px 200px 1fr;
    grid-gap: 0.2rem;
  }
}

.at-coupon__details-cont {
  width: 100%;

  h4 {
    color: #111827;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 2px;
    line-height: 150%;
  }

  span {
    color: #6b7280;
    font-size: 12px;
  }

  label {
    color: #111827;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
  }

  p {
    color: #111827;
    font-size: 14px;
    margin-bottom: 2px;
    line-height: 150%;
  }

  @include max-screen(768px) {
    grid-row: span 1;
    grid-column: 1/2;
  }
}

.at-badge_title {
  font-size: 13px;
  font-weight: 500;
}

.at-ticket_card {
  padding: 20px;
  overflow: hidden;
  position: relative;
  border: 1px solid #d1d5db;

  &:first-child {
    border-bottom: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:last-child {
    border-top: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.at-ticket_divider {
  width: 100%;
  position: relative;
  height: 16px;
  width: calc(100% - 1rem);
  background-color: white;
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  &::after {
    content: "";
    position: absolute;
    height: 50%;
    width: calc(100% - 1rem);
    top: 0.1rem;
    left: 0.5rem;
    border-bottom: 1px dashed #d1d5db;
  }
}

.at-ticket_notch {
  position: absolute;
  width: 8px;
  left: -8px;
  overflow: hidden;

  &::after {
    content: "";
    position: relative;
    display: block;
    width: 16px;
    height: 16px;
    transform: translateX(-8px);
    border: 1px solid #d1d5db;
    border-radius: 50%;
  }
  &--right {
    left: auto;
    right: -8px;
    &::after {
      transform: translateX(0px);
    }
  }
}
