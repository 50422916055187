.at-dialog {
  height: calc(100% - 60px);
  width: calc(100% - 260px);
  left: initial !important;
  top: 60px !important;
  z-index: 4 !important;
  max-height: calc(
    100% - 60px - env(safe-area-inset-top) - env(safe-area-inset-bottom)
  );
  min-height: calc(
    100% - 65px - env(safe-area-inset-top) - env(safe-area-inset-bottom)
  );

  @media screen and (max-width: 990px) {
    width: 100% !important;
  }
}

.at-dialog-floating_panel {
  .at-dialog-paper > div.at-card {
    padding-bottom: 6.5rem !important;

    @media (max-width: 767px) {
      padding-bottom: 10rem !important;
    }
  }
}

.at-dialog-paper {
  border-radius: 4px !important;
}
