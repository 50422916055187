.at-tip__list {
  list-style-type: disc !important;
  margin-left: 15px;

  .at-tip__list-sub {
    list-style-type: disc;
    margin-left: 15px;

    > li {
      margin-bottom: 5px;
      font-size: 14px;
    }
  }

  > li {
    margin-bottom: 5px;
    font-size: 14px;
  }

  > li:last-child {
    margin-bottom: 0px;
  }
}

.at-tip__list-title {
  font-weight: 500;
  font-size: 18px;
  word-break: break-word;
}

.at-tip__button svg {
  color: white;
  background: #f18e05;
  border-radius: 50%;
  padding: 10px;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.at-share-modal {
  display: flex;
  min-width: 400px;
  min-height: 200px;

  @media screen and (max-width: 500px) {
    min-width: auto;
  }
}

.at-eprocess__error-message {
  margin-left: 10px;

  p {
    font-size: 14px;
    color: red;
    font-weight: 400;
  }
}

.at-eprocess-percentage-text {
  position: absolute; 
  color: #111827; 
  font-family: Inter, serif; 
  font-weight: 500;
}

.at-icon-amount_converter {
  width: 16px;
  height: 16px;
  transform: translate(-28px, 3px);
  position: absolute;
  cursor: pointer;
  overflow: visible !important;

  &.at-subsales-form_view {
    transform: translate(-0px, 3.5px);
  }
}