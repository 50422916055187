.at-renewal-details__status {
  font-weight: 500;
  display: flex;
  align-items: baseline;

  span {
    margin-left: 5px;
    font-size: 14px;
    padding: 3px 20px;
    border-radius: 25px;
  }
}

.at-renewal-details__icon-badge {
  width: 32px;
  min-width: 32px;
  height: 32px;
  padding: 8px;
  display: flex;
  border-radius: 50%;
}

.at-renewal-details__step-indicator {
  .at-step-idenitfier__progress-number {
    width: 36px;
    height: 36px;
    border: 2px solid;
    border-color: #9ca3af;
    background-color: white;

    svg {
      width: 20px;
      height: 20px;
      fill: #9ca3af;
    }
  }

  .at-step-identifier__selected {
    border-color: #3b82f6;

    svg {
      fill: white;
    }
  }

  .at-step-idenitfier__progress-div {
    width: calc(100% - 10vw);
    transform: translate(0px, 28px);

    @media screen and (min-width: 1400px) {
      width: calc(100% - 12vw);
    }
  }
}

.at-renewal-details__new-event-input {
  height: 50px;
  padding: 0px 10px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;

  &:focus {
    border-color: #0e75bc;
  }
}

.at-renewal-details__timeline {
  position: relative;
  margin-bottom: 15px;
}
