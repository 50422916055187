.at-storey-plan__close {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
}

.at-storey-plan__title {
  display: flex;
  align-items: center;
  p {
    font-size: 14px;
    color: grey;
  }
  p,
  h4 {
    font-weight: 500;
  }
}

.at-storey-plan__legend {
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
  p {
    font-size: 12px;
    font-weight: 600;
  }
}

.at-storey-plan__table-container {
  overflow-x: auto;
  margin: 10px 0px;
  th,
  .at-storey-plan__header {
    text-align: center;
    font-size: 10px;
    background-color: #38474f;
    color: #dfdfdf;
    font-weight: 600;
    padding: 5px 10px;
    border: 1px solid #d2d2d275;
  }
  td {
    min-width: 40px;
    text-align: center;
    display: table-cell;
    border: 1px solid #d2d2d275;
  }
}

.at-storey-plan__box-floor {
  background-color: #ffd0d3;
  width: 45px;
  font-weight: 600;
  height: 60px;
  display: flex;
  font-size: 10px;
  justify-content: center;
  align-items: center;
}

.at-storey-plan__box-button {
  height: 60px;
  width: 80px;
}

.at-storey-plan__box {
  width: 80px;
  min-width: 80px;
  p {
    font-size: 8px;
    font-weight: 500;
  }
}

.at-storey-plan__reserve-dialog {
  margin: auto;
  top: 0px !important;
  left: 0px !important;
  width: fit-content !important;
  height: fit-content !important;

  .at-btn {
    @media (max-width: 630px) {
      margin-right: 0;
      margin-top: 10px;
    }
  }
}

.at-storey-plan__content-container {
  height: 100%;

  @media (max-width: 630px) {
    width: 100vw;
  }
}

.at-storey-plan__timer-container {
  color: #8b8b8b;
  font-weight: 600;
  display: flex;
  padding: 5px;
  width: 200px;
  justify-content: center;
  margin-bottom: 20px;
  margin-left: auto;

  .timer {
    font-family: "Montserrat";
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .text {
    color: #aaa;
    font-size: 14px;
    width: 100px;
    text-align: center;
  }

  .value {
    font-size: 25px;
  }

  @media (max-width: 630px) {
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.at-storey-plan__fetch-text {
  position: fixed;
  bottom: 0;
  font-size: 12px;
  left: 0;
  width: 100vw;
  padding-left: 10px;
  background-color: white;
}

.at-storey-plan__confirmation-text {
  margin: 20px 10px;
  text-align: left;

  ul {
    list-style-type: inherit;
    padding-left: 20px;
    margin-top: 20px;
  }

  span {
    color: #2193f7;
    font-weight: 600;
  }
}

.at-storey-plan__progress-button {
  color: black;
  margin: 10px 25px;
  z-index: 1;

  .at-storey-plan__progress-number {
    border: 1px solid #d7d7d7;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    color: black;
    margin: auto;
    display: flex;
    background-color: #d7d7d7;
    justify-content: center;
    align-items: center;
  }

  p {
    font-weight: 500;
    width: 70px;
    text-align: center;
    font-size: 14px;
  }

  @media (max-width: 500px) {
    margin: 10px 10px;
  }
}

.at-storey-plan__progress-div {
  transform: translate(-5px, 25px);
  position: absolute;
}

.at-storey-plan__progress-cont {
  width: 375px;
  height: 2px;
  background-color: #d7d7d7;

  @media (max-width: 500px) {
    width: 250px;
  }
}

.at-storey-plan__progress-bar {
  height: inherit;
  background-color: #00b5ad;
  width: 100px;
  content: "";
}

.at-storey-plan__add-user-button {
  margin-left: auto;
  border-width: 2px;
  font-weight: 500;
  align-items: center;
  display: flex;

  svg {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }

  @media (max-width: 500px) {
    font-size: 0px;

    svg {
      margin-right: 0px;
    }
  }
}

.at-storey-plan__remove-user-button {
  margin: auto !important;
  color: #ff0000 !important;
  padding: 5px !important;
}

.at-storey-plan__buyer-container {
  background: white;
  box-shadow: none;
  margin-bottom: 10px;
}

@keyframes blink {
  0% {
    background-color: #b12323;
  }
  25% {
    background-color: #ffffff;
  }
  50% {
    background-color: #b12323;
  }
  75% {
    background-color: #ffffff;
  }
  100% {
    background-color: #b12323;
  }
}
