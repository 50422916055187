@import "src/stylesheets/atlas/prerequisite";

.at-notification__dropdown {
  overflow: hidden;
  background: white;
  position: absolute;
  top: 65px;
  right: 25px;
  z-index: 999;
  max-height: 82vh;
  border-radius: 8px;
  box-shadow:
    0px 6px 10px rgba(0, 0, 0, 0.08),
    0px 1px 18px rgba(16, 24, 40, 0.06),
    0px 3px 5px rgba(16, 24, 40, 0.1);

  @include min-screen(475px) {
    width: 400px;
    left: initial;
  }

  @media (max-width: 576px) {
    max-height: 85vh;
  }
}

.at-notification__dropdown-body {
  background-color: #ffffff;
  color: #1f2937;
  max-height: 60vh;
  overflow: auto;

  .info-read {
    background-color: #eff6ff;
  }
}

.at-notification__dropdown-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d1d5db;
  padding: 15px;

  h1 {
    font-weight: 600;
    font-size: 20px;
    color: #111827;
  }
}

.at-notification__dropbear__link {
  display: block;
  color: #2563eb;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

.at-notification__dropdown-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d1d5db;
  padding: 20px;
}

.at-notification__dropdown-info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.at-notification__dropdown-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  border-top: 1px solid #d1d5db;

  p {
    margin-top: 5px;
    font-size: 12px;
    color: #4b5563;
  }

  &:nth-child(1) {
    border-top: none;
  }
}

.at-notification__dropdown-title {
  font-size: 14px;
  font-weight: 500;
  color: #1f2937;
}

.at-notification__dropdown-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 35px;
  min-width: 35px;
  height: 35px;
  border-radius: 50%;
  margin: 10px;

  svg {
    width: 20px;
    height: 20px;
  }
}

.at-notification__body {
  background-color: #ffffff;
  color: #1f2937;
  background: #ffffff;
  box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.08),
    0px 2px 1px rgba(16, 24, 40, 0.06),
    0px 1px 3px rgba(16, 24, 40, 0.1);
  border-radius: 8px;

  .info-read {
    background-color: #eff6ff;
  }
}

.at-notification__empty-state {
  text-align: center;
  padding: 50px 0px;

  p {
    color: #1f2937;
    font-weight: 500;
    font-size: 14px;
  }
}
