@import "src/stylesheets/atlas/prerequisite";

.project-detail__upload-photo {
  height: 150px;
  width: 100%;
  border: 2px solid $atlas-black;
  cursor: pointer;
  transition: border-color 0.2s ease;

  svg {
    transition: fill 0.2s ease;
    fill: $atlas-black;
  }

  &:hover {
    border: 2px solid $atlas-primary;
    svg {
      fill: $atlas-primary;
    }
  }
}

.project-detail-image {
  cursor: pointer;
  border-radius: 10px;
  margin-top: 5px;
  box-shadow: 2px 2px 4px;
}

.project-detail__edit-image-item {
  position: relative;
  padding: 10px 10px;
  border-radius: 10px;

  > div > .at-btn--icon {
    display: none;
    position: absolute;
    top: 5px;
    right: 3px;
  }

  &:hover {
    > div > button {
      display: flex;
    }
  }

  img {
    object-fit: cover;
    height: 250px;
  }
}

.at-projects__card {
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 0 !important;

  @media (min-width: 576px) {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}

.project-detail__title {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.at-projects__details__description-tabs {
  overflow-y: hidden;

  @media screen and (max-width: 1500px) {
    padding-left: 0;
  }

  .at-tab__button {
    background-color: transparent;
    color: #374151;
    border-radius: 0;
    margin-right: 0;
    padding: 16px;
  }

  .at-tab__selected {
    background-color: transparent !important;
    color: #f0631d !important;
    border-bottom: 2px solid #f0631d;
  }
}

.at-projects__details__upload-photo {
  border-radius: 8px;
  border: 1px dashed #d1d5db;
  background-color: #f9fafc;
  cursor: pointer;

  &:hover {
    border-color: #3b82f6;
  }

  .at-empty-state {
    margin: 16px !important;
  }
}

.at-projects__input-label {
  font-weight: 500;
  margin-bottom: 6px;
  font-size: 14px;
}

.at-image-upload,
.at-projects__details__upload-photo {
  .at-empty-state {
    .at-empty-state__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      padding: 0;
      background-color: #dbeafe;

      svg {
        width: 24px;
        height: 24px;
        fill: #3b82f6;
      }
    }

    .title {
      font-size: 14px;
      color: #2563eb;
      margin-top: 12px;
    }

    .description {
      font-size: 14px;
      opacity: 0.9;
      margin-top: 4px;
      color: #374151;
    }
  }
}

.at-projects__update-project-tabs {
  overflow-y: hidden;

  .at-tab__button {
    background-color: #d1d5db;
    color: #374151;
    border-radius: 8px 8px 0px 0px;
    margin-right: 8px;
    padding: 10px 16px;
  }

  .at-tab__selected {
    background-color: #fed7aa !important;
    color: #f0631d !important;
    border-bottom: 2px solid #f0631d;
  }
}

.at-projects__details__image-item {
  position: relative;
  height: 230px;
  text-align: center;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid #d1d5db;

  button {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  img {
    object-fit: cover;
    height: 100%;
  }
}

.at-projects__details__visualization-list-item {
  display: grid;
  grid-template-areas:
    "a a"
    "b c";
  grid-template-columns: 1fr 45px;
  gap: 12px;
  margin-bottom: 16px;

  @media screen and (min-width: 768px) {
    grid-template-areas: "a b c";
    grid-template-columns: repeat(2, 1fr) 45px;
  }

  > div:first-child {
    grid-area: a;
  }

  > div:nth-child(2) {
    grid-area: b;
  }

  > div:last-child {
    grid-area: c;
  }
}

.at-projects__details__video-list-item {
  display: grid;
  grid-template-areas:
    "a a"
    "b b"
    "c d";
  grid-template-columns: 1fr 45px;
  gap: 12px;
  margin-bottom: 16px;

  @media screen and (min-width: 768px) {
    grid-template-areas: "a b c d";
    grid-template-columns: repeat(3, 1fr) 45px;
  }

  > div:first-child {
    grid-area: a;
  }

  > div:nth-child(2) {
    grid-area: b;
  }

  > div:nth-child(3) {
    grid-area: c;
  }

  > div:last-child {
    grid-area: d;
  }
}

.at-projects__edit {
  .at-table__options {
    .at-card__header {
      padding: 12px 16px;
      gap: 12px;
      border-radius: 8px;
      border: 1px solid #d1d5db;
      background-color: #fff;
      margin-bottom: 16px;

      .at-card__title {
        margin-right: 0;
      }

      > div {
        margin-left: 0 !important;
      }

      .extra-content {
        text-align: left;
        border-top: 1px solid #d1d5db;
        padding-top: 12px;
        margin-left: 0px;
        width: 100%;
        font-size: 12px;

        @media screen and (min-width: 768px) {
          text-align: right;
          border-top: none;
          padding-top: 0;
          margin-left: auto;
          width: auto;
        }
      }
    }
  }

  @media screen and (min-width: 600px) {
    .at-table__options {
      .at-card__header {
        .at-card__title {
          font-size: 18px;
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    .at-table__options {
      .at-card__header {
        margin-bottom: 10px;
      }
    }
  }
}
