$white: #ffffff;
$grey-1: #e0e0e0;
$grey-2: #c0c0c0;
$grey-3: #a0a0a0;
$grey-4: #808080;
$grey-5: #606060;
$grey-6: #404040;
$grey-7: #202020;
$grey-8: #f2f2f0;
$black: #000000;

//Grey
$mirage: #111827;
$limed-spruce: #38474f;

//Blue
$lochmara: #0075bc;
$picton-blue: #34ace0;
$jelly-bean: #227093;
$dodger-blue: #2d95f4;
$bright-turquoise: #19ddea;
$violet-1: #89abd9;
$violet-2: #a6c2e9;

//Green
$turquoise: #33d9b2;
$deco: #c0d98a;
$shamrock: #2ecc71;
$jungle-green: #63bf71;

//Orange
$tangerine: #f0631d;
$tangerine-dark-less: darken($tangerine, 10%);
$tangerine-dark: darken($tangerine, 15%);
$sunset-orange: #ff5252;
$yellow-orange: #ffb142;
$tomato-orange: #ef926f;
$tropical-peach: #f6875f;

//White
$link-water: #eaf3f9;
$botticelli: #ccdde8;

//Dark
$outer-space: #2c3a3a;
$black-coffee: #342e37;

//Red
$valencia: #d93335;
$alizarin-crimson: #eb3134;

//Yellow
$kournikova: #ffda79;
$sundance: #ccae62;
$sunset: #f4d576;
$wild-thing: #fad443;

//Purple
$east-bay: #474787;
$moody-blue: #706fd3;
$strikemaster: #9d5896;

//Design Generals
$atlas-primary: $tangerine;
$atlas-secondary: $lochmara;
$atlas-white: $link-water;
$atlas-black: $outer-space;
$atlas-grey: $limed-spruce;
$atlas-lightgrey: $grey-2;
$atlas-success: $shamrock;
$atlas-warning: $kournikova;
$atlas-danger: $valencia;
$atlas-purple: $moody-blue;

$color-map: (
  "primary": $atlas-primary,
  "secondary": $atlas-secondary,
  "at-white": $atlas-white,
  "white": $white,
  "black": $atlas-black,
  "grey": $atlas-grey,
  "lightgrey": $atlas-lightgrey,
  "success": $atlas-success,
  "warning": $atlas-warning,
  "danger": $atlas-danger,
  "purple": $atlas-purple,
);

$spacing-map: (
  "0": 0px,
  "5": 5px,
  "10": 10px,
  "15": 15px,
  "20": 20px,
  "30": 30px,
  "50": 50px,
  "100": 100px,
);

$dir-map: (
  "t": "top",
  "b": "bottom",
  "r": "right",
  "l": "left",
);

$border-radius-map: (
  "2": 2px,
  "5": 5px,
  "50": 50%,
);

$icon-size-map: (
  "1": 10px,
  "2": 15px,
  "3": 20px,
  "4": 25px,
  "5": 32px,
);

$font-size-map: (
  "1": 10px,
  "2": 14px,
  "3": 16px,
  "4": 18px,
  "5": 20px,
  "6": 22px,
  "7": 25px,
);

$font-weight-map: (300, 400, 500, 600);
//Sidebar color order

$sidebar-col-0: $grey-8;
$sidebar-col-1: $violet-1;
$sidebar-col-2: $sunset;
$sidebar-col-3: $tomato-orange;
$sidebar-col-4: $jungle-green;

$sidebar-col-0_hover: $white;
$sidebar-col-1_hover: $violet-2;
$sidebar-col-2_hover: $wild-thing;
$sidebar-col-3_hover: $tropical-peach;
$sidebar-col-4_hover: $deco;

$pers-sales-breakpoint: 767px;
$pers-sales-breakpoint-mobile: 800px;

$sidebar-breakpoint: 991px;
$recruit-strategy-bp: 800px;
$calendar-bp: 750px;
$navbar-height: 60px;
$sidebar-width: 260px;
$select-height: 45px;

$zindex-loading-overlay: 7;
$zindex-modal: 6;
$zindex-sidebar: 6;
$zindex-navbar: 5;
$zindex-loader: 1301;
$zindex-infobox-btn: 3;
