@import "src/stylesheets/atlas/prerequisite";

.paper {
  background-color: #f3f4f6 !important;
}

.btn-tooltip {
  @media (max-width: 600px) {
    display: none;
  }
}

.popper {
  opacity: 1 !important;
}

.tool-tip {
  font-size: 14px !important;
  max-width: 500px !important;
}

.snackbar-root {
  max-width: 100% !important;
}

.at-listing__container {
  padding: 10px;
  border-radius: 8px;
  max-width: 280rem;
  margin: 0.5rem -0.5rem 0;

  @include min-screen(768px) {
    padding: 0;
  }
  @media (max-width: 767px) {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.at-listing__partner-platform-btn {
  color: #f0631d;
  width: 32px;
  position: absolute;
  right: 30px;
  bottom: 50px;
  border-radius: 50%;
  background-color: white;
  transition: all 0.2s;
  z-index: 10;
}

.at-listing__info-autosuggest {
  height: 40px;
  margin-bottom: 10px;
  .react-autosuggest__suggestions-container--open {
    margin-top: 10px;
  }
}

.at-switch-button__checked {
  transform: translateX(50px) !important;
}

.at-switch-button__bar {
  height: 27px !important;
  top: 38% !important;
  left: 54% !important;
  width: 70px !important;
  border-radius: 2em !important;
}

.at-switch-button__icon {
  width: 30px !important;
  height: 30px !important;
}

.at-switch-button__active-icon {
  color: #f18e06 !important;
}

.at-switch-button__active-bar {
  background-color: #f18e06 !important;
}

.at-switch-button__active-label {
  left: 33px !important;
  color: #ffff !important;
}

.at-switch-button__inactive-label {
  right: -5px !important;
  color: rgba(255, 255, 255, 0.5) !important;
}

.at-subsales-listing__photo {
  height: 240px;
  display: flex;
  background-color: #1f2937;
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  svg {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    background: white;
    border-radius: 50%;
    padding: 5px;
  }

  img {
    margin: auto;
    object-fit: contain;
  }
}

.at-subsales__delete {
  text-align: left;

  > div {
    display: flex;
    align-items: center;
    margin-top: 8px;
    color: #1f2937;
    font-size: 14px;
  }

  svg {
    width: 20px;
    height: 20px;
    fill: #374151;
    margin-right: 8px;
    min-width: 20px;
  }
}

.at-listing__description-tab-cont {
  margin: 16px 0px;
  border-bottom: 1px solid #d1d5db;

  button {
    margin: 0px 8px 0px 0px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 500;
    color: #374151;
    transition:
      color 0.3s,
      border 0.3s;

    &.at-listing__description-selected-tab {
      color: #f0631d;
      border-bottom: 2px solid #f0631d;
    }
  }
}

.at-listing__generated_template-cont {
  display: flex;
  margin-bottom: 20px;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-left: 20px;
  margin-right: 20px;

  .at-listing__generated_template {
    padding: 12px;
    font-size: 12px;
    width: 300px;
    color: #1f2937;
    background-color: white;
    border: 2px solid #d1d5db;
    border-radius: 8px;
    height: 100%;
    margin: 0px 1px;
    white-space: pre-line;
    cursor: pointer;
  }

  .at-listing__selected-template {
    border: 2px solid #3b82f6;
  }
}

.at-listing__generated_template-cont::-webkit-scrollbar {
  display: none;
}

.at-listing__media-link {
  display: flex;
  font-size: 14px;
  padding: 12px 24px;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  margin-bottom: 5px;

  .at-listing__media-badge {
    padding: 2px 4px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
    color: white;
    margin-right: 5px;
  }

  label {
    font-weight: 500;
    color: #111827;
  }

  a {
    color: #2563eb;
  }
}

.at-subsales-photo__tab {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 8px;
  color: #374151;
  background-color: #d1d5db;
  border-radius: 8px 8px 0px 0px;
}

.at-listing-photo__tab {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 8px;
  color: #374151;
  background-color: #d1d5db;
  border-radius: 8px 8px 0px 0px;

  svg {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    fill: #6b7280;
  }
}

.at-listing-photo__tab-selected {
  color: #c2410c;
  background: #fed7aa;
  border-bottom: 2px solid #f0631d;

  svg {
    fill: #c2410c;
  }
}
