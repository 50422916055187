.at_form_header--cont {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;

  .form-header-icon svg {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    padding: 8px;
    align-items: self-start;
    gap: 8px;
    background-color: #ffedd5;
    fill: #f0631d;
  }

  p {
    margin-left: 2px;
    font-weight: 600;
    color: #111827;
    font-size: 18px;
    line-height: 130%;
  }
}
