@import "src/stylesheets/atlas/prerequisite";

.at-product__container {
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;
  border-radius: 8px;

  @include min-screen(768px) {
    padding: 0;
  }
  @include min-screen(1600px) {
    grid-template-columns: 1fr 1fr;
  }
}

.at-product__cart-item {
  display: flex;

  strong,
  p {
    color: black;
  }
  strong {
    font-size: 15px;
  }
  p {
    font-size: 12px;
  }
  img {
    width: 150px;
    padding: 10px;
  }
  .price-tag {
    color: orange;
    padding: 10px;
    margin-left: auto;
  }
}

.search-bar {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-bottom: 16px;

  @media screen and (min-width: 576px) {
    max-width: 400px;
  }
}

.at-my_cart-badge {
  display: inline-flex;
  min-width: 20px;
  min-height: 20px;
  margin-left: 0.3rem;
  background-color: rgb(255, 255, 255);
  color: rgb(241, 142, 5);
  border-radius: 50%;
  font-size: 0.7rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
}

.at-table_card {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  max-width: 100rem;

  @media (max-width: 771px) {
    display: flex;
    flex-wrap: wrap;
  }
}

.at-table_card-item {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  background-color: white;
  padding: 12px;
  gap: 8px;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  transition: all 0.3s ease-out;

  &:hover {
    background-color: darken(white, 5%);
  }

  @media screen and (max-width: 771px) {
    flex-direction: row;
  }
}

.at-table_card-image {
  width: 100%;
  height: 10rem;
  min-height: 10rem;
  object-fit: cover;
  border-radius: 8px;

  @media screen and (max-width: 771px) {
    width: 100px;
    height: 100px;
    min-height: 100px;
  }
}

.at-table_card-title,
.at-table_card-description,
.at-table_card-price {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 150%;
}

.at-table_card-details {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  min-height: 100px;
}

.at-table_card-title {
  color: #111827;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
}

.at-table_card-description {
  color: #374151;
  font-size: 13px;
  font-weight: 400;
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 8px;

  @media screen and (max-width: 771px) {
    -webkit-line-clamp: 2;
  }
}

.at-table_card-price {
  color: #c2410c;
  font-size: 13px;
  font-weight: 600;
  margin-top: auto;
}
