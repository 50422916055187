@import "src/stylesheets/atlas/prerequisite";

// General Classes
.at-profile__card__table {
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 12px !important;

  @include min-screen(1400px) {
    font-size: 14px;
  }

  p {
    @include min-screen($pers-sales-breakpoint) {
      font-size: inherit;
    }
  }
}

.at-profile__card-grid-controller {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
  }
  @media (min-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1150px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1400px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: 1800px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (min-width: 2200px) {
    grid-template-columns: repeat(6, 1fr);
    max-width: 125rem;
  }
}

.at-profile__card__table-cont {
  width: 100%;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  background-color: $white;
  flex-direction: column;

  border-radius: 10px;
  padding: 15px 15px 0px;
  margin: 0px auto;
  position: relative;
  border: 1px solid #d1d5db;

  @media (max-width: 767px) {
    max-width: 22rem;
    margin: 0;
  }
  @media (max-width: 575px) {
    max-width: none;
  }

  &:hover {
    transform: translateY(-2px);
  }

  & .at-profile__card__table-status {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 4px 8px;
    font-weight: 600;
    font-size: 10px;
    border-radius: 25px;
    border: 1px solid;
  }

  & .at-profile__card__table-like {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #1e3a8a;
    font-weight: 600;
    font-size: 14px;

    svg {
      width: 24px;
      height: 24px;
      background: #1d4ed8;
      border-radius: 50%;
      margin-right: 5px;
      padding: 6px;
      fill: white;
    }
  }
}

.at-profile__card__table-image {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.at-profile__card__table-row-cont {
  width: 100%;
  padding-top: 10px;

  @media (min-width: 1200px) {
    min-width: 225px;
  }
}

.at-profile__card__table-row {
  overflow: hidden;
  transition: height 0.3s ease;
  display: grid;
  grid-template-columns: 1fr;

  @media (max-width: 419px) {
    grid-template-columns: 1fr;
  }
}

.at-profile__card__table-row__item {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start !important;
  position: relative;
  word-break: break-word;
  min-width: 150px;

  p {
    font-size: 14px;
    color: #374151;
    text-align: center;
    margin-bottom: 5px !important;
    font-weight: 500;
  }

  &:first-of-type p {
    color: #111827;
    font-size: 16px;
  }

  &:nth-of-type(3) p {
    color: #6b7280;
  }

  &.with-icon {
    margin-top: 10px;

    & p {
      font-size: 14px;
      font-weight: 400;
      color: #1f2937;
      padding-left: 10px;
    }
  }

  & .at-profile__card__table-row__item-icon {
    border-radius: 50%;
    background-color: #ffedd5;
    min-width: 30px;
    min-height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    & .svg-icon-user-active-status {
      margin-top: -5px;
    }

    & svg {
      width: 18px;
      height: 18px;
      fill: #f0631d;
    }
  }

  & .at-profile__card__table-row__item-action {
    width: 30px;
    height: 30px;
    min-width: 30px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: 10px;
    border: 1px solid #d1d5db;
  }

  > p {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    margin: auto;

    @include min-screen($pers-sales-breakpoint) {
      white-space: normal;
    }
  }
}

.at-profile__card__table-radio {
  display: none;
}

.at-profile__card__table-label {
  width: calc(100% + 10px);
  min-height: calc(#{var(--profile__card__table-row-height)} + 10px);
  position: absolute;
  cursor: auto;
  z-index: 1;
  top: -10px;
  margin-bottom: 0px;
}

.at-profile__card__table__name {
  font-size: 25px;
  @include at-font(600);

  @include min-screen($pers-sales-breakpoint) {
    font-size: inherit;
    @include at-font(400);
  }
}

.at-profile__card__table__height-control {
  min-height: var(--profile__card__table-row-height);
  overflow: hidden;
  transition: 0.3s ease-out height;
  position: relative;

  @include min-screen($pers-sales-breakpoint) {
    min-height: auto;
    height: auto !important;
    overflow: initial;
  }
}

.at-profile__card__table-row__item-label {
  display: block;
  position: absolute;
  font-size: 12px;
  color: $grey-3;
  @include at-font(500);
  font-style: italic;
  top: 0px;
  left: 0px;
  padding-left: 5px;

  .grid-top-object & {
    color: $tangerine;
  }
}

.at-profile__card__table__select {
  width: 100%;
  margin-bottom: 10px;

  @include min-screen(600px) {
    width: 200px;
  }

  @include min-screen($pers-sales-breakpoint) {
    margin-bottom: 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.at-profile__card__table__select--sort {
  z-index: 2;
}
.at-profile__card__table__select--page-count {
  z-index: 3;
}
.at-profile__card__table__form-input {
  width: 100%;
  max-width: 410px;
}

.at-profile__card__table__options {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 16px;
}

.at-profile__card__table__actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > svg {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 5px;
    fill: $limed-spruce;
    transition: fill 0.2s ease;

    &:hover {
      fill: $tangerine;
    }

    &:active {
      fill: $tangerine-dark;
    }

    @include min-screen($pers-sales-breakpoint) {
      width: 25px;
      height: 25px;
    }
  }
}

.at-profile__card__table-head__arrow-container {
  display: inline-flex;
  flex-direction: column;
  background-color: $lochmara;
  position: absolute;
  border-radius: 50%;
  bottom: -10px;
  left: 10px;
  z-index: 2;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  @include box-shadow(1);
}

.at-profile__card__table-head__title {
  word-break: initial;
  min-width: 100%;
}

.at-profile__card__table-head__arrow {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid white;
  display: inline-block;
  opacity: 0.5;
  transition: opacity 0.2s ease;
  cursor: pointer;
}

.at-profile__card__table-head__arrow--up {
  transform: rotate(0deg);
  margin-bottom: 2px;
}

.at-profile__card__table-head__arrow--down {
  transform: rotate(180deg);
}

.at-profile__card__table__pagination-info {
  margin: 16px auto;
  font-size: 14px;
}

.grid-item-full {
  grid-column: 1/-1;
}

.grid-top-object {
  grid-row: 2;
  grid-column: 1/-1;

  @include min-screen($pers-sales-breakpoint-mobile) {
    grid-row: 1/2;
    grid-column: 2;
  }

  &::after {
    color: $tangerine;
  }
}

.svg-icon-info {
  width: 20px;
  height: 20px;

  .svg-icon-info--danger {
    color: $alizarin-crimson;
  }
}
