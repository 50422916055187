.at-product__ticket {
  display: block;
  padding-right: 10px;
  margin-top: 10px;
  border-radius: 5px;
  border: 2px solid #e0e0e0;
  background: white;

  p {
    display: grid;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: auto;

    span {
      font-size: 14px;
      font-weight: 500;
      color: grey;
    }
  }

  @media screen and (min-width: 576px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}
