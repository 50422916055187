.at-form_chart__panel {
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.at-form_charts-cont {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
}

.at-form_chart-stack_item {
  display: flex;
  grid-gap: 0.5rem;
  flex-direction: column;
}

.at-form_chart-progress_line_horizontal {
  position: relative;
  flex: 1 1 100%;

  &.at-form_chart-can_redirect_to_next {
    div {
      border-bottom: 2px solid #3c82f6;
    }
  }
  div {
    position: absolute;
    width: calc(100% + 12rem);
    top: 1rem;
    left: -5.9rem;
    border-bottom: 2px solid #d1d5db;
  }
}

.at-form_chart-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 0.5rem;
  position: relative;
  flex: 0 0 220px;
  text-align: center;

  a {
    color: #007bff !important;
    text-decoration: underline !important;
  }

  &.at-form_chart-can_redirect {
    .at-form_chart-icon {
      background-color: #3c82f6;

      svg {
        fill: #ffffff;
        color: #ffffff;
      }
    }
  }

  &:not(.at-form_chart-is_current_form_type):not(.at-form_chart-can_redirect) {
    .at-form_chart-icon {
      border: 2px solid #9ca3af;
      background-color: #ffffff;
    }
  }

  &.at-form_chart-is_current_form_type {
    .at-form_chart-icon {
      background-color: #f0631e;

      svg {
        fill: #ffffff;
        color: #ffffff;
      }
    }
  }
}

.at-form_chart-item_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 2px;
  position: relative;
  text-align: center;

  p {
    font-size: 14px;
  }
}

.at-form_chart-icon {
  padding: 0.5rem;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  z-index: 1;

  svg {
    fill: #9ca3af;
    color: #9ca3af;
  }
}

@media (max-width: 767px) {
  .at-form_charts-cont {
    flex-direction: column;
    grid-gap: 12px;
  }

  .at-form_chart-icon {
    width: 32px;
    height: 32px;
  }

  .at-form_chart-item_info {
    align-items: flex-start;
  }

  .at-form_chart-item {
    flex-direction: row;
    flex: initial;
    align-items: flex-start;

    &:not(:last-child):after {
      content: "";
      position: absolute;
      height: 85%;
      border-right: 2px solid #d1d5db;
      top: 2rem;
      left: 1rem;
    }
  }

  .at-form_chart-item_title {
    margin-top: 0.3rem;
  }

  .at-form_chart-progress_line_horizontal {
    display: none;
  }
}
