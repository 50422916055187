.at-contact_details_header__cont {
  padding: 16px;
  background-image: url("https://i.imgur.com/pydM0yr.png") !important;
  background-repeat: no-repeat !important;
  background-size: 100% auto !important;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  margin-bottom: 16px;

  @media (max-width: 768px) { 
    .flex-div {
      display: flex;
      justify-content: center;
    }
  }

  .form-header-icon {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

.at-contact_details__view-header-button_cont {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: auto;
  grid-area: bottom / bottom / bottom / bottom;
  flex: 0 1 auto;
  position: relative;
  grid-gap: 0.5rem;
  
  svg {
    width: 16px;
    min-width: 16px;
    height: 16px;
  }

  @media (min-width: 768px) { 
    flex: 0; 

    .flex-container {
      flex: 0;
      width: 32%; 
    }
  }
}

.at-contact_details__cont {
  padding: 20px 20px 0px 20px;
  background-image: #FFFFFF !important;
  background-size: 100% auto !important;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  margin-bottom: 16px;
}

.at-contact_details {
  display: flex;
  flex-direction: column;

  span {
    color: #1f2937;
    font-size: 14px;
    line-height: 150%;
    word-break: break-all;
  }

  label {
    color: #111827;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
  }
}

.at-status_type-badge {
  color: #C2410C;
  fill: #C2410C;
  background-color: #FFEDD5;
  border-color: #FDBA74;
}

.at-status_tag-badge {
  color: #1D4ED8;
  fill: #1D4ED8;
  background-color: #DBEAFE;
  border-color: #93C5FD;
}

.at-table_mobile-text {
  flex-shrink: 1;
  width: auto;
  
  @media screen and (min-width: 992px) {
    width: 100px;
  }
}

.at-contact_details__icon {
  color: #f0631d
}