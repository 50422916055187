@import "../atlas/prerequisite";

.header {
  position: absolute;
  width: 100%;
  z-index: 10;
  left: 0;
  top: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

/* .fade-leave-active in <2.1.8 */
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.at-content {
  background-color: #f3f4f6;
  height: calc(100vh - 62.5px);
  overflow: auto;
  transition: all 0.4s ease-in-out;

  > div {
    border-radius: 0px;
  }
}

.at-content-border {
  margin: 55px 40px !important;
}

.at-content-title {
  font-weight: 500;
  font-size: 18px;
  color: #111827;
}

.at-content-subtitle {
  font-size: 14px;
  color: #374151;
}

.at-content-japanese {
  .at-input_uploader-cont {
    label.at-input_upload-label::before {
      content: "クリックしてアップロード";
    }
  }
}

@media screen and (max-width: 992px) {
  .at-desktop-view__controller {
    display: none !important;
  }
}

@media screen and (min-width: 992px) {
  .at-mobile-view__controller {
    display: none !important;
  }
}
