.at-presentation__dropbear--list {
  font-size: 12px;

  button {
    padding: 10px;
    margin-top: 5px;
    text-align: center;
    display: block;
    width: 100%;
    text-align: left;

    &:hover {
      backdrop-filter: brightness(0.8);
    }
  }
}

.at-presentation__dropbear--modal-root {
  cursor: pointer;
  border-radius: 5px;
  background-color: white;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.45);
  position: absolute !important;
  min-width: 180px;
  top: 40px;
  left: 0px;
  z-index: 3;
}
